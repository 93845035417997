import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { notification, Popover, FloatButton, Button } from "antd";
import PipelineStageSteps from "../../components/PipelineStageSteps";
import { getCurrentRunApi } from "../../api/IntegrationsApis";
import { PipelineRun } from "../../types/Integrations";
import { getStatusMessage } from "../../utils/PipelineUtils";
import { DatasetVersion } from "../../types/Dataset";
import { HistoryOutlined, CloseOutlined } from "@ant-design/icons";

const DatasetVersionRunStatus = forwardRef(
  (
    props: {
      currentVersion?: DatasetVersion;
      refreshCallback?: (resetVersion?: boolean) => void;
      statusChangeCallback?: (isRunning: boolean) => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const [currentRun, setCurrentRun] = useState<PipelineRun>();
    const [currentRunStatusOpen, setCurrentRunStatusOpen] = useState(false);
    const [refresh, setRefresh] = useState(Math.random());
    const notificationRunRef: any = useRef();

    useImperativeHandle(ref, () => ({
      refreshCurrentRun: () => {
        setCurrentRunStatusOpen(true);
        setCurrentRun(undefined);
        setRefresh(Math.random());
      },
    }));

    useEffect(() => {
      let interval: any;
      const fetchCurrentRun = () => {
        const versionId = props.currentVersion?.id;
        if (!!versionId) {
          getCurrentRunApi({ versionId: Number(versionId) })
            .then((r) => {
              if (r.success) {
                const runResult: PipelineRun = r.data;
                props.statusChangeCallback &&
                  props.statusChangeCallback(
                    runResult.final_status !== "Succeeded" &&
                      runResult.final_status !== "Failed"
                  );
                if (
                  !!notificationRunRef.current &&
                  notificationRunRef.current?.id === runResult.id &&
                  notificationRunRef.current?.final_status !== "Succeeded" &&
                  notificationRunRef.current?.final_status !== "Failed" &&
                  (runResult.final_status === "Succeeded" ||
                    runResult.final_status === "Failed")
                ) {
                  //Run ends and notify
                  const finalStatus = runResult.final_status;
                  const lastMatchingStatus = [...runResult.status]
                    .reverse()
                    .find((status) => status.status === finalStatus);
                  const descriptionText =
                    finalStatus === "Succeeded"
                      ? ""
                      : getStatusMessage(lastMatchingStatus, i18n.language);
                  const descriptionJSX = descriptionText
                    .split("\n")
                    .map((line: string, index: number, array: string[]) =>
                      array.length - 1 === index
                        ? line
                        : React.createElement(
                            "span",
                            {},
                            line,
                            React.createElement("br")
                          )
                    );
                  notification[
                    finalStatus === "Succeeded" ? "success" : "error"
                  ]({
                    message: `${props.currentVersion?.name}-${
                      finalStatus === "Succeeded"
                        ? t("dataset.actions.succeeded_notification")
                        : t("dataset.actions.failed_notification")
                    }`,
                    description: React.createElement(
                      "div",
                      {},
                      ...descriptionJSX
                    ),
                    style: {
                      minWidth: "400px",
                      maxWidth: "800px",
                      wordWrap: "break-word",
                    },
                  });
                  // handle refresh callback after the job succeeded
                  if (
                    finalStatus === "Succeeded" &&
                    !!runResult?.pipeline?.manifest?.refresh_callback &&
                    !!props.refreshCallback
                  ) {
                    props.refreshCallback(true);
                  }
                }
                notificationRunRef.current = runResult;
                setCurrentRun(runResult);
              }
            })
            .catch((_) => {
              setCurrentRun(undefined);
            });
        }
      };
      fetchCurrentRun();
      interval = setInterval(fetchCurrentRun, 5000);
      return () => {
        clearInterval(interval);
      };
    }, [props.currentVersion, refresh, t, i18n]);

    if (!currentRun) {
      return null;
    }

    return (
      <Popover
        placement="leftBottom"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("dataset.action_status.title")}
            <Button
              type="text"
              size="small"
              icon={<CloseOutlined />}
              onClick={() => setCurrentRunStatusOpen(false)}
            />
          </div>
        }
        open={currentRunStatusOpen}
        content={
          <div
            style={{
              width: "wrap-content",
              maxHeight: "500px",
              overflow: "auto",
              padding: "10px",
              background: "white",
            }}
          >
            <PipelineStageSteps run={currentRun} />
          </div>
        }
      >
        <FloatButton
          icon={<HistoryOutlined />}
          onClick={() => {
            setCurrentRunStatusOpen(!currentRunStatusOpen);
          }}
        />
      </Popover>
    );
  }
);
export default DatasetVersionRunStatus;
