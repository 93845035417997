export function getConfig() {
  let ssoConfig: {
    isYiliSso: boolean;
    authUrl: string | null | undefined;
    authRealm: string | null | undefined;
    authYiliLogoutUrl: string | null | undefined;
    authIdpHint: string | null | undefined;
  } = {
    isYiliSso: false,
    authUrl: process.env.REACT_APP_TEST_AUTH_URL,
    authRealm: process.env.REACT_APP_AUTH_REALM,
    authYiliLogoutUrl: null,
    authIdpHint: null,
  }; //default dev

  if (
    [
      "https://odp.ain.digitalyili.com",
      "https://odp.ain-test.digitalyili.com",
    ].includes(window?.location?.origin)
  ) {
    const IsYiliUatSso = window?.location?.origin?.includes("ain-test");
    ssoConfig = {
      ...ssoConfig,
      isYiliSso: true,
      authUrl: process.env.REACT_APP_AUTH_URL,
      authRealm: IsYiliUatSso ? "yili-uat" : "yili",
      authYiliLogoutUrl: IsYiliUatSso
        ? "https://iam-uat.dctest.digitalyili.com/idp/profile/OAUTH2/Redirect/GLO?redirectToLogin=true&entityID=flowsit"
        : "https://iam.digitalyili.com/idp/profile/OAUTH2/Redirect/GLO?redirectToLogin=true&entityID=flowprod",
      authIdpHint: IsYiliUatSso ? "convect-yili-iam-uat" : "convect-yili-iam",
    };
  } else if (
    ["https://odp.convect.ai", "https://odp.convect.cn"].includes(
      window?.location?.origin
    )
  ) {
    ssoConfig = {
      ...ssoConfig,
      authUrl: process.env.REACT_APP_AUTH_URL,
    };
  }

  const enableMobileFormMode =
    process.env.REACT_APP_MOBILE_FORM_MODE_HOSTS?.split(",").includes(
      window?.location?.origin
    );

  return {
    gaodeKey: process.env.REACT_APP_GAODE_KEY,
    enableMobileFormMode: enableMobileFormMode,
    version: process.env.REACT_APP_VERSION,
    apiBaseUrl:
      (process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_BASE_URL || ""
        : "") + (process.env.REACT_APP_SERVER_API_URL || ""),
    IsYiliSso: ssoConfig.isYiliSso,
    authUrl: ssoConfig.authUrl!,
    authRealm: ssoConfig.authRealm || "",
    authClientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
    authYiliLogoutUrl: ssoConfig.authYiliLogoutUrl,
    authIdpHint: ssoConfig.authIdpHint,
  };
}
