import React, { useRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import { FilterFilled } from "@ant-design/icons";
import DefaultSheetOption from "../config/DefaultSheetOption";
import {
  SheetTable,
  TableFilters,
  TableView,
  TableField,
} from "../types/Table";
import { getTableDataApi } from "../api/TableApi";
import TableDataFilterModal from "./TableDataFilterModal";
import { filterData } from "../utils/SheetFilterUtils";

const Window: any = window;

type CellData = {
  r: number;
  c: number;
  v: any;
};

// type for luckysheet cell type
type CT = {
  fa: string;
  t: string;
};

const getLuckySheetDataType: (field: TableField) => CT = function (
  field: TableField
): CT {
  const fieldType = field.type?.toUpperCase();

  switch (fieldType) {
    case "NUMBER":
      return { fa: "General", t: "n" };
    case "DATE":
      return { fa: "@", t: "s" };
    case "BOOLEAN":
      return { fa: "General", t: "b" };
    case "TEXT":
      return { fa: "@", t: "s" };
    case "REFERENCE":
      return { fa: "General", t: "g" };
    default:
      return { fa: "General", t: "g" };
  }
};

const TableDataFilterSheet = forwardRef(
  (
    props: {
      id: string;
      submitCallback?: (data: TableFilters) => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const loadingRef: any = useRef();
    const apiControllerRef: any = useRef();
    const tableDataFilterModalRef: any = useRef();

    useImperativeHandle(ref, () => ({
      resetTable: (tableview: TableView) => {
        handleResetTable(tableview, true);
      },
      filter: (filters: TableFilters) => {
        handleResetTable({ filters: filters });
      },
    }));

    const handleResetTable = async (
      tableview?: TableView,
      forceRefresh?: boolean
    ) => {
      if (!!apiControllerRef.current) {
        apiControllerRef.current?.abort();
      }
      apiControllerRef.current = new AbortController();
      if (!!loadingRef.current) {
        loadingRef.current.style.display = "flex";
      }

      const sheet = Window.luckysheet.getSheet();
      let originTableData = null;
      if (!forceRefresh && sheet) {
        originTableData = sheet.originTableData;
      } else if (tableview?.parent_table) {
        let res = await getTableDataApi(
          {
            tableId: Number(tableview.parent_table),
          },
          { signal: apiControllerRef.current?.signal },
          false
        );
        if (res.success) {
          originTableData = {
            ...tableview.parent_table_data,
            data: res.data || [],
            columns: tableview.columns,
          };
        }
      }
      if (!!originTableData) {
        const options = {
          ...DefaultSheetOption,
          showtoolbarConfig: {
            ...DefaultSheetOption.showtoolbarConfig,
            textWrapMode: false,
            frozenMode: false,
            sortAndFilter: false,
          },
          cellRightClickConfig: {
            ...DefaultSheetOption.cellRightClickConfig,
            copy: false,
            insertRow: false,
            deleteRow: false,
          },
          lang: i18n.resolvedLanguage === "zh" ? "zh" : "en",
          container: props.id,
          gridKey: props.id,
          allowUpdate: true,
          allowEdit: true,
          data: [formatSheetData(originTableData, tableview?.filters)],
          hook: {
            workbookCreateAfter: async () => {
              Window.luckysheet.setRangeShow("A1", { show: false });
            },
          },
        };
        try {
          Window.luckysheet.destroy();
          Window.luckysheet.create(options);
        } catch {}
        if (!!loadingRef.current) {
          loadingRef.current.style.display = "none";
        }
      }
    };

    const handleOpenFilterModal = () => {
      const sheet = Window.luckysheet.getSheet();
      if (sheet) {
        tableDataFilterModalRef.current &&
          tableDataFilterModalRef.current.show(
            sheet?.filters || {},
            sheet?.originTableData?.meta?.fields || []
          );
      }
    };

    const calColumnlen = (str: string) => {
      return (str.match(/[\u4E00-\u9FA5]/g) || []).length * 8 + str.length * 8;
    };

    const formatSheetData = (
      table: SheetTable,
      filters: TableFilters | undefined | null
    ) => {
      const rowLength = table.data?.length;
      const tableFields = (table.meta?.fields || []).filter(
        (item: TableField) => table.columns?.includes(item?.identifier || "")
      );
      const columnLength = tableFields?.length;
      const tableData = filterData(
        table.data || [],
        filters,
        tableFields.map((field) => field.identifier || "")
      );
      let cellData: CellData[] = [];
      for (let i = 0, len = tableFields.length; i < len; i++) {
        cellData.push({
          r: 0,
          c: i,
          v: {
            v: tableFields[i]?.name,
            bl: 1,
            notEditable: true,
          },
        });
      }
      for (let r = 0, rlen = tableData.length; r < rlen; r++) {
        for (let c = 0, clen = tableFields.length; c < clen; c++) {
          let row = tableData[r] || {};
          let field = tableFields[c];
          if (
            row[field.identifier!] !== null &&
            row[field.identifier!] !== undefined
          ) {
            cellData.push({
              r: r + 1,
              c: c,
              v: {
                ct: getLuckySheetDataType(field),
                v: row[field.identifier!],
                notEditable: true,
              },
            });
          }
        }
      }
      let config: any = {};
      config.columnlen = tableFields.reduce(
        (ret: any, field: TableField, index: number) => {
          ret[`${index}`] = Math.max(120, calColumnlen(field?.name || ""));
          return ret;
        },
        {}
      );
      return {
        name: table.name,
        originTableData: table,
        filters: filters,
        column: columnLength,
        row: rowLength,
        celldata: cellData,
        config: config,
      };
    };

    const tableDataFilterModal = useMemo(() => {
      return (
        <TableDataFilterModal
          ref={tableDataFilterModalRef}
          submitCallback={(filters: TableFilters) => {
            const sheet = Window.luckysheet.getSheet();
            sheet.filters = filters;
            !!props.submitCallback && props.submitCallback(filters);
            tableDataFilterModalRef.current &&
              tableDataFilterModalRef.current.close();
          }}
        />
      );
    }, [props]);

    return (
      <>
        {tableDataFilterModal}
        <div
          style={{
            padding: "5px 10px",
            background: "#fafafc",
            border: "1px solid #d4d4d4",
            borderBottom: "none",
          }}
        >
          <Button
            icon={<FilterFilled />}
            type="text"
            size="small"
            onClick={handleOpenFilterModal}
          >
            {t("table_filter.filter")}
          </Button>
        </div>
        <div
          id={props.id}
          style={{
            position: "relative",
            width: "100%",
            minHeight: "calc(75vh - 136px)",
            height: "400px",
          }}
        ></div>
        <div
          ref={loadingRef}
          style={{
            background: "white",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      </>
    );
  }
);

export default TableDataFilterSheet;
