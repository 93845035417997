const IconBulb = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 17 18" fill="currentColor">
      <title>bulb</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-234.000000, -404.000000)"
          fillRule="nonzero"
        >
          <g id="bulb" transform="translate(234.000000, 404.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="16.2"
              height="18"
            ></rect>
            <path
              d="M11.109375,15.609375 L6.890625,15.609375 C6.81328125,15.609375 6.75,15.6726563 6.75,15.75 L6.75,16.3125 C6.75,16.6236328 7.00136719,16.875 7.3125,16.875 L10.6875,16.875 C10.9986328,16.875 11.25,16.6236328 11.25,16.3125 L11.25,15.75 C11.25,15.6726563 11.1867188,15.609375 11.109375,15.609375 Z M9,1.125 C5.81660156,1.125 3.234375,3.70722656 3.234375,6.890625 C3.234375,9.02460937 4.39453125,10.8878906 6.1171875,11.8845703 L6.1171875,13.921875 C6.1171875,14.2330078 6.36855469,14.484375 6.6796875,14.484375 L11.3203125,14.484375 C11.6314453,14.484375 11.8828125,14.2330078 11.8828125,13.921875 L11.8828125,11.8845703 C13.6054688,10.8878906 14.765625,9.02460938 14.765625,6.890625 C14.765625,3.70722656 12.1833984,1.125 9,1.125 Z M11.2482422,10.7894531 L10.6171875,11.1550781 L10.6171875,13.21875 L7.3828125,13.21875 L7.3828125,11.1550781 L6.75175781,10.7894531 C5.36835937,9.98964844 4.5,8.51660156 4.5,6.890625 C4.5,4.40507812 6.51445313,2.390625 9,2.390625 C11.4855469,2.390625 13.5,4.40507812 13.5,6.890625 C13.5,8.51660156 12.6316406,9.98964844 11.2482422,10.7894531 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconBulb;
