import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  Layout,
  Button,
  Typography,
  Dropdown,
  Drawer,
  Space,
  Image,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  DownOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import Network from "../../Network";
import AuthService from "../../../utils/AuthService";
import Logo from "../../../common/images/logo.png";
import NavMenu from "./NavMenu";
import { connect } from "react-redux";
import type { Workspace } from "../../../types/Workspace";
import type { User } from "../../../types/User";
import WorkspacesManagement from "../../WorkspacesManagement";
import viewUtils from "../../../utils/viewUtils";
import Language from "../../Language";
import Novu from "../../Novu";
import { getConfig } from "../../../config/config";
import UserAvatarImg from "../../../common/images/user-avatar.png";
import usePermission from "../../../utils/usePermission";
const config = getConfig();

const Header = (props: { currentWorkspace: Workspace; user: User }) => {
  const { t, i18n } = useTranslation();
  const [menuState, setMenuState] = useState(false);
  const handleMenuClose = () => setMenuState(false);
  const handleMenuTrigger = () => setMenuState(!menuState);
  const permission = usePermission();

  const superUserMenue: MenuProps["items"] = [
    {
      key: "system_manage",
      label: (
        <NavLink to="/system_manage" target="_blank" rel="noreferrer">
          {t("nav.system_manage")}
        </NavLink>
      ),
    },
    { type: "divider" },
  ];

  const userItems: MenuProps["items"] = [
    ...(!!permission.isSuperuser ? superUserMenue : []),
    {
      key: "official_website",
      label: (
        <a
          href={
            i18n.resolvedLanguage === "en"
              ? "https://convect.ai"
              : "https://convect.cn"
          }
          target="_blank"
          rel="noreferrer"
        >
          {t("nav.official_website")}
        </a>
      ),
    },
    { type: "divider" },
    {
      key: "change_language",
      label: <Language />,
    },
    { type: "divider" },
    {
      key: "logout",
      label: t("common.logout"),
    },
  ];

  return (
    <>
      <Drawer
        width={"90%"}
        onClose={handleMenuClose}
        placement={"left"}
        open={menuState}
        closable={false}
        className="manage-layout-drawer"
        styles={{
          body: {
            padding: 0,
            background: "linear-gradient(132deg, #2D52D2 0%, #6789F3 100%)",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <CloseOutlined
            onClick={handleMenuClose}
            style={{
              position: "absolute",
              top: 10,
              right: 0,
              padding: 10,
              fontSize: 18,
              color: "white",
            }}
          />
          <Space
            size={20}
            direction="vertical"
            style={{ padding: "20px 20px" }}
          >
            <Image
              src={Logo}
              preview={false}
              height={"30px"}
              width={"55.26px"}
            />
            <Typography.Text ellipsis={true} style={{ color: "white" }}>
              {props.currentWorkspace?.name}
            </Typography.Text>
          </Space>
          <div style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
            <NavMenu clickCallback={handleMenuClose} />
          </div>
          <div className="menu-version">
            {t("common.version")}: {config.version}
          </div>
        </div>
      </Drawer>
      <Layout.Header className="header-content">
        <div className="menu-content">
          <WorkspacesManagement />
          <div
            className="menu-right"
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 0,
            }}
          >
            <Novu />
            <Button size="large" type="text" className="header-button">
              <Network />
            </Button>
            <Dropdown
              placement="bottomRight"
              menu={{
                items: userItems,
                onClick: (e) => {
                  if (e?.key === "logout") {
                    AuthService.logout();
                  }
                },
              }}
              overlayStyle={{ minWidth: "14rem" }}
            >
              <div className="header-user-button header-button">
                <Image src={UserAvatarImg} preview={false} />
                <div className="vertical-hidden header-user-name">
                  <Typography.Text ellipsis={true}>
                    {viewUtils.prettifyUsername(props.user?.name)}
                  </Typography.Text>
                </div>
                <DownOutlined
                  className="vertical-hidden"
                  style={{ fontSize: "0.8em" }}
                />
              </div>
            </Dropdown>
            <div className="horizontal-hidden">
              <Button
                type="text"
                className="header-button menu-trigger"
                icon={
                  !!menuState ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />
                }
                size="large"
                onClick={handleMenuTrigger}
              />
            </div>
          </div>
        </div>
      </Layout.Header>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(Header);
