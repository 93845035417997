import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Input, Form, Typography } from "antd";

type Props = {
  callback?: (key: string) => void;
};

const MapAppKeyModal = forwardRef((props: Props, ref) => {
  const { t, i18n } = useTranslation();
  const [showItem, setShowItem] = useState<{
    map_type: "baidu" | "gaode";
    app_key?: string;
  } | null>();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show: (record: { map_type: "baidu" | "gaode"; app_key?: string }) => {
      setShowItem(record);
      form.setFieldValue("app_key", record?.app_key);
    },
  }));

  const handleCancel = () => {
    setShowItem(null);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    let res = form.getFieldsValue();
    props.callback && props.callback(res.app_key);
    handleCancel();
  };

  const GUIDE = {
    baidu: {
      web: "https://lbs.baidu.com/",
      backend: "https://lbs.baidu.com/apiconsole/key",
      guide: "https://lbs.baidu.com/index.php?title=jspopularGL/guide/getkey",
    },
    gaode: {
      web: "https://lbs.amap.com/",
      backend: "https://console.amap.com/dev/key/app",
      guide: "https://lbs.amap.com/api/javascript-api-v2/prerequisites",
    },
  };

  const GuideInfo = () => {
    if (!showItem || !["baidu", "gaode"].includes(showItem?.map_type)) {
      return null;
    }

    if (i18n.resolvedLanguage === "zh") {
      return (
        <>
          <div>
            <Typography.Text>
              1.前往
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].web}
              >
                {`${showItem?.map_type === "gaode" ? "高德" : "百度"}地图`}
              </Typography.Link>
              注册并登录
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              2.在
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].backend}
              >
                后台
              </Typography.Link>
              创建应用并添加Key
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].guide}
              >
                （官方教程）
              </Typography.Link>
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              3.在
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].backend}
              >
                后台
              </Typography.Link>
              获取Key输入到下方
            </Typography.Text>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <Typography.Text>
              1.Go to{" "}
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].web}
              >
                {`${showItem?.map_type === "gaode" ? "Gaode" : "Baidu"} Map`}
              </Typography.Link>{" "}
              to register and log in
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              2.Create an application in the{" "}
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].backend}
              >
                background
              </Typography.Link>{" "}
              and add Key
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].guide}
              >
                （Official Tutorial）
              </Typography.Link>
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>
              3.Get the key in the{" "}
              <Typography.Link
                target="_blank"
                href={GUIDE[showItem?.map_type].backend}
              >
                background
              </Typography.Link>{" "}
              and enter it below
            </Typography.Text>
          </div>
        </>
      );
    }
  };

  return (
    <Modal
      centered
      forceRender={true}
      title={t("chart_view.config.map_type.input_key")}
      open={!!showItem}
      onCancel={handleCancel}
      onOk={handleSubmit}
      cancelText={t("common.cancel")}
      okText={t("common.ok")}
    >
      <GuideInfo />
      <Form form={form} style={{ marginTop: "1em" }}>
        <Form.Item name="app_key" rules={[{ required: true, message: "" }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default MapAppKeyModal;
