import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Image,
  Tooltip,
  message,
  Typography,
} from "antd";
import {
  CheckOutlined,
  DownOutlined,
  EllipsisOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Role, Workspace } from "../types/Workspace";
import {
  setCurrentWorkspace,
  updateWorkspace,
  openWorkspaceModal,
} from "../store/account";
import { User } from "../types/User";
import { quitWorkspaceApi } from "../api";
import usePermission from "../utils/usePermission";
import WorkspaceIcon from "../common/images/icons/workspace-icon.png";

const WorkspacesManagement = (props: {
  user: User;
  workspaces: Workspace[];
  currentWorkspace: Workspace | null;
  setCurrentWorkspace: (data: any) => void;
  updateWorkspace: (data: any) => void;
  openWorkspaceModal: (data: any) => void;
}) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const [hoveredWorkspace, setHoveredWorkspace] = useState<Workspace>();

  useEffect(() => {
    if (props.workspaces?.length > 0) {
      if (
        !props.currentWorkspace ||
        !props.workspaces.find((w) => w.id === props.currentWorkspace?.id)
      ) {
        props.setCurrentWorkspace({ id: props.workspaces[0].id });
      }
    }
  }, [props]);

  const handleChangeWorkspace = (workspace: Workspace) => {
    props.setCurrentWorkspace({
      id: workspace.id,
      fail: () => {
        message.error(t("workspace.tips.change_fail"));
      },
    });
  };

  const handleMouseOver = (workspace: Workspace) => {
    setHoveredWorkspace(workspace);
  };

  const handleMouseOut = (event: any) => {
    if (
      event.relatedTarget instanceof Node &&
      event.currentTarget.contains(event.relatedTarget)
    ) {
      return;
    }
    setHoveredWorkspace(undefined);
  };

  const handleQuitWorkspace = (workspace: Workspace) => {
    Modal.confirm({
      title: t("workspace.actions.quit"),
      content: (
        <Trans
          i18nKey="workspace.tips.confirm_quit_content"
          values={{
            workspace: workspace.name,
          }}
          components={{
            p: <p />,
            b: <b style={{ fontWeight: "bold" }} />,
          }}
        />
      ),
      cancelText: t("common.cancel"),
      okText: t("workspace.actions.confirm_quit"),
      onOk: async () => {
        quitWorkspaceApi({ workspace_id: workspace.id }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("workspace.actions.quit"),
                status: t("common.success"),
              })
            );
            window.location.href = "/";
          } else {
            t("common.result_status", {
              title: t("workspace.actions.quit"),
              status: t("common.fail"),
            });
          }
        });
      },
    });
  };

  const CreateWorkspaceItems: MenuProps["items"] =
    !permission.enterpriseMode || !!permission.isSuperuser
      ? [
          {
            key: "create-workspace",
            label: (
              <>
                <div
                  style={{
                    height: "1px",
                    background: "rgba(5, 5, 5, 0.06)",
                    marginBottom: "12px",
                  }}
                />
                <div style={{ paddingBottom: "3px" }}>
                  + {t("workspace.actions.add")}
                </div>
              </>
            ),
            onClick: () => {
              props.openWorkspaceModal(true);
            },
            style: {
              position: "sticky",
              bottom: 0,
              background: "white",
            },
          },
        ]
      : [];

  const workspaceItems: MenuProps["items"] = [
    ...(props.workspaces || []).map((workspace: Workspace) => ({
      key: workspace.id,
      label: (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onMouseEnter={() => handleMouseOver(workspace)}
          onMouseLeave={(e) => handleMouseOut(e)}
        >
          <Image
            src={WorkspaceIcon}
            preview={false}
            style={{ marginRight: 12, width: 32, height: 32 }}
          />
          <div
            style={{ minWidth: 0, maxWidth: "15em", flex: 1 }}
            onClick={() => handleChangeWorkspace(workspace)}
          >
            <Typography.Title
              level={5}
              ellipsis={{ rows: 1 }}
              style={{ marginBottom: 5, lineHeight: 1 }}
            >
              {workspace.name}
            </Typography.Title>
            <div style={{ fontSize: 13, color: "#000000A6" }}>
              {workspace.memberships.find((item) => {
                return item.user.username === props.user?.username;
              })?.role === Role.OWNER
                ? t("workspace.owner")
                : ""}
            </div>
          </div>
          <div
            className="actions"
            style={{ marginLeft: "1em", position: "relative" }}
          >
            {workspace.id === props.currentWorkspace?.id && (
              <Button
                size="small"
                className="active"
                type="link"
                icon={<CheckOutlined style={{ color: "rgb(32,93,255)" }} />}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            )}
            <Dropdown
              overlayClassName={`minWidth0`}
              dropdownRender={() => (
                <Menu
                  items={[
                    workspace.memberships.find((item) => {
                      return item.user.username === props.user?.username;
                    })?.role === Role.OWNER
                      ? {
                          label: t("workspace.actions.edit"),
                          key: "edit",
                          onClick: () => props.openWorkspaceModal(workspace),
                        }
                      : null,
                    {
                      label: (
                        <Tooltip
                          title={
                            workspace.memberships.find((item) => {
                              return (
                                item.user.username === props.user?.username
                              );
                            })?.role === Role.OWNER
                              ? t("workspace.tips.cannot_quit")
                              : ""
                          }
                          key="leftButton"
                        >
                          {t("workspace.actions.quit")}
                        </Tooltip>
                      ),
                      key: "quit",
                      disabled:
                        workspace.memberships.find((item) => {
                          return item.user.username === props.user?.username;
                        })?.role === Role.OWNER,
                      onClick: () => handleQuitWorkspace(workspace),
                    },
                  ]}
                />
              )}
              placement="bottom"
            >
              <Button
                size="small"
                className={`${
                  workspace.id === props.currentWorkspace?.id
                    ? "actions-active"
                    : ""
                }`}
                type="text"
                icon={<EllipsisOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </Dropdown>
          </div>
        </div>
      ),
    })),
    ...CreateWorkspaceItems,
  ];

  return (
    <>
      <Dropdown
        className="header-button"
        placement="bottomLeft"
        menu={{
          items: workspaceItems,
        }}
        overlayClassName="workspace-manage"
      >
        <Button size="large" type="text" className="header-workspace-button">
          <InteractionOutlined
            className="horizontal-hidden"
            style={{ color: "rgba(0,0,0,0.88)", fontSize: "1.2em" }}
          />
          <span
            className="vertical-hidden"
            style={{
              marginLeft: 0,
              maxWidth: "14em",
              textOverflow: "ellipsis",
              wordBreak: "break-all",
              overflow: "hidden",
            }}
          >
            {props.currentWorkspace?.name}
          </span>
          <DownOutlined
            style={{ marginTop: "0.3em" }}
            className="vertical-hidden"
          />
        </Button>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
  workspaces: store.account.workspaces,
  currentWorkspace: store.account.currentWorkspace,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { setCurrentWorkspace, updateWorkspace, openWorkspaceModal },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspacesManagement);
