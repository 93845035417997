import { responseData, API } from "./BaseApi";
import type { Workspace } from "../types/Workspace";

//Workspace
export const getAllWorkspacesApi = () => {
  return API.get<responseData, responseData>(`workspace/all`);
};

export const getCurrentWorkspaceApi = () => {
  return API.get<responseData, responseData>(`workspace/activated/`);
};

export const getWorkspaceApi = (data: { workspaceId: number }) => {
  return API.get<responseData, responseData>(`workspace/${data.workspaceId}`);
};

export const getWorkspaceListApi = () => {
  return API.get<responseData, responseData>(`workspace/`);
};

export const setCurrentWorkspaceApi = (data: { workspace_id: number }) => {
  return API.put<responseData, responseData>(`workspace/activated/`, {
    workspace_id: data.workspace_id,
  });
};

export const createWorkspaceApi = (data: { name: string }) => {
  return API.post<responseData, responseData>(`workspace/`, {
    name: data.name,
  });
};

export const updateWorkspaceApi = (data: Workspace) => {
  return API.put<responseData, responseData>(`workspace/${data.id}`, data);
};

export const deleteWorkspaceApi = (data: { workspace_id: number }) => {
  return API.delete<responseData, responseData>(
    `workspace/${data.workspace_id}`
  );
};

export const quitWorkspaceApi = (data: { workspace_id: number }) => {
  return API.put<responseData, responseData>(
    `workspace/${data.workspace_id}/quit`
  );
};

export const downloadBatchAddUsersFileApi = (
  workspace_id: number,
  locale: string
) => {
  let queryParams = new URLSearchParams();
  queryParams.append("locale", locale);
  return API.get<responseData, responseData>(
    `workspace/${workspace_id}/file?${queryParams.toString()}`,
    {
      responseType: "blob",
    }
  );
};

export const uploadBatchAddUsersFileApi = (
  workspace_id: number,
  data: FormData
) => {
  return API.post<responseData, responseData>(
    `workspace/${workspace_id}/file`,
    data,
    { responseType: "blob", headers: { "Content-Type": "multipart/form-data" } }
  );
};

//user
export const getUserListApi = (data?: { query?: string; workspace?: any }) => {
  let queryParams = new URLSearchParams();

  if (data?.query) queryParams.append("q", data.query);
  if (data?.workspace)
    queryParams.append("workspace", data.workspace.toString());
  return API.get<responseData, responseData>(`user/?${queryParams.toString()}`);
};

export const getSystemUserListApi = (data?: {
  username?: string;
  workspace_id?: string;
  is_superuser?: boolean;
  date_joined_from?: string;
  date_joined_to?: string;
  page?: number;
  page_size?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data?.username) queryParams.append("username", data.username.toString());
  if (data?.workspace_id)
    queryParams.append("workspace_id", data.workspace_id.toString());
  if (typeof data?.is_superuser === "boolean")
    queryParams.append("is_superuser", `${data.is_superuser}`);
  if (data?.date_joined_from)
    queryParams.append("date_joined_from", data.date_joined_from);
  if (data?.date_joined_to)
    queryParams.append("date_joined_to", data.date_joined_to);
  if (data?.page) queryParams.append("page", data.page.toString());
  if (data?.page_size)
    queryParams.append("page_size", data.page_size.toString());
  return API.get<responseData, responseData>(
    `user/system/?${queryParams.toString()}`
  );
};

export const getMeApi = (data: { simple?: boolean }) => {
  return API.get<responseData, responseData>(
    `user/me/${data.simple ? "?mode=SIMPLE" : ""}`
  );
};

export const getHealthApi = () => {
  return API.get<responseData, responseData>(`user/me/health`);
};

export const updateUserApi = (data: {
  username: string;
  is_superuser: boolean;
}) => {
  return API.put<responseData, responseData>(`user/${data.username}/`, {
    is_superuser: data.is_superuser,
  });
};

//notification
export const getNotificationListApi = (data: {
  page?: number;
  page_size?: number;
  read?: boolean;
  category?: string;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.read !== undefined) queryParams.append("read", data.read.toString());
  if (data.category) queryParams.append("category", data.category);
  return API.get<responseData, responseData>(
    `notification/?${queryParams.toString()}`
  );
};

export const readNotificationApi = (data: { notification_id: number }) => {
  return API.patch<responseData, responseData>(
    `notification/${data.notification_id}/`,
    { read: true }
  );
};

//log
export const getHistoryLogListApi = (data: {
  page?: number;
  page_size?: number;
  dataset_id?: number;
  version_id?: number;
  action?: string;
  operator?: string;
  start_time?: string;
  end_time?: string;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.dataset_id)
    queryParams.append("dataset_id", data.dataset_id.toString());
  if (data.version_id)
    queryParams.append("version_id", data.version_id.toString());
  if (data.action) queryParams.append("action", data.action);
  if (data.operator) queryParams.append("operator", data.operator);
  if (data.start_time) queryParams.append("start_time", data.start_time);
  if (data.end_time) queryParams.append("end_time", data.end_time);
  return API.get<responseData, responseData>(
    `log/list/?${queryParams.toString()}`
  );
};

export const getTableLogListApi = (data: {
  page?: number;
  page_size?: number;
  table_id?: number;
  action?: string;
  operator?: string;
  start_time?: string;
  end_time?: string;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.table_id) queryParams.append("table_id", data.table_id.toString());
  if (data.action) queryParams.append("action", data.action);
  if (data.operator) queryParams.append("operator", data.operator);
  if (data.start_time) queryParams.append("start_time", data.start_time);
  if (data.end_time) queryParams.append("end_time", data.end_time);
  return API.get<responseData, responseData>(
    `log/table/list/?${queryParams.toString()}`
  );
};

export const saveLogApi = (data: {
  log: string;
  log_type: string;
  event_type: string;
}) => {
  return API.post<responseData, responseData>(`system/log/`, {
    log: data.log,
    log_type: data.log_type,
    event_type: data.event_type,
  });
};
