import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Button,
  Tooltip,
  Image,
} from "antd";
import type { SelectProps } from "antd";
import { ChartView } from "../../types/ChartView";
import { DatasetVersion } from "../../types/Dataset";
import {
  Table as TableType,
  SheetTable,
  TableFilters,
  TableField,
} from "../../types/Table";
import {
  getDatasetTablesApi,
  getDatasetVersionTableDataApi,
  createDatasetVersionChartViewApi,
  updateDatasetVersionChartViewApi,
} from "../../api/DatasetApi";
import ChartViewComponent from "../../components/chart";
import TableDataFilterModal from "../../components/TableDataFilterModal";
import { PlusCircleOutlined } from "@ant-design/icons";
import { HeatmapConfigForm } from "../../components/chart/Heatmap";
import { LocationRelationshipConfigForm } from "../../components/chart/LocationRelationship";
import { LineChartConfigForm } from "../../components/chart/LineChart";
import { ColumnChartConfigForm } from "../../components/chart/ColumnChart";
import { DonutChartConfigForm } from "../../components/chart/DonutChart";
import { BarChartConfigForm } from "../../components/chart/BarChart";
import { StackedColumnChartConfigForm } from "../../components/chart/StackedColumnChart";
import { StackedBarChartConfigForm } from "../../components/chart/StackedBarChart";
import { StackedLineChartConfigForm } from "../../components/chart/StackedLineChart";
import { PercentageColumnChartConfigForm } from "../../components/chart/PercentageColumnChart";
import { PercentageBarChartConfigForm } from "../../components/chart/PercentageBarChart";
import { PieChartConfigForm } from "../../components/chart/PieChart";
import GaodeHeatmapIcon from "../../common/images/icons/chart/gaode-heatmap-icon.png";
import GaodeLocationRelationshipIcon from "../../common/images/icons/chart/gaode-location-relationship-icon.png";
import PieChartIcon from "../../common/images/icons/chart/pie-chart-icon.png";
import DonutChartIcon from "../../common/images/icons/chart/donut-chart-icon.png";
import BarChartIcon from "../../common/images/icons/chart/bar-chart-icon.png";
import StackedBarChartIcon from "../../common/images/icons/chart/stacked-bar-chart-icon.png";
import PercentageBarChartIcon from "../../common/images/icons/chart/percentage-bar-chart-icon.png";
import ColumnChartIcon from "../../common/images/icons/chart/column-chart-icon.png";
import StackedColumnChartIcon from "../../common/images/icons/chart/stacked-column-chart-icon.png";
import PercentageColumnChartIcon from "../../common/images/icons/chart/percentage-column-chart-icon.png";
import LineChartIcon from "../../common/images/icons/chart/line-chart-icon.png";
import StackedLineChartIcon from "../../common/images/icons/chart/stacked-line-chart-icon.png";

const ChartTypeData = {
  COLUMN_CHART: {
    label: "chart_view.chart_type_enum.column_chart",
    value: "COLUMN_CHART",
    group: "chart_view.chart_type_enum.column_chart",
    icon: ColumnChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  STACKED_COLUMN_CHART: {
    label: "chart_view.chart_type_enum.stacked_column_chart",
    value: "STACKED_COLUMN_CHART",
    group: "chart_view.chart_type_enum.column_chart",
    icon: StackedColumnChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  PERCENTAGE_COLUMN_CHART: {
    label: "chart_view.chart_type_enum.percentage_column_chart",
    value: "PERCENTAGE_COLUMN_CHART",
    group: "chart_view.chart_type_enum.column_chart",
    icon: PercentageColumnChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  BAR_CHART: {
    label: "chart_view.chart_type_enum.bar_chart",
    value: "BAR_CHART",
    group: "chart_view.chart_type_enum.bar_chart",
    icon: BarChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  STACKED_BAR_CHART: {
    label: "chart_view.chart_type_enum.stacked_bar_chart",
    value: "STACKED_BAR_CHART",
    group: "chart_view.chart_type_enum.bar_chart",
    icon: StackedBarChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  PERCENTAGE_BAR_CHART: {
    label: "chart_view.chart_type_enum.percentage_bar_chart",
    value: "PERCENTAGE_BAR_CHART",
    group: "chart_view.chart_type_enum.bar_chart",
    icon: PercentageBarChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  PIE_CHART: {
    label: "chart_view.chart_type_enum.pie_chart",
    value: "PIE_CHART",
    group: "chart_view.chart_type_enum.pie_chart",
    icon: PieChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  DONUT_CHART: {
    label: "chart_view.chart_type_enum.donut_chart",
    value: "DONUT_CHART",
    group: "chart_view.chart_type_enum.pie_chart",
    icon: DonutChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  LINE_CHART: {
    label: "chart_view.chart_type_enum.line_chart",
    value: "LINE_CHART",
    group: "chart_view.chart_type_enum.line_chart",
    icon: LineChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  STACKED_LINE_CHART: {
    label: "chart_view.chart_type_enum.stacked_line_chart",
    value: "STACKED_LINE_CHART",
    group: "chart_view.chart_type_enum.line_chart",
    icon: StackedLineChartIcon,
    default_config: {
      value: null,
      dimension: null,
      count_by: "count_records",
      stacked_by: null,
      sort_by: "x",
      sort_rule: "ascending",
      text_size: 12,
      color: "theme_color_1",
    },
  },
  GAODE_LOCATION_RELATIONSHIP: {
    label: "chart_view.chart_type_enum.location_relationship",
    value: "GAODE_LOCATION_RELATIONSHIP",
    group: "chart_view.chart_type_enum.map",
    icon: GaodeLocationRelationshipIcon,
    default_config: {
      map_type: "d3",
      app_key: null,
      opacity: 1,
      show_tip: false,
      main_latitude: null,
      main_longitude: null,
      sub_latitude: null,
      sub_longitude: null,
      main_name: null,
      sub_name: null,
      main_size: 5,
      sub_size: 2,
      line_width: 1,
    },
    map_type_default_config: {
      d3: {
        app_key: null,
        opacity: 1,
        show_tip: false,
        main_size: 5,
        sub_size: 2,
        line_width: 1,
      },
      gaode: {
        app_key: null,
        opacity: 1,
        show_tip: false,
        main_size: 15,
        sub_size: 8,
        line_width: 1,
      },
      baidu: {
        app_key: null,
        opacity: 1,
        show_tip: false,
        main_size: 15,
        sub_size: 8,
        line_width: 1,
      },
    },
  },
  GAODE_HEATMAP: {
    label: "chart_view.chart_type_enum.heatmap",
    value: "GAODE_HEATMAP",
    group: "chart_view.chart_type_enum.heatmap",
    icon: GaodeHeatmapIcon,
    default_config: {
      map_type: "d3",
      app_key: null,
      opacity: 1,
      show_tip: true,
      unit: "px",
      radius: 20,
      difference: false,
      latitude: null,
      longitude: null,
      value: null,
    },
    map_type_default_config: {
      d3: {
        app_key: null,
      },
      gaode: {
        opacity: 1,
        show_tip: true,
        unit: "px",
        radius: 20,
        difference: false,
        app_key: null,
      },
      baidu: {
        unit: "px",
        radius: 20,
        app_key: null,
      },
    },
  },
};

const ChartConfigForm = (props: {
  chart_view?: ChartView;
  fields: TableField[];
  form: any;
  resetData?: any;
}) => {
  if (props.chart_view?.chart_type === "COLUMN_CHART") {
    return <ColumnChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "STACKED_COLUMN_CHART") {
    return <StackedColumnChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "PERCENTAGE_COLUMN_CHART") {
    return <PercentageColumnChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "BAR_CHART") {
    return <BarChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "STACKED_BAR_CHART") {
    return <StackedBarChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "PERCENTAGE_BAR_CHART") {
    return <PercentageBarChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "PIE_CHART") {
    return <PieChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "DONUT_CHART") {
    return <DonutChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "LINE_CHART") {
    return <LineChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "STACKED_LINE_CHART") {
    return <StackedLineChartConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "GAODE_HEATMAP") {
    return <HeatmapConfigForm {...props} />;
  }
  if (props.chart_view?.chart_type === "GAODE_LOCATION_RELATIONSHIP") {
    return <LocationRelationshipConfigForm {...props} />;
  }
  return null;
};

const DatasetChartFormModal = forwardRef(
  (
    props: {
      version?: DatasetVersion;
      callback?: () => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<ChartView | undefined>();
    const [tableList, setTableList] = useState<TableType[]>([]);
    const [form] = Form.useForm();
    const [currentTable, setCurrentTable] = useState<SheetTable | undefined>();
    const tableFilterModalRef: any = useRef();

    const tableDataFilterModal = useMemo(
      () => (
        <TableDataFilterModal
          ref={tableFilterModalRef}
          submitCallback={(filters: TableFilters) => {
            setShowItem((data) => ({
              ...data,
              filters: filters,
            }));
            tableFilterModalRef.current && tableFilterModalRef.current.close();
          }}
        />
      ),
      [setShowItem]
    );

    const ChartTypeOptions = Object.values(
      Object.values(ChartTypeData).reduce((ret: any, item: any) => {
        if (!ret[item.group]) {
          ret[item.group] = {
            label: t(item.group),
            options: [
              {
                label: (
                  <Tooltip placement="top" title={t(item.label)}>
                    <Image
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "cover",
                        border: "1px solid #f0f0f0",
                      }}
                      src={item.icon}
                      preview={false}
                    />
                  </Tooltip>
                ),
                value: item.value,
                title: t(item.label),
              },
            ],
          };
        } else {
          ret[item.group].options.push({
            label: (
              <Tooltip placement="top" title={t(item.label)}>
                <Image
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover", border: "1px solid #f0f0f0" }}
                  src={item.icon}
                  preview={false}
                />
              </Tooltip>
            ),
            value: item.value,
            title: t(item.label),
          });
        }
        return ret;
      }, {})
    ) as SelectProps[];

    useEffect(() => {
      if (!!props.version?.dataset) {
        getDatasetTablesApi({ datasetId: Number(props.version?.dataset) }).then(
          (res) => {
            if (res.success) {
              setTableList(res.data);
            }
          }
        );
      }
    }, [props.version]);

    useImperativeHandle(ref, () => ({
      show: (record: ChartView) => {
        const formatRecord = {
          ...record,
          version: record.version || props.version?.id,
        };
        setShowItem(formatRecord);
        form.resetFields();
        form.setFieldsValue(formatRecord);
      },
    }));

    useEffect(() => {
      const controller = new AbortController();
      if (
        (showItem?.version || props.version?.id) &&
        showItem &&
        showItem?.table &&
        showItem?.table !== currentTable?.id
      ) {
        if (!props.version?.id)
          getDatasetTablesApi({
            datasetId: Number(showItem.version_data?.dataset),
          }).then((res) => {
            if (res.success) {
              setTableList(res.data);
            }
          });

        getDatasetVersionTableDataApi(
          {
            versionId: Number(showItem?.version || props.version?.id),
            tableId: Number(showItem?.table),
          },
          { signal: controller.signal }
        )
          .then((res) => {
            if (res.success) {
              setCurrentTable(res.data);
            }
          })
          .catch((error) => console.log(error));
      }
      return () => {
        controller.abort();
      };
    }, [showItem, props.version, currentTable]);

    const handleManageFilters = () => {
      if (!currentTable) {
        message.error(t("chart_view.tip.table_blank"));
      } else {
        tableFilterModalRef.current &&
          tableFilterModalRef.current.show(
            showItem?.filters || {},
            currentTable?.meta?.fields
          );
      }
    };

    const handleValuesChange = (changedFields: any, allFields: any) => {
      if (Object.keys(changedFields).includes("name")) {
        return null;
      } else if (!!changedFields.table) {
        const formData = {
          ...allFields,
          chart_type: null,
          chart_config: {},
        };
        setShowItem(formData);
        form.setFieldsValue(formData);
      } else if (!!changedFields.chart_type) {
        let formData;
        const chartGroup = [
          "COLUMN_CHART",
          "STACKED_COLUMN_CHART",
          "PERCENTAGE_COLUMN_CHART",
          "BAR_CHART",
          "STACKED_BAR_CHART",
          "PERCENTAGE_BAR_CHART",
          "PIE_CHART",
          "DONUT_CHART",
          "LINE_CHART",
          "STACKED_LINE_CHART",
        ];

        if (
          chartGroup.includes(changedFields.chart_type) &&
          chartGroup.includes(showItem?.chart_type as any)
        ) {
          formData = {
            filters: showItem?.filters,
            ...allFields,
            chart_config: {
              ...showItem?.chart_config,
              ...allFields.chart_config,
            },
          };
        } else {
          formData = {
            filters: showItem?.filters,
            ...allFields,
            chart_config:
              (ChartTypeData as any)[allFields.chart_type]?.default_config ||
              {},
          };
        }
        setShowItem(formData);
        form.setFieldsValue(formData);
      } else if (!!changedFields?.chart_config?.map_type) {
        const formData = {
          filters: showItem?.filters,
          ...allFields,
          chart_config: {
            ...allFields?.chart_config,
            ...((ChartTypeData as any)[allFields.chart_type]
              ?.map_type_default_config[
              changedFields?.chart_config?.map_type
            ] || {}),
          },
        };
        setShowItem(formData);
        form.setFieldsValue(formData);
      } else {
        setShowItem({ filters: showItem?.filters, ...allFields });
      }
    };

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleSubmit = () => {
      form
        .validateFields()
        .then((data) => {
          if (data?.id) {
            updateDatasetVersionChartViewApi({
              chartId: data.id,
              data: {
                name: data.name,
                version: data.version,
                table: data.table,
                chart_type: data.chart_type,
                chart_config: data.chart_config,
                filters: showItem?.filters || {},
              },
            }).then((res) => {
              if (res.success) {
                message.success(
                  t("chart_view.tip.update", { status: t("common.success") })
                );
                handleCancel();
                props.callback && props.callback();
              } else {
                message.error(
                  t("chart_view.tip.update", { status: t("common.fail") })
                );
              }
            });
          } else {
            createDatasetVersionChartViewApi({
              name: data.name,
              version: data.version,
              table: data.table,
              chart_type: data.chart_type,
              chart_config: data.chart_config,
              filters: showItem?.filters || {},
            }).then((res) => {
              if (res.success) {
                message.success(
                  t("chart_view.tip.create", { status: t("common.success") })
                );
                handleCancel();
                props.callback && props.callback();
              } else {
                message.error(
                  t("chart_view.tip.create", { status: t("common.fail") })
                );
              }
            });
          }
        })
        .catch((error) => {
          if (error?.errorFields) {
            form.scrollToField(error?.errorFields[0].name[0]);
            if (error?.errorFields[0].name.includes("app_key")) {
              message.error(t("chart_view.config.map_type.input_key"));
            }
          }
        });
    };

    return (
      <>
        {tableDataFilterModal}
        <Modal
          width={1400}
          centered
          forceRender={true}
          title={t("chart_view.title")}
          open={!!showItem}
          onCancel={handleCancel}
          onOk={handleSubmit}
          cancelText={t("common.cancel")}
          okText={!!showItem?.id ? t("common.save") : t("common.create")}
          styles={{ body: { padding: 0 } }}
          style={{ zIndex: 1001 }}
        >
          <div
            style={{
              width: "100%",
              padding: "24px 0",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                flex: 3,
                minWidth: "min(calc(100% - 48px), 450px)",
                maxWidth: "100%",
                margin: "0 24px",
                height: "calc(100vh - 20em)",
              }}
            >
              {!!showItem && (
                <ChartViewComponent id="chart-modal" chart_view={showItem} />
              )}
            </div>
            <div
              style={{
                minWidth: "min(calc(100% - 48px), 350px)",
                maxWidth: "100%",
                margin: "0 24px",
                maxHeight: "calc(100vh - 20em)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Form
                layout="vertical"
                name="chart form"
                className="chart_view"
                form={form}
                labelAlign="left"
                onValuesChange={handleValuesChange}
              >
                <Form.Item hidden name="id">
                  <Input />
                </Form.Item>
                <Form.Item hidden name="version">
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("chart_view.name")}
                  name="name"
                  rules={[{ required: true, max: 20, message: "" }]}
                >
                  <Input showCount maxLength={20} />
                </Form.Item>
                <Form.Item
                  label={t("chart_view.select_dataframe")}
                  name="table"
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    options={(tableList || []).map((table) => ({
                      value: table.id,
                      label: table.name,
                    }))}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleManageFilters}
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                    type="link"
                    icon={<PlusCircleOutlined />}
                  >
                    {(showItem?.filters?.items || []).length > 0
                      ? t("table_filter.count_filters", {
                          count: showItem?.filters?.items?.length,
                        })
                      : t("table_filter.add_filter")}
                  </Button>
                </Form.Item>
                <Form.Item
                  label={t("chart_view.select_chart_type")}
                  name="chart_type"
                  rules={[{ required: true, message: "" }]}
                >
                  <Select
                    virtual={false}
                    style={{ width: "100%" }}
                    popupClassName="select-chart-type"
                    options={ChartTypeOptions}
                    optionLabelProp="title"
                  />
                </Form.Item>
                <ChartConfigForm
                  chart_view={showItem}
                  fields={currentTable?.meta?.fields || []}
                  form={form}
                  resetData={setShowItem}
                />
              </Form>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

export default DatasetChartFormModal;
