import { ChartView } from "./ChartView";

export type DashboardItem = {
  id?: number;
  dataset_version_chart_view?: ChartView;
  type?: DashboardItemType;
};

export enum DashboardItemType {
  CHART = "CHART",
}
