import { User } from "./User";

export enum Role {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  OWNER = "OWNER",
}

export const RoleText = {
  ADMIN: "管理员",
  MEMBER: "成员",
};

export type Invitation = {
  id: number;
  invitee_email: string;
  invitee_account: string;
  role: Role;
  delete_flag: boolean;
};

export type Membership = {
  id: number;
  user: User;
  operator?: User;
  role: Role;
  delete_flag: boolean;
  hover?: boolean;
  role_open?: boolean;
  type?: string;
  created_at?: string;
  workspace?: Workspace;
};

export type Workspace = {
  id: number;
  name: string;
  memberships: Membership[];
  invitations: Invitation[];
  created_at?: string;
  updated_at?: string;
  space_type?: string;
  owner?: User;
};
