import { DataRowValidation } from "../types/Table";
// import { Simulate } from "react-dom/test-utils";
// import error = Simulate.error;

export interface Config {
  [key: string]: string;
}

class JsonValidatorErrorTranslator {
  private static readonly typeTranslations: Config = {
    string: "字符串",
    number: "数字",
    boolean: "布尔值",
  };

  public static translate(error: string, cellValue: any): string {
    const mustBeUnique = error.match(/^(.*) must be unique/);
    if (mustBeUnique) {
      const fieldIdentifier = mustBeUnique[1];
      if (fieldIdentifier.includes("primary key")) {
        return `主键字段必须唯一`;
      }
      return `字段 ${fieldIdentifier} 必须唯一`;
    }

    // None error
    const noneMatch = error.match(/^None is not of type '([^']*)'/);
    if (noneMatch) {
      return `该值不能为空`;
    }

    // type error
    const typeMatch = error.match(/^(.*) is not of type '([^']*)'/);
    if (typeMatch) {
      const value = typeMatch[1];
      const type = typeMatch[2];
      const translatedType =
        JsonValidatorErrorTranslator.typeTranslations[type];
      if (translatedType) {
        return `${value} 类型不是 '${translatedType}'`;
      }
    }

    // nullable type error, can be merged with type error, but for now keep it separate
    const nullableTypeMatch = error.match(
      /^(.*) is not of type '([^']*)', 'null'/
    );
    if (nullableTypeMatch) {
      const value = nullableTypeMatch[1];
      const type = nullableTypeMatch[2];
      const translatedType =
        JsonValidatorErrorTranslator.typeTranslations[type];
      if (translatedType) {
        return `${value} 类型不是 '${translatedType}'`;
      }
    }

    // pattern error
    const patternMatch = error.match(/^(.*) does not match '([^']*)'/);
    if (patternMatch) {
      const notMatchDateFormat =
        error.match(
          /^(.*) does not match \u0027\^d\{4}\[-\/\]d\{2}\[-\/\]d\{2}\( d\{2}:d\{2}:d\{2}\)\?\$'$/
        ) ||
        error.match(
          /^(.*) does not match \u0027\^\\\\d\{4}\[-\/\]\\\\d\{2}\[-\/\]\\\\d\{2}\( \\\\d\{2}:\\\\d\{2}:\\\\d\{2}\)\?\$'$/
        );
      if (notMatchDateFormat) {
        const text = notMatchDateFormat[1];
        return `${text} 不是期望的日期格式`;
      }

      const value = patternMatch[1];
      const pattern = patternMatch[2];
      return `'${value}' 不符合 '${pattern}'`;
    }

    // regex pattern error
    const patternsMatch = error.match(
      /^(.*) (does|do) not match any of the regexes: (.*)'/
    );
    if (patternsMatch) {
      const value = patternsMatch[1];
      const patterns = patternsMatch[3];
      return `'${value}' 不匹配任何一个正则表达式：'${patterns}'`;
    }

    // items error
    // const itemsMatch = error.match(
    //   /^Expected at most (.*) items, but found (.*)/
    // );
    // if (patternsMatch) {
    //   const value1 = patternsMatch[1];
    //   const value2 = patternsMatch[2];
    //   return `期望最多 ${value1} 个元素，但是找到了 ${value2} 个`;
    // }

    const constMatch = error.match(/^(.*) was expected/);
    if (constMatch) {
      const value = constMatch[1];
      return `期望值为 '${value}'`;
    }

    const tooManyItemsMatch = error.match(
      /Too many items match the given schema \(expected at most (\d+)\)/
    );
    if (tooManyItemsMatch) {
      const maxContains = tooManyItemsMatch[1];
      return `匹配给定 schema 的项目太多（最多期望 ${maxContains} 个）`;
    }

    const noItemsMatch = error.match(
      /(.*) does not contain items matching the given schema/
    );
    if (noItemsMatch) {
      const instance = noItemsMatch[1];
      return `实例 ${instance} 不包含与给定 schema 匹配的项目`;
    }

    const tooFewItemsMatch = error.match(
      /Too few items match the given schema \(expected at least (\d+) but only (\d+) matched\)/
    );
    if (tooFewItemsMatch) {
      const minContains = tooFewItemsMatch[1];
      const matches = tooFewItemsMatch[2];
      return `匹配给定 schema 的项目太少（期望至少 ${minContains} 个，但仅匹配到 ${matches} 个）`;
    }

    const lessThanOrEqualToMinimum = error.match(
      /(.*) is less than or equal to the minimum of (.*)/
    );
    if (lessThanOrEqualToMinimum) {
      const instance = lessThanOrEqualToMinimum[1];
      const minimum = lessThanOrEqualToMinimum[2];
      return `实例 ${instance} 小于或等于最小值 ${minimum}`;
    }

    const greaterThanOrEqualToMaximum = error.match(
      /(.*) is greater than or equal to the maximum of (.*)/
    );
    if (greaterThanOrEqualToMaximum) {
      const instance = greaterThanOrEqualToMaximum[1];
      const maximum = greaterThanOrEqualToMaximum[2];
      return `实例 ${instance} 大于或等于最大值 ${maximum}`;
    }

    const lessThanMinimum = error.match(
      /(.*) is less than the minimum of (.*)/
    );
    if (lessThanMinimum) {
      const instance = lessThanMinimum[1];
      const minimum = lessThanMinimum[2];
      return `实例 ${instance} 小于最小值 ${minimum}`;
    }

    const greaterThanMaximum = error.match(
      /(.*) is greater than the maximum of (.*)/
    );
    if (greaterThanMaximum) {
      const instance = greaterThanMaximum[1];
      const maximum = greaterThanMaximum[2];
      return `实例 ${instance} 大于最大值 ${maximum}`;
    }

    const notMultipleOf = error.match(/(.*) is not a multiple of (.*)/);
    if (notMultipleOf) {
      const instance = notMultipleOf[1];
      const dB = notMultipleOf[2];
      return `实例 ${instance} 不是 ${dB} 的倍数`;
    }

    const tooShort = error.match(/(.*) is too short/);
    if (tooShort) {
      const instance = tooShort[1];
      return `实例 ${instance} 太短`;
    }

    const tooLong = error.match(/(.*) is too long/);
    if (tooLong) {
      const instance = tooLong[1];
      return `实例 ${instance} 太长`;
    }

    const nonUniqueElements = error.match(/(.*) has non-unique elements/);
    if (nonUniqueElements) {
      const instance = nonUniqueElements[1];
      return `实例 ${instance} 含有非唯一元素`;
    }

    const doesNotMatchPattern = error.match(/(.*) does not match (.*)/);
    if (doesNotMatchPattern) {
      const instance = doesNotMatchPattern[1];
      const pattern = doesNotMatchPattern[2];
      return `实例 ${instance} 不符合模式 ${pattern}`;
    }

    const stringTooShort = error.match(/(.*) is too short/);
    if (stringTooShort) {
      const instance = stringTooShort[1];
      return `实例 ${instance} 太短`;
    }

    const stringTooLong = error.match(/(.*) is too long/);
    if (stringTooLong) {
      const instance = stringTooLong[1];
      return `实例 ${instance} 太长`;
    }

    const dependencyError = error.match(/(.*) is a dependency of (.*)/);
    if (dependencyError) {
      const dependency = dependencyError[1];
      const property = dependencyError[2];
      return `实例属性 ${dependency} 是 ${property} 的依赖项`;
    }

    const notInEnum = error.match(/(.*) is not one of (.*)/);
    if (notInEnum) {
      // const instance = notInEnum[1];
      // const enums = notInEnum[2];
      return `实例 ${cellValue} 不在下拉框值中，请检查`;
    }

    const requiredProperty = error.match(/(.*) is a required property/);
    if (requiredProperty) {
      const property = requiredProperty[1];
      return `属性 ${property} 是必需的`;
    }

    const notEnoughProperties = error.match(
      /(.*) does not have enough properties/
    );
    if (notEnoughProperties) {
      const instance = notEnoughProperties[1];
      return `实例 ${instance} 没有足够的属性`;
    }

    const tooManyProperties = error.match(/(.*) has too many properties/);
    if (tooManyProperties) {
      const instance = tooManyProperties[1];
      return `实例 ${instance} 具有太多属性`;
    }

    const notValidAnyOf = error.match(
      /(.*) is not valid under any of the given schemas/
    );
    if (notValidAnyOf) {
      const instance = notValidAnyOf[1];
      return `实例 ${instance} 不符合任何给定的模式`;
    }

    const validUnderEachOneOf = error.match(/(.*) is valid under each of (.*)/);
    if (validUnderEachOneOf) {
      const instance = validUnderEachOneOf[1];
      const schemas = validUnderEachOneOf[2];
      return `实例 ${instance} 符合多个模式：${schemas}`;
    }

    const shouldNotBeValid = error.match(/(.*) should not be valid under (.*)/);
    if (shouldNotBeValid) {
      const instance = shouldNotBeValid[1];
      const notSchema = shouldNotBeValid[2];
      return `实例 ${instance} 不应符合模式 ${notSchema}`;
    }

    const unevaluatedItemsNotAllowed = error.match(
      /Unevaluated items are not allowed \((.*) (.*) unexpected\)/
    );
    if (unevaluatedItemsNotAllowed) {
      const itemCount = unevaluatedItemsNotAllowed[1];
      const items = unevaluatedItemsNotAllowed[2];
      return `不允许未评估的项目（${itemCount} 个 ${items} 未预期）`;
    }

    const unevaluatedPropertiesNotAllowed = error.match(
      /Unevaluated properties are not allowed \((.*) (.*) unexpected\)/
    );
    if (unevaluatedPropertiesNotAllowed) {
      const propertyCount = unevaluatedPropertiesNotAllowed[1];
      const properties = unevaluatedPropertiesNotAllowed[2];
      return `不允许未评估的属性（${propertyCount} 个 ${properties} 未预期）`;
    }

    const unevaluatedPropertiesInvalid = error.match(
      /Unevaluated properties are not valid under the given schema \((.*) (.*) unevaluated and invalid\)/
    );
    if (unevaluatedPropertiesInvalid) {
      const propertyCount = unevaluatedPropertiesInvalid[1];
      const properties = unevaluatedPropertiesInvalid[2];
      return `未评估的属性不符合给定的模式（${propertyCount} 个 ${properties} 未评估且无效）`;
    }

    // return error if not match any pattern
    return error;
  }
}

export function translateValidationError(input: string, value: any): string {
  // validation logic
  return JsonValidatorErrorTranslator.translate(input, value);
}

export function translateDataValidation(
  validation: DataRowValidation,
  value: any
): DataRowValidation {
  validation.message = translateValidationError(validation.message, value);
  return validation;
}
