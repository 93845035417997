// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { Map, ZoomControl } from "react-bmapgl";
import * as mapvgl from "mapvgl";
import * as d3 from "d3";
import BMapLoader from "../../BMapLoader";
import { useTranslation } from "react-i18next";
import { Form, Select, InputNumber, Collapse } from "antd";
import { ChartView } from "../../../types/ChartView";
const { Panel } = Collapse;

type Props = {
  id: string;
  chart_view: any;
  table_data: any;
};

const BaiduHeatmap = (props: Props) => {
  const [mapSdkLoading, setMapSdkLoading] = useState(false);
  const mapRef: any = useRef();

  useEffect(() => {
    let view = null;
    let heatmapLayer = null;
    if (
      !props.chart_view?.chart_config?.latitude ||
      !props.chart_view?.chart_config?.longitude ||
      !props.chart_view?.chart_config?.value ||
      !props.chart_view?.chart_config?.app_key ||
      !props.table_data ||
      !props.table_data?.filtered_data
    ) {
      return;
    }
    (async () => {
      BMapLoader({
        key: props.chart_view?.chart_config?.app_key,
      })
        .then(() => {
          setMapSdkLoading(true);
          if (!mapRef.current) {
            return;
          }
          view = new mapvgl.View({
            map: mapRef.current.map,
          });
          var geoData = [];
          for (
            let i = 0, len = props.table_data?.filtered_data?.length || 0;
            i < len;
            i++
          ) {
            let rowData = (props.table_data?.filtered_data || [])[i];
            geoData.push({
              geometry: {
                type: "Point",
                coordinates: [
                  rowData[props.chart_view?.chart_config?.longitude],
                  rowData[props.chart_view?.chart_config?.latitude],
                ],
              },
              properties: {
                count: rowData[props.chart_view?.chart_config?.value],
              },
            });
          }
          let maxValue = d3.max(geoData, (d) => d.properties.count);
          if (!!props.chart_view?.chart_config?.max) {
            maxValue = Math.min(maxValue, props.chart_view?.chart_config?.max);
          }
          heatmapLayer = new mapvgl.HeatmapLayer({
            size: props.chart_view?.chart_config?.radius,
            height: 0,
            unit: props.chart_view?.chart_config?.unit,
            min: props.chart_view?.chart_config?.min || 0,
            max: maxValue,
            gradient: {
              0.25: "rgba(0, 0, 255, 1)",
              0.55: "rgba(0, 255, 0, 1)",
              0.85: "rgba(255, 255, 0, 1)",
              1: "rgba(255, 0, 0, 1)",
            },
          });
          view.addLayer(heatmapLayer);
          heatmapLayer.setData(geoData);
        })
        .catch((e) => {
          setMapSdkLoading(false);
        });
    })();
    return () => {
      if (!!heatmapLayer && !!view) {
        view.removeLayer(heatmapLayer);
        heatmapLayer.destroy();
        view.destroy();
      }
    };
  }, [props, mapSdkLoading]);

  return (
    <>
      <div
        id={`baidu-heatmap-${props.id}`}
        style={{ width: "100%", height: "100%" }}
      >
        {mapSdkLoading && (
          <Map
            ref={mapRef}
            style={{ width: "100%", height: "100%" }}
            center={!!BMapGL && new BMapGL.Point(108.5525, 34.3227)}
            zoom={5}
          >
            <ZoomControl />
          </Map>
        )}
      </div>
    </>
  );
};

export default BaiduHeatmap;

export const BaiduHeatmapConfigForm = (props: {
  chart_view?: ChartView;
  fields: any;
  form: any;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.longitude")}
        name={["chart_config", "longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.latitude")}
        name={["chart_config", "latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.value")}
        name={["chart_config", "value"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{ padding: 0, background: "white" }}
      >
        <Panel
          header={t("chart_view.more_settings")}
          key="more_settings"
          forceRender
        >
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.unit")}
            name={["chart_config", "unit"]}
          >
            <Select
              options={[
                { label: t("chart_view.config.gaode_heatmap.px"), value: "px" },
                {
                  label: t("chart_view.config.gaode_heatmap.meter"),
                  value: "m",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.radius")}
            name={["chart_config", "radius"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.min")}
            name={["chart_config", "min"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.max")}
            name={["chart_config", "max"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Panel>
      </Collapse>
    </>
  );
};
