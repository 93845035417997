import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Spin } from "antd";

export const LoadingView = (props: { message?: string }) => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        background: "rgba(255,255,255,0.3)",
      }}
    >
      <Spin size="large" tip={props.message || ""} />
    </div>
  );
};

export const GlobalLoadingView = forwardRef(function GlobalLoadingView(
  props,
  ref
) {
  const [show, setShow] = useState<boolean | string>(false);

  useImperativeHandle(ref, () => ({
    show: (message: string | null | undefined) => {
      setShow(message ? message : true);
    },
    hide: () => {
      setShow(false);
    },
  }));

  if (!show) {
    return null;
  }
  return <LoadingView message={typeof show === "string" ? show : undefined} />;
});

class GlobalLoaderUtil {
  static instance: GlobalLoaderUtil | null = null;
  loader: any;

  constructor() {
    this.loader = null;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new GlobalLoaderUtil();
    }
    return this.instance;
  }

  setGlobalLoader(globalLoaderRef: any) {
    this.loader = globalLoaderRef;
  }

  show(message?: string | null | undefined) {
    if (this.loader) {
      this.loader.show(message);
    }
  }

  hide() {
    if (this.loader) {
      this.loader.hide();
    }
  }
}

const GlobalLoader = GlobalLoaderUtil.getInstance();
export default GlobalLoader;
