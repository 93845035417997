// @ts-nocheck
import React, { useEffect } from "react";
import AMapLoader from "../../AMapLoader";
import { useTranslation } from "react-i18next";
import { Form, Select, Slider, Switch, InputNumber, Collapse } from "antd";
import { ChartView } from "../../../types/ChartView";
const { Panel } = Collapse;

type Props = {
  id: string;
  chart_view: any;
  table_data: any;
};

const GaodeHeatmap = (props: Props) => {
  useEffect(() => {
    document.getElementById(`gaode-heatmap-${props.id}`).innerHTML = "";
    if (
      !props.chart_view?.chart_config?.latitude ||
      !props.chart_view?.chart_config?.longitude ||
      !props.chart_view?.chart_config?.value ||
      !props.chart_view?.chart_config?.app_key ||
      !props.table_data ||
      !props.table_data?.filtered_data
    ) {
      return;
    }
    (async () => {
      AMapLoader({
        key: props.chart_view?.chart_config?.app_key,
      })
        .then((AMap) => {
          const map = new AMap.Map(`gaode-heatmap-${props.id}`, {
            resizeEnable: true,
            zooms: [2, 22],
            zoom: 4.75,
            // center: [xxx, xxx],
            showLabel: true,
          });
          const loca = new Loca.Container({ map });
          const heatmap = new Loca.HeatMapLayer({
            zIndex: 10,
            visible: true,
            opacity: props.chart_view?.chart_config?.opacity || 0,
            zooms: [2, 22],
          });

          let geoData = [];
          for (
            let i = 0, len = props.table_data?.filtered_data?.length || 0;
            i < len;
            i++
          ) {
            let rowData = (props.table_data?.filtered_data || [])[i];
            geoData.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  rowData[props.chart_view?.chart_config?.longitude],
                  rowData[props.chart_view?.chart_config?.latitude],
                ],
              },
              properties: {
                value: rowData[props.chart_view?.chart_config?.value],
              },
            });
          }
          const geo = new Loca.GeoJSONSource({
            data: {
              type: "FeatureCollection",
              features: geoData,
            },
          });

          heatmap.setSource(geo, {
            radius: props.chart_view?.chart_config?.radius,
            unit: props.chart_view?.chart_config?.unit,
            difference: props.chart_view?.chart_config?.difference,
            value: function (index, feature) {
              return feature.properties.value;
            },
            min: props.chart_view?.chart_config?.min,
            max: props.chart_view?.chart_config?.max,
          });
          loca.add(heatmap);

          AMap.plugin(["AMap.ToolBar"], function () {
            map.addControl(new AMap.ToolBar());
          });

          if (props.chart_view?.chart_config?.show_tip) {
            map.on("mousemove", function (e) {
              var feat = heatmap.queryFeature(e.pixel.toArray());
              map.clearMap();
              if (feat) {
                map.add(
                  new AMap.Marker({
                    position: feat.lnglat,
                    anchor: "bottom-center",
                    offset: new AMap.Pixel(0, -10),
                    content:
                      '<div style="background:rgba(0,0,0,0.5);border-radius: 4px;color: #fff; padding: 3px 10px;">' +
                      feat.value.toFixed(2) +
                      "</div>",
                  })
                );
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, [props]);

  return (
    <>
      <div
        id={`gaode-heatmap-${props.id}`}
        style={{ width: "100%", height: "100%" }}
      ></div>
    </>
  );
};

export default GaodeHeatmap;

export const GaodeHeatmapConfigForm = (props: {
  chart_view?: ChartView;
  fields: any;
  form: any;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.longitude")}
        name={["chart_config", "longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.latitude")}
        name={["chart_config", "latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.value")}
        name={["chart_config", "value"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{ padding: 0, background: "white" }}
      >
        <Panel
          header={t("chart_view.more_settings")}
          key="more_settings"
          forceRender
        >
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.unit")}
            name={["chart_config", "unit"]}
          >
            <Select
              options={[
                { label: t("chart_view.config.gaode_heatmap.px"), value: "px" },
                {
                  label: t("chart_view.config.gaode_heatmap.meter"),
                  value: "meter",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.radius")}
            name={["chart_config", "radius"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.min")}
            name={["chart_config", "min"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.max")}
            name={["chart_config", "max"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.opacity")}
            name={["chart_config", "opacity"]}
          >
            <Slider min={0} max={1} step={0.1} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.show_tip")}
            name={["chart_config", "show_tip"]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.difference")}
            name={["chart_config", "difference"]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Panel>
      </Collapse>
    </>
  );
};
