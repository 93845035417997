import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dataset, DatasetItem } from "../../types/Dataset";
import { v4 as uuidv4 } from "uuid";
import {
  Table as TableType,
  TableField,
  TableType as TableTypeEnum,
  TableView,
} from "../../types/Table";
import {
  checkDatasetWorkspacePermissionApi,
  createDatasetApi,
  createDatasetTableApi,
  createDatasetTableViewApi,
  deleteDatasetTableApi,
  deleteDatasetTableViewApi,
  getDatasetApi,
  getDatasetTablesApi,
  getDatasetTableViewsApi,
  updateDatasetApi,
  updateDatasetItemsIndexApi,
  updateDatasetTableApi,
  updateDatasetTableViewApi,
} from "../../api/DatasetApi";
import type { MenuProps } from "antd";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Typography,
  Popconfirm,
} from "antd";
import TableInfoDrawer from "../../components/TableInfoDrawer";
import TableViewFormModal from "../../components/TableViewFormModal";
import { getTableListApi } from "../../api/TableApi";
import viewUtils from "../../utils/viewUtils";
import usePermission from "../../utils/usePermission";
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
} from "react-sortable-hoc";
import { showWorkspacePermissionModal } from "../../components/WorkspacePermissionModal";

const DatasetInfoPage = () => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");

  const permission = usePermission();
  const navigate = useNavigate();
  const { dataset_id } = useParams();
  const location = useLocation();
  const [dataset, setDataset] = useState<Dataset | undefined | null>();
  const IsCreate = location.pathname.includes("create");
  const [form] = Form.useForm();
  const tableInforDrawerRef: any = useRef();
  const tableViewModalRef: any = useRef();
  const [referencedTables, setReferencedTables] = useState<TableType[]>([]);

  const refreshInfo = useCallback(
    (refreshPrimaryTable = false) => {
      if (dataset_id) {
        let dataset: Dataset;
        let datasetItems: DatasetItem[] = [];
        getDatasetApi({ datasetId: Number(dataset_id) })
          .then((res) => {
            if (res.success) {
              dataset = res.data;
              return (dataset.tables || []).length > 0
                ? getDatasetTablesApi({ datasetId: Number(dataset_id) })
                : null;
            } else {
              if (res.code === 404) {
                checkDatasetWorkspacePermissionApi({
                  datasetId: Number(dataset_id),
                }).then((res) => {
                  if (res.success) {
                    showWorkspacePermissionModal({
                      hasPermission: res.data.has_permission,
                      targetWorkspaceId: res.data.workspace_id,
                    });
                  }
                });
              }
              setDataset(null);
            }
          })
          .then((res) => {
            if (res?.status) {
              res.data.forEach((table: TableType) => {
                let datasetitem = (dataset?.items || []).find(
                  (item: DatasetItem) =>
                    item.content_type === "table" && item.object_id === table.id
                );
                datasetItems.push({
                  ...datasetitem,
                  content_type: "table",
                  object_id: table.id,
                  temp_id: uuidv4(),
                  object: {
                    ...table,
                    id: IsCreate ? undefined : table.id,
                    meta: {
                      ...table?.meta,
                      fields: IsCreate
                        ? table?.meta?.fields?.map((field: TableField) => ({
                            ...field,
                            id: undefined,
                          }))
                        : table?.meta?.fields,
                    },
                  },
                });
              });
            }
            return (dataset.table_views || []).length > 0
              ? getDatasetTableViewsApi({ datasetId: Number(dataset_id) })
              : null;
          })
          .then((res) => {
            if (res?.status) {
              res.data.forEach((table: TableView) => {
                let datasetitem = (dataset?.items || []).find(
                  (item: DatasetItem) =>
                    item.content_type === "tableview" &&
                    item.object_id === table.id
                );
                datasetItems.push({
                  ...datasetitem,
                  content_type: "tableview",
                  object_id: table.id,
                  temp_id: uuidv4(),
                  object: {
                    ...table,
                    id: IsCreate ? undefined : table.id,
                  },
                });
              });
            }
            datasetItems = datasetItems
              .sort(
                (a, b) =>
                  (a.index !== null && a.index !== undefined
                    ? a.index
                    : 99999) -
                  (b.index !== null && b.index !== undefined ? b.index : 99999)
              )
              .map((item: DatasetItem, index: number) => ({
                ...item,
                index: index,
              }));
            dataset.name = IsCreate ? "" : dataset.name;
            dataset.description = IsCreate ? "" : dataset.description;
            dataset.items = datasetItems;
            setDataset(dataset);
            form.resetFields();
            form.setFieldsValue(dataset);
          });
      } else {
        setDataset({});
      }
      if (!!refreshPrimaryTable) {
        getTableListApi({
          tableType: "PRIMARY",
          page_size: 1000,
        }).then((res) => {
          if (res.success) {
            setReferencedTables(res.data);
          }
        });
      }
    },
    // eslint-disable-next-line
    [dataset_id, IsCreate]
  );

  useEffect(() => {
    if (!!permission.username) {
      refreshInfo(true);
    }
  }, [permission, refreshInfo]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDatasetItemEdit = (item: DatasetItem) => {
    if (item.content_type === "table") {
      tableInforDrawerRef.current &&
        tableInforDrawerRef.current.show(
          {
            ...item?.object,
            table_type: TableTypeEnum.COMMON,
            temp_id: item?.temp_id,
          },
          (form.getFieldValue("items") || [])
            .filter((item: DatasetItem) => item.content_type === "table")
            .map((item: DatasetItem) => ({
              ...item?.object,
              temp_id: item?.temp_id,
            }))
        );
    } else {
      tableViewModalRef.current &&
        tableViewModalRef.current.show({
          ...item?.object,
          temp_id: item?.temp_id,
        });
    }
  };

  const handleDatasetItemCopy = (item: DatasetItem) => {
    if (item.content_type === "table") {
      handleDatasetItemEdit({
        ...item,
        temp_id: undefined,
        object: {
          ...item.object,
          id: undefined,
          name: undefined,
          description: undefined,
          identifier: undefined,
          table_type: TableTypeEnum.COMMON,
          ...(item?.object?.meta?.fields
            ? {
                meta: {
                  ...item?.object?.meta,
                  fields: item?.object?.meta?.fields?.map(
                    (field: TableField) => ({
                      ...field,
                      id: undefined,
                    })
                  ),
                },
              }
            : {}),
        },
      });
    }
  };

  const handleDatasetItemDelete = (item: DatasetItem) => {
    if (IsCreate) {
      const OriginItems = form.getFieldValue("items") || [];
      form.setFieldValue(
        "items",
        OriginItems.filter(
          (dItem: DatasetItem) => dItem.temp_id !== item.temp_id
        ).map((dItem: DatasetItem, index: number) => ({
          ...dItem,
          index: index,
        }))
      );
    } else {
      if (item.content_type === "tableview") {
        //delete existed dataset table
        deleteDatasetTableViewApi({
          datasetId: Number(dataset?.id),
          tableViewId: Number(item?.object_id),
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.success"),
              })
            );
            refreshInfo();
          } else {
            message.error(
              viewUtils.prettifyErrorMessage(res.message) ||
                t("common.result_status", {
                  title: t("common.delete"),
                  status: t("common.fail"),
                })
            );
          }
        });
      } else {
        //delete existed dataset table
        deleteDatasetTableApi({
          datasetId: Number(dataset?.id),
          tableId: Number(item?.object_id),
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.success"),
              })
            );
            refreshInfo();
          } else {
            message.error(
              viewUtils.prettifyErrorMessage(res.message) ||
                t("common.result_status", {
                  title: t("common.delete"),
                  status: t("common.fail"),
                })
            );
          }
        });
      }
    }
  };

  const handleSubmit = (data: {
    id?: number;
    name: string;
    description: string;
    items?: DatasetItem[];
  }) => {
    if (IsCreate) {
      createDatasetApi({
        dataset: {
          name: data.name,
          description: data.description,
        },
        tables: (data.items || [])
          .filter((item: DatasetItem) => item.content_type === "table")
          .map((item: DatasetItem) => ({
            ...item.object,
            fields: !item?.object?.fields
              ? item.object?.meta?.fields
              : item.object.fields,
            index: item.index,
          })),
        table_views: (data.items || [])
          .filter((item: DatasetItem) => item.content_type === "tableview")
          .map((item: DatasetItem) => ({
            ...item.object,
            index: item.index,
          })),
      }).then((res) => {
        if (res.success) {
          message.success(
            t("common.result_status", {
              title: t("common.create"),
              status: t("common.success"),
            })
          );
          navigate(`${IsSimpleLayout ? "/simple" : ""}/dataset`);
        } else {
          message.error(
            viewUtils.prettifyErrorMessage(res.message) ||
              t("common.result_status", {
                title: t("common.create"),
                status: t("common.fail"),
              })
          );
        }
      });
    } else {
      updateDatasetApi({
        datasetId: Number(dataset?.id),
        data: {
          name: data.name,
          description: data.description,
        },
      }).then((res) => {
        if (res.success) {
          message.success(
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.success"),
            })
          );
        } else {
          message.error(
            viewUtils.prettifyErrorMessage(res.message) ||
              t("common.result_status", {
                title: t("common.update"),
                status: t("common.fail"),
              })
          );
        }
      });
    }
  };

  const handleTableInfoSubmit = (table: TableType) => {
    const OriginItems = form.getFieldValue("items") || [];
    if (IsCreate) {
      if (!!table?.temp_id) {
        //update dataset form table
        form.setFieldsValue({
          items: OriginItems.map((item: DatasetItem) =>
            item.temp_id === table.temp_id
              ? {
                  ...item,
                  object: table,
                }
              : item
          ),
        });
      } else {
        //create dataset form table
        form.setFieldsValue({
          items: [
            ...OriginItems,
            {
              content_type: "table",
              index: OriginItems.length,
              object_id: table.id,
              temp_id: uuidv4(),
              object: table,
            },
          ],
        });
      }
      tableInforDrawerRef.current && tableInforDrawerRef.current.close();
    } else {
      if (!!table.id) {
        //update existed dataset table
        updateDatasetTableApi({
          datasetId: Number(dataset?.id),
          tableId: Number(table?.id),
          data: table,
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.update"),
                status: t("common.success"),
              })
            );
            tableInforDrawerRef.current && tableInforDrawerRef.current.close();
            refreshInfo();
          } else {
            let description = t("common.result_status", {
              title: t("common.update"),
              status: t("common.fail"),
            });
            if (
              JSON.stringify(res.message).includes(
                "name must make a unique set"
              )
            ) {
              description = t("table.tips.name_existed");
            } else if (
              JSON.stringify(res.message).includes(
                "table_meta_id is not provided"
              )
            ) {
              description = t("table.tips.fields_empty");
            }
            message.error(description);
          }
        });
      } else {
        //create existed dataset table
        createDatasetTableApi({
          datasetId: Number(dataset?.id),
          data: {
            ...table,
            index: OriginItems.length,
          },
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.create"),
                status: t("common.success"),
              })
            );
            tableInforDrawerRef.current && tableInforDrawerRef.current.close();
            refreshInfo();
          } else {
            let description = t("common.result_status", {
              title: t("common.create"),
              status: t("common.fail"),
            });
            if (
              JSON.stringify(res.message).includes(
                "name must make a unique set"
              )
            ) {
              description = t("table.tips.name_existed");
            } else if (
              JSON.stringify(res.message).includes(
                "table_meta_id is not provided"
              )
            ) {
              description = t("table.tips.fields_empty");
            }
            message.error(description);
          }
        });
      }
    }
  };

  const handleTableViewSubmit = (table_view: TableView) => {
    const OriginItems = form.getFieldValue("items") || [];
    if (IsCreate) {
      if (!!table_view?.temp_id) {
        //update dataset form table view
        form.setFieldsValue({
          items: OriginItems.map((item: DatasetItem) =>
            item.temp_id === table_view.temp_id
              ? {
                  ...item,
                  object: table_view,
                }
              : item
          ),
        });
      } else {
        //create dataset form table view
        form.setFieldsValue({
          items: [
            ...OriginItems,
            {
              content_type: "tableview",
              index: OriginItems.length,
              object_id: table_view.id,
              temp_id: uuidv4(),
              object: table_view,
            },
          ],
        });
      }
      tableViewModalRef.current && tableViewModalRef.current.close();
    } else {
      if (!!table_view.id) {
        //update existed dataset table view
        updateDatasetTableViewApi({
          datasetId: Number(dataset?.id),
          tableViewId: Number(table_view?.id),
          data: {
            parent_table: table_view.parent_table,
            columns: table_view.columns,
            filters: table_view.filters,
          },
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.update"),
                status: t("common.success"),
              })
            );
            tableViewModalRef.current && tableViewModalRef.current.close();
            refreshInfo();
          } else {
            message.error(
              viewUtils.prettifyErrorMessage(res.message) ||
                t("common.result_status", {
                  title: t("common.update"),
                  status: t("common.fail"),
                })
            );
          }
        });
      } else {
        createDatasetTableViewApi({
          datasetId: Number(dataset?.id),
          data: {
            ...table_view,
            index: OriginItems.length,
          },
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.create"),
                status: t("common.success"),
              })
            );
            tableViewModalRef.current && tableViewModalRef.current.close();
            refreshInfo();
          } else {
            message.error(
              viewUtils.prettifyErrorMessage(res.message) ||
                t("common.result_status", {
                  title: t("common.create"),
                  status: t("common.fail"),
                })
            );
          }
        });
      }
    }
  };

  const AddTableBtnItems: {
    items: MenuProps["items"];
    onClick: MenuProps["onClick"];
  } = {
    items: [
      {
        key: "blank",
        label: t("dataset.blank_table"),
      },
      {
        key: "table",
        label: t("table.title"),
      },
      {
        key: "excel",
        label: `excel ${t("common.import")}`,
        disabled: true,
      },
    ],
    onClick: (e) => {
      switch (e?.key) {
        case "table":
          handleDatasetItemEdit({ content_type: "tableview" });
          break;
        default:
          handleDatasetItemEdit({ content_type: "table" });
      }
    },
  };

  const handleOnSortEnd = (data: any) => {
    const items = form.getFieldValue("items") || [];
    const [reorderedItem] = items.splice(data.oldIndex, 1);
    items.splice(data.newIndex, 0, reorderedItem);
    items.forEach((item: DatasetItem, index: number) => {
      item.index = index;
    });
    form.setFieldValue("items", items);
    try {
      const elems = document.getElementsByClassName("draggable-item");
      for (let i = elems.length - 1; i >= 0; i--) {
        if (elems[i] != null) {
          elems[i]?.parentNode?.removeChild(elems[i]);
        }
      }
    } catch (e) {}
    if (!IsCreate) {
      // update dataset items index immediately
      updateDatasetItemsIndexApi({
        datasetId: Number(dataset?.id),
        items: items.map((item: DatasetItem) => ({
          type: item.content_type!,
          id: item.object_id!,
          index: item.index!,
        })),
      });
    }
  };

  const TableCard = (tprops: { table: DatasetItem }) => {
    return (
      <Card size="small" style={{ cursor: "grabbing" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.8em",
          }}
        >
          <Typography.Paragraph
            strong
            ellipsis={{
              rows: 1,
              tooltip:
                tprops?.table?.object?.name ||
                tprops?.table?.object?.parent_table_data?.name,
            }}
            style={{ wordBreak: "break-all", marginBottom: 0 }}
          >
            {tprops.table?.content_type === "tableview" &&
              `【${t("table.title")}】`}
            {tprops?.table?.object?.name ||
              tprops?.table?.object?.parent_table_data?.name}
          </Typography.Paragraph>
          <Space size={0} style={{ marginLeft: "2em" }}>
            <Button
              size="small"
              type="link"
              disabled={
                !IsCreate &&
                !viewUtils.isDatasetAdmin(
                  dataset || undefined,
                  permission.username
                )
              }
              onClick={() => {
                handleDatasetItemEdit(tprops.table);
              }}
            >
              {t("common.edit")}
            </Button>
            {tprops.table?.content_type === "table" && (
              <Button
                size="small"
                type="link"
                disabled={
                  !IsCreate &&
                  !viewUtils.isDatasetAdmin(
                    dataset || undefined,
                    permission.username
                  )
                }
                onClick={() => {
                  handleDatasetItemCopy(tprops.table);
                }}
              >
                {t("common.copy")}
              </Button>
            )}
            <Popconfirm
              disabled={
                !IsCreate &&
                !viewUtils.isDatasetAdmin(
                  dataset || undefined,
                  permission.username
                )
              }
              title={t("dataset.actions.delete_table")}
              description={t("dataset.tips.confirm_delete_table")}
              onConfirm={() => {
                handleDatasetItemDelete(tprops.table);
              }}
              okText={t("common.delete")}
              cancelText={t("common.cancel")}
            >
              <Button
                size="small"
                type="link"
                disabled={
                  !IsCreate &&
                  !viewUtils.isDatasetAdmin(
                    dataset || undefined,
                    permission.username
                  )
                }
              >
                {t("common.delete")}
              </Button>
            </Popconfirm>
          </Space>
        </div>
        <Typography.Paragraph
          ellipsis={{
            rows: 3,
            tooltip:
              tprops?.table?.object?.description ||
              tprops?.table?.object?.parent_table_data?.description,
          }}
          style={{ wordBreak: "break-all", marginBottom: 0, height: "5em" }}
        >
          {tprops?.table?.object?.description ||
            tprops?.table?.object?.parent_table_data?.description}
        </Typography.Paragraph>
      </Card>
    );
  };

  const SortableItem: React.ComponentClass<
    SortableElementProps & { item: DatasetItem },
    any
  > = SortableElement(({ item }: { item: DatasetItem }) => {
    return (
      <Col xs={24} md={12} xl={8} xxl={6}>
        <TableCard table={item} />
      </Col>
    );
  });

  const SortableList: React.ComponentClass<
    SortableContainerProps & { items: DatasetItem[] },
    any
  > = SortableContainer(({ items }: { items: DatasetItem[] }) => {
    return (
      <Row gutter={[16, 16]} style={{ marginTop: "1em" }}>
        {items.map((item: DatasetItem, index: number) => (
          <SortableItem
            index={item.index || index}
            key={`${item.temp_id}`}
            item={item}
          />
        ))}
      </Row>
    );
  });

  return (
    <>
      <TableInfoDrawer
        ref={tableInforDrawerRef}
        submitCallback={handleTableInfoSubmit}
        referenceTables={referencedTables}
      />
      <TableViewFormModal
        ref={tableViewModalRef}
        submitCallback={handleTableViewSubmit}
      />
      <NavHyperLink
        showBack={true}
        items={[
          {
            title: t("dataset.title"),
            path: `${IsSimpleLayout ? "/simple" : ""}/dataset`,
          },
          {
            title: IsCreate
              ? dataset_id
                ? t("common.copy")
                : t("common.create")
              : t("common.edit"),
          },
        ]}
      />
      <div className="layout-content-box" style={{ marginBottom: "80px" }}>
        <Form
          form={form}
          labelAlign="right"
          layout="vertical"
          onFinish={handleSubmit}
        >
          {!!dataset ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <Typography.Title
                  level={5}
                  style={{ marginBottom: 0, marginRight: "1em" }}
                >
                  {t("dataset.info")}
                </Typography.Title>
                {!IsCreate && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !!dataset?.id &&
                      !viewUtils.isDatasetAdmin(dataset, permission.username)
                    }
                  >
                    {t("common.save")}
                  </Button>
                )}
              </div>
              <Form.Item
                name="name"
                label={t("dataset.name")}
                style={{ maxWidth: 500 }}
                rules={[
                  { required: true },
                  {
                    max: 50,
                    message: t("common.char_len_limit", {
                      title: t("dataset.name"),
                      count: 50,
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label={t("common.description")}
                style={{ maxWidth: 500 }}
                rules={[
                  {
                    max: 100,
                    message: t("common.char_len_limit", {
                      title: t("common.description"),
                      count: 100,
                    }),
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography.Title level={5} style={{ marginBottom: 16 }}>
                  {t("dataset.tables")}
                </Typography.Title>
                <Dropdown
                  menu={AddTableBtnItems}
                  placement="bottomRight"
                  disabled={
                    !IsCreate &&
                    !viewUtils.isDatasetAdmin(dataset, permission.username)
                  }
                >
                  <Button type="primary">
                    {t("dataset.actions.add_table")}
                  </Button>
                </Dropdown>
              </div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="items" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues?.items !== curValues?.items
                }
              >
                {() => {
                  const items = (form.getFieldValue("items") ||
                    []) as DatasetItem[];
                  if (items?.length < 1) {
                    return <Empty style={{ margin: "3em 0" }} />;
                  }
                  return (
                    <>
                      <Typography.Text>
                        {t("dataset.tips.table_drag_tip")}
                      </Typography.Text>
                      <SortableList
                        helperClass="draggable-item"
                        distance={1}
                        axis="xy"
                        onSortEnd={handleOnSortEnd}
                        items={items}
                      />
                    </>
                  );
                }}
              </Form.Item>
              {!!IsCreate && (
                <Space className="page-form-actions">
                  <Button onClick={handleBack}>{t("common.back")}</Button>
                  <Button type="primary" htmlType="submit">
                    {t("common.save")}
                  </Button>
                </Space>
              )}
            </>
          ) : (
            <div style={{ textAlign: "center", padding: "10vh 0" }}>
              {dataset === null ? (
                <Empty description={t("common.no_permission")} />
              ) : (
                <Spin />
              )}
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default DatasetInfoPage;
