import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import { PipelineRun } from "../types/Integrations";
import {
  findLastNonPendingStatus,
  getStatusName,
  getStatusMessage,
  makeGetStatus,
} from "../utils/PipelineUtils";
import { useTranslation } from "react-i18next";

const PipelineStageSteps = ({ run }: { run: PipelineRun }) => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState<any>();
  const getStatusString = makeGetStatus(t);

  const getStatus = (status: any) => {
    if (!status.status) {
      return "wait";
    }

    switch (status.status.toLowerCase()) {
      case "succeeded":
        return "finish";
      case "failed":
        return "error";
      case "running":
        return "process";
      case "pending":
        return "wait";
      default:
        return "wait";
    }
  };

  useEffect(() => {
    if (!run) {
      return;
    }

    const processedData = run.status.map((status: any, index: number) => {
      const descriptionLines = getStatusMessage(status, i18n.language)
        .split("\n")
        .map((line: string, idx: number) => (
          <span key={idx}>
            {line}
            {idx !== line.length - 1 && <br />}
          </span>
        ));

      return {
        title: getStatusName(status, i18n.language),
        subTitle: getStatusString(status),
        status: getStatus(status),
        description: descriptionLines,
        disabled: true,
      };
    });
    setItems(processedData);

    const currentItem = findLastNonPendingStatus(run.status);
    setCurrentItem(currentItem);
  }, [run, i18n]);

  return (
    <Steps
      direction="vertical"
      size="small"
      current={currentItem ? currentItem.index : 0}
      items={items}
    />
  );
};

export default PipelineStageSteps;
