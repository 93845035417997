// @ts-nocheck
import React, { useEffect } from "react";
import AMapLoader from "../../AMapLoader";
import { useTranslation } from "react-i18next";
import { Form, Select, Slider, Switch, InputNumber, Collapse } from "antd";
import { ChartView } from "../../../types/ChartView";
const { Panel } = Collapse;

type Props = {
  id: string;
  chart_view: any;
  table_data: any;
};

const GaodeLocationRelationship = (props: Props) => {
  useEffect(() => {
    document.getElementById(
      `gaode-location-relationship-${props.id}`
    ).innerHTML = "";
    if (
      !props.chart_view?.chart_config?.main_latitude ||
      !props.chart_view?.chart_config?.main_longitude ||
      !props.chart_view?.chart_config?.sub_latitude ||
      !props.chart_view?.chart_config?.sub_longitude ||
      !props.chart_view?.chart_config?.app_key ||
      !props.table_data ||
      !props.table_data?.filtered_data
    ) {
      return;
    }
    (async () => {
      AMapLoader({
        key: props.chart_view?.chart_config?.app_key,
      })
        .then((AMap) => {
          const map = new AMap.Map(`gaode-location-relationship-${props.id}`, {
            resizeEnable: true,
            zooms: [2, 22],
            zoom: 4.75,
            // center: [xxx, xxx],
            showLabel: true,
          });
          const loca = new Loca.Container({ map });
          let lineData = [];
          let mainScatterData = {};
          let subScatterData = {};

          for (
            let i = 0, len = props.table_data?.filtered_data?.length || 0;
            i < len;
            i++
          ) {
            let rowData = (props.table_data?.filtered_data || [])[i];
            const main_longitude =
              rowData[props.chart_view?.chart_config?.main_longitude];
            const main_latitude =
              rowData[props.chart_view?.chart_config?.main_latitude];
            const sub_longitude =
              rowData[props.chart_view?.chart_config?.sub_longitude];
            const sub_latitude =
              rowData[props.chart_view?.chart_config?.sub_latitude];

            if (
              !!main_longitude &&
              !!main_latitude &&
              !!sub_longitude &&
              !!sub_latitude
            ) {
              lineData.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [sub_longitude, sub_latitude],
                    [main_longitude, main_latitude],
                  ],
                },
                properties: {},
              });
            }

            if (!!main_longitude && !!main_latitude) {
              const main_lnglat = `${main_longitude},${main_latitude}`;
              if (!mainScatterData[main_lnglat]) {
                mainScatterData[main_lnglat] = {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [main_longitude, main_latitude],
                  },
                  properties: {
                    main: true,
                    name: rowData[props.chart_view?.chart_config?.main_name],
                  },
                };
              }
            }

            if (!!sub_longitude && !!sub_latitude) {
              const sub_lnglat = `${sub_longitude},${sub_latitude}`;
              if (!subScatterData[sub_lnglat]) {
                subScatterData[sub_lnglat] = {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [sub_longitude, sub_latitude],
                  },
                  properties: {
                    main: false,
                    name: rowData[props.chart_view?.chart_config?.sub_name],
                  },
                };
              }
            }
          }

          const LineSource = new Loca.GeoJSONSource({
            data: {
              type: "FeatureCollection",
              features: lineData,
            },
          });
          const lineLayer = new Loca.LineLayer({
            // loca,
            zIndex: 10,
            visible: true,
            opacity: props.chart_view?.chart_config?.opacity || 0,
            zooms: [2, 22],
          });
          lineLayer.setSource(LineSource, {
            color: "rgba(100,100,100,0.7)",
            lineWidth: props.chart_view?.chart_config?.line_width || 1,
          });
          loca.add(lineLayer);

          const scatter = new Loca.ScatterLayer({
            // loca,
            zIndex: 11,
            opacity: props.chart_view?.chart_config?.opacity || 0,
            visible: true,
            zooms: [2, 22],
          });
          const scatterGeo = new Loca.GeoJSONSource({
            data: {
              type: "FeatureCollection",
              features: [
                ...Object.values(subScatterData),
                ...Object.values(mainScatterData),
              ],
            },
          });
          scatter.setSource(scatterGeo);
          scatter.setStyle({
            unit: "px",
            size: (_, feature) =>
              !!feature?.properties?.main
                ? new Array(2).fill(
                    props.chart_view?.chart_config?.main_size || 15
                  )
                : new Array(2).fill(
                    props.chart_view?.chart_config?.sub_size || 8
                  ),
            borderWidth: 0,
            color: (_, feature) =>
              !!feature?.properties?.main
                ? `rgba(255,0,0,1)`
                : `rgba(100,94,249,1)`,
          });
          loca.add(scatter);

          AMap.plugin(["AMap.ToolBar"], function () {
            map.addControl(new AMap.ToolBar());
          });

          if (
            props.chart_view?.chart_config?.show_tip &&
            (props.chart_view?.chart_config?.main_name ||
              props.chart_view?.chart_config?.sub_name)
          ) {
            map.on("mousemove", (e) => {
              var feat = scatter.queryFeature(e.pixel.toArray());
              map.clearMap();
              if (feat && !!feat?.properties?.name) {
                map.add(
                  new AMap.Marker({
                    position: feat.coordinates,
                    anchor: "bottom-center",
                    offset: new AMap.Pixel(
                      0,
                      !!feat?.properties?.main
                        ? -0.8 *
                          (props.chart_view?.chart_config?.main_size || 20)
                        : -1 * (props.chart_view?.chart_config?.sub_size || 10)
                    ),
                    content:
                      '<div style="text-align: center;width: ' +
                      ((feat?.properties?.name?.length || 1) + 2) +
                      'em; max-width: 10em; background:rgba(0,0,0,0.5);border-radius: 4px;color: #fff; padding: 3px 10px;">' +
                      feat?.properties?.name +
                      "</div>",
                  })
                );
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, [props]);

  return (
    <>
      <div
        id={`gaode-location-relationship-${props.id}`}
        style={{ width: "100%", height: "100%" }}
      ></div>
    </>
  );
};

export default GaodeLocationRelationship;

export const GaodeLocationRelationshipConfigForm = (props: {
  chart_view?: ChartView;
  fields: any;
  form: any;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("chart_view.config.location_relationship.main_longitude")}
        name={["chart_config", "main_longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.location_relationship.main_latitude")}
        name={["chart_config", "main_latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.location_relationship.sub_longitude")}
        name={["chart_config", "sub_longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.location_relationship.sub_latitude")}
        name={["chart_config", "sub_latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.show_tip !== curValues?.show_tip
        }
      >
        {() => {
          if (
            !props.form ||
            !props.form.getFieldValue("chart_config")?.show_tip
          ) {
            return <></>;
          }
          return (
            <>
              <Form.Item
                label={t("chart_view.config.location_relationship.main_name")}
                name={["chart_config", "main_name"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  options={props.fields
                    ?.filter((item) => item.type === "TEXT")
                    ?.map((item) => ({
                      label: item.name,
                      value: item.identifier,
                    }))}
                />
              </Form.Item>
              <Form.Item
                label={t("chart_view.config.location_relationship.sub_name")}
                name={["chart_config", "sub_name"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  options={props.fields
                    ?.filter((item) => item.type === "TEXT")
                    ?.map((item) => ({
                      label: item.name,
                      value: item.identifier,
                    }))}
                />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{ padding: 0, background: "white" }}
      >
        <Panel
          header={t("chart_view.more_settings")}
          key="more_settings"
          forceRender
        >
          <Form.Item
            label={t("chart_view.config.location_relationship.opacity")}
            name={["chart_config", "opacity"]}
          >
            <Slider min={0} max={1} step={0.1} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.show_tip")}
            name={["chart_config", "show_tip"]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.main_size")}
            name={["chart_config", "main_size"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.sub_size")}
            name={["chart_config", "sub_size"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.line_width")}
            name={["chart_config", "line_width"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Panel>
      </Collapse>
    </>
  );
};
