const IconShare = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="currentColor">
      <title>share</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-107.000000, -569.000000)"
          fillRule="nonzero"
        >
          <g id="share" transform="translate(107.000000, 569.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="22"
              height="22"
            ></rect>
            <path
              d="M16.15625,14.265625 C15.5439453,14.265625 14.9789062,14.4804688 14.5363281,14.8392578 L10.0847656,11.61875 C10.1212891,11.4189453 10.140625,11.2105469 10.140625,11 C10.140625,10.7894531 10.1212891,10.5832031 10.0847656,10.38125 L14.5363281,7.16074219 C14.9789062,7.51953125 15.5439453,7.734375 16.15625,7.734375 C17.5785156,7.734375 18.734375,6.57851562 18.734375,5.15625 C18.734375,3.73398438 17.5785156,2.578125 16.15625,2.578125 C14.7339844,2.578125 13.578125,3.73398438 13.578125,5.15625 C13.578125,5.40546875 13.6125,5.64394531 13.6791016,5.87167969 L9.45097656,8.93320313 C8.82363281,8.10175781 7.82675781,7.5625 6.703125,7.5625 C4.80390625,7.5625 3.265625,9.10078125 3.265625,11 C3.265625,12.8992187 4.80390625,14.4375 6.703125,14.4375 C7.82675781,14.4375 8.82363281,13.8982422 9.45097656,13.0667969 L13.6791016,16.1283203 C13.6125,16.3560547 13.578125,16.5966797 13.578125,16.84375 C13.578125,18.2660156 14.7339844,19.421875 16.15625,19.421875 C17.5785156,19.421875 18.734375,18.2660156 18.734375,16.84375 C18.734375,15.4214844 17.5785156,14.265625 16.15625,14.265625 Z M16.15625,4.0390625 C16.7728516,4.0390625 17.2734375,4.53964844 17.2734375,5.15625 C17.2734375,5.77285156 16.7728516,6.2734375 16.15625,6.2734375 C15.5396484,6.2734375 15.0390625,5.77285156 15.0390625,5.15625 C15.0390625,4.53964844 15.5396484,4.0390625 16.15625,4.0390625 Z M6.703125,12.890625 C5.66113281,12.890625 4.8125,12.0419922 4.8125,11 C4.8125,9.95800781 5.66113281,9.109375 6.703125,9.109375 C7.74511719,9.109375 8.59375,9.95800781 8.59375,11 C8.59375,12.0419922 7.74511719,12.890625 6.703125,12.890625 Z M16.15625,17.9609375 C15.5396484,17.9609375 15.0390625,17.4603516 15.0390625,16.84375 C15.0390625,16.2271484 15.5396484,15.7265625 16.15625,15.7265625 C16.7728516,15.7265625 17.2734375,16.2271484 17.2734375,16.84375 C17.2734375,17.4603516 16.7728516,17.9609375 16.15625,17.9609375 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconShare;
