import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Table as TableType,
  TableField,
  TableFieldType,
  fieldTypeToTitle,
} from "../types/Table";
import type { ColumnsType } from "antd/es/table";
import { Table, Space, Tooltip, Tag } from "antd";
import { DragOutlined, InfoCircleOutlined } from "@ant-design/icons";
import dragula from "dragula";
import "dragula/dist/dragula.css";

const getIndexInParent = (el: Element | undefined) => {
  if (el) {
    return Array.from(el.parentNode!.children).indexOf(el);
  } else {
    return 0;
  }
};

const TableFieldsList = (props: {
  fields?: TableField[];
  actions?: (table: TableType, index: number) => React.ReactNode;
  rowSelection?: object;
  referenceTables?: TableType[];
  onFieldsChange?: (newFields: TableField[]) => void;
  showFilterField?: boolean;
  style?: any;
}) => {
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState<TableField[]>([]);
  const drakeRef: any = useRef();

  useEffect(() => {
    setFields(
      (props.fields || []).sort((a, b) => {
        const ret =
          (a.index !== null && a.index !== undefined ? a.index : 99999) -
          (b.index !== null && b.index !== undefined ? b.index : 99999);
        if (ret === 0 && !!a.id && !!b.id) {
          return a.id - b.id;
        }
        return ret;
      })
    );
  }, [props.fields]);

  const handleReorder = (dragIndex: number, droppedIndex: number) => {
    setFields((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex - 1, 1)[0];
      newState.splice(droppedIndex - 1, 0, item);

      newState.map((field, index) => {
        field.index = index;
      });

      if (props.onFieldsChange) {
        props.onFieldsChange(newState);
      }

      return newState;
    });
  };

  useEffect(() => {
    if (!!props.actions && !drakeRef.current) {
      let start: number;
      let end: number;
      const container = document.querySelector(
        ".field-list-dragula-table .ant-table-tbody"
      )!;
      drakeRef.current = dragula([container], {
        moves: (el) => {
          start = getIndexInParent(el);
          return true;
        },
        invalid: function (el, handle) {
          return !["svg", "path"].includes(handle?.tagName || "");
        },
      });

      drakeRef.current.on("drop", (el: any) => {
        end = getIndexInParent(el);
        handleReorder(start, end);
      });
    }
  }, [props.actions]);

  return (
    <Table
      size="small"
      rowClassName={!!props.actions ? "field-list-dragula-table-row" : ""}
      rowKey={(r) => r?.identifier || ""}
      className={` ${!!props.actions ? "field-list-dragula-table" : ""}`}
      style={{ marginTop: "16px", ...props.style }}
      scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
      pagination={false}
      columns={
        [
          ...(!!props.actions
            ? [
                {
                  key: "drag",
                  title: "",
                  className: "drag-column",
                  render: (d: TableType, value: TableType, index: number) => (
                    <DragOutlined className="draggable" type="swap" />
                  ),
                },
              ]
            : []),
          {
            key: "name",
            title: t("table.field.name"),
            render: (d: TableField) => (
              <Space
                size={[5, 0]}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {d.name}
                {!!d.readonly && (
                  <Tag color="red">{t("table.field.readonly")}</Tag>
                )}
              </Space>
            ),
          },
          {
            key: "identifier",
            title: t("table.field.identifier"),
            dataIndex: "identifier",
          },
          {
            key: "type",
            title: t("table.field.type"),
            render: (d: TableField) => {
              if (d?.type === "REFERENCE") {
                const fk_table = props.referenceTables?.find(
                  (table: TableType) => table.id === d.fk_to
                );
                return (
                  <Space>
                    {fieldTypeToTitle[d?.type as TableFieldType]}
                    <Tooltip
                      title={
                        <>
                          <p>
                            {t("table.field.reference_table")}：
                            {d?.fk_table_name}
                          </p>
                          <p>
                            {t("table.field.reference_field")}：
                            {(
                              fk_table?.meta?.fields?.find(
                                (field: TableField) =>
                                  field.identifier === d?.fk_field
                              ) || {}
                            ).name || d?.fk_field}
                          </p>
                          <p>
                            {t("table.field.reference_field_display")}：
                            {(
                              fk_table?.meta?.fields?.find(
                                (field: TableField) =>
                                  field.identifier === d?.fk_literal
                              ) || {}
                            ).name || d?.fk_literal}
                          </p>
                        </>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                );
              } else {
                return (
                  <Space>
                    {i18n.resolvedLanguage === "zh"
                      ? fieldTypeToTitle[d?.type as TableFieldType]
                      : d?.type || ""}
                  </Space>
                );
              }
              // return (
              //   fk_to
              // )
            },
            // <Space>
            //   {fieldTypeToTitle[d?.type as TableFieldType]}
            //   {d?.type === "REFERENCE" && (
            //     <Tooltip
            //       title={
            //         <>
            //           <p>引用表：{d?.fk_table_name}</p>
            //           <p>引用字段：{d?.fk_field}</p>
            //           <p>引用字段显示值：{d?.fk_literal}</p>
            //         </>
            //       }
            //     >
            //       <InfoCircleOutlined />
            //     </Tooltip>
            //   )}
            // </Space>
            // ),
          },
          {
            title: t("table.field.is_pk"),
            dataIndex: "is_pk",
            key: "is_pk",
            render: (record: any) =>
              record ? t("common.yes") : t("common.no"),
          },
          {
            title: t("table.field.is_unique"),
            dataIndex: "is_unique",
            key: "is_unique",
            render: (record: any) =>
              record ? t("common.yes") : t("common.no"),
          },
          {
            title: t("table.field.is_nullable"),
            dataIndex: "is_nullable",
            key: "is_nullable",
            render: (record: any) =>
              record ? t("common.yes") : t("common.no"),
          },
          ...(!!props.showFilterField
            ? [
                {
                  title: "是否筛选项",
                  dataIndex: "is_filterable",
                  key: "is_filterable",
                  render: (record: any) =>
                    record ? t("common.yes") : t("common.no"),
                },
              ]
            : []),
          ...(!!props.actions
            ? [
                {
                  key: "actions",
                  title: t("common.actions"),
                  fixed: "right",
                  render: (d: TableType, value: TableType, index: number) =>
                    props.actions && props.actions(d, index),
                },
              ]
            : []),
        ] as ColumnsType<TableField>
      }
      dataSource={fields || []}
      {...(props.rowSelection
        ? {
            rowSelection: props.rowSelection,
          }
        : null)}
    />
  );
};

export default TableFieldsList;
