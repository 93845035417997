import { useSelector } from "react-redux";
import type { Workspace } from "../types/Workspace";

const useCurrentWorkspace = () => {
  const currentWorkspace: Workspace = useSelector(
    (store: any) => store.account.currentWorkspace
  );

  return currentWorkspace;
};

export default useCurrentWorkspace;
