const IconCompare = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="currentColor">
      <title>对比</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-107.000000, -494.000000)"
          fillRule="nonzero"
        >
          <g id="对比" transform="translate(107.000000, 494.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="22"
              height="22"
            ></rect>
            <path
              d="M3.80767578,16.9230703 L8.88460547,16.9230703 L8.88460547,18.6153945 L2.11539453,18.6153945 L2.11539453,3.38460547 L8.88460547,3.38460547 L8.88460547,5.07692969 L3.80769727,5.07692969 L3.80769727,16.9230703 L3.80767578,16.9230703 Z M13.1153945,16.9230703 L14.8076973,16.9230703 L14.8076973,18.6153945 L13.115373,18.6153945 L13.115373,16.9230703 L13.1153945,16.9230703 Z M15.6538379,16.9230703 L17.3461621,16.9230703 L17.3461621,18.6153945 L15.6538379,18.6153945 L15.6538379,16.9230703 Z M18.1923027,18.6153945 L18.1923027,16.9230703 L19.884627,16.9230703 L19.884627,18.6153945 L18.1923027,18.6153945 Z M18.1923027,12.6923027 L18.1923027,11 L19.884627,11 L19.884627,12.6923242 L18.1923027,12.6923242 L18.1923027,12.6923027 Z M18.1923027,15.2307676 L18.1923027,13.5384434 L19.884627,13.5384434 L19.884627,15.2307676 L18.1923027,15.2307676 Z M18.1923027,10.1538379 L18.1923027,8.46153516 L19.884627,8.46153516 L19.884627,10.1538594 L18.1923027,10.1538594 L18.1923027,10.1538379 Z M18.1923027,7.61539453 L18.1923027,5.92307031 L19.884627,5.92307031 L19.884627,7.61539453 L18.1923027,7.61539453 Z M18.1923027,5.07692969 L18.1923027,3.38460547 L19.884627,3.38460547 L19.884627,5.07692969 L18.1923027,5.07692969 Z M17.3461621,5.07692969 L15.6538379,5.07692969 L15.6538379,3.38460547 L17.3461621,3.38460547 L17.3461621,5.07692969 Z M14.8076973,5.07692969 L13.115373,5.07692969 L13.115373,3.38460547 L14.8076973,3.38460547 L14.8076973,5.07692969 Z M10.5769297,1.69230273 L12.2692324,1.69230273 L12.2692324,20.3076973 L10.5769082,20.3076973 L10.5769082,1.69230273 L10.5769297,1.69230273 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconCompare;
