import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Dataset, DatasetVersion } from "../../types/Dataset";
import { Table as TableInfo } from "../../types/Table";
import {
  getDatasetTableViewsApi,
  getDatasetVersionTableViewsApi,
  createDatasetVersionApi,
  updateDatasetVersionApi,
  getDatasetApi,
  getDatasetTablesApi,
  getDatasetExtraConfigApi,
  updateDatasetExtraConfigApi,
} from "../../api/DatasetApi";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  message,
} from "antd";
import viewUtils from "../../utils/viewUtils";
import { PlusCircleOutlined } from "@ant-design/icons";
import { TableView, TableFilters, SheetTable } from "../../types/Table";
import TableDataFilterModal from "../../components/TableDataFilterModal";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import SelectInstancesModal from "../../components/SelectFlowInstanceModal";
import GlobalLoader from "../../components/GlobalLoading";

const DatasetVersionFormModal = forwardRef(
  (
    props: {
      forceOpen?: boolean;
      callback?: (data: DatasetVersion, resetTables?: SheetTable[]) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showItem, setShowItem] = useState<DatasetVersion | undefined>();
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const tableDataFilterModalRef: any = useRef();
    const [tableViews, setTableViews] = useState([] as TableView[]);
    const [tables, setTables] = useState([] as any[]);
    const [currentTableView, setCurrentTableView] = useState<TableView>();
    const [dataset, setDataset] = useState<Dataset>();
    const [dataToAppend, setDataToAppend] = useState<any[]>([]);

    useEffect(() => {
      form.resetFields();
      form.setFieldsValue(showItem);
      getTableViews();
      if (showItem?.dataset) {
        getDatasetApi({ datasetId: showItem.dataset }).then((res) => {
          if (res.success) {
            form.setFieldsValue({
              copy_tables: res.data.extra_config?.saved_copy_tables,
            });
            setDataset(res.data);
          }
        });
        getDatasetTablesApi({ datasetId: showItem.dataset }).then((res) => {
          if (res.success) {
            setTables(res.data);
          }
        });
      }
    }, [showItem, form]);

    useImperativeHandle(ref, () => ({
      show: (item: DatasetVersion) => {
        setShowItem(item);
      },
    }));

    const getTableViews = () => {
      if (!showItem) {
        return;
      }
      if (showItem.id) {
        getDatasetVersionTableViewsApi({
          versionId: Number(showItem.id),
        }).then((res) => {
          if (res.success) {
            setTableViews(res.data);
          }
        });
      } else {
        getDatasetTableViewsApi({
          datasetId: Number(showItem.dataset),
        }).then((res) => {
          if (res.success) {
            const tableviews = res.data;
            tableviews.forEach((tableview: TableView) => {
              if (
                tableview.filters &&
                tableview.filters.items &&
                tableview.filters.items.length > 0
              ) {
                tableview.filters!.applied = true;
              } else {
                tableview.filters!.applied = false;
              }
            });
            setTableViews(tableviews);
          }
        });
      }
    };

    const handleTableViewCheck = (
      e: CheckboxChangeEvent,
      tableView: TableView
    ) => {
      tableView.filters!.applied = e.target.checked;
      setTableViews([...tableViews]);
    };

    const handleAddFilters = (tableView: TableView) => {
      tableDataFilterModalRef.current &&
        tableDataFilterModalRef.current.show(
          tableView.filters || {},
          tableView.parent_table_data?.meta?.fields || []
        );
      setCurrentTableView(tableView);
    };

    const tableDataFilterModal = useMemo(() => {
      return (
        <TableDataFilterModal
          ref={tableDataFilterModalRef}
          submitCallback={(filters: TableFilters) => {
            const applied = currentTableView!.filters?.applied;
            currentTableView!.filters = filters;
            currentTableView!.filters.applied = applied;
            setTableViews([...tableViews]);
            tableDataFilterModalRef.current &&
              tableDataFilterModalRef.current.close();
          }}
        />
      );
    }, [currentTableView]);

    const closeModal = () => {
      setShowItem(undefined);
    };

    const handleCancel = () => {
      closeModal();
      if (!!props.forceOpen) {
        navigate("/dataset");
      }
    };

    const handleCopySettings = (copy_tables: TableInfo[]) => {
      form.setFieldsValue({
        copy_tables: copy_tables,
      });
    };

    const handleSubmit = async () => {
      setConfirmLoading(true);

      try {
        await form.validateFields();
      } catch (e) {
        setConfirmLoading(false);
        return;
      }

      const data: DatasetVersion = form.getFieldsValue();
      data.table_views = tableViews;

      const tablesToReset: SheetTable[] | undefined = [];

      for (let datum of dataToAppend ? dataToAppend : []) {
        const table = tables.find(
          (table) => table.identifier === datum.table_identifier
        );
        if (table) {
          tablesToReset.push(table);
        }
      }

      if (!data.id) {
        const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));
        let processedData = deepClone(data);
        if (dataToAppend && dataToAppend.length > 0) {
          processedData.data = dataToAppend;
        }

        createDatasetVersionApi(processedData).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.create"),
                status: t("common.success"),
              })
            );
            closeModal();
            props.callback && props.callback(res.data, tablesToReset);
          } else {
            if (
              JSON.stringify(res.message).includes(
                "The fields dataset, name must make a unique set."
              )
            ) {
              form.setFields([
                {
                  name: "name",
                  errors: [t("dataset.version_info.name_existed")],
                },
              ]);
              return;
            }

            message.error(
              viewUtils.prettifyErrorMessage(res.message) ||
                t("common.result_status", {
                  title: t("common.create"),
                  status: t("common.fail"),
                })
            );
          }
        });
      } else {
        updateDatasetVersionApi({
          versionId: data.id,
          // add data to data if data is not null and not empty
          data: {
            name: data.name,
            description: data.description,
            dataset: data.dataset,
            table_views: data.table_views,
            ...(dataToAppend && dataToAppend.length > 0
              ? { data: dataToAppend }
              : {}),
          },
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.update"),
                status: t("common.success"),
              })
            );
            closeModal();
            props.callback && props.callback(res.data, tablesToReset);
          } else {
            if (
              JSON.stringify(res.message).includes(
                "The fields dataset, name must make a unique set."
              )
            ) {
              form.setFields([
                {
                  name: "name",
                  errors: [t("dataset.version_info.name_existed")],
                },
              ]);
              return;
            }

            message.error(
              viewUtils.prettifyErrorMessage(res.message) ||
                t("common.result_status", {
                  title: t("common.update"),
                  status: t("common.fail"),
                })
            );
          }
        });
      }

      setConfirmLoading(false);
    };

    return (
      <Modal
        centered
        destroyOnClose={true}
        title={
          (showItem?.id ? t("common.edit") : t("common.add")) +
          t("dataset.version_info.version")
        }
        open={!!showItem}
        maskClosable={!props.forceOpen}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        onOk={handleSubmit}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
      >
        {tableDataFilterModal}
        <Form form={form} style={{ margin: "2em 0" }}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="dataset" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t("common.required", {
                  title: t("dataset.version_info.name"),
                }),
              },
              {
                max: 50,
                message: t("common.char_len_limit", {
                  title: t("dataset.version_info.name"),
                  count: 50,
                }),
              },
            ]}
          >
            <Input placeholder={t("dataset.version_info.name")} />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              {
                max: 100,
                message: t("common.char_len_limit", {
                  title: t("dataset.version_info.description"),
                  count: 100,
                }),
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              rows={4}
              placeholder={t("dataset.version_info.description")}
            />
          </Form.Item>
          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: "0.9em", marginTop: 5 }}
          >
            {t("dataset.version_info.tip")}
          </Typography.Paragraph>
          {!!showItem?.show_copy && (
            <>
              <Form.Item
                name="copy_last_version"
                valuePropName="checked"
                style={{ display: "inline-block" }}
              >
                <Checkbox>{t("dataset.version_info.copy")}</Checkbox>
              </Form.Item>
              <Form.Item name="copy_tables" style={{ display: "inline-block" }}>
                <CopySettingsButton
                  dataset={dataset}
                  tables={tables}
                  onOk={handleCopySettings}
                />
              </Form.Item>
            </>
          )}
          {tableViews.length > 0 && (
            <div>{t("dataset.version_info.primary_table")}</div>
          )}
          {tableViews.map((tableView) => {
            return (
              <div
                key={tableView?.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={tableView.filters?.applied}
                  onChange={(e) => {
                    handleTableViewCheck(e, tableView);
                  }}
                >
                  {tableView?.parent_table_data?.name}
                </Checkbox>
                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    handleAddFilters(tableView);
                  }}
                  style={{ paddingLeft: 0 }}
                >
                  {tableView.filters &&
                  tableView.filters.items &&
                  tableView.filters.items.length > 0
                    ? t("dataset.version_info.count_filters", {
                        count: tableView.filters?.items?.length,
                      })
                    : t("dataset.version_info.add_filters")}
                </Button>
              </div>
            );
          })}
        </Form>
        {((dataset?.action_config &&
          dataset?.action_config?.pipeline_app_id ===
            "push_to_flow_warehouse_planning") ||
          (dataset?.action_config &&
            dataset?.action_config?.pipeline_app_id ===
              "warehouse_logistics_cost_simulator_yenai") ||
          (dataset?.push_config &&
            dataset?.push_config?.push_method ===
              "push_to_flow_warehouse_planning")) && (
          <SelectInstancesModal
            dataset={dataset}
            tables={tables}
            handleResult={(data) => {
              setDataToAppend(data);
            }}
          />
        )}
      </Modal>
    );
  }
);

const CopySettingsButton = (props: {
  dataset?: Dataset;
  tables: TableInfo[];
  onOk: (copy_tables: TableInfo[]) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const handleClickSettings = () => {
    if (!props.dataset) {
      return;
    }

    GlobalLoader.show();
    getDatasetExtraConfigApi({
      datasetId: Number(props.dataset.id),
    }).then((res) => {
      if (res.success) {
        const extraConfig = res.data;
        form.setFieldsValue({ tables: extraConfig.saved_copy_tables });
        setIndeterminate(
          extraConfig.saved_copy_tables.length > 0 &&
            extraConfig.saved_copy_tables.length < props.tables.length
        );
        setCheckAll(
          extraConfig.saved_copy_tables.length >= props.tables.length
        );
        setOpen(true);
      } else {
        message.error(
          t("common.result_status", {
            title: t("dataset.version_info.get_settings"),
            status: t("common.fail"),
          })
        );
      }
      GlobalLoader.hide();
    });
  };

  const closeModal = () => {
    setOpen(false);
  };

  const onCheckAllChange = (e: any) => {
    form.setFieldValue(
      "tables",
      e.target.checked
        ? (props.tables || []).map((table: TableInfo) => table.id)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onTableChange = (list: any[]) => {
    setIndeterminate(
      !!list.length && list.length < (props.tables || []).length
    );
    setCheckAll(list.length >= (props.tables || []).length);
  };

  const handleOk = () => {
    if (!props.dataset) {
      return;
    }
    updateDatasetExtraConfigApi({
      datasetId: Number(props.dataset.id),
      data: { saved_copy_tables: form.getFieldValue("tables") },
    }).then((res) => {
      if (res.success) {
        props.onOk(form.getFieldValue("tables"));
        setOpen(false);
      } else {
        message.error(
          t("common.result_status", {
            title: t("common.save"),
            status: t("common.fail"),
          })
        );
      }
    });
  };

  return (
    <>
      <Modal
        centered
        width={550}
        forceRender={true}
        title={t("nav.settings")}
        open={!!open}
        onCancel={closeModal}
        onOk={handleOk}
      >
        <Form form={form} style={{ margin: "2em 0" }}>
          <Typography.Paragraph>
            <span style={{ fontWeight: "bold" }}>
              {t("dataset.version_info.table_name")}
            </span>
            <Checkbox
              style={{ marginLeft: "2em" }}
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              {t("common.check_all")}
            </Checkbox>
          </Typography.Paragraph>
          <Form.Item
            style={{ width: "100%" }}
            name="tables"
            rules={[
              { required: true, message: t("common.required", { title: "" }) },
            ]}
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onTableChange}
              className="compare-checkbox"
            >
              <Row gutter={[16, 10]} style={{ width: "100%" }}>
                {(props.tables || []).map((table: TableInfo) => (
                  <Col key={table.id} xs={12} sm={8}>
                    <Checkbox value={table.id}>
                      <Typography.Text ellipsis={{ tooltip: table.name }}>
                        {table.name}
                      </Typography.Text>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: "0.9em", marginTop: 5 }}
          >
            {t("dataset.version_info.copy_table_tip")}
          </Typography.Paragraph>
        </Form>
      </Modal>
      <Button
        type="link"
        size="small"
        style={{ paddingLeft: 0 }}
        onClick={handleClickSettings}
      >
        {t("nav.settings")}
      </Button>
    </>
  );
};

export default DatasetVersionFormModal;
