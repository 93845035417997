import React, { useEffect, useState, useRef } from "react";
import NavHyperLink from "../../../components/NavHyperLink";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import {
  DatasetTemplate,
  DatasetTemplateTable,
  DatasetTemplateTableField,
} from "../../../types/DatasetTemplate";
import { TableType as TableTypeEnum } from "../../../types/Table";
import { Workspace } from "../../../types/Workspace";
import {
  Empty,
  message,
  Form,
  Input,
  Select,
  Checkbox,
  Space,
  Typography,
  Dropdown,
  Button,
  Card,
  Row,
  Col,
} from "antd";
import { getAllWorkspacesApi } from "../../../api";
import {
  createDatasetTemplateApi,
  getDatasetTemplateApi,
  updateDatasetTemplateApi,
} from "../../../api/DatasetTemplateApi";
import DatasetTemplateTableModal from "./DatasetTemplateTableModal";
import DatasetTemplateTableViewModal from "./DatasetTemplateTableViewModal";
import { v4 as uuidv4 } from "uuid";
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
} from "react-sortable-hoc";
import { PipelineApp } from "../../../types/Integrations";
import {
  getAllFlowAppsApi,
  getPipelineAppsApi,
} from "../../../api/IntegrationsApis";
import { connect } from "react-redux";

const DatasetTemplateInfoPage = (props: { currenttoken?: string }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const IsCreate = location.pathname.includes("create");
  const { dataset_templdate_id } = useParams();
  const [allWorkspaces, setAllWorkspaces] = useState<Workspace[]>([]);
  const [selectedPipelineType, setSelectedPipelineType] = useState(null);
  const [pipelineApps, setPipelineApps] = useState<PipelineApp[]>([]);
  const [flowoptApps, setFlowoptApps] = useState([]);
  const pipelineAppId = Form.useWatch(
    ["manifest", "action_config", "pipeline_app_id"],
    form
  );

  const tableModalRef: any = useRef();
  const tableViewModalRef: any = useRef();

  useEffect(() => {
    getAllWorkspacesApi().then((res) => {
      if (res.success) {
        setAllWorkspaces(res.data);
      }
    });

    getPipelineAppsApi().then((res) => {
      if (res.success) {
        setPipelineApps(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (!props.currenttoken) {
      return;
    }
    getAllFlowAppsApi({
      flowApiToken: props.currenttoken,
    }).then((res) => {
      if (res.success) {
        setFlowoptApps(res.data);
      }
    });
  }, [props.currenttoken]);

  useEffect(() => {
    const selectedApp: any = pipelineApps.find(
      (app: any) => app.id === pipelineAppId
    );
    setSelectedPipelineType(selectedApp?.type);
  }, [pipelineAppId]);

  useEffect(() => {
    if (!!dataset_templdate_id) {
      getDatasetTemplateApi({ id: Number(dataset_templdate_id) }).then(
        (res) => {
          if (res.success) {
            form.setFieldsValue({
              ...res.data,
            });
          }
        }
      );
    } else {
      form.setFieldsValue({
        active: true,
        manifest: {
          tables: [],
          base_tables: [],
        },
      });
    }
  }, [dataset_templdate_id, form]);

  const handleSubmit = (data: DatasetTemplate) => {
    let ret = data;
    console.log(data);

    ret.manifest!.tables = (ret?.manifest?.tables || []).map(
      (table: DatasetTemplateTable, index: number) => ({
        ...table,
        index: index,
      })
    );
    if (IsCreate) {
      createDatasetTemplateApi(ret).then((res) => {
        if (res.success) {
          message.success("数据集模版创建成功");
          handleBack();
        } else {
          message.error("数据集模版创建失败");
        }
      });
    } else {
      updateDatasetTemplateApi({
        id: Number(dataset_templdate_id),
        data: ret,
      }).then((res) => {
        if (res.success) {
          message.success("数据集模版更新成功");
          handleBack();
        } else {
          message.error("数据集模版更新失败");
        }
      });
    }
  };

  const handleTableEdit = (table: DatasetTemplateTable) => {
    if (table.table_type === "TABLEVIEW") {
      tableViewModalRef.current &&
        tableViewModalRef.current.show(
          table,
          form.getFieldValue("manifest")?.base_tables || []
        );
    } else if (table.table_type === "PRIMARY") {
      tableModalRef.current &&
        tableModalRef.current.show(table, form.getFieldsValue(), []);
    } else {
      tableModalRef.current &&
        tableModalRef.current.show(
          table,
          form.getFieldsValue(),
          form.getFieldValue("manifest")?.base_tables || []
        );
    }
  };

  const handleTableDelete = (table: DatasetTemplateTable) => {
    let manifest = form.getFieldValue("manifest");
    if (!!table?.temp_id && table?.table_type === TableTypeEnum.PRIMARY) {
      if (
        manifest.tables.find(
          (t: DatasetTemplateTable) =>
            (t.table_type === "TABLEVIEW" &&
              t.parent_table === table.temp_id) ||
            (t.table_type === TableTypeEnum.COMMON &&
              t.fields?.find(
                (f: DatasetTemplateTableField) => f.fk_to === table?.temp_id
              ))
        )
      ) {
        message.error("该表已引用，不可删除");
        return;
      }
    }

    if (table.table_type === "PRIMARY") {
      manifest.base_tables = (manifest?.base_tables || []).filter(
        (t: DatasetTemplateTable) => t.temp_id !== table.temp_id
      );
    } else {
      manifest.tables = (manifest?.tables || []).filter(
        (t: DatasetTemplateTable) => t.temp_id !== table.temp_id
      );
    }
    form.setFieldsValue({ manifest: manifest });
  };

  const TableCard = (tprops: { table: DatasetTemplateTable }) => {
    const refTable =
      tprops.table.table_type === "TABLEVIEW" &&
      (form.getFieldValue("manifest")?.base_tables || []).find(
        (t: DatasetTemplateTable) => t.temp_id === tprops.table.parent_table
      );
    return (
      <Card
        size="small"
        style={
          tprops.table?.table_type !== TableTypeEnum.PRIMARY
            ? { cursor: "grabbing" }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.8em",
          }}
        >
          <Typography.Paragraph
            strong
            ellipsis={{ rows: 1, tooltip: true }}
            style={{ wordBreak: "break-all", marginBottom: 0 }}
          >
            {tprops.table?.table_type === "TABLEVIEW" && `【基础数据】`}
            {tprops?.table?.name?.zh ||
              tprops?.table?.name?.en ||
              refTable?.name?.zh ||
              refTable?.name?.en}
          </Typography.Paragraph>
          <Space size={0} style={{ marginLeft: "2em" }}>
            <Button
              size="small"
              type="link"
              onClick={() => handleTableEdit(tprops.table)}
            >
              编辑
            </Button>
            <Button
              size="small"
              type="link"
              danger
              onClick={() => handleTableDelete(tprops.table)}
            >
              删除
            </Button>
          </Space>
        </div>
        <Typography.Paragraph
          ellipsis={{ rows: 3, tooltip: true }}
          style={{ wordBreak: "break-all", marginBottom: 0, height: "5em" }}
        >
          {tprops?.table?.description?.zh || tprops?.table?.description?.en}
        </Typography.Paragraph>
      </Card>
    );
  };

  const handleOnSortEnd = (data: any) => {
    let manifest = form.getFieldValue("manifest");
    const tables = manifest?.tables || [];
    const [reorderedItem] = tables.splice(data.oldIndex, 1);
    tables.splice(data.newIndex, 0, reorderedItem);
    manifest.tables = tables;
    form.setFieldValue("manifest", manifest);
    try {
      const elems = document.getElementsByClassName("draggable-item");
      for (let i = elems.length - 1; i >= 0; i--) {
        if (!!elems[i]) {
          elems[i]?.parentNode?.removeChild(elems[i]);
        }
      }
    } catch (e) {}
  };

  const SortableItem: React.ComponentClass<
    SortableElementProps & { table: DatasetTemplateTable },
    any
  > = SortableElement(({ table }: { table: DatasetTemplateTable }) => {
    return (
      <Col xs={24} md={12} xl={8} xxl={6}>
        <TableCard table={table} />
      </Col>
    );
  });

  const SortableList: React.ComponentClass<
    SortableContainerProps & { tables: DatasetTemplateTable[] },
    any
  > = SortableContainer(({ tables }: { tables: DatasetTemplateTable[] }) => {
    return (
      <Row gutter={[16, 16]} style={{ marginTop: "1em" }}>
        {tables.map((table: DatasetTemplateTable, index: number) => (
          <SortableItem index={index} key={`${table.temp_id}`} table={table} />
        ))}
      </Row>
    );
  });

  const handleBack = () => {
    navigate(`/system_manage/templates/dataset`);
  };

  const handleTableInfoSubmit = (table: DatasetTemplateTable) => {
    let manifest = form.getFieldValue("manifest");
    if (table.table_type === TableTypeEnum.PRIMARY) {
      const tables = manifest?.base_tables || [];
      manifest.base_tables = !!table.temp_id
        ? tables.map((t: DatasetTemplateTable) =>
            t.temp_id === table.temp_id ? table : t
          )
        : [...tables, { ...table, temp_id: uuidv4() }];
    } else {
      const tables = manifest?.tables || [];
      manifest.tables = !!table.temp_id
        ? tables.map((t: DatasetTemplateTable) =>
            t.temp_id === table.temp_id && t.table_type === TableTypeEnum.COMMON
              ? table
              : t
          )
        : [...tables, { ...table, temp_id: uuidv4() }];
    }
    form.setFieldsValue({ manifest: manifest });
  };

  const handleTableViewInfoSubmit = (table: DatasetTemplateTable) => {
    table.table_type = "TABLEVIEW";
    let manifest = form.getFieldValue("manifest");
    const tables = manifest?.tables || [];
    manifest.tables = !!table.temp_id
      ? tables.map((t: DatasetTemplateTable) =>
          t.temp_id === table.temp_id && t.table_type === "TABLEVIEW"
            ? table
            : t
        )
      : [...tables, { ...table, temp_id: uuidv4() }];
    form.setFieldsValue({ manifest: manifest });
    tableViewModalRef.current && tableViewModalRef.current.close();
  };

  return (
    <>
      <DatasetTemplateTableModal
        ref={tableModalRef}
        submitCallback={handleTableInfoSubmit}
      />
      <DatasetTemplateTableViewModal
        ref={tableViewModalRef}
        submitCallback={handleTableViewInfoSubmit}
      />
      <NavHyperLink
        showBack={true}
        items={[
          {
            title: "数据集模版",
            path: `/system_manage/templates/dataset`,
          },
          {
            title: IsCreate ? (dataset_templdate_id ? "复制" : "创建") : "编辑",
          },
        ]}
      />
      <div style={{ padding: "2em 0 100px 8px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="common-padding"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name={["manifest", "base_tables"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={["manifest", "tables"]} hidden>
            <Input />
          </Form.Item>
          <div className="d-flex">
            <Form.Item
              name={["manifest", "title", "zh"]}
              label="模版名称（中）"
              style={{ flex: 1 }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["manifest", "title", "en"]}
              label="模版名称（英）"
              style={{ flex: 1 }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="d-flex">
            <Form.Item
              name={["manifest", "desc", "zh"]}
              label="模版描述（中）"
              style={{ flex: 1 }}
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name={["manifest", "desc", "en"]}
              label="模版描述（英）"
              style={{ flex: 1 }}
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
          <Form.Item
            label="是否启用"
            name="active"
            valuePropName="checked"
            hidden={!!IsCreate}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name={["manifest", "action_config", "pipeline_app_id"]}
            label="执行流程"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={pipelineApps?.map((item: PipelineApp) => ({
                label: item.manifest?.display_name["zh"],
                value: item.id,
              }))}
            />
          </Form.Item>
          {selectedPipelineType === "push_to_flowopt" && (
            <>
              <Form.Item
                name={["manifest", "action_config", "flow_app"]}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["manifest", "action_config", "default_flow_app_id"]}
                label="应用名称"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  onSelect={(value, option) => {
                    form.setFieldsValue({
                      manifest: {
                        action_config: {
                          flow_app: option.data.app_id,
                          flow_app_id: option.data.id,
                        },
                      },
                    });
                  }}
                  options={flowoptApps?.map((item: any) => ({
                    label: item?.app_manifest?.display_name["zh"],
                    value: item.id,
                    data: { id: item.id, app_id: item.app_id },
                  }))}
                ></Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            name={"template_type"}
            label="模版类型"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                { label: "全局", value: "PUBLIC" },
                { label: "私有", value: "PRIVATE" },
              ]}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.template_type !== curValues?.template_type
            }
          >
            {() => {
              if (form.getFieldValue("template_type") !== "PRIVATE") {
                return <div />;
              }
              return (
                <Form.Item label="分配空间" name="private_workspaces">
                  <Select
                    allowClear
                    mode="multiple"
                    options={allWorkspaces.map((item) => ({
                      label: `${item.name}(${
                        item.memberships?.find((u) => u.role === "OWNER")?.user
                          ?.name
                      })`,
                      value: item.id,
                    }))}
                    filterOption={(
                      input: string,
                      option?: { label: string; value: number }
                    ) => {
                      return (option?.label ?? "").includes(input);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <div className="d-flex">
            <Form.Item
              name={["manifest", "dataset_name", "zh"]}
              label="默认数据集名称（中）"
              style={{ flex: 1 }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["manifest", "dataset_name", "en"]}
              label="默认数据集名称（英）"
              style={{ flex: 1 }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="d-flex">
            <Form.Item
              name={["manifest", "dataset_desc", "zh"]}
              label="默认数据集描述（中）"
              style={{ flex: 1 }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name={["manifest", "dataset_desc", "en"]}
              label="默认数据集描述（英）"
              style={{ flex: 1 }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              所需基础数据表：
            </Typography.Title>
            <Button
              type="primary"
              onClick={() => handleTableEdit({ table_type: "PRIMARY" })}
            >
              新增表
            </Button>
          </div>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.manifest?.base_tables !==
              curValues?.manifest?.base_tables
            }
          >
            {() => {
              const base_tables = (form.getFieldValue("manifest")
                ?.base_tables || []) as DatasetTemplateTable[];
              if (base_tables?.length < 1) {
                return <Empty style={{ margin: "3em 0" }} />;
              }
              return (
                <Row gutter={[16, 16]} style={{ marginTop: "1em" }}>
                  {base_tables.map((table: DatasetTemplateTable) => (
                    <Col key={table.temp_id} xs={24} md={12} xl={8} xxl={6}>
                      <TableCard table={table} />
                    </Col>
                  ))}
                </Row>
              );
            }}
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              数据表：
              <span style={{ fontWeight: "normal", fontSize: "0.875em" }}>
                拖拽可调整顺序
              </span>
            </Typography.Title>

            <Dropdown
              menu={{
                items: [
                  {
                    key: "table",
                    label: "空白表",
                  },
                  {
                    key: "table_view",
                    label: "基础数据",
                  },
                ],
                onClick: (e) => {
                  switch (e?.key) {
                    case "table_view":
                      handleTableEdit({ table_type: "TABLEVIEW" });
                      break;
                    default:
                      handleTableEdit({ table_type: "COMMON" });
                  }
                },
              }}
              placement="bottomRight"
            >
              <Button type="primary">新增表</Button>
            </Dropdown>
          </div>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.manifest?.base_tables !==
                curValues?.manifest?.base_tables ||
              prevValues?.manifest?.tables !== curValues?.manifest?.tables
            }
          >
            {() => {
              const tables = (form.getFieldValue("manifest")?.tables ||
                []) as DatasetTemplateTable[];
              if (tables?.length < 1) {
                return <Empty style={{ margin: "3em 0" }} />;
              }
              return (
                <SortableList
                  helperClass="draggable-item"
                  distance={1}
                  axis="xy"
                  onSortEnd={handleOnSortEnd}
                  tables={tables}
                />
              );
            }}
          </Form.Item>

          <Space className="page-form-actions">
            <Button onClick={handleBack}>返回</Button>
            <Button type="primary" htmlType="submit">
              {IsCreate ? "创建" : "保存"}
            </Button>
          </Space>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currenttoken: store.account.token,
});

export default connect(mapStateToProps, {})(DatasetTemplateInfoPage);
