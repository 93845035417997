import { SheetTable, TableField } from "../types/Table";

export type TableCompare = {
  id: number;
  name: string;
  fields: TableField[];
  data: TableCompareData[];
};

export type TableCompareData = {
  row: number;
  status: string;
  originData: any[];
  newData: any[];
};

export type TableCompareChangeField = {
  field: string;
  origin_value: any;
  new_value: any;
};

export type TableCompareDataStatistics = {
  delete_count: number;
  add_count: number;
  change_data: TableCompareChangeField[];
};

const compareRowData = (orginData: any, newData: any) => {
  if (newData.join("") === orginData.join("")) {
    return "same";
  } else {
    for (let celli = 0, cellLen = newData.length; celli < cellLen; celli++) {
      if (newData[celli] > orginData[celli]) {
        return "delete";
      } else if (newData[celli] < orginData[celli]) {
        return "new";
      }
    }
  }
};

const sortCompareTableData = (
  fields: TableField[],
  originData: any[],
  tableData: any[]
) => {
  let OriginData = [],
    NewData = [];
  for (
    let i = 0,
      olen = originData.length,
      len = tableData.length,
      maxLen = Math.max(originData.length, tableData.length);
    i < maxLen;
    i++
  ) {
    if (i < olen) {
      OriginData.push(
        fields.map((field: TableField) => {
          var cell = (originData[i] || {})[field?.identifier || ""];
          return cell !== null && cell !== undefined ? cell : "";
        })
      );
    }
    if (i < len) {
      NewData.push(
        fields.map((field: TableField) => {
          var cell = (tableData[i] || {})[field?.identifier || ""];
          return cell !== null && cell !== undefined ? cell : "";
        })
      );
    }
  }
  OriginData = OriginData.sort((a: any, b: any) =>
    compareRowData(a, b) === "new" ? 1 : -1
  );
  NewData = NewData.sort((a: any, b: any) =>
    compareRowData(a, b) === "new" ? 1 : -1
  );
  return [OriginData, NewData];
};

const compareRowDataFields = (
  fields: TableField[],
  originData: any,
  newData: any
) => {
  let ret: TableCompareChangeField[] = [];
  for (let i = 0, len = originData?.length || 0; i < len; i++) {
    if (originData[i] !== newData[i]) {
      ret.push({
        field: fields[i]?.identifier || "",
        origin_value: originData[i],
        new_value: newData[i],
      });
    }
  }
  return ret;
};

export const calCompareTableDataStatistics = (
  originTable: SheetTable,
  table: SheetTable
) => {
  const fields = table?.meta?.fields || originTable?.meta?.fields || [];
  const OriginTableDataLength = originTable.data?.length || 0;
  const NewTableDataLength = table.data?.length || 0;

  let [OriginData, NewData] = sortCompareTableData(
    fields,
    originTable.data || [],
    table.data || []
  );
  let result: TableCompareDataStatistics = {
    delete_count: 0,
    add_count: 0,
    change_data: [],
  };
  let i = 0,
    oi = 0,
    ni = 0,
    rowStatus: any = {},
    compareFinish = false;
  while (!compareFinish) {
    if (oi < OriginTableDataLength && ni < NewTableDataLength) {
      const ret = compareRowData(OriginData[oi], NewData[ni]);
      if (ret === "same") {
        i++;
        oi++;
        ni++;
      } else if (ret === "delete") {
        if (i > 0 && rowStatus[i - 1] === "new") {
          result.add_count = result.add_count - 1;
          rowStatus[i - 1] = "changed";
          result.change_data = result.change_data.concat(
            compareRowDataFields(fields, OriginData[oi], NewData[ni - 1])
          );
        } else {
          rowStatus[i] = "delete";
          result.delete_count = result.delete_count + 1;
          i++;
        }
        oi++;
      } else {
        if (i > 0 && rowStatus[i - 1] === "delete") {
          result.delete_count = result.delete_count - 1;
          rowStatus[i - 1] = "changed";
          result.change_data = result.change_data.concat(
            compareRowDataFields(fields, OriginData[oi - 1], NewData[ni])
          );
        } else {
          rowStatus[i] = "new";
          result.add_count = result.add_count + 1;
          i++;
        }
        ni++;
      }
    } else if (oi >= OriginTableDataLength && ni < NewTableDataLength) {
      for (
        let forindex = 0, len = NewTableDataLength;
        ni < len;
        ni++, i++, forindex++
      ) {
        if (forindex === 0 && i > 0 && rowStatus[i - 1] === "delete") {
          result.delete_count = result.delete_count - 1;
          rowStatus[i - 1] = "changed";
          result.change_data = result.change_data.concat(
            compareRowDataFields(fields, OriginData[oi - 1], NewData[ni])
          );
          i--;
        } else {
          rowStatus[i] = "new";
          result.add_count = result.add_count + 1;
        }
      }
    } else {
      for (
        let forindex = 0, olen = OriginTableDataLength;
        oi < olen;
        oi++, i++, forindex++
      ) {
        if (forindex === 0 && i > 0 && rowStatus[i - 1] === "new") {
          result.add_count = result.add_count - 1;
          rowStatus[i - 1] = "changed";
          result.change_data = result.change_data.concat(
            compareRowDataFields(fields, OriginData[oi], NewData[ni - 1])
          );
          i--;
        } else {
          rowStatus[i] = "delete";
          result.delete_count = result.delete_count + 1;
        }
      }
    }
    if (oi >= OriginTableDataLength && ni >= NewTableDataLength) {
      compareFinish = true;
    }
  }
  return result;
};

export const calCompareTableData = (
  originTable: SheetTable,
  table: SheetTable
) => {
  const OriginTableDataLength = originTable.data?.length || 0;
  const NewTableDataLength = table.data?.length || 0;

  let result: TableCompare = {
    id: table?.id || originTable?.id || 0,
    name: table?.name || originTable?.name || "",
    fields: table?.meta?.fields || originTable?.meta?.fields || [],
    data: [],
  };
  if (result.fields.length < 1) {
    return result;
  }
  let [OriginData, NewData] = sortCompareTableData(
    result.fields,
    originTable.data || [],
    table.data || []
  );
  let i = 0,
    oi = 0,
    ni = 0,
    compareFinish = false;
  while (!compareFinish) {
    if (oi < OriginTableDataLength && ni < NewTableDataLength) {
      const ret = compareRowData(OriginData[oi], NewData[ni]);
      if (ret === "same") {
        result.data.push({
          row: i,
          status: "nochange",
          originData: OriginData[oi],
          newData: NewData[ni],
        });
        i++;
        oi++;
        ni++;
      } else if (ret === "delete") {
        if (i > 0 && result.data[i - 1].status === "new") {
          result.data[i - 1].status = "changed";
          result.data[i - 1].originData = OriginData[oi];
        } else {
          result.data.push({
            row: i,
            status: "delete",
            originData: OriginData[oi],
            newData: [],
          });
          i++;
        }
        oi++;
      } else {
        if (i > 0 && result.data[i - 1].status === "delete") {
          result.data[i - 1].status = "changed";
          result.data[i - 1].newData = NewData[ni];
        } else {
          result.data.push({
            row: i,
            status: "new",
            originData: [],
            newData: NewData[ni],
          });
          i++;
        }
        ni++;
      }
    } else if (oi >= OriginTableDataLength && ni < NewTableDataLength) {
      for (
        let forindex = 0, len = NewTableDataLength;
        ni < len;
        ni++, i++, forindex++
      ) {
        if (forindex === 0 && i > 0 && result.data[i - 1].status === "delete") {
          result.data[i - 1].status = "changed";
          result.data[i - 1].newData = NewData[ni];
          i--;
        } else {
          result.data.push({
            row: i,
            status: "new",
            originData: [],
            newData: NewData[ni],
          });
        }
      }
    } else {
      for (
        let forindex = 0, olen = OriginTableDataLength;
        oi < olen;
        oi++, i++, forindex++
      ) {
        if (forindex === 0 && i > 0 && result.data[i - 1].status === "new") {
          result.data[i - 1].status = "changed";
          result.data[i - 1].originData = OriginData[oi];
          i--;
        } else {
          result.data.push({
            row: i,
            status: "delete",
            originData: OriginData[oi],
            newData: [],
          });
        }
      }
    }
    if (oi >= OriginTableDataLength && ni >= NewTableDataLength) {
      compareFinish = true;
    }
  }
  return result;
};
