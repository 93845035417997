import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Workspace, Role } from "../types/Workspace";
import type { User } from "../types/User";

export type Permission = {
  isAdmin?: boolean;
  email?: string;
  username?: string;
  name?: string;
  isSuperuser?: boolean;
  enterpriseMode?: boolean;
};

const usePermission = () => {
  const [permission, setPermission] = useState<Permission>({});

  const currentWorkspace: Workspace = useSelector(
    (store: any) => store.account.currentWorkspace
  );
  const user: User = useSelector((store: any) => store.account.user);

  useEffect(() => {
    if (!!user?.username) {
      setPermission({
        isAdmin:
          currentWorkspace?.memberships.find((membership) => {
            return membership?.user?.username === user?.username;
          })?.role !== Role.MEMBER,
        email: user?.email,
        username: user?.username,
        name: user?.name,
        isSuperuser: user?.is_superuser || false,
        enterpriseMode: user?.enterprise_mode || false,
      });
    }
  }, [currentWorkspace, user]);

  return permission;
};

export default usePermission;
