import React, { useEffect } from "react";
import {
  Button,
  Space,
  Form,
  Col,
  Row,
  Select,
  Input,
  InputNumber,
} from "antd";
import type { Task } from "../../types/Dataset";
import type { SheetTable, TableField } from "../../types/Table";
import usePermission from "../../utils/usePermission";

const FilterForm = (props: {
  table: SheetTable;
  filter: any;
  submitCallback?: (data: any) => void;
}) => {
  const [form] = Form.useForm();
  const permission = usePermission();

  const handleSubmit = () => {
    !!props.submitCallback && props.submitCallback(form.getFieldsValue());
  };

  const handleReset = () => {
    form.resetFields();
    handleSubmit();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(props.filter || {});
  }, [props.filter, form]);

  return (
    <div>
      <Form form={form} id="sales-report-filter-form">
        <Row gutter={[20, 0]}>
          {!!permission.isAdmin && (
            <Col xs={24} md={12} xl={8} xxl={6}>
              <Form.Item label={"分配人"} name={"assignee"}>
                <Select
                  options={(props.table?.tasks || []).map((item: Task) => ({
                    label: `${item?.assignee_user?.name}(${item?.assignee_user?.username})`,
                    value: item?.assignee_user?.username,
                  }))}
                />
              </Form.Item>
            </Col>
          )}
          {(props.table?.meta?.fields || [])
            .filter((field: TableField) => !!field.is_filterable)
            .map((field: TableField) => (
              <Col
                xs={24}
                md={12}
                xl={8}
                xxl={6}
                key={`field-${field.identifier}`}
              >
                <Form.Item
                  label={field.name}
                  name={["fields", field.identifier || ""]}
                >
                  {field.type === "NUMBER" ? (
                    <InputNumber
                      min={field.validation_rules?.minimum || 0}
                      max={field.validation_rules?.maximum || 10000000}
                      style={{ width: "100%" }}
                    />
                  ) : field.type === "BOOLEAN" ? (
                    <Select
                      options={[
                        { label: "是", value: true },
                        { label: "否", value: false },
                      ]}
                    />
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Form>
      <div>
        <Space
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1em",
          }}
        >
          <Button type="primary" onClick={handleSubmit}>
            查询
          </Button>
          <Button onClick={handleReset}>重置</Button>
        </Space>
      </div>
    </div>
  );
};

export default FilterForm;
