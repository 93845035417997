import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { SheetTable, TableField } from "../types/Table";
import { Task } from "../types/Dataset";
import { useTranslation } from "react-i18next";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  Modal,
  Select,
  Steps,
  Upload,
  Checkbox,
  Row,
  Col,
  Typography,
  message,
  Button,
  Tooltip,
  Tabs,
  Form,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@ant-design/icons";
import { fileToWorkBook } from "../utils/SheetUtils";
import { UploadChangeParam } from "antd/es/upload";
import { WorkBook } from "xlsx";
import * as XLSX from "xlsx";
import {
  toDate,
  toBoolean,
  toNumber,
  getFormatDate_XLSX,
} from "../utils/TypeUtils";
import GlobalLoader from "../components/GlobalLoading";
import usePermission from "../utils/usePermission";
import type { TabsProps } from "antd";

type TableFieldsMap = {
  sheet: string;
  fields: Map<string, string>;
};

const TableImportBatchModal = forwardRef(
  (
    props: {
      submitCallback?: (
        data: SheetTable[],
        save?: boolean,
        lock?: boolean
      ) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const permission = usePermission();
    const [form] = Form.useForm();
    const [showItem, setShowItem] = useState<SheetTable[] | undefined>();
    const [workbook, setWorkbook] = React.useState<WorkBook | undefined>();
    const [selectedTables, setSelectedTables] = React.useState<
      any[] | undefined
    >();
    const [tableFieldsMap, setTableFieldsMap] = useState<any>();
    const fieldsMapperRef: any = useRef();

    useImperativeHandle(ref, () => ({
      show: (tables: SheetTable[]) => {
        setShowItem(tables);
        setWorkbook(undefined);
        setSelectedTables(undefined);
        setTableFieldsMap(undefined);
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setShowItem(undefined);
      setWorkbook(undefined);
      setSelectedTables(undefined);
      setTableFieldsMap(undefined);
    };

    const calImportData = () => {
      let data: any = [];
      if (!workbook) {
        message.error(t("table.import.upload_blank"));
        return data;
      }
      if (Object.keys(tableFieldsMap || {})?.length < 1) {
        message.error(t("table.import.table_blank"));
        return data;
      }
      const mapRet = form.getFieldsValue();
      try {
        Object.keys(mapRet).forEach((table: string) => {
          if (!mapRet[table]?.sheet) {
            fieldsMapperRef.current && fieldsMapperRef.current.changeTab(table);
            message.error(t("table.import.table_blank"));
            throw "error";
          }
          const fields = Object.values(mapRet[table]?.fields || {});
          if (!fields?.find((f: any) => f.selected)) {
            fieldsMapperRef.current && fieldsMapperRef.current.changeTab(table);
            message.error(t("table.import.field_blank"));
            throw "error";
          }
          if (fields?.find((f: any) => f.selected && !f.column)) {
            fieldsMapperRef.current && fieldsMapperRef.current.changeTab(table);
            message.error(t("table.import.match_blank"));
            throw "error";
          }
        });
      } catch (e) {
        return data;
      }
      GlobalLoader.show();
      const tables = Object.keys(mapRet);
      for (let i = 0, len = tables.length; i < len; i++) {
        if (!data[i]) {
          data[i] = {
            identifier: tables[i],
            data: [],
          };
        }
        const tableMap = mapRet[tables[i]];
        let currentSheet = workbook?.Sheets[tableMap.sheet];
        const sheetData = XLSX.utils.sheet_to_json(currentSheet, {
          defval: "",
          raw: false,
        });
        const tableFields =
          showItem?.find((t: SheetTable) => t.identifier === tables[i])?.meta
            ?.fields || [];
        for (let r = 0, len = sheetData.length; r < len; r++) {
          let newRow: any = {};
          let rowData: any = sheetData[r];
          for (let c = 0, clen = tableFields.length; c < clen; c++) {
            const field = tableFields[c];
            const sheetfield = tableMap.fields[field.identifier || ""];
            if (sheetfield && !!sheetfield.selected && !!sheetfield.column) {
              switch (field.type?.toUpperCase()) {
                case "NUMBER":
                  newRow[field.identifier || ""] = toNumber(
                    rowData[sheetfield.column]
                  );
                  break;
                case "BOOLEAN":
                  newRow[field.identifier || ""] = toBoolean(
                    rowData[sheetfield.column]
                  );
                  break;
                case "DATE":
                  const value = getFormatDate_XLSX(rowData[sheetfield.column]);
                  newRow[field.identifier || ""] = !!value.getTime()
                    ? toDate(value)
                    : rowData[sheetfield.column];
                  break;
                default:
                  newRow[field.identifier || ""] = `${
                    rowData[sheetfield.column]
                  }`;
                  break;
              }
            }
          }
          data[i].data.push(newRow);
        }
      }
      return data;
    };

    const handleSubmitAndLock = () => {
      const data = calImportData();
      if (data.length > 0) {
        props.submitCallback && props.submitCallback(data, true, true);
      }
    };

    const handleSubmitAndSave = () => {
      const data = calImportData();
      if (data.length > 0) {
        props.submitCallback && props.submitCallback(data, true);
      }
    };

    const handleSubmit = () => {
      const data = calImportData();
      if (data.length > 0) {
        props.submitCallback && props.submitCallback(data);
      }
    };

    const FileUploader = () => {
      const handleUploadResult = (info: UploadChangeParam) => {
        const file: File | undefined = (info.fileList[0] || {})?.originFileObj;
        if (file) {
          try {
            fileToWorkBook(file).then((workbook) => {
              setWorkbook(workbook);
              GlobalLoader.hide();
            });
          } catch (e: any) {
            console.log(e.message);
            GlobalLoader.hide();
          }
        } else {
          GlobalLoader.hide();
        }
      };

      return (
        <>
          <Upload.Dragger
            name="file"
            accept=".xlsx, .xls, .csv"
            multiple={false}
            maxCount={1}
            beforeUpload={() => {
              GlobalLoader.show();
              return false;
            }}
            onChange={handleUploadResult}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("table.import.upload")}</p>
            <p className="ant-upload-hint">{t("table.import.upload_format")}</p>
          </Upload.Dragger>
        </>
      );
    };

    const TableSelecter = () => {
      const [indeterminate, setIndeterminate] = useState(false);
      const [checkAll, setCheckAll] = useState(false);

      const tables = showItem?.filter(
        (table: SheetTable) => table.table_type === "COMMON"
      );
      const currentAvailableTables =
        tables
          ?.filter((table: SheetTable) =>
            table?.tasks?.find(
              (task: Task) =>
                task.assignee === permission?.username && !task.is_locked
            )
          )
          .map((table: SheetTable) => table.identifier || "") || [];

      const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setSelectedTables(e.target.checked ? currentAvailableTables : []);
      };

      const handleTablesChange = (list: any[]) => {
        setSelectedTables(list as number[]);
      };

      useEffect(() => {
        const list =
          selectedTables?.filter(
            (t) => !!currentAvailableTables.includes(`${t}`)
          ) || [];
        setIndeterminate(
          !!list.length && list.length < currentAvailableTables.length
        );
        setCheckAll(list.length >= currentAvailableTables.length);
        if (list.join("-") !== (selectedTables || []).join("-")) {
          setSelectedTables(list);
          setIndeterminate(list.length < currentAvailableTables.length);
          setCheckAll(list.length >= currentAvailableTables.length);
        }
      }, [tables, currentAvailableTables, selectedTables]);

      return (
        <>
          <Checkbox
            style={{ marginBottom: "1em" }}
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            {t("common.check_all")}
          </Checkbox>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={handleTablesChange}
            className="compare-checkbox"
            value={selectedTables || []}
          >
            <Row gutter={[16, 10]} style={{ width: "100%" }}>
              {tables?.map((table: SheetTable) => (
                <Col key={table.identifier} xs={12} sm={8} md={6}>
                  <Tooltip
                    placement="topLeft"
                    overlayStyle={{ zIndex: 99999 }}
                    title={
                      currentAvailableTables.includes(`${table.identifier}`)
                        ? ""
                        : t("table.import.no_task_tips")
                    }
                  >
                    <Checkbox
                      value={table.identifier}
                      disabled={
                        !currentAvailableTables.includes(`${table.identifier}`)
                      }
                    >
                      <Typography.Text ellipsis={{ tooltip: table.name }}>
                        {table.name}
                      </Typography.Text>
                    </Checkbox>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </>
      );
    };

    const FieldsMapper = forwardRef((props, ref) => {
      const [currentTab, setCurrentTab] = useState<string | undefined>();
      const sourceFields = workbook?.SheetNames.reduce(
        (ret: any, sheetName: string) => {
          ret[sheetName] = calSourceFields(workbook?.Sheets[sheetName]);
          return ret;
        },
        {}
      );

      useImperativeHandle(ref, () => ({
        changeTab: (tab: string) => {
          setCurrentTab(tab);
        },
        close: handleCancel,
      }));

      useEffect(() => {
        form.resetFields();
        form.setFieldsValue(tableFieldsMap);
      }, [tableFieldsMap]);

      const handleValuesChange = (changedValues: any, allValues: any) => {
        try {
          const sheet = (Object.values(changedValues)[0] as any).sheet;
          if (!!sheet) {
            const table = Object.keys(changedValues)[0];
            form.setFieldValue(table, calSheetFieldMap(table, sheet));
          }
        } catch (e) {}
      };

      const onCheckAllChange = (table: string, checked: boolean) => {
        let value = { ...form.getFieldValue(table) };
        Object.keys(value?.fields || {}).forEach((f: string) => {
          value.fields[f] = {
            ...value?.fields[f],
            selected: checked,
          };
        });
        form.setFieldValue(table, value);
      };

      const items: TabsProps["items"] =
        selectedTables?.map((tindex: any) => {
          const table =
            showItem?.find((t: SheetTable) => t.identifier === `${tindex}`) ||
            {};
          return {
            key: table.identifier!,
            label: table.name!,
            forceRender: true,
            children: (
              <>
                <Form.Item name={[`${tindex}`, "sheet"]}>
                  <Select
                    style={{ width: "100%" }}
                    showSearch={true}
                    placeholder={t("table.import.table_blank")}
                    // value={tableFieldsMap?.get(`${table.identifier}`)?.sheet}
                    // onChange={(sheetName) => changeSelectSheet(table.identifier!, sheetName)}
                    options={(workbook?.SheetNames || []).map((sheetName) => ({
                      value: sheetName,
                      label: sheetName,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    (prevValues || {})[`${tindex}`] !==
                    (curValues || {})[`${tindex}`]
                  }
                >
                  {() => {
                    const fieldsMap = form.getFieldValue(`${tindex}`);
                    const fields = sourceFields[fieldsMap?.sheet || ""];
                    if (!fields) {
                      return null;
                    }
                    return (
                      <>
                        <Row
                          align="middle"
                          gutter={[8, 8]}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: "1em",
                          }}
                        >
                          <Col span={2}>
                            <Checkbox
                              indeterminate={
                                !!Object.values(fieldsMap?.fields || {}).find(
                                  (f: any) => !f.selected
                                ) &&
                                !!Object.values(fieldsMap?.fields || {}).find(
                                  (f: any) => f.selected
                                )
                              }
                              onChange={(e: CheckboxChangeEvent) =>
                                onCheckAllChange(
                                  table.identifier!,
                                  e.target.checked
                                )
                              }
                              checked={
                                !Object.values(fieldsMap?.fields || {}).find(
                                  (f: any) => !f.selected
                                )
                              }
                            />
                          </Col>
                          <Col span={10}>
                            <Typography.Text strong>
                              {t("table.import.target_field")}
                            </Typography.Text>
                          </Col>
                          <Col span={2}>
                            <ArrowLeftOutlined />
                          </Col>
                          <Col span={10}>
                            <Typography.Text strong>
                              {t("table.import.source_field")}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row
                          align="middle"
                          gutter={[8, 8]}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: "1em",
                          }}
                        >
                          {(table?.meta?.fields || []).map(
                            (field: TableField) => (
                              <React.Fragment key={field.identifier}>
                                <Col span={2}>
                                  <Form.Item
                                    name={[
                                      `${tindex}`,
                                      "fields",
                                      `${field.identifier}`,
                                      "selected",
                                    ]}
                                    style={{ marginBottom: 0 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                                <Col span={10}>{field.name}</Col>
                                <Col span={2}>
                                  <ArrowLeftOutlined />
                                </Col>
                                <Col span={10}>
                                  <Form.Item
                                    name={[
                                      `${tindex}`,
                                      "fields",
                                      `${field.identifier}`,
                                      "column",
                                    ]}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Select
                                      allowClear={true}
                                      style={{ width: "100%" }}
                                      options={fields.map(
                                        (fieldName: string) => {
                                          return {
                                            key: fieldName,
                                            label: fieldName,
                                            value: fieldName,
                                          } as any;
                                        }
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </React.Fragment>
                            )
                          )}
                        </Row>
                      </>
                    );
                  }}
                </Form.Item>
              </>
            ),
          };
        }) || [];

      return (
        <Form
          name="fieldmap-form"
          form={form}
          onValuesChange={handleValuesChange}
        >
          <Tabs
            items={items}
            activeKey={currentTab}
            onChange={(activeKey: string) => setCurrentTab(activeKey)}
          />
        </Form>
      );
    });

    const handleGoBackUpload = () => {
      setWorkbook(undefined);
      setSelectedTables(undefined);
      setTableFieldsMap(undefined);
    };

    const handleGoBackSelectTables = () => {
      setSelectedTables(undefined);
      setTableFieldsMap(undefined);
    };

    const calSourceFields = (worksheet: any) => {
      let sourceFields: string[] = [];
      try {
        if (!!worksheet) {
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          for (let C = range.s.c; C <= range.e.c; C++) {
            const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: 0 })];
            sourceFields.push(cell.v);
          }
        }
      } catch (e) {
        console.log(e);
      }
      return sourceFields;
    };

    const calSheetFieldMap = (table_identifier: string, sheet?: string) => {
      const tableInfo = showItem?.find(
        (t: SheetTable) => t.identifier === table_identifier
      );
      if (!tableInfo) {
        return null;
      }
      const sheetName = sheet || tableInfo?.name || "";
      const tableSheet = workbook?.Sheets[sheetName];
      if (!tableSheet) {
        return {};
      }
      const sourceFields = calSourceFields(tableSheet);
      if ((sourceFields || []).length < 1) {
        return {};
      }
      let fieldmap: any = {};
      tableInfo?.meta?.fields?.forEach((field: TableField) => {
        let column = sourceFields?.find((c: string) => field.name === c);
        fieldmap[field.identifier!] = { selected: !!column, column: column };
      });
      return {
        sheet: sheetName,
        fields: fieldmap,
      };
    };

    const handleNextMapFields = () => {
      if ((selectedTables || [])?.length < 1) {
        message.error(t("table.import.table_blank"));
        return;
      }
      let map: any = {};
      for (let i = 0, len = selectedTables?.length || 0; i < len; i++) {
        const table_identifier = (selectedTables || [])[i];
        let fieldMap = calSheetFieldMap(`${table_identifier}`);
        if (!!fieldMap) {
          map[`${table_identifier}`] = fieldMap;
        }
      }
      setTableFieldsMap(map);
    };

    return (
      <Modal
        centered
        destroyOnClose
        title={t("table.import.batch_import")}
        open={!!showItem}
        onCancel={handleCancel}
        width={700}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t("common.cancel")}
          </Button>,
          ...(!!workbook
            ? [
                <Button
                  key="previous"
                  onClick={
                    tableFieldsMap
                      ? handleGoBackSelectTables
                      : handleGoBackUpload
                  }
                >
                  {t("common.step.previous")}
                </Button>,
              ]
            : []),
          ...(!!workbook && !tableFieldsMap
            ? [
                <Button key="next" type="primary" onClick={handleNextMapFields}>
                  {t("common.step.next")}
                </Button>,
              ]
            : []),
          ...(!!tableFieldsMap
            ? [
                <Button
                  key="submit_and_lock"
                  type="primary"
                  onClick={handleSubmitAndLock}
                >
                  {t("table.import.import_and_lock")}
                </Button>,
                <Button
                  key="submit_and_save"
                  type="primary"
                  onClick={handleSubmitAndSave}
                >
                  {t("table.import.import_and_save")}
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                  {t("common.import")}
                </Button>,
              ]
            : []),
        ]}
      >
        <Steps
          size="small"
          type="navigation"
          current={!workbook ? 0 : !tableFieldsMap ? 1 : 2}
          items={[
            { title: t("table.import.upload") },
            { title: t("table.import.select_tables") },
            { title: t("table.import.match_field") },
          ]}
        />
        <div style={{ marginBottom: "2em" }} />
        {!workbook ? (
          <FileUploader />
        ) : !tableFieldsMap ? (
          <TableSelecter />
        ) : (
          <FieldsMapper ref={fieldsMapperRef} />
        )}
      </Modal>
    );
  }
);

export default TableImportBatchModal;
