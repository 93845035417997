import { Modal, ModalProps, message } from "antd";
import { bindActionCreators } from "redux";
import { setCurrentWorkspace } from "../store/account";
import { connect, Provider } from "react-redux";
import { useEffect, useState } from "react";
import { Workspace } from "../types/Workspace";
import store from "../store";
import ReactDOM from "react-dom/client";
import { getWorkspaceApi } from "../api";

export interface WorkspacePermissionModalProps extends ModalProps {
  hasPermission: boolean;
  targetWorkspaceId?: number;
  onCancel?: () => void;
  setCurrentWorkspace: (data: any) => void;
}

const WorkspacePermissionModal = (props: WorkspacePermissionModalProps) => {
  const [targetWorkspace, setTargetWorkspace] = useState<Workspace>();

  useEffect(() => {
    if (props.targetWorkspaceId) {
      getWorkspaceApi({ workspaceId: props.targetWorkspaceId }).then((res) => {
        if (res.success) {
          setTargetWorkspace(res.data);
        }
      });
    }
  }, [props.targetWorkspaceId]);

  const onOK = () => {
    if (props.hasPermission) {
      changeWorkspace();
      props.onCancel?.();
    } else {
      props.onCancel?.();
    }
  };

  const changeWorkspace = () => {
    props.setCurrentWorkspace({
      id: targetWorkspace?.id,
      fail: () => {
        message.error("切换空间失败");
      },
    });
  };

  return (
    <Modal
      {...props}
      centered={true}
      title="提示"
      okText={props.hasPermission ? "确定切换" : "确定"}
      cancelButtonProps={
        props.hasPermission ? { hidden: false } : { hidden: true }
      }
      onCancel={props.onCancel}
      onOk={onOK}
    >
      {props.hasPermission ? (
        <div>当前数据属于【{targetWorkspace?.name}】空间，是否切换空间？</div>
      ) : (
        <div>没有当前【{targetWorkspace?.name}】空间的权限，请向管理员申请</div>
      )}
    </Modal>
  );
};

const mapStateToProps = (store: any) => ({
  workspaces: store.account.workspaces,
  currentWorkspace: store.account.currentWorkspace,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ setCurrentWorkspace }, dispatch);

const ConnectedWorkspacePermissionModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspacePermissionModal);

export function showWorkspacePermissionModal(
  props: Omit<WorkspacePermissionModalProps, "setCurrentWorkspace">
) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  const close = () => {
    // 在 React 18 中，你可以通过调用 root.unmount() 来卸载和清理组件
    root.unmount();
    document.body.removeChild(div);
  };

  const finalProps = {
    open: true,
    ...props,
    onCancel: () => {
      props.onCancel?.();
      close();
    },
  };

  // 在 React 18 中，使用 ReactDOM.createRoot 替代了 ReactDOM.render
  const root = ReactDOM.createRoot(div);

  root.render(
    <Provider store={store}>
      <ConnectedWorkspacePermissionModal {...finalProps} />
    </Provider>
  );
}
