import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import {
  DatasetTemplateTable,
  DatasetTemplateTableField,
  DatasetTemplate,
} from "../../../types/DatasetTemplate";
import {
  TableType as TableTypeEnum,
  fieldTypeToTitle,
  TableFieldType,
} from "../../../types/Table";
import { Drawer } from "antd";
import {
  Tooltip,
  message,
  Form,
  Input,
  Select,
  Checkbox,
  Space,
  Typography,
  Button,
  Table,
  Modal,
  Radio,
  InputNumber,
  Tag,
} from "antd";
import { DragOutlined, InfoCircleOutlined } from "@ant-design/icons";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { v4 as uuidv4 } from "uuid";

const getIndexInParent = (el: Element | undefined) => {
  if (el) {
    return Array.from(el.parentNode!.children).indexOf(el);
  } else {
    return 0;
  }
};

const TableFieldInfoModal = forwardRef(
  (
    props: {
      table_type: string;
      submitCallback: (
        data: DatasetTemplateTableField,
        closeModal: () => void
      ) => void;
      referenceTables?: DatasetTemplateTable[];
    },
    ref
  ) => {
    const [showItem, setShowItem] = useState<
      DatasetTemplateTableField | undefined
    >();
    const [selectedReferenceTable, setSelectedReferenceTable] = useState<
      DatasetTemplateTable | undefined
    >();

    const [form] = Form.useForm();

    useEffect(() => {
      form.resetFields();
      form.setFieldsValue({
        is_pk: false,
        is_unique: false,
        is_nullable: true,
        readonly: false,
        ...showItem,
      });
      if (!!showItem?.fk_to) {
        setSelectedReferenceTable(
          props?.referenceTables?.find(
            (table: DatasetTemplateTable) => table.temp_id === showItem?.fk_to
          )
        );
      }
    }, [showItem, form, props?.referenceTables]);

    useImperativeHandle(ref, () => ({
      show: (item: DatasetTemplateTableField) => {
        setShowItem(item);
      },
    }));

    const handleCancel = () => setShowItem(undefined);

    const handleChangeFkto = (temp_id: string) => {
      const FkTable = (props.referenceTables || []).find(
        (item) => item.temp_id === temp_id
      );
      setSelectedReferenceTable(FkTable);
      form.setFieldsValue({
        fk_field: null,
        fk_literal: null,
      });
    };

    const handleSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      props.submitCallback &&
        props.submitCallback(form.getFieldsValue(), handleCancel);
    };

    return (
      <Modal
        centered
        title={showItem?.name ? "编辑字段" : "新建字段"}
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
      >
        <Form form={form} labelCol={{ span: 6 }}>
          <Form.Item name="temp_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name={["name", "zh"]}
            label="字段名称(中)"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["name", "en"]}
            label="字段名称(英)"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="identifier"
            label={"字段标识"}
            rules={[
              { required: true },
              {
                max: 64,
                message: "最长64位",
              },
              {
                pattern: /^[a-z][a-z_$0-9]{0,63}$/,
                message:
                  "仅支持小写字母、下划线、数字以及美元符号（$），且必须以小写字母开头",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {props.table_type === TableTypeEnum.COMMON && (
            <Form.Item
              name="readonly"
              valuePropName="checked"
              label={"是否只读"}
            >
              <Checkbox />
            </Form.Item>
          )}

          <Form.Item
            name="is_pk"
            label={"是否主键"}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="is_unique"
            label={"是否唯一"}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="is_nullable"
            label={"是否允许空值"}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="type"
            label={"字段类型"}
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="TEXT">文本</Select.Option>
              <Select.Option value="NUMBER">数字</Select.Option>
              <Select.Option value="DATE">日期</Select.Option>
              <Select.Option value="BOOLEAN">布尔值</Select.Option>
              {(props.referenceTables || []).length > 0 && (
                <Select.Option value="REFERENCE">引用</Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.type !== curValues?.type
            }
          >
            {() => {
              if (form.getFieldValue("type") !== "NUMBER") {
                return null;
              }
              return (
                <Form.Item noStyle>
                  <Form.Item
                    name={["validation_rules", "minimum"]}
                    label={"最小值"}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    name={["validation_rules", "maximum"]}
                    label={"最大值"}
                  >
                    <InputNumber />
                  </Form.Item>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.type !== curValues?.type ||
              prevValues?.fk_to !== curValues?.fk_to
            }
          >
            {() => {
              if (form.getFieldValue("type") !== "REFERENCE") {
                return null;
              }
              return (
                <>
                  <Form.Item
                    name="fk_to"
                    label={"引用表"}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch={true}
                      onChange={handleChangeFkto}
                      options={(props.referenceTables || []).map((item) => ({
                        label: `${item.name?.zh}(${item.name?.en})`,
                        value: item.temp_id,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name="fk_field"
                    label={"引用字段"}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch={true}
                      options={(selectedReferenceTable?.fields || [])
                        .filter((item) => !!item.is_pk || !!item.is_unique)
                        .map((item) => ({
                          label: `${item.name?.zh}(${item.name?.en})`,
                          value: item.temp_id,
                        }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name="fk_literal"
                    label={"引用字段显示值"}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch={true}
                      options={(selectedReferenceTable?.fields || []).map(
                        (item) => ({
                          label: `${item.name?.zh}(${item.name?.en})`,
                          value: item.temp_id,
                        })
                      )}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

const DatasetTemplateTableModal = forwardRef(
  (
    props: {
      submitCallback?: (data: DatasetTemplateTable) => void;
    },
    ref
  ) => {
    const [showItem, setShowItem] = useState<
      DatasetTemplateTable | undefined
    >();
    const [referenceTables, setReferencedTables] = useState<
      DatasetTemplateTable[]
    >([]);
    const [form] = Form.useForm();

    const drakeRef: any = useRef();
    const tableFieldModalRef: any = useRef();
    const templateDataRef: any = useRef();

    useImperativeHandle(ref, () => ({
      show: (
        item: DatasetTemplateTable,
        template: DatasetTemplate,
        referenceTables: DatasetTemplateTable[]
      ) => {
        setShowItem(item);
        form.resetFields();
        form.setFieldsValue({ ...item });
        templateDataRef.current = template;
        setReferencedTables(referenceTables);
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    useEffect(() => {
      !!drakeRef.current && drakeRef.current.destroy();
      let start: number;
      let end: number;
      const container = document.querySelector(".ant-table-tbody")!;
      drakeRef.current = dragula([container], {
        moves: (el) => {
          start = getIndexInParent(el);
          return true;
        },
        invalid: function (el, handle) {
          return !["svg", "path"].includes(handle?.tagName || "");
        },
      });

      const handleReorder = (dragIndex: number, droppedIndex: number) => {
        const oldState = form.getFieldValue("fields") || [];
        const newState = [...oldState];
        const item = newState.splice(dragIndex - 1, 1)[0];
        newState.splice(droppedIndex - 1, 0, item);
        form.setFieldsValue({ fields: newState });
      };

      drakeRef.current.on("drop", (el: any) => {
        end = getIndexInParent(el);
        handleReorder(start, end);
      });
    }, [showItem, form]);

    const handleTableFieldInfoSubmit = (
      data: DatasetTemplateTableField,
      closeModal: () => void
    ) => {
      const OriginFields = form.getFieldValue("fields") || [];
      const OriginField = !!data?.temp_id
        ? OriginFields.find(
            (f: DatasetTemplateTableField) => f.temp_id === data.temp_id
          )
        : undefined;
      if (!!data?.temp_id && showItem?.table_type === TableTypeEnum.PRIMARY) {
        if (
          OriginField.type !== data.type &&
          templateDataRef.current?.manifest?.tables.find(
            (t: DatasetTemplateTable) =>
              t.table_type === "TABLEVIEW" &&
              t.parent_table === showItem?.temp_id &&
              t.filters?.items?.find((item) => item.field === data?.temp_id)
          )
        ) {
          message.error("该字段已引用筛选，不可修改字段类型");
          return;
        }
        if (
          !data.is_pk &&
          !data.is_unique &&
          templateDataRef.current?.manifest?.tables.find(
            (t: DatasetTemplateTable) =>
              t.table_type === TableTypeEnum.COMMON &&
              t.fields?.find(
                (f: DatasetTemplateTableField) =>
                  f.fk_to === showItem?.temp_id && f.fk_field === data.temp_id
              )
          )
        ) {
          message.error("该字段已引用，必须是主键或者唯一");
          return;
        }
      }
      let FieldsRet = [];
      if (!!data?.temp_id) {
        FieldsRet = OriginFields.map((item: DatasetTemplateTableField) =>
          data.temp_id === item.temp_id ? data : item
        );
      } else {
        FieldsRet = [...OriginFields, { ...data, temp_id: uuidv4() }];
      }
      form.setFieldsValue({ fields: FieldsRet });
      closeModal();
    };

    const handleEditField = (field: DatasetTemplateTableField) => {
      tableFieldModalRef?.current && tableFieldModalRef?.current.show(field);
    };

    const handleDeleteField = (field: DatasetTemplateTableField) => {
      if (
        !!showItem?.temp_id &&
        showItem?.table_type === TableTypeEnum.PRIMARY
      ) {
        if (
          templateDataRef.current?.manifest?.tables.find(
            (t: DatasetTemplateTable) =>
              t.table_type === TableTypeEnum.COMMON &&
              t.fields?.find(
                (f: DatasetTemplateTableField) =>
                  f.fk_to === showItem?.temp_id &&
                  (f.fk_field === field.temp_id ||
                    f.fk_literal === field.temp_id)
              )
          )
        ) {
          message.error("该字段已引用，不可删除");
          return;
        }

        if (
          templateDataRef.current?.manifest?.tables.find(
            (t: DatasetTemplateTable) =>
              t.table_type === "TABLEVIEW" &&
              t.parent_table === showItem?.temp_id &&
              (t.columns?.includes(field?.temp_id || "") ||
                t.filters?.items?.find((item) => item.field === field?.temp_id))
          )
        ) {
          message.error("该字段已引用，不可删除");
          return;
        }
      }
      const OriginFields = form.getFieldValue("fields") || [];
      form.setFieldsValue({
        fields: OriginFields.filter(
          (item: DatasetTemplateTableField) => item.temp_id !== field.temp_id
        ),
      });
    };

    const handleSubmit = (data: any) => {
      let tableData: DatasetTemplateTable = {
        temp_id: data.temp_id,
        table_type: data.table_type,
        name: data.name,
        description: data.description,
        fields: data.fields,
      };
      if (data.table_type === TableTypeEnum.PRIMARY) {
        tableData.creatable =
          !!data.creatable &&
          !!data.fields?.find(
            (field: DatasetTemplateTableField) => !!field.is_pk
          );
      }
      props.submitCallback && props.submitCallback(tableData);
      handleCancel();
    };

    return (
      <>
        <TableFieldInfoModal
          ref={tableFieldModalRef}
          referenceTables={referenceTables}
          submitCallback={handleTableFieldInfoSubmit}
          table_type={showItem?.table_type || TableTypeEnum.PRIMARY}
        />
        <Drawer
          title={showItem?.index !== undefined ? "编辑" : "新增"}
          placement="right"
          onClose={handleCancel}
          open={!!showItem}
          width={"max(50vw, 800px)"}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="common-padding"
          >
            <Form.Item name="index" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="temp_id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="table_type" hidden>
              <Input />
            </Form.Item>
            <div className="d-flex">
              <Form.Item
                name={["name", "zh"]}
                label="表名称（中）"
                style={{ flex: 1 }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["name", "en"]}
                label="表名称（英）"
                style={{ flex: 1 }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="d-flex">
              <Form.Item
                name={["description", "zh"]}
                label="表描述（中）"
                style={{ flex: 1 }}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item
                name={["description", "en"]}
                label="表描述（英）"
                style={{ flex: 1 }}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
            {showItem?.table_type === TableTypeEnum.PRIMARY && (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues?.fields !== curValues?.fields
                }
              >
                {() => {
                  const fields = (form.getFieldValue("fields") ||
                    []) as DatasetTemplateTableField[];
                  if (
                    !fields.find(
                      (field: DatasetTemplateTableField) => !!field.is_pk
                    )
                  ) {
                    return null;
                  }
                  return (
                    <Form.Item
                      name="creatable"
                      valuePropName="checked"
                      label={"是否允许用户选择创建该基础数据表"}
                    >
                      <Checkbox />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}
            <Form.Item name="fields" hidden>
              <Input />
            </Form.Item>
            <div
              className="d-flex"
              style={{
                marginBottom: "1em",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography.Title level={5}>表字段：</Typography.Title>
              <Button type="primary" onClick={() => handleEditField({})}>
                新增字段
              </Button>
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues?.fields !== curValues?.fields
              }
            >
              {() => {
                const fields = (form.getFieldValue("fields") ||
                  []) as DatasetTemplateTableField[];
                return (
                  <Table
                    size="small"
                    rowClassName={"field-list-dragula-table-row"}
                    rowKey={(r) => r?.temp_id || ""}
                    className={"field-list-dragula-table"}
                    scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                    pagination={false}
                    columns={[
                      {
                        key: "drag",
                        title: "",
                        className: "drag-column",
                        render: (d: DatasetTemplateTableField) => (
                          <DragOutlined className="draggable" type="swap" />
                        ),
                      },
                      {
                        key: "name",
                        title: "字段名称",
                        render: (d: DatasetTemplateTableField) => (
                          <Space
                            size={[5, 0]}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {d.name?.zh}
                            {!!d.readonly && <Tag color="red">只读</Tag>}
                          </Space>
                        ),
                      },
                      {
                        key: "identifier",
                        title: "字段标识",
                        dataIndex: "identifier",
                      },
                      {
                        key: "type",
                        title: "字段类型",
                        render: (d: DatasetTemplateTableField) => {
                          if (d?.type === "REFERENCE") {
                            const fk_table = referenceTables?.find(
                              (table: DatasetTemplateTable) =>
                                table.temp_id === d.fk_to
                            );
                            return (
                              <Space>
                                {fieldTypeToTitle[d?.type as TableFieldType]}
                                <Tooltip
                                  title={
                                    <>
                                      <p>
                                        引用表：
                                        {fk_table?.name?.zh ||
                                          fk_table?.name?.en}
                                      </p>
                                      <p>
                                        引用字段：
                                        {(
                                          fk_table?.fields?.find(
                                            (
                                              field: DatasetTemplateTableField
                                            ) => field.temp_id === d?.fk_field
                                          ) || {}
                                        ).name?.zh || d?.fk_field}
                                      </p>
                                      <p>
                                        引用字段显示值：
                                        {(
                                          fk_table?.fields?.find(
                                            (
                                              field: DatasetTemplateTableField
                                            ) => field.temp_id === d?.fk_literal
                                          ) || {}
                                        ).name?.zh || d?.fk_literal}
                                      </p>
                                    </>
                                  }
                                >
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </Space>
                            );
                          } else {
                            return fieldTypeToTitle[d?.type as TableFieldType];
                          }
                        },
                      },
                      {
                        title: "是否主键",
                        dataIndex: "is_pk",
                        key: "is_pk",
                        render: (record: any) => (record ? "是" : "否"),
                      },
                      {
                        title: "是否唯一",
                        dataIndex: "is_unique",
                        key: "is_unique",
                        render: (record: any) => (record ? "是" : "否"),
                      },
                      {
                        title: "是否允许空值",
                        dataIndex: "is_nullable",
                        key: "is_nullable",
                        render: (record: any) => (record ? "是" : "否"),
                      },
                      {
                        key: "actions",
                        title: "操作",
                        render: (
                          d: DatasetTemplateTableField,
                          value: DatasetTemplateTableField,
                          index: number
                        ) => (
                          <>
                            <Button
                              size="small"
                              type="link"
                              onClick={() => handleEditField(d)}
                            >
                              编辑
                            </Button>
                            <Button
                              size="small"
                              type="link"
                              danger
                              onClick={() => handleDeleteField(d)}
                            >
                              删除
                            </Button>
                          </>
                        ),
                      },
                    ]}
                    dataSource={fields || []}
                  />
                );
              }}
            </Form.Item>
            <Space
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2em",
              }}
            >
              <Button onClick={handleCancel}>取消</Button>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </Space>
          </Form>
        </Drawer>
      </>
    );
  }
);

export default DatasetTemplateTableModal;
