import { SheetTable } from "../types/Table";
import { Task } from "../types/Dataset";
import { calCompareTableDataStatistics } from "./SheetCompareUtils";

const checkYiliDemandPlan = (tables: SheetTable[]) => {
  const DemandTable = "需求计划";
  const ProductionTable = "生产计划";
  const TargetDemandTable = "配平需求计划";

  const ProductFieldEnum = ["product_name", "productname"];
  let productionTableProductField = "productname";
  let demandTableProductField = "productname";
  const ValueField = "number";

  const productionPlanMap: any = {};
  const demandPlanMap: any = {};

  let currentDemandTable;

  for (let i = 0, len = tables.length; i < len; i++) {
    let table = tables[i];

    const TableData = table?.all_data || table?.data || [];

    if ([table.origin_table_name, table.name].includes(ProductionTable)) {
      if (!!(TableData || [])[0]) {
        const firstRow = TableData[0];
        for (
          let feindex = 0, felen = ProductFieldEnum.length;
          feindex < felen;
          feindex++
        ) {
          if (firstRow[ProductFieldEnum[feindex]]) {
            productionTableProductField = ProductFieldEnum[feindex];
            break;
          }
        }
      }
    } else if ([table.origin_table_name, table.name].includes(DemandTable)) {
      if (!!(TableData || [])[0]) {
        const firstRow = TableData[0];
        for (
          let feindex = 0, felen = ProductFieldEnum.length;
          feindex < felen;
          feindex++
        ) {
          if (firstRow[ProductFieldEnum[feindex]]) {
            demandTableProductField = ProductFieldEnum[feindex];
            break;
          }
        }
      }
    }

    if ([table.origin_table_name, table.name].includes(ProductionTable)) {
      for (let r = 0, rlen = TableData.length || 0; r < rlen; r++) {
        let productname = TableData[r][productionTableProductField] || "";
        productionPlanMap[productname] =
          (productionPlanMap[productname] || 0) +
          (TableData[r][ValueField] || 0);
      }
    } else if ([table.origin_table_name, table.name].includes(DemandTable)) {
      currentDemandTable = table;
      for (let r = 0, rlen = TableData.length || 0; r < rlen; r++) {
        let productname = TableData[r][demandTableProductField] || "";
        if (!demandPlanMap[productname]) {
          demandPlanMap[productname] = { sum: 0, count: 0 };
        }
        demandPlanMap[productname] = {
          sum: demandPlanMap[productname].sum + (TableData[r][ValueField] || 0),
          count: demandPlanMap[productname].count + 1,
        };
      }
    }
  }

  for (let product in demandPlanMap) {
    const rate = productionPlanMap[product] / demandPlanMap[product].sum;
    if (productionPlanMap[product] !== undefined && rate !== 1) {
      let table =
        currentDemandTable?.table_type === "PRIMARY"
          ? tables.find((table) => table.name === TargetDemandTable)
          : currentDemandTable;
      const ret = {
        status: false,
        table_id: table?.id,
        table: table?.identifier,
        origin_table: currentDemandTable?.identifier,
        origin_data:
          currentDemandTable?.table_type === "PRIMARY"
            ? [
                {
                  data: currentDemandTable?.data || [],
                },
              ]
            : null,
        data: (currentDemandTable?.table_type === "PRIMARY"
          ? [
              {
                data: currentDemandTable?.data || [],
              },
            ]
          : currentDemandTable?.tasks || []
        ).map((task: Task) => ({
          ...task,
          data: (task.data || []).map((item: any) => {
            let retData = { ...item };
            if (demandPlanMap[retData[demandTableProductField]].sum === 0) {
              retData[ValueField] = Number(
                (
                  (productionPlanMap[retData[productionTableProductField]] *
                    1.0) /
                  demandPlanMap[retData[demandTableProductField]].count
                ).toFixed(2)
              );
            } else {
              retData[ValueField] = Number(
                (
                  (retData[ValueField] *
                    1.0 *
                    productionPlanMap[retData[productionTableProductField]]) /
                  demandPlanMap[retData[demandTableProductField]].sum
                ).toFixed(2)
              );
            }
            return retData;
          }),
        })),
      };
      const compareDataRet = calCompareTableDataStatistics(
        {
          id: table?.id,
          name: table?.name,
          meta: table?.meta,
          data: (table?.tasks || []).reduce((ret: any, task: any) => {
            ret = ret.concat(task.data || []);
            return ret;
          }, []),
        },
        {
          data: (ret.data || []).reduce((ret: any, task: any) => {
            ret = ret.concat(task.data || []);
            return ret;
          }, []),
        }
      );
      if (
        !compareDataRet?.delete_count &&
        !compareDataRet.add_count &&
        !compareDataRet.change_data?.length
      ) {
        return { status: true };
      }
      return ret;
    }
  }

  if (currentDemandTable?.table_type === "PRIMARY") {
    let table =
      currentDemandTable?.table_type === "PRIMARY"
        ? tables.find((table) => table.name === TargetDemandTable)
        : null;
    return {
      status: true,
      table_id: table?.id,
      table: table?.identifier,
      origin_data: [
        {
          data: currentDemandTable?.data || [],
        },
      ],
    };
  } else {
    return { status: true };
  }
};

export const checkBalance = (tables: SheetTable[]) => {
  if (window?.location?.hostname?.includes("digitalyili.com")) {
    return checkYiliDemandPlan(tables);
  }

  return { status: true };
};
