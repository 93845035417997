export const colorTheme = [
  {
    value: "theme_color_1",
    colors: [
      "#4777FF",
      "#5BBCFF",
      "#FFC44F",
      "#FF9A2A",
      "#F56E34",
      "#7E36E2",
      "#7CB305",
    ],
  },
  {
    value: "theme_color_2",
    colors: [
      "#222C66",
      "#5561AA",
      "#E8520F",
      "#F58252",
      "#E9C296",
      "#FFE7BA",
      "#ECEFF1",
    ],
  },
  {
    value: "theme_color_3",
    colors: [
      "#460870",
      "#BD1E30",
      "#E93527",
      "#F57244",
      "#F5BA3B",
      "#0FB2C4",
      "#00707C",
    ],
  },
  {
    value: "theme_color_4",
    colors: [
      "#02178B",
      "#4891F5",
      "#9FAEF4",
      "#F56B87",
      "#F49CAF",
      "#02178B",
      "#F5F5F5",
    ],
  },
  {
    value: "theme_color_5",
    colors: [
      "#0C40D6",
      "#4777FF",
      "#7E36E2",
      "#F5026B",
      "#F15207",
      "#F5B70A",
      "#FBE9E7",
    ],
  },
  {
    value: "theme_color_6",
    colors: [
      "#220B00",
      "#E88000",
      "#F5C206",
      "#618801",
      "#004F00",
      "#A3CED8",
      "#ECEFF1",
    ],
  },
  {
    value: "theme_color_7",
    colors: [
      "#DD4133",
      "#C02826",
      "#EDB002",
      "#DD8B02",
      "#407BE6",
      "#2057BF",
      "#349D50",
    ],
  },
  {
    value: "theme_color_8",
    colors: [
      "#3641C7",
      "#14016A",
      "#889CF4",
      "#F5F5F5",
      "#F4BC61",
      "#EF5F3C",
      "#F67CA2",
    ],
  },
  {
    value: "red",
    colors: [
      "#B71C1C",
      "#C62828",
      "#D32F2F",
      "#E53935",
      "#F44336",
      "#EF5350",
      "#E57373",
      "#EF9A9A",
      "#F8BBD0",
      "#FFEBEE",
    ],
  },
  {
    value: "pink",
    colors: [
      "#880E4F",
      "#A3154A",
      "#C2185B",
      "#D81B60",
      "#E91E63",
      "#EC407A",
      "#F06292",
      "#F48FB1",
      "#F8BBD0",
      "#FCE4EC",
    ],
  },
  {
    value: "purple",
    colors: [
      "#4A148C",
      "#6A1B9A",
      "#7B1FA2",
      "#8E24AA",
      "#9C27B0",
      "#AB47BC",
      "#BA68C8",
      "#CE93D8",
      "#E1BEE7",
      "#F3E5F5",
    ],
  },
  {
    value: "deep_purple",
    colors: [
      "#311B92",
      "#4027A0",
      "#4B34AE",
      "#5E35B1",
      "#673AB7",
      "#7E57C2",
      "#9575CD",
      "#B39DDB",
      "#D1C4E9",
      "#EDE7F6",
    ],
  },
  {
    value: "indigo",
    colors: [
      "#1A237E",
      "#232F9C",
      "#2E3AA1",
      "#3949AB",
      "#4455B4",
      "#5067BD",
      "#5C79C6",
      "#697BCF",
      "#8598DB",
      "#C5CAE9",
    ],
  },
  {
    value: "blue",
    colors: [
      "#0D47A1",
      "#1652B2",
      "#1F5EC2",
      "#1E88E5",
      "#2B95F0",
      "#399CFB",
      "#58A9F4",
      "#76B7ED",
      "#94C4E6",
      "#BBDEFB",
    ],
  },
  {
    value: "light_blue",
    colors: [
      "#01579B",
      "#026BB2",
      "#0280C9",
      "#039BE5",
      "#2AA5EB",
      "#52B0F1",
      "#7ABCF7",
      "#A3C7FD",
      "#CBE3FE",
      "#E1F5FE",
    ],
  },
  {
    value: "cyan",
    colors: [
      "#006064",
      "#007276",
      "#008587",
      "#00ACC1",
      "#20BFD9",
      "#40D2F0",
      "#71E0F7",
      "#A1EEFE",
      "#C9F7FE",
      "#E0F7FA",
    ],
  },
  {
    value: "teal",
    colors: [
      "#004D40",
      "#00614F",
      "#00755F",
      "#00897B",
      "#20A18C",
      "#41B99D",
      "#72D1AE",
      "#A3E8BF",
      "#CEEFD0",
      "#E0F2F1",
    ],
  },
  {
    value: "green",
    colors: [
      "#1B5E20",
      "#2C6F2C",
      "#3E8338",
      "#43A047",
      "#62B059",
      "#82C06A",
      "#A2D07C",
      "#C2E08D",
      "#D8EB9E",
      "#E8F5E9",
    ],
  },
  {
    value: "light_green",
    colors: [
      "#33691E",
      "#467D28",
      "#5B9232",
      "#7CB342",
      "#8EBF53",
      "#A3CA63",
      "#B8D574",
      "#CEDF84",
      "#E3EA95",
      "#F1F8E9",
    ],
  },
  {
    value: "lime",
    colors: [
      "#827717",
      "#8F8B1F",
      "#9B9F28",
      "#C0CA33",
      "#C8CF45",
      "#D1D457",
      "#DAD96A",
      "#E3DE7C",
      "#ECF29E",
      "#F9FBE7",
    ],
  },
  {
    value: "yellow",
    colors: [
      "#F57F17",
      "#F68E1D",
      "#F99D23",
      "#FDD835",
      "#FDE148",
      "#FEEA5A",
      "#FFF36D",
      "#FFF780",
      "#FFFC93",
      "#FFFDE7",
    ],
  },
  {
    value: "amber",
    colors: [
      "#FF6F00",
      "#FF8100",
      "#FF9400",
      "#FFB300",
      "#FFC013",
      "#FFCD27",
      "#FFDA3A",
      "#FFE64D",
      "#FFF360",
      "#FFF8E1",
    ],
  },
  {
    value: "orange",
    colors: [
      "#E65100",
      "#E96A00",
      "#EC8400",
      "#FB8C00",
      "#FDA203",
      "#FFB717",
      "#FFCC2B",
      "#FFE13F",
      "#FFF653",
      "#FFF3E0",
    ],
  },
  {
    value: "deep_orange",
    colors: [
      "#BF360C",
      "#C74610",
      "#D05613",
      "#F4511E",
      "#F4642C",
      "#F5783A",
      "#F78B48",
      "#FA9F56",
      "#FCB263",
      "#FBE9E7",
    ],
  },
  {
    value: "brown",
    colors: [
      "#3E2723",
      "#4A3229",
      "#57402E",
      "#6D4C41",
      "#84715B",
      "#9B9676",
      "#B2BA90",
      "#CADFAA",
      "#E1F3C4",
      "#EFEBE9",
    ],
  },
  {
    value: "grey",
    colors: [
      "#212121",
      "#3A3A3A",
      "#535353",
      "#757575",
      "#8E8E8E",
      "#A7A7A7",
      "#C0C0C0",
      "#D9D9D9",
      "#F2F2F2",
      "#FAFAFA",
    ],
  },
  {
    value: "blue_grey",
    colors: [
      "#263238",
      "#37464A",
      "#485B5D",
      "#546E7A",
      "#758991",
      "#96A4A9",
      "#B7BEC0",
      "#D8D9D8",
      "#F9F3EF",
      "#ECEFF1",
    ],
  },
];
