import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SheetTable, TableField } from "../../types/Table";
import { DatasetVersion } from "../../types/Dataset";
import { Button, Modal, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload";
import {
  createDatasetVersionTaskApi,
  updateDatasetVersionTaskDataApi,
} from "../../api/DatasetApi";
import { calCompareTableDataStatistics } from "../../utils/SheetCompareUtils";
import GlobalLoader from "../../components/GlobalLoading";
import { fileToWorkBook } from "../../utils/SheetUtils";
import * as XLSX from "xlsx";

const ImportData = (props: {
  version?: DatasetVersion | null;
  table?: SheetTable;
  refreshCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleCancel = () => setOpen(false);

  const cal_tasks_data = (fields: TableField[], data: any) => {
    let tasks_data: any = {};
    let origin_data: any = {};
    const tablePrimaryFields =
      fields.filter((field: TableField) => !!field.is_pk) || [];
    for (
      let ti = 0, tlen = (props.table?.tasks || []).length;
      ti < tlen;
      ti++
    ) {
      let task = props.table?.tasks[ti];
      let assignee = task.assignee;
      if (!origin_data[`${assignee}`]) {
        origin_data[`${assignee}`] = {
          task: task,
          data: {},
        };
      }
      for (let i = 0, len = (task.data || []).length; i < len; i++) {
        let row = task.data[i];
        let key = tablePrimaryFields
          .map((field: TableField) => row[`${field.identifier}`])
          .join("\t");
        origin_data[`${assignee}`].data[key] = true;
      }
    }
    for (let i = 0, len = data.length; i < len; i++) {
      let row = data[i];
      let assignee = row["分配人"];
      if (!tasks_data[`${assignee}`]) {
        tasks_data[`${assignee}`] = {
          assignee: assignee,
          id: origin_data[`${assignee}`]?.task?.id,
          data: [...(origin_data[`${assignee}`]?.task?.data || [])],
          is_locked: origin_data[`${assignee}`]?.task?.is_locked || false,
        };
      }
      let key = tablePrimaryFields
        .map((field: TableField) => row[`${field.name}`])
        .join("\t");
      if (!(origin_data[`${assignee}`]?.data || {})[key]) {
        let row_data = fields.reduce((ret: any, field: TableField) => {
          if (
            row[`${field.name}`] !== undefined &&
            row[`${field.name}`] !== null
          ) {
            ret[`${field.identifier}`] = row[`${field.name}`];
          }
          return ret;
        }, {});
        tasks_data[`${assignee}`].data.push(row_data);
      }
    }
    Object.keys(tasks_data).forEach((assignee: string) => {
      let originData: any = origin_data[`${assignee}`]?.task?.data || [];
      if (tasks_data[`${assignee}`].data.length === originData.length) {
        delete tasks_data[`${assignee}`];
      } else {
        tasks_data[`${assignee}`].origin_data = originData;
      }
    });
    return Object.values(tasks_data);
  };

  const handleUploadResult = (info: UploadChangeParam) => {
    const file: File | undefined = (info.fileList[0] || {})?.originFileObj;
    if (file) {
      try {
        fileToWorkBook(file)
          .then(async (workbook) => {
            let currentSheet: XLSX.WorkSheet | null = null;
            try {
              currentSheet = workbook?.Sheets[workbook.SheetNames[0]];
            } catch (e) {
              console.log(e);
            }
            if (!currentSheet) {
              throw "文件数据不存在";
            }

            const sheetData = XLSX.utils.sheet_to_json(currentSheet, {
              defval: "",
              raw: false,
            });
            if (!((sheetData[0] || {}) as any)["分配人"]) {
              throw "缺少分配人数据";
            }

            const tableFields = props.table?.meta?.fields || [];
            const tablePrimaryFields =
              tableFields.filter((field: TableField) => !!field.is_pk) || [];
            if (tablePrimaryFields.length < 1) {
              throw `缺少主键`;
            }
            tablePrimaryFields?.forEach((field: TableField) => {
              if (!((sheetData[0] || {}) as any)[`${field.name}`]) {
                throw `缺少主键【${field.name}】数据`;
              }
            });

            const new_tasks_data = cal_tasks_data(tableFields, sheetData);

            if (new_tasks_data.length < 1) {
              message.info(`暂无更新数据`);
              GlobalLoader.hide();
              handleCancel();
              return;
            }

            let success_tasks = [];
            let fail_tasks = [];
            for (let i = 0, len = new_tasks_data.length; i < len; i++) {
              let task: any = new_tasks_data[i];
              if (!task.id) {
                let taskRes = await createDatasetVersionTaskApi({
                  assignee: task.assignee,
                  table_id: Number(props.table?.id),
                  version_id: Number(props.version?.id),
                });
                if (taskRes.success) {
                  task.id = taskRes.data?.id;
                }
              }
              if (!!task.id && !task.is_locked) {
                const compareDataRet = calCompareTableDataStatistics(
                  {
                    id: props.table?.id,
                    name: props.table?.name,
                    meta: props.table?.meta,
                    data: task.origin_data,
                  },
                  { data: task.data }
                );
                let res = await updateDatasetVersionTaskDataApi({
                  taskId: Number(task?.id),
                  data: {
                    data: task.data,
                    ...(compareDataRet.delete_count > 0 ||
                    compareDataRet.add_count > 0 ||
                    compareDataRet.change_data.length > 0
                      ? {
                          log: compareDataRet,
                        }
                      : {}),
                  },
                });
                if (!!res.success) {
                  success_tasks.push(task.assignee);
                } else {
                  fail_tasks.push(task.assignee);
                }
              } else {
                fail_tasks.push(task.assignee);
              }
            }
            if (success_tasks.length > 0) {
              message.success(
                `成功导入${success_tasks.length}个任务${
                  fail_tasks.length > 0
                    ? `, 失败导入${fail_tasks.length}个任务`
                    : ""
                }`
              );
            } else {
              message.error(`失败导入${fail_tasks.length}个任务`);
            }
            if (!!props.refreshCallback) {
              props.refreshCallback!();
            }
            handleCancel();
          })
          .catch((e: any) => {
            message.error(`导入失败: ${e}`);
            GlobalLoader.hide();
          });
      } catch (e: any) {
        console.log(e.message);
        message.error(`导入失败`);
        GlobalLoader.hide();
      }
    } else {
      GlobalLoader.hide();
    }
  };

  return (
    <>
      <Modal
        title={"导入"}
        centered
        open={open}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
      >
        <Upload.Dragger
          className="hide-file"
          name="file"
          accept=".xlsx, .xls, .csv"
          multiple={false}
          maxCount={1}
          beforeUpload={() => {
            GlobalLoader.show();
            return false;
          }}
          onChange={handleUploadResult}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t("table.import.upload")}</p>
          <p className="ant-upload-hint">{t("table.import.upload_format")}</p>
        </Upload.Dragger>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        导入
      </Button>
    </>
  );
};

export default ImportData;
