import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { message, Select, Modal, Input, Button, Form, Checkbox } from "antd";
import { Dataset } from "../../types/Dataset";
import { patchDatasetApi } from "../../api/DatasetApi";
import {
  checkFlowApiTokenApi,
  getFlowAppsApi,
  getFlowSessionsApi,
  getPipelineAppsApi,
} from "../../api/IntegrationsApis";
import viewUtils from "../../utils/viewUtils";
import IconSettingOutlined from "../../common/images/icons/setting-outlined";
import { getApiKeysApi } from "../../api/ApiKeyApi";
import { getCurrentWorkspaceApi } from "../../api";
import { PipelineApp } from "../../types/Integrations";
import { Workspace } from "../../types/Workspace";
import usePermission from "../../utils/usePermission";

const DatasetActionConfig = (props: {
  dataset?: Dataset;
  currentWorkspace?: Workspace;
  updateCallback?: (dataset: Dataset) => void;
}) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const flowWorkspaceId = Form.useWatch("flow_workspace_id", form);
  const flowAppId = Form.useWatch("flow_app_id", form);
  const flowApiToken = Form.useWatch("flow_api_token", form);
  const [flowoptAppOptions, setFlowoptAppOptions] = useState([]);
  const [flowoptSessionOptions, setFlowoptSessionOptions] = useState([]);
  const [pipelineApps, setPipelineApps] = useState<PipelineApp[]>([]);
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedPipelineType, setSelectedPipelineType] = useState(null);
  const [selectedPipelineApp, setSelectedPipelineApp] =
    useState<PipelineApp | null>(null);
  const [preApp, setPreApp] = useState();
  const permission = usePermission();

  useEffect(() => {
    const selectedPipelineApp: any = pipelineApps.find(
      (app: any) => app.id === form.getFieldValue("pipeline_app_id")
    );
    setSelectedPipelineApp(selectedPipelineApp);
    setSelectedPipelineType(selectedPipelineApp?.type);
  }, [form, pipelineApps]);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    // if dataset has push_config but not action_config, create action_config from push_config
    if (props.dataset?.push_config && !props.dataset?.action_config) {
      patchDatasetApi({
        datasetId: Number(props.dataset?.id),
        data: {
          action_config: {
            ...props.dataset?.push_config,
            pipeline_app_id: props.dataset?.push_config?.push_method,
          },
        },
      }).then((res) => {
        if (res.success) {
          !!props.updateCallback && props.updateCallback(res.data);
        } else {
          message.error(
            viewUtils.prettifyErrorMessage(res.message) ||
              t("common.result_status", {
                title: t("common.update"),
                status: t("common.fail"),
              })
          );
        }
      });
    }

    form.setFieldsValue({
      ...props.dataset?.action_config,
      id: props.dataset?.id,
    });

    setFlowoptAppOptions([...flowoptAppOptions]);
    checkFlowoptSession();

    getPipelineAppsApi().then((res) => {
      if (res.success) {
        setPipelineApps(res.data);
        if (res.data.length > 0) {
          form.setFieldValue(
            "pipeline_app_id",
            props.dataset?.action_config?.pipeline_app_id || res.data[0]?.id
          );
        }
      }
    });

    fetchApiKeys();
  }, [props.dataset, form, show]);

  const fetchApiKeys = () => {
    // get current workspace
    getCurrentWorkspaceApi().then((res) => {
      if (res.success) {
        let workspace = res.data;
        getApiKeysApi(workspace).then((res) => {
          if (res.success) {
            setApiKeys(res.data);
            if (res.data.length > 0) {
              form.setFieldValue(
                "odp_api_token",
                props.dataset?.action_config?.odp_api_token ||
                  res.data[0]?.value
              );
            }
          }
        });
      }
    });
  };

  useEffect(() => {
    if (flowoptAppOptions && flowoptAppOptions.length > 0) {
      // check if the flow app id is in the flowoptAppOptions
      const selectedApp = flowoptAppOptions.find(
        (app: any) => app.id === props.dataset?.action_config?.flow_app_id
      );
      if (!selectedApp) {
        form.setFieldsValue({
          flow_app_id: null,
          flow_app: null,
          flow_folder_id: null,
        });
      } else {
        form.setFieldsValue({
          flow_app_id: props.dataset?.action_config?.flow_app_id,
          flow_app: props.dataset?.action_config?.flow_app,
        });
      }
    }
  }, [flowoptAppOptions]);

  const checkFlowoptSession = () => {
    if (flowoptSessionOptions && flowoptSessionOptions.length > 0) {
      // check if the flow folder id is in the flowoptSessionOptions
      const selectedSession = flowoptSessionOptions.find(
        (session: any) =>
          session.id === props.dataset?.action_config?.flow_folder_id
      );
      if (!selectedSession) {
        form.setFieldsValue({ flow_folder_id: null });
      } else {
        form.setFieldsValue({
          flow_folder_id: props.dataset?.action_config?.flow_folder_id,
        });
      }
    }
  };

  useEffect(() => {
    if (flowWorkspaceId && flowApiToken && flowWorkspaceId.length === 36) {
      getFlowAppsApi({
        flowApiToken: flowApiToken,
        flowWorkspaceId: flowWorkspaceId,
        page: 1,
        pageSize: 100,
      }).then((res) => {
        if (res.success) {
          setFlowoptAppOptions(res.data);
        } else {
          setFlowoptAppOptions([]);
        }
      });
    } else {
      form.setFieldsValue({ flow_app_id: null, flow_app: null });
      setFlowoptAppOptions([]);
    }
  }, [flowWorkspaceId, flowApiToken]);

  useEffect(() => {
    if (
      flowWorkspaceId &&
      flowApiToken &&
      flowAppId &&
      flowWorkspaceId.length === 36 &&
      flowAppId.length === 36
    ) {
      getFlowSessionsApi({
        flowApiToken: flowApiToken,
        flowWorkspaceId: flowWorkspaceId,
        flowAppId: flowAppId,
        page: 1,
        pageSize: 100,
      }).then((res) => {
        setFlowoptSessionOptions(res.data);
        setPreApp(flowAppId);

        if (res.data.length === 1) {
          form.setFieldsValue({ flow_folder_id: res.data[0].id });
        } else {
          checkFlowoptSession();
          if (preApp !== flowAppId) {
            if (preApp) {
              form.setFieldsValue({ flow_folder_id: null });
            }
          }
        }
      });
    } else {
      form.setFieldsValue({ flow_folder_id: null });
      setFlowoptSessionOptions([]);
    }
  }, [flowWorkspaceId, flowApiToken, flowAppId]);

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const data: any = form.getFieldsValue();
    patchDatasetApi({
      datasetId: Number(data?.id),
      data: {
        action_config: {
          ...props.dataset?.action_config,
          pipeline_app_id: data.pipeline_app_id,
          push_method: data.push_method,
          odp_api_token: data.odp_api_token,
          flow_api_token: data.flow_api_token,
          flow_app_id: data.flow_app_id,
          flow_app: data.flow_app,
          flow_workspace_id: data.flow_workspace_id,
          flow_folder_id: data.flow_folder_id || "",
          flow_solve_instance: !!data.flow_solve_instance,
          flow_lock_file: !!data.flow_lock_file,
        },
      },
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.update"),
            status: t("common.success"),
          })
        );
        closeModal();
        !!props.updateCallback && props.updateCallback(res.data);
      } else {
        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const getLabel = (item: PipelineApp) => {
    const nameObj = item.manifest?.display_name;
    if (nameObj) {
      return i18n.resolvedLanguage === "zh" ? nameObj.zh : nameObj.en;
    }
    return item.name;
  };

  const handleClickAddOdpApiToken = () => {
    window.open(
      window.location.origin + "/settings/workspace?tab=auth_management",
      "_blank"
    );
  };

  const handleClickRefresh = () => {
    fetchApiKeys();
  };

  return (
    <>
      <Modal
        centered
        forceRender={true}
        title={t("dataset.push_config.title")}
        open={!!show}
        onCancel={closeModal}
        onOk={handleSubmit}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
      >
        <Form
          layout="vertical"
          form={form}
          style={{ margin: "2em 0" }}
          colon={false}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="pipeline_app_id"
            label={t("dataset.action_config.pipeline")}
            rules={[
              {
                required: true,
                message: t("common.required", {
                  title: t("dataset.action_config.pipeline"),
                }),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={pipelineApps?.map((item: PipelineApp) => ({
                label: getLabel(item),
                value: item.id,
              }))}
              onChange={(value) => {
                const selectedApp: any = pipelineApps.find(
                  (app: any) => app.id === value
                );
                setSelectedPipelineApp(selectedApp);
                setSelectedPipelineType(selectedApp?.type);
              }}
              placeholder={t("dataset.action_config.is_pipeline_blank")}
            />
          </Form.Item>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Form.Item
              name="odp_api_token"
              label={t("dataset.push_config.odp_api_token")}
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  message: t("common.required", {
                    title: t("dataset.push_config.odp_api_token"),
                  }),
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={apiKeys?.map((item: any) => ({
                  label: item?.name,
                  value: item.id,
                }))}
              />
            </Form.Item>
            {permission.isAdmin && (
              <Button
                type="link"
                style={{ paddingRight: 0 }}
                onClick={handleClickAddOdpApiToken}
              >
                {t("common.add")}
              </Button>
            )}
            <Button
              type="link"
              style={{ paddingRight: 0 }}
              onClick={handleClickRefresh}
            >
              {t("common.refresh")}
            </Button>
          </div>
          {/* display flowopt configs only if the pipeline type is push_to_flowopt or push_to_flow_algo */}
          {["push_to_flowopt", "push_to_flow_algo"].includes(
            selectedPipelineType || ""
          ) && (
            <>
              <Form.Item
                name="flow_workspace_id"
                label={t("dataset.push_config.workspace_id")}
                rules={[
                  {
                    required: true,
                    message: t("common.required", {
                      title: t("dataset.push_config.workspace_id"),
                    }),
                  },
                ]}
                tooltip={t("dataset.push_config.workspace_id_tip")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="flow_api_token"
                label="Flow API Token"
                rules={[
                  {
                    required: true,
                    message: t("common.required", { title: "Flow API Token" }),
                  },
                  () => ({
                    async validator(_, value) {
                      if (!value || value.length < 1) {
                        return Promise.resolve();
                      }
                      const isValid = await checkFlowApiTokenApi({
                        flowApiToken: value,
                        flowWorkspaceId:
                          form.getFieldValue("flow_workspace_id"),
                      });
                      if (!isValid) {
                        return Promise.reject(
                          new Error(
                            t("dataset.push_config.flow_api_token_invalid")
                          )
                        );
                      }
                    },
                  }),
                ]}
                tooltip={t("dataset.push_config.flow_api_token_tip")}
              >
                <Input />
              </Form.Item>
              {selectedPipelineType !== "push_to_flow_algo" && (
                <>
                  <Form.Item name="flow_app" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="flow_app_id"
                    label={t("dataset.push_config.app")}
                    rules={[
                      {
                        required: true,
                        message: t("common.required", {
                          title: t("dataset.push_config.app"),
                        }),
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder={t("dataset.push_config.is_blank")}
                      value={form.getFieldValue("flow_app_id") || undefined} // 确保没有初始值时为 undefined
                      onSelect={(value, option) => {
                        form.setFieldsValue({
                          flow_app: option.data.app_id,
                          flow_app_id: option.data.id,
                        });
                      }}
                      options={flowoptAppOptions?.map((item: any) => ({
                        label:
                          item?.app_manifest?.display_name[
                            i18n.resolvedLanguage === "zh" ? "zh" : "en"
                          ],
                        value: item.id,
                        data: { id: item.id, app_id: item.app_id },
                      }))}
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    name="flow_folder_id"
                    label={t("dataset.push_config.session")}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder={t("dataset.push_config.folder_is_blank")}
                      value={form.getFieldValue("flow_folder_id") || undefined} // 确保没有初始值时为 undefined
                      options={flowoptSessionOptions?.map((item: any) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    ></Select>
                  </Form.Item>
                </>
              )}
              {selectedPipelineType === "push_to_flowopt" &&
                (selectedPipelineApp?.id === "push_data_to_flowopt_apps" ||
                  !!selectedPipelineApp?.manifest?.flow_solve) && (
                  <>
                    <Form.Item
                      name="flow_solve_instance"
                      valuePropName="checked"
                      style={{ marginLeft: 35 }}
                    >
                      <Checkbox>{t("dataset.push_config.auto_solve")}</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="flow_lock_file"
                      valuePropName="checked"
                      style={{ marginLeft: 35 }}
                    >
                      <Checkbox>{t("dataset.push_config.auto_lock")}</Checkbox>
                    </Form.Item>
                  </>
                )}
            </>
          )}
        </Form>
      </Modal>
      <div className="action-item">
        <Button
          icon={
            <IconSettingOutlined
              style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
            />
          }
          onClick={() => openModal()}
        >
          {t("nav.settings")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(DatasetActionConfig);
