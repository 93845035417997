const dark_vscode_tribute = {
  default: "#D4D4D4",
  background: "#1E1E1E",
  background_warning: "#1E1E1E",
  string: "#CE8453",
  number: "#B5CE9F",
  colon: "#49B8F7",
  keys: "#9CDCFE",
  keys_whiteSpace: "#AF74A5",
  primitive: "#6392C6",
};

const light_mitsuketa_tribute = {
  default: "#D4D4D4",
  background: "#FCFDFD",
  background_warning: "#FEECEB",
  string: "#FA7921",
  number: "#70CE35",
  colon: "#49B8F7",
  keys: "#59A5D8",
  keys_whiteSpace: "#835FB6",
  primitive: "#386FA4",
};

const themes = {
  dark_vscode_tribute: dark_vscode_tribute,
  light_mitsuketa_tribute: light_mitsuketa_tribute,
};

export default themes;
