import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import type { MenuProps } from "antd";
import { Layout, Typography, Dropdown, Space, Image } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AuthService from "../../../utils/AuthService";
import viewUtils from "../../../utils/viewUtils";
import UserAvatarImg from "../../../common/images/user-avatar.png";

const Header = (props: any) => {
  const { t } = useTranslation();

  const userItems: MenuProps["items"] = [
    {
      key: "profile",
      label: props.user?.username,
      disabled: true,
    },
    { type: "divider" },
    {
      key: "logout",
      label: t("common.logout"),
    },
  ];

  return (
    <>
      <Layout.Header className="header-content">
        <div style={{ height: "100%" }}>
          <NavLink to="/" target="_blank">
            <span className="title">Odp平台管理</span>
          </NavLink>
        </div>
        <Space className="header-button-list">
          <Dropdown
            placement="bottomRight"
            menu={{
              items: userItems,
              onClick: (e) => {
                if (e?.key === "logout") {
                  AuthService.logout();
                }
              },
            }}
            overlayStyle={{ minWidth: "14em" }}
          >
            <div className="header-button">
              <Image
                src={UserAvatarImg}
                preview={false}
                style={{ width: "2.5em", height: "2.5em" }}
              />
              <div
                style={{
                  maxWidth: "10em",
                  padding: "0 0.8em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text ellipsis={true}>
                  {viewUtils.prettifyUsername(props.user?.name)}
                </Typography.Text>
              </div>
              <DownOutlined
                className="vertical-hidden"
                style={{ fontSize: "0.8em" }}
              />
            </div>
          </Dropdown>
        </Space>
      </Layout.Header>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
});

export default connect(mapStateToProps, {})(Header);
