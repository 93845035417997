import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Typography,
  Form,
  Select,
  Space,
  DatePicker,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { getTableLogListApi, getUserListApi } from "../../api";
import { TableLogRecord } from "../../types/LogRecord";
import { User } from "../../types/User";
import { Workspace } from "../../types/Workspace";
import viewUtils from "../../utils/viewUtils";
import { SheetTable } from "../../types/Table";

const TableActionRecord = connect(
  (store: any) => ({
    currentWorkspace: store.account.currentWorkspace,
  }),
  {}
)((props: { table?: SheetTable; currentWorkspace?: Workspace }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [users, setUsers] = useState<User[]>([]);
  const [logs, setLogs] = useState<{ data: TableLogRecord[]; total: number }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    action: undefined,
    operator: undefined,
    start_time: undefined,
    end_time: undefined,
  });
  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  useEffect(() => {
    if (props.currentWorkspace?.id) {
      getUserListApi({ workspace: Number(props.currentWorkspace?.id) }).then(
        (res) => {
          if (res.success) {
            setUsers(res.data);
          }
        }
      );
    }
  }, [props]);

  useEffect(() => {
    if (!!props.table?.id) {
      getTableLogListApi({ table_id: props.table?.id!, ...filter })
        .then((res) => {
          if (res.success) {
            setLogs({
              data: res.data,
              total: res.pagination?.count || 0,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching logs:", error);
        });
    }
  }, [props.table, filter]);

  const renderDetail = (log: TableLogRecord) => {
    switch (log.action) {
      case "CHANGE_DATA":
        let texts = [];
        if (!!log.detail?.delete_count) {
          texts.push(
            t("table.action_record.detail_info.change_data.delete", {
              table: log.table?.name || " ",
              count: log.detail?.delete_count,
            })
          );
        }
        if (!!log.detail?.add_count) {
          texts.push(
            t("table.action_record.detail_info.change_data.add", {
              table: log.table?.name || " ",
              count: log.detail?.add_count,
            })
          );
        }

        if (!!log.detail?.change_data?.length) {
          let changeTextArray: string[] = [];
          const fields = (log.table?.meta?.fields || []).reduce((ret, item) => {
            ret[item.identifier || ""] = item;
            return ret;
          }, {} as any);

          for (let i = 0, len = log.detail?.change_data.length; i < len; i++) {
            let field = fields[log.detail?.change_data[i].field];

            if (!!field) {
              changeTextArray.push(
                t("table.action_record.detail_info.change_data.change_cell", {
                  field: field?.name || "",
                  origin: log.detail?.change_data[i].origin_value || "",
                  new: log.detail?.change_data[i].new_value || " ",
                })
              );
            }
          }

          if (changeTextArray.length > 0) {
            texts.push(changeTextArray.join("; "));
          }
        }
        return texts.join("\n");
      case "SYNC_DATA":
        return t("table.action_record.detail_info.sync_data.sync", {
          datetime: viewUtils.utcToLocal(log.updated_at),
        });
      default:
        return "";
    }
  };

  const columns: ColumnsType<TableLogRecord> = [
    {
      title: t("table.action_record.detail"),
      width: "45%",
      key: "detail",
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              placement: "bottomLeft",
              overlayStyle: {
                whiteSpace: "pre-line",
                maxWidth: "min(100vw, 400px)",
              },
            },
          }}
          style={{ marginBottom: 0, whiteSpace: "pre-line" }}
        >
          {renderDetail(r)}
        </Typography.Paragraph>
      ),
    },
    {
      title: t("table.action_record.operator"),
      key: "operator",
      render: (r) => viewUtils.prettifyUsername(r.operator?.name),
    },
    {
      title: t("table.action_record.start_time"),
      key: "created_at",
      render: (r) => viewUtils.prettifyDatatime(r.created_at),
    },
  ];

  const handleSearch = () => {
    const search = form.getFieldsValue();
    const date = search.date || [];
    setFilter((data) => ({
      ...data,
      page: 1,
      action: search.action || undefined,
      operator: search.operator || undefined,
      start_time: !!date[0] ? date[0].format("YYYY-MM-DD") : undefined,
      end_time: !!date[1] ? date[1].format("YYYY-MM-DD") : undefined,
    }));
  };

  const handleResetSearch = () => {
    form.resetFields();
    setFilter((data) => ({
      ...data,
      page: 1,
      action: undefined,
      operator: undefined,
      start_time: undefined,
      end_time: undefined,
    }));
  };

  return (
    <>
      <Form form={form} name="log search form">
        <Space
          style={{ display: "flex", flexWrap: "wrap", marginBottom: "1em" }}
        >
          <Form.Item name="operator" noStyle>
            <Select
              allowClear
              placeholder={t("table.action_record.operator")}
              style={{ width: "15em" }}
              options={users.map((user) => ({
                value: user.username,
                label: `${viewUtils.prettifyUsername(user.name)}(${
                  user.username
                })`,
              }))}
            />
          </Form.Item>
          <Form.Item name="date" noStyle>
            <DatePicker.RangePicker allowEmpty={[true, true]} />
          </Form.Item>
          <Button type="primary" onClick={handleSearch}>
            {t("common.search")}
          </Button>
          <Button onClick={handleResetSearch}>{t("common.reset")}</Button>
        </Space>
      </Form>
      <Table
        className="common-table"
        size={"small"}
        rowKey={(r) => r?.id || ""}
        scroll={{ x: 500, scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={logs.data}
        pagination={false}
      />
      <Pagination
        size={"small"}
        hideOnSinglePage={!logs.data?.length}
        showQuickJumper
        showTotal={(total) => t("common.total", { count: total })}
        current={filter.page}
        pageSize={filter.page_size}
        total={logs.total}
        onChange={handlePageChange}
        style={{
          textAlign: "right",
          justifyContent: "flex-end",
          margin: "1em 0",
        }}
      />
    </>
  );
});

const TableActionHistory = (props: { table?: SheetTable }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        centered
        width={1000}
        destroyOnClose={true}
        title={t("table.action_record.title")}
        open={!!show}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <TableActionRecord table={props.table} />
      </Modal>
      <div className="action-item">
        <Button onClick={() => setShow(true)}>
          {t("table.action_record.title")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(TableActionHistory);
