import React, { PropsWithChildren, ErrorInfo } from "react";
import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Image404 from "../common/images/404.png";
import { saveLogApi } from "../api";

export class ErrorBoundary extends React.Component<
  PropsWithChildren,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };

    window.onerror = (message, source, line, column, error) => {
      console.log("An error occurred:", message, source, line, column, error);
      if (process.env.NODE_ENV !== "development") {
        saveLogApi({
          log: JSON.stringify({
            message: error?.message,
            stack: error?.stack,
          }),
          log_type: "ERROR",
          event_type: "SYSTEM_ERROR",
        }).then((res) => {
          if (res.status) {
            console.log("error saved");
          }
        });
      }
      return true;
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    console.log("error", error, errorInfo);
  }

  render() {
    if (this.state?.hasError) {
      return <CommonErrorView />;
    }

    return this.props.children;
  }
}

const CommonErrorView = (props: {
  message?: string;
  style?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        background: "white",
        ...props.style,
      }}
    >
      <Image
        src={Image404}
        preview={false}
        style={{
          width: "600px",
          maxWidth: "min(90vw, 60vh)",
          marginBottom: "2em",
          marginLeft: "-2%",
        }}
      />
      <Typography.Text
        style={{
          fontSize: "1.1em",
          color: "rgba(0,0,0,0.65)",
        }}
      >
        {props.message || t("common.server_error_tips")}
      </Typography.Text>
    </div>
  );
};

export default CommonErrorView;
