import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Card, Typography, Form, Input, InputNumber, Select } from "antd";
import { SheetTable, TableField, DataRowValidation } from "../types/Table";
// import { toBoolean, toDate, toNumber } from "../utils/TypeUtils";
import { translateDataValidation } from "../utils/SheetValidationUtils";

const TablesSheetForm = forwardRef(
  (
    props: {
      tables?: SheetTable[];
      changeTableCallback?: (table: SheetTable) => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const currentTable = props.tables?.find((t) => t.active);
    const [form] = Form.useForm();
    const currentErrorTip: any = useRef();

    useImperativeHandle(ref, () => ({
      getCurrentTableData: () => {
        return form.getFieldValue("data");
      },
      prepareValidation: (validation?: DataRowValidation[]) => {
        if (currentErrorTip?.current?.length > 0) {
          currentErrorTip.current.forEach((item: any, index: number) => {
            form.setFields([
              {
                name: ["data", item.index, item.field],
                errors: [],
              },
            ]);
          });
          currentErrorTip.current = [];
        }
        if (validation) {
          currentErrorTip.current = [];
          let validationErrors: any = [];
          if (validation.length > 10000) {
            validationErrors = validation?.slice(0, 10000);
          } else {
            validationErrors = validation;
          }
          validationErrors?.forEach(
            (item: DataRowValidation, index: number) => {
              const currentCell = form.getFieldValue([
                "data",
                item.index,
                item.field,
              ]);
              const validationItem =
                i18n.resolvedLanguage === "zh"
                  ? translateDataValidation(item, currentCell || "")
                  : item;
              currentErrorTip.current.push({
                index: item.index,
                field: item.field,
              });
              form.setFields([
                {
                  name: ["data", item.index, item.field],
                  errors: [validationItem?.message],
                },
              ]);
            }
          );
        }
      },
    }));

    useEffect(() => {
      if (currentTable) {
        form.setFieldsValue({
          data: currentTable?.data || [],
        });
      }
    }, [currentTable, form]);

    const handleChangeTable = (key: string) => {
      if (props.tables) {
        let changeTable = props.tables?.find((t) => t.identifier === key);
        !!changeTable &&
          props.changeTableCallback &&
          props.changeTableCallback(changeTable);
      }
    };

    return (
      <>
        {!!props.tables && props.tables?.length > 1 && (
          <div
            style={{
              position: "sticky",
              top: 0,
              background: "#f5f5f5",
              zIndex: 3,
              padding: "0 10px",
              marginBottom: 10,
            }}
          >
            <Tabs
              onChange={handleChangeTable}
              activeKey={`${currentTable?.identifier}`}
              size="small"
              tabBarStyle={{
                marginBottom: 0,
              }}
              items={props.tables.map((item, index) => ({
                label: `${item.name}`,
                key: `${item.identifier}`,
              }))}
            />
          </div>
        )}
        <Form
          form={form}
          className="tables-sheet-form"
          style={{ zIndex: 2, padding: "0 10px" }}
        >
          {!!currentTable &&
            currentTable?.data?.map((item: any, index: number) => (
              <Card
                size="small"
                key={index}
                style={{
                  marginBottom: 10,
                  borderRadius: 3,
                }}
              >
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {index + 1}
                </Typography.Title>
                {currentTable?.meta?.fields?.map((item: TableField) => (
                  <Form.Item
                    name={["data", index, item?.identifier || ""]}
                    label={item.name}
                    key={item.identifier}
                  >
                    {item.type?.toUpperCase() === "NUMBER" ? (
                      <InputNumber
                        disabled={!!item?.readonly}
                        style={{ width: "100%" }}
                      />
                    ) : item.type?.toUpperCase() === "DATE" ? (
                      <Input disabled={!!item?.readonly} />
                    ) : item.type?.toUpperCase() === "BOOLEAN" ? (
                      <Select
                        disabled={!!item?.readonly}
                        options={[
                          { label: t("common.yes"), value: true },
                          { label: t("common.no"), value: false },
                        ]}
                      />
                    ) : item.type?.toUpperCase() === "REFERENCE" ? (
                      <Select
                        disabled={!!item?.readonly}
                        showSearch
                        options={item?.fk_enums?.map((enumItem) => ({
                          label: enumItem?.literal,
                          value: enumItem?.field,
                        }))}
                      />
                    ) : (
                      <Input disabled={!!item?.readonly} />
                    )}
                  </Form.Item>
                ))}
              </Card>
            ))}
        </Form>
      </>
    );
  }
);

export default TablesSheetForm;
