const IconImport = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="currentColor">
      <title>Import</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-641.000000, -404.000000)"
          fillRule="nonzero"
        >
          <g id="Import" transform="translate(641.000000, 404.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="18"
              height="18"
            ></rect>
            <path
              d="M15.6146484,13.3136719 L14.6689453,13.3136719 C14.5951172,13.3136719 14.5335937,13.3751953 14.5335937,13.4490234 L14.5335937,14.5353516 L3.46464844,14.5353516 L3.46464844,3.46464844 L14.5353516,3.46464844 L14.5353516,4.55097656 C14.5353516,4.62480469 14.596875,4.68632812 14.6707031,4.68632812 L15.6164062,4.68632812 C15.6902344,4.68632812 15.7517578,4.6265625 15.7517578,4.55097656 L15.7517578,2.78964844 C15.7517578,2.49082031 15.5109375,2.25 15.2121094,2.25 L2.78964844,2.25 C2.49082031,2.25 2.25,2.49082031 2.25,2.78964844 L2.25,15.2103516 C2.25,15.5091797 2.49082031,15.75 2.78964844,15.75 L15.2103516,15.75 C15.5091797,15.75 15.75,15.5091797 15.75,15.2103516 L15.75,13.4490234 C15.75,13.3734375 15.6884766,13.3136719 15.6146484,13.3136719 Z M15.8554688,8.3671875 L10.3359375,8.3671875 L10.3359375,7.03125 C10.3359375,6.91347656 10.1988281,6.84667969 10.1074219,6.92050781 L7.61308594,8.88925781 C7.54101563,8.94550781 7.54101563,9.05449219 7.61308594,9.11074219 L10.1074219,11.0794922 C10.2005859,11.1533203 10.3359375,11.0865234 10.3359375,10.96875 L10.3359375,9.6328125 L15.8554688,9.6328125 C15.9328125,9.6328125 15.9960938,9.56953125 15.9960938,9.4921875 L15.9960938,8.5078125 C15.9960938,8.43046875 15.9328125,8.3671875 15.8554688,8.3671875 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconImport;
