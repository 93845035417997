import * as XLSX from "xlsx";

export const blobToWorkBook = async (blob: Blob) =>
  XLSX.read(await blob.arrayBuffer());

export const fileToWorkBook = async (file: File) => {
  // convert file to blob
  let blob;
  // if file is csv and without BOM, add BOM
  if (
    file.type === "text/csv" &&
    file.text().toString().charAt(0) !== "\uFEFF"
  ) {
    blob = new Blob(["\uFEFF", file], { type: file.type });
  } else {
    blob = new Blob([file], { type: file.type });
  }

  return blobToWorkBook(blob);
};
