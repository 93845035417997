import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector, {
  DetectorOptions,
} from "i18next-browser-languagedetector";

import en from "../common/locales/en";
import zh from "../common/locales/zh";

const resources = {
  en: { translation: en },
  zh: { translation: zh },
};

const customNavigatorDetector = new LanguageDetector();
customNavigatorDetector.addDetector({
  name: "customNavigator",
  lookup(options: DetectorOptions) {
    const navigatorRef: any = window?.navigator;
    if (typeof navigatorRef !== "undefined") {
      const lang = navigatorRef?.userLanguage || navigator?.language || "";
      if (lang === "en" || lang.startsWith("en-") || lang.startsWith("EN-")) {
        return "en";
      }
      if (lang === "zh" || lang.startsWith("zh-") || lang.startsWith("ZH-")) {
        return "zh";
      }
    }
    return undefined;
  },
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(customNavigatorDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      supportedLngs: ["en", "zh"],
      fallbackLng: "zh",
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          "querystring",
          "cookie",
          "localStorage",
          "sessionStorage",
          "customNavigator",
          "navigator",
          "htmlTag",
        ],
      },
    },
    (err, t) => {
      try {
        document.getElementsByTagName(
          "body"
        )[0].className = `language-${i18n.resolvedLanguage}`;
      } catch (e) {}
    }
  );

i18n.on("languageChanged", () => {
  try {
    document.getElementsByTagName(
      "body"
    )[0].className = `language-${i18n.resolvedLanguage}`;
  } catch (e) {}
});

export default i18n;
