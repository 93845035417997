import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NavHyperLink from "../../components/NavHyperLink";
import { Dataset } from "../../types/Dataset";
import { SheetTable, TableField } from "../../types/Table";
import {
  getDatasetApi,
  getDatasetVersionTableDataApi,
} from "../../api/DatasetApi";
import {
  Table,
  Tabs,
  Spin,
  Typography,
  Pagination,
  Space,
  Button,
  Form,
  Checkbox,
  Modal,
  Select,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type {
  TableCompare,
  TableCompareData,
} from "../../utils/SheetCompareUtils";
import { calCompareTableData } from "../../utils/SheetCompareUtils";
import { SettingOutlined } from "@ant-design/icons";
import viewUtils from "../../utils/viewUtils";

type FilterConfig = {
  only_changed: boolean;
  only_greater_than_0: boolean;
};

type ShowDiffConfig = {
  show_value: boolean;
  show_ratio: boolean;
  decimal_format: number;
};

const CompareTableData = (props: {
  table?: TableCompare;
  filter?: FilterConfig;
  showDiff?: ShowDiffConfig;
}) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any>();
  const [pagination, setPagination] = useState<any>({ page: 1, total: 0 });
  const [cursor, setCursor] = useState<number | undefined>();

  const jumpToRow = (row?: number, calPage = false) => {
    setCursor(row);
    if (calPage) {
      setPagination((data: any) => ({
        ...data,
        page: parseInt(`${(row || 0) / 100 + 1}`),
      }));
    }
    try {
      const elem = document.getElementById("compare-table");
      if (!!elem) {
        elem.scrollTop = ((row || 0) % 100) * 25.2;
      }
    } catch (e) {}
  };

  const handleLast = () => {
    (!!pagination.last || pagination.last === 0) &&
      jumpToRow(pagination.last, true);
  };

  const handleFirst = () => {
    (!!pagination.first || pagination.first === 0) &&
      jumpToRow(pagination.first, true);
  };

  const handlePre = () => {
    if (!!pagination.first || pagination.first === 0) {
      if (cursor === undefined || cursor === null) {
        jumpToRow(pagination.first, true);
      } else {
        if (
          (tableData?.data?.length || 0) > 0 &&
          (cursor || 0) > pagination.first
        ) {
          for (let i = (cursor || 0) - 1; i >= pagination.first; i--) {
            if (tableData?.data[i].status !== "nochange") {
              jumpToRow(i, true);
              break;
            }
          }
        }
      }
    }
  };

  const handleNext = () => {
    if (!!pagination.last || pagination.last === 0) {
      if (cursor === undefined || cursor === null) {
        jumpToRow(pagination.first, true);
      } else {
        if (
          (tableData?.data?.length || 0) > 0 &&
          (cursor || 0) < pagination.last
        ) {
          for (let i = (cursor || 0) + 1; i <= pagination.last; i++) {
            if (tableData?.data[i].status !== "nochange") {
              jumpToRow(i, true);
              break;
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    let first = null,
      last = null;
    if ((tableData?.data?.length || 0) > 0) {
      for (let i = 0, len = tableData?.data?.length || 0; i < len; i++) {
        if (tableData?.data[i].status !== "nochange") {
          first = i;
          break;
        }
      }
      if (!!first || first === 0) {
        for (let i = tableData?.data?.length || 1; i > 0; i--) {
          if (tableData?.data[i - 1].status !== "nochange") {
            last = i - 1;
            break;
          }
        }
      }
    }
    setPagination({
      page: 1,
      total: tableData?.data?.length || 0,
      first: first,
      last: last,
    });
    jumpToRow(undefined);
  }, [tableData]);

  useEffect(() => {
    if (props.filter?.only_changed || props.filter?.only_greater_than_0) {
      let data = [];
      const originData = props.table?.data || [];
      for (let i = 0, len = originData.length; i < len; i++) {
        let row = originData[i];
        if (props.filter?.only_changed && row.status === "nochange") {
          continue;
        }
        if (props.filter?.only_greater_than_0 && row.status === "nochange") {
          if (
            row.newData.filter((item) => !isNaN(parseFloat(item))).length > 0 &&
            row.newData.filter(
              (item) => !isNaN(parseFloat(item)) && Number(item) > 0
            ).length < 1
          ) {
            continue;
          }
        }
        data.push(row);
      }
      setTableData({
        ...props.table,
        data: data,
      });
    } else {
      setTableData(props.table);
    }
  }, [props.table, props.filter]);

  const handlePageChange = (page: number) => {
    setPagination((data: any) => ({ ...data, page: page }));
    jumpToRow(page * 100);
  };

  const renderCell = (
    field: TableField,
    value: any,
    originValue: any,
    table: string
  ) => {
    const valueIsEmpty = value === null || value === undefined;
    const originValueIsEmpty =
      originValue === null || originValue === undefined;

    if (valueIsEmpty) {
      return "";
    }

    let cellStatus = "";
    if (value !== originValue) {
      if (table === "new") {
        if (originValueIsEmpty) {
          cellStatus = "cell-new";
        } else {
          cellStatus = "cell-changed";
        }
      } else {
        if (originValueIsEmpty) {
          cellStatus = "cell-delete";
        }
      }
    }

    return (
      <Typography.Text className={cellStatus}>
        {field.type?.toUpperCase() === "NUMBER"
          ? viewUtils.decimalFormat(value, props.showDiff?.decimal_format || 2)
          : field.type?.toUpperCase() === "BOOLEAN"
          ? !!value
            ? "True"
            : "False"
          : `${value}`}
        {!!props.showDiff?.show_value &&
          viewUtils.showDiffValue(value, table === "new" ? originValue : null)}
        {!!props.showDiff?.show_ratio &&
          viewUtils.showDiffRatio(value, table === "new" ? originValue : null)}
      </Typography.Text>
    );
  };

  const columns = (key = "origin") => {
    return [
      {
        key: "status",
        width: "1em",
        align: "center",
        fixed: "left",
        render: (r: TableCompareData) =>
          r.status === "delete" && key === "origin"
            ? "-"
            : r.status === "new" && key === "new"
            ? "+"
            : "",
      },
      ...(tableData?.fields || []).map((field: TableField, index: number) => ({
        key: `field-${field.identifier}`,
        title: field.name,
        render: (r: TableCompareData) => (
          <Typography.Text
            className={
              r.originData[index] !== r.newData[index] && key === "new"
                ? "cell-changed"
                : ""
            }
          >
            {renderCell(
              field,
              r[key === "origin" ? "originData" : "newData"][index],
              r[key === "origin" ? "newData" : "originData"][index],
              key
            )}
          </Typography.Text>
        ),
      })),
    ] as ColumnsType<any>;
  };

  return (
    <>
      <div
        id="compare-table"
        style={{
          display: "flex",
          maxHeight: "calc(100vh - 265px)",
          overflowY: "auto",
          border: "1px solid #dddddd",
        }}
      >
        <Table
          className="compare-table"
          style={{ width: "50%" }}
          size="small"
          rowKey={(r: TableCompareData) => r.row}
          columns={columns("origin")}
          dataSource={tableData?.data || []}
          scroll={{
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
          rowClassName={(r) =>
            `row-${r.status} ${
              cursor === r.row && r.status !== "nochange" ? "row-selected" : ""
            }`
          }
          pagination={{
            current: pagination.page,
            pageSize: 100,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
        <Table
          className="compare-table compare-new-table"
          style={{ width: "50%" }}
          size="small"
          rowKey={(r: TableCompareData) => r.row}
          columns={columns("new")}
          dataSource={tableData?.data || []}
          scroll={{
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
          rowClassName={(r) =>
            `row-${r.status} ${
              cursor === r.row && r.status !== "nochange" ? "row-selected" : ""
            }`
          }
          pagination={{
            current: pagination.page,
            pageSize: 100,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      </div>
      <div
        style={{
          border: "1px solid #dddddd",
          borderTop: "none",
          width: "100%",
          height: "45px",
          marginBottom: "-12px",
          display: "flex",
          alignItems: "center",
          padding: "0 10px",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Space style={{ position: "absolute", left: 10 }}>
          <Button
            size="small"
            type="primary"
            style={{ color: "#52c41a", background: "#e6ffec" }}
          >
            {t("common.add")}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ color: "#ff4d4f", background: "#ffebe9" }}
          >
            {t("common.delete")}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ color: "#faad14", background: "#fffbe6" }}
          >
            {t("common.change")}
          </Button>
        </Space>
        <Space>
          <Button
            onClick={handleFirst}
            disabled={
              (!pagination.first && pagination.first !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) <= pagination.first)
            }
          >
            {t("common.first")}
          </Button>
          <Button
            onClick={handlePre}
            disabled={
              (!pagination.first && pagination.first !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) <= pagination.first)
            }
          >
            {t("common.previous")}
          </Button>
          <Button
            onClick={handleNext}
            disabled={
              (!pagination.last && pagination.last !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) >= pagination.last)
            }
          >
            {t("common.next")}
          </Button>
          <Button
            onClick={handleLast}
            disabled={
              (!pagination.last && pagination.last !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) >= pagination.last)
            }
          >
            {t("common.last")}
          </Button>
        </Space>
        <Pagination
          size="small"
          showTotal={(total) => t("common.total", { count: total })}
          pageSize={100}
          hideOnSinglePage={true}
          showSizeChanger={false}
          current={pagination.page}
          total={pagination.total}
          onChange={handlePageChange}
          style={{ position: "absolute", right: 10 }}
        />
      </div>
    </>
  );
};

const DatasetVersionComparePage = () => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const { dataset_id, versions, tables } = useParams();
  const [currentDataset, setCurrentDataset] = useState<Dataset | undefined>();
  const versionDataRef: any = useRef({});
  const currentTableIdRef: any = useRef();
  const [currentTable, setCurrentTable] = useState<TableCompare | undefined>();
  const [tableList, setTableList] = useState<SheetTable[]>([]);
  const [filter, setFilter] = useState({
    only_changed: false,
    only_greater_than_0: false,
  });
  const [showDiff, setShowDiff] = useState({
    show_value: false,
    show_ratio: false,
    decimal_format: 2,
  });

  useEffect(() => {
    const only_changed = window.localStorage.getItem("compare_only_changed");
    const only_greater_than_0 = window.localStorage.getItem(
      "compare_only_greater_than_0"
    );
    if (only_changed || only_greater_than_0) {
      setFilter({
        only_changed: !!only_changed,
        only_greater_than_0: !!only_greater_than_0,
      });
    }
    const show_diff_value = window.localStorage.getItem(
      "compare_show_diff_value"
    );
    const show_diff_ratio = window.localStorage.getItem(
      "compare_show_diff_ratio"
    );
    const decimal_format = window.localStorage.getItem(
      "compare_decimal_format"
    );
    if (show_diff_value || show_diff_ratio || decimal_format) {
      setShowDiff({
        show_value: !!show_diff_value,
        show_ratio: !!show_diff_ratio,
        decimal_format: parseInt(decimal_format || "2"),
      });
    }
  }, []);

  useEffect(() => {
    getDatasetApi({ datasetId: Number(dataset_id) }).then((res) => {
      if (res.success) {
        setCurrentDataset(res.data);
      }
    });
  }, [dataset_id]);

  useEffect(() => {
    const versionIds = versions?.split("-");
    const tableIds = tables?.split("-");
    if (
      !!versionIds &&
      versionIds.length > 1 &&
      !!tableIds &&
      tableIds.length > 0
    ) {
      currentTableIdRef.current = Number(tableIds[0]);
      versionDataRef.current = {};
      setTableList([]);
      setCurrentTable(undefined);
      for (let tableindex = 0; tableindex < tableIds.length; tableindex++) {
        getDatasetVersionTableDataApi({
          versionId: Number(versionIds[0]),
          tableId: Number(tableIds[tableindex]),
        }).then((originRes) => {
          if (originRes?.success) {
            return getDatasetVersionTableDataApi({
              versionId: Number(versionIds[1]),
              tableId: Number(tableIds[tableindex]),
            }).then((res) => {
              if (res.success) {
                versionDataRef.current[`table-${tableIds[tableindex]}`] =
                  calCompareTableData(originRes.data, res.data);
                setTableList(
                  Object.values(versionDataRef.current)
                    .map((table: any) => ({
                      id: table?.id || 0,
                      name: table?.name || "",
                    }))
                    .sort(
                      (a: any, b: any) =>
                        tableIds.indexOf(`${a.id}`) -
                        tableIds.indexOf(`${b.id}`)
                    )
                );
                if (
                  !!currentTableIdRef.current &&
                  currentTableIdRef.current === res.data?.id
                ) {
                  setCurrentTable(
                    versionDataRef.current[`table-${res.data?.id}`]
                  );
                }
              }
            });
          }
        });
      }
    }
  }, [versions, tables]);

  const handleTabChange = (tableId: string) => {
    currentTableIdRef.current = Number(tableId);
    setCurrentTable(
      versionDataRef.current[`table-${tableId}`] || { id: Number(tableId) }
    );
  };

  const renderCurrentTableData = useMemo(
    () => (
      <CompareTableData
        table={currentTable}
        filter={filter}
        showDiff={showDiff}
      />
    ),
    [currentTable, filter, showDiff]
  );

  const ConfigModalButton = () => {
    const [show, setShow] = useState(false);
    const [form] = Form.useForm();

    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);

    useEffect(() => {
      form.setFieldsValue({
        only_changed: filter.only_changed,
        only_greater_than_0: filter.only_greater_than_0,
        show_value: showDiff.show_value,
        show_ratio: showDiff.show_ratio,
        decimal_format: showDiff.decimal_format,
      });
    }, [show, form]);

    const handleSubmit = () => {
      const data = form.getFieldsValue();
      let filterRet = {} as any;
      let showDiffRet = {} as any;
      if (data?.only_changed !== filter.only_changed) {
        if (!!data?.only_changed) {
          window.localStorage.setItem("compare_only_changed", "true");
        } else {
          window.localStorage.removeItem("compare_only_changed");
        }
        filterRet.only_changed = !!data?.only_changed;
      }
      if (data?.only_greater_than_0 !== filter.only_greater_than_0) {
        if (!!data?.only_greater_than_0) {
          window.localStorage.setItem("compare_only_greater_than_0", "true");
        } else {
          window.localStorage.removeItem("compare_only_greater_than_0");
        }
        filterRet.only_greater_than_0 = !!data?.only_greater_than_0;
      }
      if (data?.show_value !== showDiff.show_value) {
        if (!!data?.show_value) {
          window.localStorage.setItem("compare_show_diff_value", "true");
        } else {
          window.localStorage.removeItem("compare_show_diff_value");
        }
        showDiffRet.show_value = !!data?.show_value;
      }
      if (data?.show_ratio !== showDiff.show_ratio) {
        if (!!data?.show_ratio) {
          window.localStorage.setItem("compare_show_diff_ratio", "true");
        } else {
          window.localStorage.removeItem("compare_show_diff_ratio");
        }
        showDiffRet.show_ratio = !!data?.show_ratio;
      }
      if (data?.decimal_format !== showDiff.decimal_format) {
        const decimal_format = parseInt(data?.decimal_format) || 2;
        window.localStorage.setItem(
          "compare_decimal_format",
          decimal_format.toString()
        );
        showDiffRet.decimal_format = decimal_format;
      }
      if (Object.keys(filterRet).length > 0) {
        setFilter((data) => ({ ...data, ...filterRet }));
      }
      if (Object.keys(showDiffRet).length > 0) {
        setShowDiff((data) => ({ ...data, ...showDiffRet }));
      }
      closeModal();
    };

    return (
      <>
        <Modal
          centered
          title={t("nav.settings")}
          open={show}
          onCancel={closeModal}
          onOk={handleSubmit}
        >
          <Form name="compare config form" form={form}>
            <Form.Item
              name="only_changed"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Checkbox>
                {t("dataset.file_compare_settings.only_changed")}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="only_greater_than_0"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Checkbox>
                {t("dataset.file_compare_settings.only_greater_than_0")}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="show_value"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Checkbox>
                {t("dataset.file_compare_settings.show_diff_value")}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="show_ratio"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Checkbox>
                {t("dataset.file_compare_settings.show_diff_ratio")}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="decimal_format"
              label={t("sheet.decimal_format")}
              style={{ marginTop: "0.5em" }}
            >
              <Select
                style={{ width: "10em" }}
                options={new Array(10).fill(null).map((item, index) => ({
                  value: index,
                  label: index,
                }))}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Button icon={<SettingOutlined />} onClick={openModal} />
      </>
    );
  };

  return (
    <>
      <NavHyperLink
        showBack={true}
        items={[
          {
            title: t("dataset.title"),
            path: `${IsSimpleLayout ? "/simple" : ""}/dataset`,
          },
          {
            title: currentDataset?.name || "",
            path: `${
              IsSimpleLayout ? "/simple" : ""
            }/dataset/data/${dataset_id}`,
          },
          { title: t("dataset.file_compare") },
        ]}
      />
      <div className="layout-content-box" style={{ minWidth: "1000px" }}>
        <div style={{ textAlign: "end" }}>
          <ConfigModalButton />
        </div>
        {tableList.length > 0 ? (
          <>
            <Tabs
              onTabClick={handleTabChange}
              activeKey={`${currentTable?.id}`}
              items={tableList.map((table: SheetTable) => ({
                label: table.name || "",
                key: `${table.id}`,
              }))}
            />
            {renderCurrentTableData}
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "10vh 0" }}>
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};

export default DatasetVersionComparePage;
