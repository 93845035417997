import { User } from "./User";

export type Notification = {
  id: number;
  title: string;
  receiver: User;
  content: string;
  category: string;
  created_at: string;
  updated_at: string;
  read: boolean;
  link: string;
};

export enum NotificationCategory {
  DATASET = "dataset",
  WORKSPACE = "workspace",
  PLATFORM = "platform",
}

export enum NotificationType {
  ALL = "all",
  READ = "read",
  UNREAD = "unread",
}
