const IconExport = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="currentColor">
      <title>export</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-721.000000, -404.000000)"
          fillRule="nonzero"
        >
          <g id="export" transform="translate(721.000000, 404.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="18"
              height="18"
            ></rect>
            <path
              d="M15.6146484,13.3136719 L14.6689453,13.3136719 C14.5951172,13.3136719 14.5335937,13.3751953 14.5335937,13.4490234 L14.5335937,14.5353516 L3.46464844,14.5353516 L3.46464844,3.46464844 L14.5353516,3.46464844 L14.5353516,4.55097656 C14.5353516,4.62480469 14.596875,4.68632812 14.6707031,4.68632812 L15.6164062,4.68632812 C15.6902344,4.68632812 15.7517578,4.6265625 15.7517578,4.55097656 L15.7517578,2.78964844 C15.7517578,2.49082031 15.5109375,2.25 15.2121094,2.25 L2.78964844,2.25 C2.49082031,2.25 2.25,2.49082031 2.25,2.78964844 L2.25,15.2103516 C2.25,15.5091797 2.49082031,15.75 2.78964844,15.75 L15.2103516,15.75 C15.5091797,15.75 15.75,15.5091797 15.75,15.2103516 L15.75,13.4490234 C15.75,13.3734375 15.6884766,13.3136719 15.6146484,13.3136719 Z M15.9416016,8.88925781 L13.4472656,6.92050781 C13.3541016,6.84667969 13.21875,6.91347656 13.21875,7.03125 L13.21875,8.3671875 L7.69921875,8.3671875 C7.621875,8.3671875 7.55859375,8.43046875 7.55859375,8.5078125 L7.55859375,9.4921875 C7.55859375,9.56953125 7.621875,9.6328125 7.69921875,9.6328125 L13.21875,9.6328125 L13.21875,10.96875 C13.21875,11.0865234 13.3558594,11.1533203 13.4472656,11.0794922 L15.9416016,9.11074219 C16.0136719,9.05449219 16.0136719,8.94550781 15.9416016,8.88925781 L15.9416016,8.88925781 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconExport;
