import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Table as TableType } from "../types/Table";
import { Drawer } from "antd";
import TableForm from "./TableForm";
import { useTranslation } from "react-i18next";

const TableInfoDrawer = forwardRef(
  (
    props: {
      disabled?: boolean;
      submitCallback?: (data: TableType) => void;
      referenceTables?: TableType[];
      needPrimaryKey?: boolean;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<TableType | undefined>();
    const [existedTables, setExistedTables] = useState<TableType[]>([]);

    useImperativeHandle(ref, () => ({
      show: (item: TableType, tables?: TableType[]) => {
        setShowItem(item);
        if (tables) {
          setExistedTables(tables);
        }
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    return (
      <Drawer
        title={t(
          `table.actions.${
            props.disabled ? "info" : showItem?.id ? "edit" : "new"
          }`
        )}
        placement="right"
        onClose={handleCancel}
        open={!!showItem}
        width={"max(50vw, 500px)"}
      >
        <TableForm
          initData={showItem}
          disabled={props.disabled}
          cancelCallback={handleCancel}
          submitCallback={props.submitCallback}
          needPrimaryKey={props.needPrimaryKey}
          referenceTables={
            (showItem?.id
              ? (props.referenceTables || []).filter(
                  (table) => table.id !== showItem?.id
                )
              : props.referenceTables) || []
          }
          checkName={{ existedTables }}
        />
      </Drawer>
    );
  }
);

export default TableInfoDrawer;
