import { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import useCurrentWorkspace from "../../utils/useCurrentWorkspace";
import TableInfoDrawer from "../../components/TableInfoDrawer";
import { useParams } from "react-router-dom";
import { Role, SheetTable } from "../../types/Table";
import { Spin, Space, Button, Empty, message, notification } from "antd";
import TablesSheet from "../../components/TablesSheet";
import {
  getTableApi,
  updateTableDataApi,
  getTableDataApi,
  checkTableWorkspacePermissionApi,
  exportTableDataApi,
  getTableCurrentSyncStatusApi,
  syncTableApi,
} from "../../api/TableApi";
import viewUtils from "../../utils/viewUtils";
import { showWorkspacePermissionModal } from "../../components/WorkspacePermissionModal";
import { connect } from "react-redux";
import { User } from "../../types/User";
import TableShare from "./TableShare";
import GlobalLoader from "../../components/GlobalLoading";
import TableActionHistory from "./TableActionHistory";
import { calCompareTableDataStatistics } from "../../utils/SheetCompareUtils";
import { validateEmptyFields } from "../../utils/TableDataValidationUtils";
import CommonErrorView from "../../components/CommonErrorView";
const TableDataPage = (props: { user: User }) => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const currentWorkspace = useCurrentWorkspace();
  const { table_id } = useParams();
  const [tabledata, setTabledata] = useState<SheetTable | undefined | null>();
  const [previousTableData, setPreviousTableData] = useState();
  const tableInfoDrawerRef: any = useRef();
  const sheetRef: any = useRef();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [currentSyncJob, setCurrentSyncJob] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    refreshTableData();
    fetchCurrentRun(true);
    // eslint-disable-next-line
  }, [currentWorkspace, table_id]);

  const fetchCurrentRun = (skipRefresh?: boolean) => {
    getTableCurrentSyncStatusApi({ tableId: Number(table_id) }).then((res) => {
      if (res.success) {
        if (["cancelled", "failed", "succeeded"].includes(res?.data?.status)) {
          if (!skipRefresh) {
            refreshTableData(true);
          }
        } else {
          setCurrentSyncJob(res.data);
        }
      }
    });
  };

  useEffect(() => {
    let interval: any;
    if (!!currentSyncJob) {
      interval = setTimeout(fetchCurrentRun, 5000);
    }
    return () => {
      !!interval && clearInterval(interval);
    };
  }, [currentSyncJob]);

  const refreshTableData = (clearCurrentSyncJob?: boolean) => {
    if (currentWorkspace && table_id) {
      let table: SheetTable | null = null;
      getTableApi({ tableId: Number(table_id) })
        .then((res) => {
          if (res.success) {
            table = res.data;
            if (res.data.table_type === "COMMON") {
              setErrorMessage("此表为数据集中的表，不能打开查看");
              setShowError(true);
            } else {
              return getTableDataApi({ tableId: Number(table_id) });
            }
          } else {
            if (res.code === 403) {
              checkTableWorkspacePermissionApi({
                datasetId: Number(table_id),
              }).then((res) => {
                if (res.success) {
                  showWorkspacePermissionModal({
                    hasPermission: res.data.has_permission,
                    targetWorkspaceId: res.data.workspace_id,
                  });
                }
              });
            } else if (res.code === 404) {
              setErrorMessage("id 不存在");
              setShowError(true);
            }
          }
        })
        .then((res) => {
          if (res?.success) {
            sheetRef.current && sheetRef.current.resetInitStatus();
            setTabledata({
              ...table,
              data: res.data,
            });
            if (clearCurrentSyncJob) {
              setCurrentSyncJob(undefined);
            }
          }
        });
    } else {
      sheetRef.current && sheetRef.current.resetInitStatus();
      setTabledata({});
    }
  };

  const handleUpdateData = () => setUpdateStatus(true);

  const handleOpenInfo = () => {
    tableInfoDrawerRef.current &&
      tableInfoDrawerRef.current.show({ ...tabledata, data: [] });
  };

  const handleExport = () => {
    GlobalLoader.show();
    exportTableDataApi({ tableId: Number(tabledata?.id) }).then((res) => {
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = `${tabledata?.name}.xlsx`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        GlobalLoader.hide();
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        GlobalLoader.hide();
        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("common.export"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const handleSyncTable = () => {
    GlobalLoader.show();
    syncTableApi({ tableId: Number(tabledata?.id) }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("table.actions.sync"),
            status: t("common.success"),
          })
        );
        setCurrentSyncJob(res.data);
      } else {
        GlobalLoader.hide();
        message.error(
          t("common.result_status", {
            title: t("table.actions.sync"),
            status: t("common.fail"),
          })
        );
      }
    });
  };

  const renderSheetView = useMemo(
    () => (
      <TablesSheet
        ref={sheetRef}
        id="luckysheet-table-data"
        tables={tabledata ? [tabledata] : []}
        updateCallback={handleUpdateData}
      />
    ),
    [tabledata]
  );

  const handleImportCurrentTable = () => {
    sheetRef?.current && sheetRef.current.importCurrentTable(tabledata);
  };

  const handleSaveCurrentTable = () => {
    const data = sheetRef?.current && sheetRef.current.getCurrentTableData();
    if (!tabledata?.id) {
      message.error(
        t("common.result_status", {
          title: t("common.save"),
          status: t("common.fail"),
        })
      );
      return;
    }

    const compareDataRet = calCompareTableDataStatistics(
      {
        id: tabledata?.id,
        name: tabledata?.name,
        meta: tabledata?.meta,
        data: previousTableData || tabledata?.data,
      },
      {
        data: data || [],
      }
    );

    const validateDataResult = validateEmptyFields(tabledata, data);

    if (!validateDataResult?.status) {
      notification.error({
        message: t("dataset.tips.data_validation_failed"),
        description: (
          <>
            {validateDataResult.missingFields?.map((item) => {
              return (
                <p>
                  {t("dataset.tips.missing_field", {
                    row: item.row + 2,
                    field: item.field.name,
                  })}
                </p>
              );
            })}
          </>
        ),
      });
      return;
    }

    updateTableDataApi({
      tableId: Number(tabledata?.id),
      data: {
        data: data || [],
        ...(compareDataRet.delete_count > 0 ||
        compareDataRet.add_count > 0 ||
        compareDataRet.change_data.length > 0
          ? {
              log: compareDataRet,
            }
          : {}),
      },
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.save"),
            status: t("common.success"),
          })
        );
        setPreviousTableData(data);
        sheetRef?.current && sheetRef.current.prepareValidation();
      } else {
        let messageStr = t("common.try_again");
        if (res.message?.is_valid === false && !!res.errors) {
          messageStr = t("common.result_status", {
            title: t("table.actions.data_validation"),
            status: t("common.fail"),
          });
          sheetRef?.current && sheetRef.current.prepareValidation(res.errors);
        }
        message.error(messageStr);
      }
    });
  };

  return (
    <>
      <NavHyperLink
        showBack={true}
        items={[
          {
            title: t("table.title"),
            path: `${IsSimpleLayout ? "/simple" : ""}/table`,
          },
          { title: tabledata?.name || "" },
        ]}
      />
      <div className="layout-content-box" style={{ minWidth: "700px" }}>
        {showError ? (
          <CommonErrorView
            style={{ position: "relative", height: "100%", width: "auto" }}
            message={errorMessage}
          />
        ) : (
          <>
            {!!tabledata ? (
              <div style={{ position: "relative" }}>
                <TableInfoDrawer ref={tableInfoDrawerRef} disabled={true} />
                <Space size={10} className="page-header-actions">
                  <Space size={0} align="baseline">
                    <Button type="link" onClick={handleOpenInfo}>
                      {t("table.actions.info")}
                    </Button>
                  </Space>
                  <Space>
                    {tabledata.role === Role.ADMIN && (
                      <TableActionHistory table={tabledata} />
                    )}
                    {!!tabledata?.sync_config?.manual && (
                      <Button onClick={handleSyncTable}>
                        {t("table.actions.sync")}
                      </Button>
                    )}
                    {props.user && tabledata.role === Role.ADMIN && (
                      <TableShare table={tabledata} />
                    )}
                    {tabledata.role === Role.ADMIN && (
                      <Button onClick={handleImportCurrentTable}>
                        {t("common.import")}
                      </Button>
                    )}
                    <Button onClick={handleExport}>{t("common.export")}</Button>
                    {tabledata.role === Role.ADMIN && (
                      <Button
                        type="primary"
                        onClick={handleSaveCurrentTable}
                        disabled={!updateStatus}
                      >
                        {t("common.save")}
                      </Button>
                    )}
                  </Space>
                </Space>
                <div style={{ position: "relative", background: "white" }}>
                  {renderSheetView}
                </div>
                {!!currentSyncJob && (
                  <div
                    style={{
                      position: "absolute",
                      background: "rgba(255,255,255,0.4)",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Spin tip={t("table.tips.syncing")}> </Spin>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "10vh 0" }}>
                {tabledata === null ? (
                  <Empty description={t("common.no_permission")} />
                ) : (
                  <Spin />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
});

export default connect(mapStateToProps, {})(TableDataPage);
