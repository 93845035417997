import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  message,
  Select,
  Button,
  Drawer,
  Table,
  Tooltip,
  Form,
  Dropdown,
} from "antd";
import type { MenuProps, SelectProps } from "antd";
import { CheckOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { DatasetVersion, Task } from "../../types/Dataset";
import { User } from "../../types/User";
import { SheetTable, TableView } from "../../types/Table";
import { Workspace } from "../../types/Workspace";
import {
  createDatasetVersionTaskApi,
  deleteDatasetVersionTaskApi,
  updateDatasetVersionTaskApi,
  updateDatasetVersionTaskDataApi,
} from "../../api/DatasetApi";
import { getTableDataApi } from "../../api/TableApi";
import { getUserListApi } from "../../api";
import viewUtils from "../../utils/viewUtils";
import IconBulb from "../../common/images/icons/bulb";
import FillDataFromTableModal from "../../components/FillDataFromTableModal";
import GlobalLoader from "../../components/GlobalLoading";
import { filterData } from "../../utils/SheetFilterUtils";
import { calCompareTableDataStatistics } from "../../utils/SheetCompareUtils";

import { getConfig } from "../../config/config";
const config = getConfig();

const DatasetOperators = (props: {
  version?: DatasetVersion;
  table?: SheetTable;
  currentWorkspace?: Workspace;
  updateCallback?: (action: String, task: Task) => void;
  handleOpenBatchAddTask?: () => void;
  changeTask?: {
    onSelect: (id: any) => void;
    value: any;
    options: SelectProps["options"];
  };
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [show, setShow] = useState(false);
  const assignees = props?.table?.tasks?.map((task: Task) => task.assignee);
  const fillDataFromTableModalRef: any = useRef();

  const currentTableTasksCount = props.table?.tasks?.reduce(
    (ret: any, task: Task) => {
      if (task.is_locked) {
        ret[0]++;
      }
      ret[1]++;
      return ret;
    },
    [0, 0]
  );

  useEffect(() => {
    if (props.table?.id && props.version?.id) {
      getUserListApi({ workspace: Number(props.currentWorkspace?.id) }).then(
        (res) => {
          if (res.success) {
            setUsers(res.data);
          }
        }
      );
    }
  }, [props]);

  const handleOpenBatchAddTask = () =>
    props.handleOpenBatchAddTask && props.handleOpenBatchAddTask();

  const handleAdd = (data: any) => {
    if (assignees?.includes(data.username)) {
      message.error(t("dataset.tips.user_existed"));
    } else {
      createDatasetVersionTaskApi({
        assignee: `${data.username}`,
        table_id: Number(props.table?.id),
        version_id: Number(props.version?.id),
      }).then((res) => {
        if (res.success) {
          message.success(
            t("common.result_status", {
              title: t("common.add"),
              status: t("common.success"),
            })
          );
          props.updateCallback && props.updateCallback("add", res.data);
        } else {
          message.error(
            viewUtils.prettifyErrorMessage(res.message) ||
              t("common.result_status", {
                title: t("common.add"),
                status: t("common.fail"),
              })
          );
        }
      });
    }
  };

  const handleRemove = (task: Task) => {
    deleteDatasetVersionTaskApi({
      taskId: Number(task?.id),
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.delete"),
            status: t("common.success"),
          })
        );
        props.updateCallback && props.updateCallback("delete", task);
      } else {
        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("common.delete"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const handleFillDataSubmit = async (table_view: TableView) => {
    GlobalLoader.show();
    const tableFields: string[] =
      props.table?.meta?.fields?.map((field) => field.identifier || "") || [];
    const parentTableFields: string[] = table_view?.columns || [];
    const diffFieldkeys = parentTableFields.filter(
      (field) => !tableFields.includes(field)
    );
    let currentTask = props.table?.tasks?.find(
      (task: Task) => task.id === table_view?.task_id
    );
    if (diffFieldkeys?.length > 0) {
      message.error(t("dataset.tips.data_setup_mismatch"));
      GlobalLoader.hide();
      return;
    } else {
      let res = await getTableDataApi(
        {
          tableId: Number(table_view.parent_table),
        },
        false
      );
      if (!res.success) {
        message.error(
          t("common.result_status", {
            title: t("dataset.tips.data_setup"),
            status: t("common.fail"),
          })
        );
        GlobalLoader.hide();
        return;
      }
      const FilteredData = filterData(
        res.data || [],
        table_view.filters,
        table_view.columns,
        { removeEmpty: true }
      );
      const compareDataRet = calCompareTableDataStatistics(
        {
          id: props.table?.id,
          name: props.table?.name,
          meta: props.table?.meta,
          data: currentTask.data,
        },
        { data: FilteredData }
      );
      res = await updateDatasetVersionTaskDataApi({
        taskId: Number(currentTask?.id),
        data: {
          data: FilteredData,
          ...(compareDataRet.delete_count > 0 ||
          compareDataRet.add_count > 0 ||
          compareDataRet.change_data.length > 0
            ? {
                log: compareDataRet,
              }
            : {}),
        },
      });
      if (!res.success) {
        message.error(
          t("common.result_status", {
            title: t("dataset.tips.data_setup"),
            status: t("common.fail"),
          })
        );
        GlobalLoader.hide();
        return;
      }
      res = await updateDatasetVersionTaskApi({
        taskId: Number(currentTask?.id),
        data: {
          assignee: currentTask?.assignee,
          table_id: Number(props.table?.id),
          version_id: Number(props.version?.id),
          data_config: {
            parent_table: table_view.parent_table,
            columns: table_view.columns || [],
            filters: table_view.filters || {},
            parent_table_name: table_view?.parent_table_data?.name,
          },
        },
      });
      if (!res.success) {
        message.error(
          t("common.result_status", {
            title: t("dataset.tips.data_setup"),
            status: t("common.fail"),
          })
        );
        GlobalLoader.hide();
        return;
      }
      currentTask = {
        ...currentTask,
        data_config: res.data?.data_config || {},
      };
    }
    message.success(
      t("common.result_status", {
        title: t("dataset.tips.data_setup"),
        status: t("common.success"),
      })
    );
    fillDataFromTableModalRef.current &&
      fillDataFromTableModalRef.current.close();
    props.updateCallback && props.updateCallback("update", currentTask);
    GlobalLoader.hide();
  };

  const handleFillData = (task: Task) => {
    fillDataFromTableModalRef.current &&
      fillDataFromTableModalRef.current.show({
        ...task?.data_config,
        task_id: Number(task?.id),
      });
  };

  const items: MenuProps["items"] = props.changeTask?.options?.map((task) => ({
    key: task.value || "",
    label: (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => {
          if (task.value !== props.changeTask?.value) {
            !!props.changeTask?.onSelect &&
              props.changeTask?.onSelect(task.value);
          }
        }}
      >
        <div style={{ flex: 1 }}>{task.label || ""}</div>
        {task.value === props.changeTask?.value && (
          <CheckOutlined
            style={{
              color: "#52c41a",
              marginLeft: "10px",
            }}
          />
        )}
      </div>
    ),
  }));

  return (
    <>
      <FillDataFromTableModal
        table={props.table}
        ref={fillDataFromTableModalRef}
        submitCallback={handleFillDataSubmit}
      />
      <Drawer
        title={t("dataset.manage_tasks")}
        placement="right"
        onClose={() => setShow(false)}
        open={!!show}
        width={"min(90vw, 600px)"}
      >
        <Form onFinish={handleAdd} style={{ display: "flex" }}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: t("dataset.tips.select_user"),
              },
            ]}
            style={{ flex: 1, marginRight: 5 }}
          >
            <Select
              showSearch={true}
              style={{ width: "100%" }}
              options={users
                .filter((user: User) => !assignees?.includes(user.username))
                .map((item) => ({
                  label: `${viewUtils.prettifyUsername(item.name)}(${
                    item.username
                  })`,
                  value: item.username,
                }))}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("common.add_with_info", { title: t("dataset.operator") })}
          </Button>
        </Form>
        <Table
          className="common-table"
          pagination={false}
          rowKey={(r) => r?.id || ""}
          columns={[
            {
              key: "assignee",
              title: t("dataset.operator"),
              render: (r) => (
                <Tooltip title={r.assignee_user?.username}>
                  {viewUtils.prettifyUsername(
                    r.assignee_user?.name || r.assignee_user?.username
                  )}
                </Tooltip>
              ),
            },
            ...(!!config.enableMobileFormMode
              ? [
                  {
                    key: "table",
                    title: (
                      <>
                        {t("common.source")}
                        <Tooltip title={t("dataset.tips.data_setup_tips")}>
                          <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                        </Tooltip>
                      </>
                    ),
                    render: (d: Task) => (
                      <>
                        {d?.data_config?.parent_table_name || ""}
                        <Button
                          size="small"
                          type="link"
                          onClick={() => handleFillData(d)}
                        >
                          {t(
                            `common.${
                              !!d?.data_config?.parent_table
                                ? "reset"
                                : "not_configured"
                            }`
                          )}
                        </Button>
                      </>
                    ),
                  },
                ]
              : []),
            {
              width: 100,
              key: "status",
              title: t("common.status"),
              render: (r) =>
                !!r.is_locked
                  ? t("dataset.task_status.locked")
                  : !!r.data && r.data.length > 0
                  ? t("dataset.task_status.saved")
                  : t("dataset.task_status.pending"),
            },
            {
              key: "actions",
              title: t("common.actions"),
              width: 100,
              render: (d: Task) => (
                <>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => handleRemove(d)}
                  >
                    {t("common.delete")}
                  </Button>
                </>
              ),
            },
          ]}
          dataSource={props.table?.tasks || []}
        />
        <Button
          type="link"
          style={{ marginTop: "1em", padding: 0 }}
          onClick={handleOpenBatchAddTask}
        >
          {t("dataset.actions.batch_operations")}
        </Button>
      </Drawer>
      <Tooltip
        title={props.version?.is_locked ? t("dataset.tips.need_unlock") : ""}
      >
        <div className="sheet-action-item">
          <Dropdown menu={{ items }}>
            <Button
              className={props.version?.is_locked ? "ant-btn-disabled" : ""}
              disabled={props.version?.is_locked}
              size="small"
              icon={<IconBulb style={{ marginRight: "-3px" }} />}
              onClick={() => !props.version?.is_locked && setShow(true)}
            >
              {t("dataset.actions.tasks")}
              {currentTableTasksCount[1] !== 0 &&
                `(${currentTableTasksCount[0]}/${currentTableTasksCount[1]})`}
            </Button>
          </Dropdown>
        </div>
      </Tooltip>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(DatasetOperators);
