import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  message,
  Space,
  Table,
  Tooltip,
  Input,
  Form,
} from "antd";
import { connect } from "react-redux";
import type { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import usePermission from "../../utils/usePermission";
import { ApiKey } from "../../types/ApiKey";
import { User } from "../../types/User";
import { Workspace } from "../../types/Workspace";
import { getWorkspaceApi } from "../../api";
import {
  createApiKeyApi,
  deleteApiKeyApi,
  getApiKeysApi,
  updateApiKeyApi,
} from "../../api/ApiKeyApi";
import modal from "antd/lib/modal";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const ApiKeyManagePage = (props: {
  user: User;
  currentWorkspace: Workspace;
}) => {
  const { t } = useTranslation();
  const permission = usePermission();
  const workspace_id = props.currentWorkspace.id;
  const [workspace, setWorkspace] = useState<Workspace | undefined>();
  const [apiKeys, setApiKeys] = useState<ApiKey[] | undefined>();
  const [apiKeyModalVisible, setApiKeyModalVisible] = useState(false);
  const [editingApiKey, setEditingApiKey] = useState<ApiKey | undefined>();

  useEffect(() => {
    if (editingApiKey) {
      setApiKeyModalVisible(true);
    }
  }, [editingApiKey]);

  useEffect(() => {
    if (!!permission.username) {
      let workspace;
      if (workspace_id) {
        // get workspace
        getWorkspaceApi({ workspaceId: Number(workspace_id) }).then((res) => {
          if (res.success) {
            setWorkspace(res.data);
            workspace = res.data;
            fetchApiKeys(workspace);
          } else {
            setWorkspace(undefined);
          }
        });
      }
    }
  }, [permission, workspace_id]);

  const fetchApiKeys = (workspace: Workspace) => {
    getApiKeysApi(workspace).then((res) => {
      if (res.success) {
        setApiKeys(res.data);
      } else {
        setApiKeys([]);
      }
    });
  };

  const columns: ColumnsType<ApiKey> = [
    {
      key: "name",
      title: t("common.name"),
      dataIndex: "name",
      ellipsis: true,
    },
    {
      key: "key",
      title: t("workspace.token"),
      dataIndex: "key",
      ellipsis: true,
    },
    {
      key: "owner",
      title: t("common.owner"),
      render: (d: ApiKey) => (
        <Tooltip title={d.owner.username}>
          {viewUtils.prettifyUsername(d.owner.name)}
        </Tooltip>
      ),
    },
    {
      key: "created_at",
      title: t("common.created_at"),
      render: (d: ApiKey) => viewUtils.prettifyDatatime(d.created_at),
    },
    {
      key: "last_used",
      title: t("workspace.last_used"),
      render: (d: ApiKey) => viewUtils.prettifyDatatime(d.last_used),
    },
    {
      key: "actions",
      title: t("common.actions"),
      align: "center",
      render: (d: ApiKey) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button size="small" type="link" onClick={() => handleUpdate(d)}>
              {t("common.edit")}
            </Button>
            <Button size="small" type="link" onClick={() => handleDelete(d)}>
              {t("common.delete")}
            </Button>
          </div>
        );
      },
    },
  ];

  const handleCreate = () => {
    // create
    setApiKeyModalVisible(true);
  };

  const handleUpdate = (apiKey: ApiKey) => {
    // update
    setEditingApiKey(apiKey);
  };

  const handleDelete = (apiKey: ApiKey) => {
    Modal.confirm({
      title: `${t("common.delete")}${t("workspace.token")}`,
      icon: <ExclamationCircleOutlined />,
      content: t("workspace.tips.confirm_api_key", {
        name: apiKey.name,
      }),
      onOk: () => {
        deleteApiKeyApi(apiKey).then((res) => {
          if (res.success) {
            setApiKeys(apiKeys?.filter((k) => k.id !== apiKey.id));
          } else {
            message.error(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.fail"),
              })
            );
          }
        });
      },
      cancelText: t("common.cancel"),
      okText: t("common.ok"),
    });
  };

  const handleCreateOrUpdateResult = (values: any) => {
    // create or update result
    setEditingApiKey(undefined);
    if (values.id) {
      updateApiKeyApi(values).then((res) => {
        if (res.success) {
          setApiKeys(
            apiKeys?.map((k) => {
              if (k.id === values.id) {
                k.name = values.name;
              }
              return k;
            })
          );
        } else {
          message.error(
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.fail"),
            })
          );
        }
      });
    } else {
      if (!workspace) {
        message.error(t("workspace.tips.workspace_not_found"));
        return;
      }

      createApiKeyApi({ ...values }, workspace).then((res) => {
        if (res.success) {
          modal.confirm({
            title: t("common.result_status", {
              title: t("common.create"),
              status: t("common.success"),
            }),
            width: 600,
            cancelText: t("common.close"),
            content: (
              <>
                <Paragraph>{t("workspace.tips.you_key")}</Paragraph>
                <Paragraph strong code style={{ display: 'inline' }}>
                  {res.data.key}
                </Paragraph>
                <Button type="primary" size="small" onClick={() => handleClickCopyToken(res.data.key)}>{t("workspace.tips.copy_token")}</Button>
                <Paragraph>{t("workspace.tips.save_key")}</Paragraph>
              </>
            ),
            onCancel: () => {
              // retrieve api keys
              fetchApiKeys(workspace);
            },
            okButtonProps: {
              hidden: true,
            },
          });
        } else {
          message.error(
            t("common.result_status", {
              title: t("common.create"),
              status: t("common.fail"),
            })
          );
        }
      });
    }
  };

  const handleClickCopyToken = (token: string) => {
    navigator.clipboard.writeText(token)
    .then(() => {
      message.success(
        t("common.result_status", {
          title: t("common.copy"),
          status: t("common.success"),
        })
      );
    })
    .catch(() => {
      message.error(
        t("common.result_status", {
          title: t("common.copy"),
          status: t("common.fail"),
        })
      );
    });
  }

  const handleCreateOrUpdateCancel = () => {
    setEditingApiKey(undefined);
  };

  const handleCopy = () => {
    message.success(
      t("workspace.tips.copy_workspace_id", { status: t("common.success") })
    );
  };

  const ApiKeyModal = ({
    visible,
    onClose,
    ApiKey,
    handleResult,
  }: {
    visible: boolean;
    onClose: () => void;
    ApiKey?: ApiKey;
    handleResult: (values: any) => void;
  }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
  
    // 在模态框关闭或提交后重置 form
    const afterClose = () => form.resetFields();
  
    useEffect(() => {
      form.resetFields();
      if (!!ApiKey) {
        form.setFieldsValue({ name: ApiKey.name });
      }
    }, [ApiKey]);
  
    // 提交 form
    const onSubmit = () => {
      form.validateFields().then((values) => {
        if (ApiKey) {
          values.id = ApiKey.id;
        }
        handleResult(values);
        onClose();
      });
    };
  
    return (
      <Modal
        centered
        open={visible}
        onCancel={onClose}
        onOk={onSubmit}
        afterClose={afterClose}
        title={`${t(ApiKey ? "common.update" : "common.create")} API Key`}
      >
        <Form form={form}>
          <Form.Item
            name="name"
            label={t("common.name")}
            rules={[
              { required: true, message: "" },
              {
                max: 50,
                message: t("common.char_len_limit", {
                  title: t("common.name"),
                  count: 50,
                }),
              },
            ]}
          >
            <Input
              placeholder={t("common.required", { title: t("common.name") })}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <ApiKeyModal
        visible={apiKeyModalVisible}
        onClose={() => {
          setApiKeyModalVisible(false);
          handleCreateOrUpdateCancel();
        }}
        ApiKey={editingApiKey}
        handleResult={handleCreateOrUpdateResult}
      />
      <div style={{ position: "relative", paddingTop: "1.5em" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.5em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Title level={5} style={{ margin: 0 }}>
              {t("common.workspace")} ID：
            </Title>
            <Title
              level={5}
              style={{ margin: 0 }}
              copyable={{
                tooltips: false,
                icon: [<CopyOutlined />, <CopyOutlined />],
                onCopy: handleCopy,
              }}
            >
              {workspace?.id}
            </Title>
          </div>
          <Space size={10} className="tab-action">
            <Button type="primary" onClick={handleCreate}>
              {t("common.add_with_info", { title: t("workspace.token") })}
            </Button>
          </Space>
        </div>

        <div>
          <Table
            className="common-table"
            rowKey={(r) => r.id}
            scroll={{ x: 700, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={apiKeys ?? []}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: [10, 20, 50],
              showTotal: (total) => t("common.total", { count: total }),
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps)(ApiKeyManagePage);
