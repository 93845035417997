import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Button, Input } from "antd";

import GaodeHeatmap, { GaodeHeatmapConfigForm } from "./GaodeHeatmap";
import D3Heatmap, { D3HeatmapConfigForm } from "./D3Heatmap";
import BaiduHeatmap, { BaiduHeatmapConfigForm } from "./BaiduHeatmap";
import { ChartView } from "../../../types/ChartView";
import MapAppKeyModal from "../../MapAppKeyModal";

type Props = {
  id: string;
  chart_view: any;
  table_data: any;
};

const Heatmap = (props: Props) => {
  if (props.chart_view?.chart_config?.map_type === "gaode") {
    return <GaodeHeatmap {...props} />;
  } else if (props.chart_view?.chart_config?.map_type === "baidu") {
    return <BaiduHeatmap {...props} />;
  } else {
    return <D3Heatmap {...props} />;
  }
};

export default Heatmap;

export const HeatmapConfigForm = (props: {
  chart_view?: ChartView;
  fields: any;
  form: any;
  resetData?: any;
}) => {
  const { t } = useTranslation();
  const mapAppKeyModalRef: any = useRef();

  const openMapKeyModal = () => {
    const config = props.form?.getFieldValue("chart_config");
    mapAppKeyModalRef.current &&
      mapAppKeyModalRef.current.show({
        map_type: config?.map_type,
        app_key: config?.app_key,
      });
  };

  const handleKeyChange = (key: string) => {
    let config = props.form?.getFieldValue("chart_config");
    props.form?.setFieldValue("chart_config", {
      ...config,
      app_key: key,
    });
    props.resetData &&
      props.resetData((data: any) => ({
        ...data,
        chart_config: {
          ...data.chart_config,
          app_key: key,
        },
      }));
  };

  return (
    <>
      <MapAppKeyModal ref={mapAppKeyModalRef} callback={handleKeyChange} />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.chart_config?.map_type !==
          curValues?.chart_config?.map_type
        }
      >
        {() => {
          const need_key =
            props.form?.getFieldValue("chart_config")?.map_type !== "d3";
          return (
            <div style={{ marginBottom: 24 }}>
              <Form.Item
                initialValue="d3"
                label={t("chart_view.config.map_type.title")}
                name={["chart_config", "map_type"]}
                style={{ marginBottom: 0 }}
              >
                <Select
                  options={[
                    {
                      label: t("chart_view.config.map_type.default"),
                      value: "d3",
                    },
                    {
                      label: t("chart_view.config.map_type.gaode"),
                      value: "gaode",
                    },
                    {
                      label: t("chart_view.config.map_type.baidu"),
                      value: "baidu",
                    },
                  ]}
                />
              </Form.Item>
              {!!need_key && (
                <Button
                  type="link"
                  onClick={openMapKeyModal}
                  style={{ padding: "0.5em 0 0 0" }}
                >
                  {t("chart_view.config.map_type.input_key")}
                </Button>
              )}
              {!!need_key && (
                <Form.Item
                  hidden
                  name={["chart_config", "app_key"]}
                  rules={[{ required: true, message: "" }]}
                >
                  <Input />
                </Form.Item>
              )}
            </div>
          );
        }}
      </Form.Item>
      {props.chart_view?.chart_config?.map_type === "gaode" ? (
        <GaodeHeatmapConfigForm {...props} />
      ) : props.chart_view?.chart_config?.map_type === "baidu" ? (
        <BaiduHeatmapConfigForm {...props} />
      ) : (
        <D3HeatmapConfigForm {...props} />
      )}
    </>
  );
};
