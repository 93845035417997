import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/reset.css";
import "./common/style/reset.css";
import "./common/style/global.css";
import App from "./App";
import "./config/i18n";
import i18next from "./config/i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import AuthService from "./utils/AuthService";
import GlobalLoader, {
  LoadingView,
  GlobalLoadingView,
} from "./components/GlobalLoading";
import CommonErrorView, { ErrorBoundary } from "./components/CommonErrorView";
import { getConfig } from "./config/config";
const config = getConfig();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<LoadingView />);

let isIframeInSafari = false;
try {
  isIframeInSafari =
    window !== window?.parent &&
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent);
} catch (e) {
  console.log(e);
}
if (isIframeInSafari) {
  root.render(
    <CommonErrorView message="不支持Safari浏览器，可换成其他浏览器继续使用" />
  );
} else {
  AuthService.initKeycloak(
    () => {
      root.render(
        <ErrorBoundary>
          <Provider store={store}>
            <GlobalLoadingView
              ref={(globalLoaderRef) => {
                GlobalLoader.setGlobalLoader(globalLoaderRef);
              }}
            />
            <App />
          </Provider>
        </ErrorBoundary>
      );
    },
    () => {
      AuthService.login(
        !!config.authIdpHint
          ? {
              idpHint: config.authIdpHint,
            }
          : {
              locale: i18next?.language === "en" ? "en" : "zh-CN",
            }
      );
    }
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
