import { Badge, Button, List, Popover, Space, Tabs, TabsProps } from "antd";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { User } from "../types/User";
import IconNotification from "../common/images/icons/notification";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { IMessage } from "@novu/headless";
import { NotificationCategory } from "../types/Notification";
import viewUtils from "../utils/viewUtils";
import { t } from "i18next";
import useNovu from "../utils/useNovu";

const Novu = (props: { currentUser?: User }) => {
  const navigate = useNavigate();
  const [datalist, setDatalist] = useState<{
    data?: IMessage[];
    total: number;
  }>({
    data: undefined,
    total: 0,
  });
  const [filter, setFilter] = useState<{
    page: number;
    limit: number;
    category: NotificationCategory;
  }>({
    page: 0,
    limit: 1000,
    category: NotificationCategory.DATASET,
  });
  const novu = useNovu();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if(!props.currentUser) {
      return;
    }
    novu.initSession({
      onInited: () => {
        updateUnreadCount();
      },
      onUnreadCountChanged: () => {
        updateUnreadCount();
      },
    });

    return () => {
      if (novu.socket.current) {
        novu.socket.current.disconnect();
      }
    };
  }, [props.currentUser]);

  useEffect(() => {
    updateMessages();
  }, [filter]);

  const updateMessages = async () => {
    if (!novu.isAvailable()) {
      return;
    }
    setDatalist({ data: undefined, total: 0 });
    const data = await novu.getMessages({
      ...filter,
      payload: { category: filter.category },
    });
    setDatalist({
      data: data.data,
      total: data.totalCount,
    });
  };

  const updateUnreadCount = async () => {
    if (!novu.isAvailable()) {
      return;
    }
    const data = await novu.getMessageCount({
      read: false,
      payload: Object.values(NotificationCategory).map((value) => ({
        category: value,
      })),
    });
    setUnreadCount(data.data.count);
  };

  const handleCategoryChange = (key: string) => {
    setFilter({
      ...filter,
      page: 0,
      category: key as NotificationCategory,
    });
  };

  const handleClickReadAll = async () => {
    if (!novu.isAvailable()) {
      return;
    }
    await novu.markAllMessagesAsRead();
    updateMessages();
  };

  const handleClickNotification = async (notification: IMessage) => {
    if (!novu.isAvailable()) {
      return;
    }
    const data = await novu.markMessaegsAsRead(notification._id);
    notification.read = data.data[0].read;
    setDatalist({ ...datalist });

    if (notification.payload.in_app_redirect_url) {
      let url;
      if (
        (notification.payload.in_app_redirect_url as string).startsWith("http")
      ) {
        url = `${notification.payload.in_app_redirect_url}`;
      } else {
        url = `${window.location.origin}${notification.payload.in_app_redirect_url}`;
      }
      window.open(url, "_blank")?.focus();
    }
  };

  const handleClickHistory = () => {
    navigate("/notification");
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setFilter({ ...filter });
    }
  };

  if (props.currentUser?.notification_info) {
    return (
      <Popover
        onOpenChange={handleOpenChange}
        content={
          <div className="layout-content-box">
            <div className="notification-tab">
              <Tabs
                style={{ flex: 1 }}
                renderTabBar={
                  ((props, DefaultTabBar) => (
                    <DefaultTabBar
                      {...props}
                      className="notification-type-tab"
                    />
                  )) as TabsProps["renderTabBar"]
                }
                items={
                  [
                    {
                      key: NotificationCategory.DATASET,
                      label: t("notification.popover.dataset"),
                    },
                    {
                      key: NotificationCategory.WORKSPACE,
                      label: t("notification.popover.workspace"),
                    },
                    {
                      key: NotificationCategory.PLATFORM,
                      label: t("notification.popover.platform"),
                    },
                  ] as TabsProps["items"]
                }
                tabBarExtraContent={
                  <div
                    style={{ color: "#2a61ff", cursor: "pointer" }}
                    onClick={handleClickReadAll}
                  >
                    {t("notification.popover.read_all")}
                  </div>
                }
                onChange={handleCategoryChange}
              />
              <div
                style={{
                  width: "40em",
                  height: "20em",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <List
                  pagination={false}
                  loading={!datalist.data}
                  dataSource={datalist.data}
                  renderItem={(notification) => (
                    <List.Item
                      style={{ marginRight: "1em" }}
                      onClick={() => handleClickNotification(notification)}
                    >
                      <List.Item.Meta
                        title={
                          <Space size="small">
                            {notification.read ? null : <Badge dot></Badge>}
                            {notification.payload.in_app_title as string}
                          </Space>
                        }
                        description={
                          <div style={{ cursor: "pointer" }}>
                            {notification.payload.in_app_content as string}
                          </div>
                        }
                      />
                      <span>
                        {viewUtils.prettifyDatatime(notification.createdAt)}
                      </span>
                    </List.Item>
                  )}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "1em",
                  marginTop: "1em",
                }}
              >
                <ClockCircleOutlined onClick={handleClickHistory} />
              </div>
            </div>
          </div>
        }
      >
        <Button size="large" type="text" className="header-button">
          <Badge count={unreadCount} showZero size="small">
            <IconNotification
              style={{ fontSize: 20, color: "rgb(87, 91, 102)" }}
            />
          </Badge>
        </Button>
      </Popover>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (store: any) => ({
  currentUser: store.account.user,
});

export default connect(mapStateToProps, {})(Novu);
