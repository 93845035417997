import Keycloak from "keycloak-js";
import { getConfig } from "../config/config";
const config = getConfig();

const _kc = new Keycloak({
  url: config.authUrl,
  realm: config.authRealm,
  clientId: config.authClientId,
});

const initKeycloak = (
  onAuthenticatedCallback: () => void,
  onUnAuthenticatedCallback: () => void
) => {
  _kc
    .init({
      // onLoad: "check-sso",
      // onLoad: "login-required",
      // redirectUri: window.location.href,
      checkLoginIframe: false,
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
    })
    .then((auth) => {
      if (auth) {
        onAuthenticatedCallback();
      } else {
        onUnAuthenticatedCallback();
      }
    });
};

const username = () => _kc.tokenParsed?.username;
const preferredUsername = () => _kc.tokenParsed?.preferred_username;
const tokenParsed = () => _kc.tokenParsed;
const token = () => _kc.token;
const hasRealmRole = _kc.hasRealmRole;
const login = _kc.login;
const logout = () => {
  config.IsYiliSso
    ? _kc.logout({
        redirectUri: `${config.authYiliLogoutUrl}&redirctToUrl=${window.location.origin}`,
      })
    : _kc.logout();
};
const isLoggedIn = () => !!_kc.token;
const AuthService = {
  initKeycloak,
  login,
  logout,
  hasRealmRole,
  tokenParsed,
  username,
  preferredUsername,
  isLoggedIn,
  token,
};
export default AuthService;
