import React, { useEffect, useState } from "react";
import NavHyperLink from "../../components/NavHyperLink";
import {
  Button,
  Table,
  Modal,
  message,
  Form,
  Input,
  Typography,
  Radio,
  Select,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAirbyteConnectionsApi } from "../../api/IntegrationsApis";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Table as TableType } from "../../types/Table";
import {
  getSystemTableListApi,
  getSystemTableApi,
  updateSystemTableApi,
} from "../../api/TableApi";

const RadioGroup = Radio.Group;

const BaseTableManage = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [baseTables, setBaseTables] = useState<TableType[]>([]);
  const [filter, setFilter] = useState({ page: 1, page_size: 10 });

  useEffect(() => {
    getSystemTableListApi({
      page: filter.page,
      page_size: filter.page_size,
      sync_config: JSON.stringify({ enabled: true }),
    }).then((res) => {
      if (res.success) {
        setBaseTables(res.data);
        setTotal(res.pagination?.count || 0);
      }
    });
  }, [filter]);

  const handleAddedBaseTable = () => {
    setFilter({ ...filter });
  };

  const handleConfiged = () => {
    setFilter({ ...filter });
  };

  const handleDelete = (table: TableType) => {
    Modal.confirm({
      title: t("system_manage.base_table_manage.confirm_delete", {
        table_name: table?.name,
      }),
      onOk: () => {
        if (!table.id) {
          return;
        }
        updateSystemTableApi({
          tableId: table.id,
          data: { sync_config: {} },
        }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.success"),
              })
            );
            setFilter((data) => ({ ...data }));
          } else {
            message.error(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.fail"),
              })
            );
          }
        });
      },
      cancelText: t("common.cancel"),
      okText: t("common.ok"),
    });
  };

  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const columns: ColumnsType<TableType> = [
    {
      title: "表名称",
      key: "name",
      width: 200,
      render: (r) => r?.name,
    },
    {
      title: "描述",
      key: "description",
      width: 200,
      render: (r) => r?.description || "-",
    },
    {
      title: "所属空间",
      key: "workspace",
      width: 200,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              title: r?.workspace?.name,
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          className="mb-0"
        >
          {r?.workspace?.name || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "创建时间",
      key: "created_at",
      width: 200,
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: "更新时间",
      key: "update_at",
      width: 200,
      render: (r) => viewUtils.utcToLocal(r.updated_at),
    },
    {
      title: "创建人",
      key: "owner",
      width: 200,
      render: (r) => r?.owner_user?.name,
    },
    {
      key: "actions",
      title: "操作",
      fixed: "right",
      render: (r: TableType) => (
        <div style={{ display: "flex" }}>
          <ConfigButton table={r} onConfiged={handleConfiged} />
          <Button
            size="small"
            type="link"
            danger
            onClick={() => handleDelete(r)}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavHyperLink items={[{ title: "基础数据" }]} />
        <AddBaseTableButton onAdded={handleAddedBaseTable} />
      </div>
      <div style={{ marginTop: "1.5em" }}>
        <Table
          className="common-table"
          rowKey={(r) => r.id || ""}
          scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
          columns={columns}
          dataSource={baseTables || []}
          pagination={{
            showQuickJumper: true,
            showTotal: (total) => `共 ${total} 条`,
            current: filter.page,
            pageSize: filter.page_size,
            total: total,
            onChange: handlePageChange,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            style: { margin: "1em" },
          }}
        />
      </div>
    </>
  );
};

const AddBaseTableButton = (props: { onAdded: () => void }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const res = await getSystemTableApi({
      tableId: form.getFieldValue("id"),
    });
    if (!res.success) {
      message.error("请输入正确的ID");
      return;
    }
    const table = res.data;
    Modal.confirm({
      icon: null,
      content: (
        <>
          <div>空间名称：{table.workspace.name}</div>
          <div>表名称：{table.name}</div>
        </>
      ),
      onOk: () => {
        updateSystemTableApi({
          tableId: form.getFieldValue("id"),
          data: { sync_config: { enabled: true } },
        }).then((res) => {
          if (res.success) {
            message.success("新增基础数据成功");
            setOpen(false);
            props.onAdded();
          } else {
            message.error("新增基础数据失败");
          }
        });
      },
      cancelText: t("common.cancel"),
      okText: t("common.ok"),
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title={`${t("common.add")}${t("table.title")}`}
        okText={t("common.save")}
        cancelText={t("common.cancel")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          name="app form"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19 }}
          autoComplete="off"
        >
          <Form.Item label={"基础数据ID"} name="id">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        {t("common.add")}
      </Button>
    </>
  );
};

const ConfigButton = (props: { table: TableType; onConfiged: () => void }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [connectionOptions, setConnectionOptions] = useState([]);

  useEffect(() => {
    if (!open) {
      return;
    }
    form.setFieldsValue({
      manual: props.table.sync_config?.manual || true,
      connection_id: props.table.sync_config?.connection_id,
    });
    getAirbyteConnectionsApi().then((res) => {
      setConnectionOptions(
        res.data.connections.map((item: any) => {
          return {
            label: item.name,
            value: item.connectionId,
          };
        })
      );
    });
  }, [open]);

  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    if (!props.table.id) {
      return;
    }
    const sync_config = {
      ...props.table.sync_config,
      ...form.getFieldsValue(),
    };
    if (!sync_config.manual) {
      sync_config.connection_id = null;
    }
    const res = await updateSystemTableApi({
      tableId: props.table.id,
      data: { sync_config: sync_config },
    });
    if (res.success) {
      message.success("新增配置成功");
      setOpen(false);
      props.onConfiged();
    } else {
      message.error("新增配置失败");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title={"配置"}
        okText={t("common.save")}
        cancelText={t("common.cancel")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
      >
        <Form name="user form" form={form} autoComplete="off">
          <Form.Item label={"是否手工拉取数据"} name="manual">
            <RadioGroup>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.manual !== currentValues.manual
            }
          >
            {() => {
              return form.getFieldValue("manual") ? (
                <Form.Item label={"任务名称"} name="connection_id">
                  <Select options={connectionOptions} />
                </Form.Item>
              ) : (
                <></>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
      <Button
        size="small"
        type="link"
        onClick={() => {
          setOpen(true);
        }}
      >
        配置
      </Button>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  workspaces: store.account.workspaces,
});

export default connect(mapStateToProps)(BaseTableManage);
