import { User } from "./User";
import { DatasetVersion } from "./Dataset";

export type PipelineApp = {
  id: string;
  name: string;
  active: boolean;
  manifest: any;
  type: string;
  is_private: boolean;
};

export type PipelineRun = {
  id: number;
  pipeline: PipelineApp;
  run_id: string;
  status: any;
  start_time: string;
  end_time: string;
  duration: number;
  result: string;
  operator: User;
  version: DatasetVersion;
  final_status: string;
};

export interface ProcessNode {
  name: string;
  main_log: string;
  displayName: string;
  phase: string;
  startedAt: Date;
  finishedAt: Date;
}

export const StatusEnum = new Map([
  ["Failed", "task_execution.status_enum.failed"],
  ["Succeeded", "task_execution.status_enum.succeeded"],
  ["Running", "task_execution.status_enum.running"],
  ["Pending", "task_execution.status_enum.pending"],
  ["Canceled", "task_execution.status_enum.canceled"],
  ["Error", "task_execution.status_enum.error"],
  ["", "task_execution.status_enum.pending"],
]);
