export function toBoolean<T>(value: T): boolean | T {
  if (typeof value === "string") {
    const lowerCaseValue = value.toLowerCase().trim();
    if (["true", "yes", "是", "真", "1"].includes(lowerCaseValue)) {
      return true;
    } else if (["false", "no", "否", "假", "0"].includes(lowerCaseValue)) {
      return false;
    }
  } else if (typeof value === "number") {
    if (value === 1) {
      return true;
    } else if (value === 0) {
      return false;
    }
  }
  return typeof value === "boolean" ? value : value;
}

export function toNumber<T>(value: T): number | T {
  if (typeof value === "string") {
    if (/^\d+(\.\d+)?%$/.test(value)) {
      try {
        return Number(value.replace("%", "")) / 100.0;
      } catch (e) {}
    }
    const result = Number(value);
    return isNaN(result) ? value : result;
  }
  return value;
}

/**
 * Convert a value of any type to a string in the format "yyyy-MM-dd".
 *
 * @param value The value to be converted to a date string.
 * @returns A string in the format "yyyy-MM-dd", or the original value if it cannot be converted.
 */
export function toDate<T>(value: T): string {
  function formatDate(date: Date): string {
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function formatDateTime(date: Date): string {
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  if (
    typeof value === "object" &&
    value instanceof Date &&
    !isNaN(value.getTime())
  ) {
    return value.getHours() === 0 &&
      value.getMinutes() === 0 &&
      value.getSeconds() === 0
      ? formatDate(value)
      : formatDateTime(value);
  } else if (typeof value === "number") {
    const date =
      value.toString().length === 13 ? new Date(value) : new Date(value * 1000);
    return date.getHours() === 0 &&
      date.getMinutes() === 0 &&
      date.getSeconds() === 0
      ? formatDate(date)
      : formatDateTime(date);
  } else if (typeof value === "string") {
    const match = value.match(
      /(\d{4})[^\d]?(\d{1,2})[^\d]?(\d{1,2})(?:[^\d]?(\d{1,2})[^\d]?(\d{1,2})[^\d]?(\d{1,2}))?/
    );
    if (match) {
      const year = match[1];
      const month = match[2].padStart(2, "0");
      const day = match[3].padStart(2, "0");
      if (match[4] && match[5] && match[6]) {
        const hours = match[4].padStart(2, "0");
        const minutes = match[5].padStart(2, "0");
        const seconds = match[6].padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    }
  }

  return `${value}`;
}

export function getFormatDate_XLSX(serial: any) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  var d = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
  return d;
}
