import React, { useState, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./ManageLayout.css";
import { Layout, Image, ConfigProvider } from "antd";
import Logo from "../../../common/images/logo.png";

import NavMenu from "./NavMenu";
import Header from "./Header";
import WorkspaceFormModal from "../../WorkspaceFormModal";

import { getConfig } from "../../../config/config";
const config = getConfig();

const ManageLayout = (props: PropsWithChildren) => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const [collapsed, setCollapsed] = useState(false);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "#ffffff",
          },
        },
      }}
    >
      <Layout className="manage-layout">
        {!IsSimpleLayout && <WorkspaceFormModal />}
        <Layout>
          {!IsSimpleLayout && (
            <Layout.Sider
              width={240}
              theme="dark"
              className="slider-horizontal"
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
            >
              <div className="slider-content">
                <div className="logo">
                  <Image src={Logo} preview={false} />
                </div>
                <div className="menu-content">
                  <NavMenu />
                </div>
                {!collapsed && (
                  <div className="menu-version">
                    {t("common.version")}: {config.version}
                  </div>
                )}
              </div>
            </Layout.Sider>
          )}
          <Layout.Content>
            {!IsSimpleLayout && <Header />}
            <div className="layout-content">{props.children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default ManageLayout;
