const IconSave = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="currentColor">
      <title>save</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-561.000000, -404.000000)"
          fillRule="nonzero"
        >
          <g id="save" transform="translate(561.000000, 404.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="18"
              height="18"
            ></rect>
            <path
              d="M15.7025391,5.15566406 L12.8443359,2.29746094 C12.7125,2.165625 12.5507812,2.06894531 12.375,2.01621094 L12.375,1.96875 L2.53125,1.96875 C2.22011719,1.96875 1.96875,2.22011719 1.96875,2.53125 L1.96875,15.46875 C1.96875,15.7798828 2.22011719,16.03125 2.53125,16.03125 L15.46875,16.03125 C15.7798828,16.03125 16.03125,15.7798828 16.03125,15.46875 L16.03125,5.95019531 C16.03125,5.65136719 15.9134766,5.36660156 15.7025391,5.15566406 Z M6.75,3.234375 L11.25,3.234375 L11.25,5.0625 L6.75,5.0625 L6.75,3.234375 Z M14.765625,14.765625 L3.234375,14.765625 L3.234375,3.234375 L5.625,3.234375 L5.625,5.625 C5.625,5.93613281 5.87636719,6.1875 6.1875,6.1875 L11.8125,6.1875 C12.1236328,6.1875 12.375,5.93613281 12.375,5.625 L12.375,3.61757813 L14.765625,6.00820312 L14.765625,14.765625 Z M9,7.76953125 C7.60253906,7.76953125 6.46875,8.90332031 6.46875,10.3007812 C6.46875,11.6982422 7.60253906,12.8320312 9,12.8320312 C10.3974609,12.8320312 11.53125,11.6982422 11.53125,10.3007812 C11.53125,8.90332031 10.3974609,7.76953125 9,7.76953125 Z M9,11.7070312 C8.22304688,11.7070312 7.59375,11.0777344 7.59375,10.3007812 C7.59375,9.52382812 8.22304688,8.89453125 9,8.89453125 C9.77695313,8.89453125 10.40625,9.52382812 10.40625,10.3007812 C10.40625,11.0777344 9.77695313,11.7070312 9,11.7070312 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconSave;
