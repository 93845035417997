import { TFunction } from "i18next";

export const findLastNonPendingStatus = (statusArray: any[]) => {
  let lastIndex = -1;
  let lastStatus = null;

  for (let i = 0; i < statusArray.length; i++) {
    if (statusArray[i].status !== "Pending") {
      lastIndex = i;
      lastStatus = statusArray[i];
    }
  }

  return { status: lastStatus, index: lastIndex };
};

export const getStatusName = (status: any, language: string) => {
  return status && status.name && status.name[language]
    ? status.name[language]
    : "";
};

export const getStatusDescription = (status: any, language: string) => {
  return status && status.description && status.description[language]
    ? status.description[language]
    : "";
};

export const getStatusMessage = (status: any, language: string) => {
  return status && status.message && status.message[language]
    ? status.message[language]
    : "";
};

export const makeGetStatus = (t: TFunction) => {
  return (status: any) => {
    if (status && status.status) {
      return t("dataset.action_status." + status.status.toLowerCase());
    } else {
      return "";
    }
  };
};
