import React, { PropsWithChildren } from "react";
import "./Layout.css";
import { Layout, Empty, ConfigProvider, Spin } from "antd";
import NavMenu from "./NavMenu";
import Header from "./Header";
import { connect } from "react-redux";

interface Props extends PropsWithChildren {
  currentUser?: any;
}

const SystemManageLayout = (props: Props) => {
  if (!props.currentUser?.is_superuser) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!props.currentUser ? <Spin /> : <Empty description="暂无权限" />}
      </div>
    );
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "#fff",
            headerHeight: 60,
            headerPadding: "0 20px 0 30px",
          },
        },
      }}
    >
      <Layout className="system-layout">
        <Header />
        <Layout>
          <Layout.Sider width={240} className="system-layout-slider">
            <NavMenu />
          </Layout.Sider>
          <Layout.Content className="system-layout-content">
            {props.children}
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

const mapStateToProps = (store: any) => ({
  currentUser: store.account.user,
});

export default connect(mapStateToProps, {})(SystemManageLayout);
