const IconLock = (props: any) => (
  <span className="anticon anticon-setting" {...props}>
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="currentColor">
      <title>lock</title>
      <g id="icon切图" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="icon"
          transform="translate(-481.000000, -404.000000)"
          fillRule="nonzero"
        >
          <g id="lock" transform="translate(481.000000, 404.000000)">
            <rect
              id="矩形"
              opacity="0"
              x="0"
              y="0"
              width="18"
              height="18"
            ></rect>
            <path
              d="M14.625,8.15625 L13.4296875,8.15625 L13.4296875,4.21875 C13.4296875,2.97597656 12.4224609,1.96875 11.1796875,1.96875 L6.8203125,1.96875 C5.57753906,1.96875 4.5703125,2.97597656 4.5703125,4.21875 L4.5703125,8.15625 L3.375,8.15625 C3.06386719,8.15625 2.8125,8.40761719 2.8125,8.71875 L2.8125,15.46875 C2.8125,15.7798828 3.06386719,16.03125 3.375,16.03125 L14.625,16.03125 C14.9361328,16.03125 15.1875,15.7798828 15.1875,15.46875 L15.1875,8.71875 C15.1875,8.40761719 14.9361328,8.15625 14.625,8.15625 Z M5.8359375,4.21875 C5.8359375,3.67558594 6.27714844,3.234375 6.8203125,3.234375 L11.1796875,3.234375 C11.7228516,3.234375 12.1640625,3.67558594 12.1640625,4.21875 L12.1640625,8.15625 L5.8359375,8.15625 L5.8359375,4.21875 Z M13.921875,14.765625 L4.078125,14.765625 L4.078125,9.421875 L13.921875,9.421875 L13.921875,14.765625 Z M8.5078125,12.3222656 L8.5078125,13.2539062 C8.5078125,13.33125 8.57109375,13.3945312 8.6484375,13.3945312 L9.3515625,13.3945312 C9.42890625,13.3945312 9.4921875,13.33125 9.4921875,13.2539062 L9.4921875,12.3222656 C9.70488281,12.1693359 9.84375,11.9197266 9.84375,11.6367188 C9.84375,11.1708984 9.46582031,10.7929688 9,10.7929688 C8.53417969,10.7929688 8.15625,11.1708984 8.15625,11.6367188 C8.15625,11.9197266 8.29511719,12.1693359 8.5078125,12.3222656 Z"
              id="形状"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default IconLock;
