import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Space,
  Typography,
  message,
  Select,
  Button,
  Drawer,
  Form,
  Dropdown,
} from "antd";
import type { MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { TableMembership } from "../../types/Table";
import { User } from "../../types/User";
import { Workspace } from "../../types/Workspace";
import { getTableMembersApi, updateTableMembersApi } from "../../api/TableApi";
import { getUserListApi } from "../../api";
import viewUtils from "../../utils/viewUtils";
import { SheetTable } from "../../types/Table";

const TableShare = (props: {
  table?: SheetTable;
  currentWorkspace?: Workspace;
  currentUser?: User;
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [members, setMembers] = useState<TableMembership[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.currentWorkspace?.id) {
      getUserListApi({ workspace: Number(props.currentWorkspace?.id) }).then(
        (res) => {
          if (res.success) {
            setUsers(res.data);
          }
        }
      );
    }
  }, [props.currentWorkspace]);

  useEffect(() => {
    if (props.table?.id && show) {
      getTableMembersApi({ tableId: Number(props.table?.id) }).then((res) => {
        if (res.success) {
          setMembers(res.data);
        }
      });
    }
  }, [props.table, show]);

  const handleAdd = (data: any) => {
    if (data.user === props.table?.owner) {
      message.error(t("table.tips.no_permission"));
      return;
    }
    const usermember = members.filter(
      (m: TableMembership) => m.user === data.user
    )[0];
    if (!!usermember) {
      handleSave(
        members.map((m: TableMembership) =>
          m.user === data.user
            ? {
                ...m,
                role: data.role,
                delete: false,
              }
            : m
        )
      );
    } else {
      handleSave([
        ...members,
        {
          user: data.user,
          user_info: users.filter((u: User) => u.username === data.user)[0],
          role: data.role,
        },
      ]);
    }
  };

  const handleDelete = (member: TableMembership) => {
    if (member.user === props.table?.owner) {
      message.error(t("table.tips.no_permission"));
      return;
    }
    handleSave(
      members.map((m: TableMembership) =>
        m.user === member.user
          ? {
              ...m,
              delete: true,
            }
          : m
      )
    );
  };

  const handleChange = (member: TableMembership, role: string) => {
    if (member.user === props.table?.owner) {
      message.error(t("table.tips.no_permission"));
      return;
    }
    handleSave(
      members.map((m: TableMembership) =>
        m.user === member.user
          ? {
              ...m,
              role: role,
            }
          : m
      )
    );
  };

  const handleSave = (members: TableMembership[]) => {
    updateTableMembersApi({
      tableId: Number(props.table?.id),
      data: members,
    }).then((res) => {
      if (res.success) {
        setMembers(res.data);
      } else {
        message.error(
          t("common.result_status", {
            title: t("common.save"),
            status: t("common.fail"),
          })
        );
      }
    });
  };

  return (
    <>
      <Drawer
        title={t("table.actions.share")}
        placement="right"
        onClose={() => setShow(false)}
        open={!!show}
        width={"max(50vw, 500px)"}
      >
        <Form onFinish={handleAdd} style={{ display: "flex" }}>
          <Form.Item
            name="user"
            rules={[
              {
                required: true,
                message: t("table.tips.select_user"),
              },
            ]}
            style={{ flex: 1, marginRight: 5 }}
          >
            <Select
              showSearch={true}
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes((input || "").toLowerCase())
              }
              options={users
                .filter((user: User) => {
                  if (user.username === props.table?.owner) {
                    return false;
                  }
                  const usermember = members.filter(
                    (m) => m.user === user.username
                  )[0];
                  return !usermember || !!usermember.delete;
                })
                .map((item) => ({
                  label: `${viewUtils.prettifyUsername(item.name)}(${
                    item.username
                  })`,
                  value: item.username,
                }))}
            />
          </Form.Item>
          <Form.Item
            name="role"
            initialValue="VISITOR"
            style={{ width: "15em", marginRight: 5 }}
          >
            <Select className="select-role" style={{ width: "100%" }}>
              <Select.Option value="VISITOR" label={t("share.view")}>
                <Space direction="vertical" size={2}>
                  <Typography.Text>{t("share.view")}</Typography.Text>
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "0.8em" }}
                  >
                    {t("table.tips.only_view")}
                  </Typography.Text>
                </Space>
              </Select.Option>
              <Select.Option value="ADMIN" label={t("share.admin")}>
                <Space direction="vertical" size={2}>
                  <Typography.Text>{t("share.admin")}</Typography.Text>
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "0.8em" }}
                  >
                    {t("table.tips.can_manage")}
                  </Typography.Text>
                </Space>
              </Select.Option>
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("common.add")}
          </Button>
        </Form>
        <Typography.Title level={5}>{t("table.members")}</Typography.Title>
        {members
          .filter((member: TableMembership) => !member.delete)
          .map((member: TableMembership) => (
            <div
              key={member.user}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0.5em 0",
              }}
            >
              <Typography.Text>
                {`${viewUtils.prettifyUsername(member.user_info?.name)}(${
                  member.user
                })`}
              </Typography.Text>
              <Dropdown
                disabled={
                  props.currentUser?.username === member.user ||
                  member.user === props.table?.owner ||
                  member.id === null
                }
                menu={{
                  items: [
                    {
                      label: (
                        <Space direction="vertical" size={2}>
                          <Typography.Text>{t("share.view")}</Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: "0.8em" }}
                          >
                            {t("table.tips.only_view")}
                          </Typography.Text>
                        </Space>
                      ),
                      key: "visitor",
                    },
                    {
                      label: (
                        <Space direction="vertical" size={2}>
                          <Typography.Text>{t("share.admin")}</Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: "0.8em" }}
                          >
                            {t("table.tips.can_manage")}
                          </Typography.Text>
                        </Space>
                      ),
                      key: "admin",
                    },
                    {
                      label: (
                        <Typography.Text type="danger">
                          {t("table.actions.remove_member")}
                        </Typography.Text>
                      ),
                      key: "delete",
                    },
                  ] as MenuProps["items"],
                  onClick: (data: any) => {
                    if (data.key === "visitor") {
                      handleChange(member, "VISITOR");
                    }
                    if (data.key === "admin") {
                      handleChange(member, "ADMIN");
                    }
                    if (data.key === "delete") {
                      handleDelete(member);
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Space>
                  <Typography.Text
                    disabled={
                      props.currentUser?.username === member.user ||
                      member.user === props.table?.owner ||
                      member.id === null
                    }
                  >
                    {member.role === "ADMIN"
                      ? t("share.admin")
                      : t("share.view")}
                  </Typography.Text>
                  <DownOutlined
                    hidden={
                      props.currentUser?.username === member.user ||
                      member.user === props.table?.owner ||
                      member.id === null
                    }
                  />
                </Space>
              </Dropdown>
            </div>
          ))}
      </Drawer>
      {/* <Button onClick={() => setShow(true)}>{t("common.share")}</Button> */}
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
  currentUser: store.account.user,
});

export default connect(mapStateToProps, {})(TableShare);
