import React, { forwardRef, useState, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Popover, List, ConfigProvider, Typography } from "antd";
import { DataRowValidation } from "../../types/Table";
import viewUtils from "../../utils/viewUtils";
import type { TableField } from "../../types/Table";

const Window: any = window;

const DatasetTableErrorTips = forwardRef((props: {}, ref) => {
  const { t } = useTranslation();
  const [showItem, setShowItem] = useState<DataRowValidation[] | undefined>();

  useImperativeHandle(ref, () => ({
    show: (errors: DataRowValidation[]) => {
      let data = errors?.slice(0, 500) || [];
      if (data.length > 0) {
        const sheet = Window.luckysheet.getSheet();
        if (!sheet?.originTableData?.meta?.fields) {
          return;
        }
        let columnIndexMap = new Map();
        viewUtils
          .sortTableFields(sheet?.originTableData?.meta?.fields || [])
          .forEach((col: TableField, index: number) => {
            columnIndexMap.set(col.identifier!, index);
          });
        for (let i = 0, len = data.length; i < len; i++) {
          data[i].col_index = columnIndexMap.get(data[i].field);
        }
        setShowItem(data);
      }
    },
    hide: handleClose,
  }));

  const handleClose = () => setShowItem(undefined);

  const handleJumpTo = (item: DataRowValidation) => {
    try {
      Window.luckysheet.setRangeShow({
        row: [item.index + 1, item.index + 1],
        column: [item.col_index, item.col_index],
      });
      Window.luckysheet.scroll({
        targetRow: item.index + 1,
        targetColumn: item.col_index,
      });
    } catch (e) {}
  };

  const ErrorList = () => {
    return (
      <ConfigProvider
        theme={{
          components: {
            List: {
              itemPaddingSM: "3px 10px",
            },
          },
        }}
      >
        <div
          style={{
            minWidth: "20em",
            maxWidth: "min(30vw, 500px)",
            marginTop: 10,
          }}
        >
          <List
            itemLayout="vertical"
            size="small"
            pagination={{
              size: "small",
              pageSize: 10,
              hideOnSinglePage: true,
            }}
            split={false}
            dataSource={showItem}
            renderItem={(item) => (
              <List.Item
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography.Paragraph
                  style={{ marginBottom: 0, flex: 1, marginRight: "1em" }}
                  ellipsis={{
                    rows: 2,
                    tooltip: {
                      placement: "bottom",
                      overlayStyle: {
                        whiteSpace: "pre-line",
                        maxWidth: "min(100vw, 400px)",
                      },
                    },
                  }}
                >
                  {t("dataset.sheet_error_tips.message", {
                    row: item.index + 1,
                    error: item.message,
                  })}
                </Typography.Paragraph>
                <Button
                  type="link"
                  size="small"
                  onClick={() => handleJumpTo(item)}
                >
                  {t("dataset.sheet_error_tips.jump_to")}
                </Button>
              </List.Item>
            )}
          />
        </div>
      </ConfigProvider>
    );
  };

  return (
    <Popover
      arrow={false}
      content={<ErrorList />}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("dataset.sheet_error_tips.title")}
          <CloseOutlined
            onClick={handleClose}
            style={{
              padding: 10,
              fontSize: 14,
            }}
          />
        </div>
      }
      placement="topRight"
      open={!!showItem}
    >
      <div
        style={{
          position: "fixed",
          right: "10px",
          bottom: 0,
          width: "1px",
          height: "1px",
        }}
      ></div>
    </Popover>
  );
});

export default DatasetTableErrorTips;
