import { TableFilters, TableFilterItem } from "../types/Table";

const mergeDuplicates = (
  data: any,
  filters: TableFilters | undefined | null
) => {
  const fields = Object.keys(
    (filters?.items || [])
      .filter((item) => item?.type === "DUPLICATE")
      .reduce((ret: any, item: TableFilterItem) => {
        ret[item?.field || ""] = true;
        return ret;
      }, {})
  );
  let result: any = {};
  let temp: any = {};
  for (let i = 0, len = data.length; i < len; i++) {
    for (let j = 0; j < fields.length; j++) {
      if (!temp[fields[j]]) {
        temp[fields[j]] = {};
      }
      let value = data[i][fields[j]] || "null";
      if (!temp[fields[j]][value]) {
        temp[fields[j]][value] = true;
      } else {
        if (!result[fields[j]]) {
          result[fields[j]] = [];
        }
        result[fields[j]].push(value);
      }
    }
  }
  return result;
};

export const filterData = (
  data: any,
  filters: TableFilters | undefined | null,
  columns?: string[] | undefined | null,
  options?: {
    removeEmpty?: boolean;
  }
) => {
  if (!!filters) {
    let res: any = [];
    const duplicatesData = mergeDuplicates(data, filters);
    for (let i = 0, len = data.length; i < len; i++) {
      let isValid = filters.type === "OR" ? false : true;
      let row = data[i];
      try {
        for (
          let fi = 0, flen = (filters?.items || []).length;
          fi < flen;
          fi++
        ) {
          let filterItem = (filters?.items || [])[fi];
          let checkRes = false;
          if (filterItem.type === "=") {
            if (
              !!row[filterItem?.field ?? ""] &&
              // eslint-disable-next-line
              row[filterItem?.field ?? ""] == filterItem?.value
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === "!=") {
            if (
              !!row[filterItem?.field ?? ""] &&
              !!row[filterItem?.field ?? ""] &&
              row[filterItem?.field ?? ""] !== filterItem?.value
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === "INCLUDE") {
            if (
              !!row[filterItem?.field ?? ""] &&
              row[filterItem?.field ?? ""]?.includes(filterItem?.value || "")
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === "EXCEPT") {
            if (
              !!row[filterItem?.field ?? ""] &&
              !row[filterItem?.field ?? ""]?.includes(filterItem?.value || "")
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === "NULL") {
            if (row[filterItem?.field ?? ""] === "") {
              checkRes = true;
            }
          }
          if (filterItem.type === "NOT NULL") {
            if (row[filterItem?.field ?? ""] !== "") {
              checkRes = true;
            }
          }
          if (filterItem.type === "DUPLICATE") {
            if (
              duplicatesData[filterItem?.field ?? ""]?.includes(
                row[filterItem?.field ?? ""] || "null"
              )
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === ">") {
            if (
              !!row[filterItem?.field ?? ""] &&
              row[filterItem?.field ?? ""] > filterItem?.value
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === ">=") {
            if (
              !!row[filterItem?.field ?? ""] &&
              row[filterItem?.field ?? ""] >= filterItem?.value
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === "<") {
            if (
              !!row[filterItem?.field ?? ""] &&
              row[filterItem?.field ?? ""] < filterItem?.value
            ) {
              checkRes = true;
            }
          }
          if (filterItem.type === "<=") {
            if (
              !!row[filterItem?.field ?? ""] &&
              row[filterItem?.field ?? ""] <= filterItem?.value
            ) {
              checkRes = true;
            }
          }
          if (checkRes) {
            if (filters?.type === "OR") {
              isValid = true;
              break;
            }
          } else {
            if (filters?.type !== "OR") {
              isValid = false;
              break;
            }
          }
        }
      } catch (e) {}
      if (isValid) {
        if (!!columns) {
          res.push(
            Object.keys(row).reduce((ret: any, k: string) => {
              if (columns.includes(k)) {
                if (
                  !options?.removeEmpty ||
                  (row[k] !== "" && row[k] !== null && row[k] !== undefined)
                ) {
                  ret[k] = row[k];
                }
              }
              return ret;
            }, {})
          );
        } else {
          res.push(row);
        }
      }
    }
    return res;
  } else {
    if (!!columns) {
      let res: any = [];
      for (let i = 0, len = data.length; i < len; i++) {
        let row = data[i];
        res.push(
          Object.keys(row).reduce((ret: any, k: string) => {
            if (columns.includes(k)) {
              if (
                !options?.removeEmpty ||
                (row[k] !== "" && row[k] !== null && row[k] !== undefined)
              ) {
                ret[k] = row[k];
              }
            }
            return ret;
          }, {})
        );
      }
      return res;
    } else {
      return data;
    }
  }
};
