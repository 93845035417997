import { TFunction, i18n } from "i18next";
import { Dataset } from "../types/Dataset";
import { SheetTable } from "../types/Table";

const Schema = {
  en: [
    {
      apps: [
        "3858b807-4b6b-4e3c-8fde-fda737ef783f", //分销计划
      ],
      tables: [
        {
          name: "Parameter",
          fields: [
            {
              name: "name",
            },
            {
              name: "value",
            },
          ],
        },
        {
          name: "Product",
          fields: [
            {
              name: "product_name",
            },
            {
              name: "product_code",
            },
          ],
        },
        {
          name: "Site",
          identifier: "site",
          fields: [
            {
              name: "site_name",
            },
            {
              name: "site_code",
            },
          ],
        },
        {
          name: "Demand_Plan",
          identifier: "demand_plan",
          fields: [
            {
              name: "product_name",
            },
            {
              name: "site_name",
            },
            {
              name: "number",
            },
          ],
        },
        {
          name: "Production_Plan",
          fields: [
            {
              name: "product_name",
            },
            {
              name: "site_name",
            },
            {
              name: "number",
            },
          ],
        },
        {
          name: "Transportation_Mode_Constraint",
          fields: [
            {
              name: "sku",
            },
            {
              name: "mode",
            },
            {
              name: "allowed",
            },
          ],
        },
        {
          name: "Transportation_Bound_Constraint",
          fields: [
            {
              name: "origin",
            },
            {
              name: "destination",
            },
            {
              name: "mode",
            },
            {
              name: "constraint_type",
            },
            {
              name: "quantity",
            },
          ],
        },
        {
          name: "Flow_Direction_Constraint",
          fields: [
            {
              name: "origin",
            },
            {
              name: "destination",
            },
            {
              name: "mode",
            },
            {
              name: "sku_product_group_id",
            },
            {
              name: "constraint_type",
            },
            {
              name: "quantity",
            },
          ],
        },
        {
          name: "Site_Set",
          fields: [
            {
              name: "name",
            },
            {
              name: "member",
            },
          ],
        },
        {
          name: "Product_Set",
          fields: [
            {
              name: "name",
            },
            {
              name: "member",
            },
          ],
        },
        {
          name: "Transportation_Mode_Set",
          fields: [
            {
              name: "name",
            },
            {
              name: "member",
            },
          ],
        },
        {
          name: "Transportation_Cost",
          fields: [
            {
              name: "origin",
            },
            {
              name: "destination",
            },
            {
              name: "sku_product_group_id",
            },
            {
              name: "mode",
            },
            {
              name: "cost",
            },
          ],
        },
        {
          name: "Site_Info",
          fields: [
            {
              name: "name",
            },
            {
              name: "latitude",
            },
            {
              name: "longitude",
            },
          ],
        },
      ],
    },
    {
      identifier: "master_production_planning",
      apps: [
        "f1a7b9b6-be8b-4d24-b2d6-4501912e8ce7", //产销协同与供应链主计划
      ],
      tables: [
        {
          name: "Parameter",
          fields: [
            {
              name: "name",
            },
            {
              name: "value",
            },
          ],
        },
        {
          name: "Product",
          fields: [
            {
              name: "sku",
            },
            {
              name: "status",
            },
            {
              name: "price",
            },
            {
              name: "allow_new_buy",
            },
          ],
        },
        {
          name: "Site",
          identifier: "site",
          fields: [
            {
              name: "name",
            },
            {
              name: "site_type",
            },
            {
              name: "holding_cost",
            },
            {
              name: "region_id",
            },
          ],
        },
        {
          name: "BOM",
          identifier: "bom",
          fields: [
            {
              name: "bom_header_id",
            },
            {
              name: "sku",
            },
            {
              name: "quantity",
            },
            {
              name: "in_out",
            },
          ],
        },
        {
          name: "Demand_Plan",
          identifier: "demand_plan",
          fields: [
            {
              name: "site_id",
            },
            {
              name: "sku",
            },
            {
              name: "time_index",
            },
            {
              name: "quantity",
            },
            {
              name: "priority",
            },
          ],
        },
        {
          name: "Special_Requirements",
          identifier: "special_requirements",
          fields: [
            {
              name: "site_id",
            },
            {
              name: "sku",
            },
            {
              name: "time_index",
            },
            {
              name: "quantity",
            },
            {
              name: "priority",
            },
          ],
        },
        {
          name: "Beginning_Inventory",
          identifier: "beginning_inventory",
          fields: [
            {
              name: "site_id",
            },
            {
              name: "sku",
            },
            {
              name: "quantity",
            },
          ],
        },
        {
          name: "Inventory_Target",
          identifier: "inventory_target",
          fields: [
            {
              name: "site_id",
            },
            {
              name: "sku",
            },
            {
              name: "time_index",
            },
            {
              name: "inventory_target",
            },
          ],
        },
        {
          name: "Transportation_Strategy",
          identifier: "transportation_strategy",
          fields: [
            {
              name: "origin_site_id",
            },
            {
              name: "destination_site_id",
            },
            {
              name: "cost",
            },
            {
              name: "active",
            },
            {
              name: "moq",
            },
          ],
        },
        {
          name: "Production_Strategy",
          identifier: "production_strategy",
          fields: [
            {
              name: "site_id",
            },
            {
              name: "sku",
            },
            {
              name: "bom_header_id",
            },
            {
              name: "cost",
            },
            {
              name: "production_group_id",
            },
            {
              name: "production_rate",
            },
            {
              name: "moq",
            },
          ],
        },
        {
          name: "Production_Line_Constraints",
          identifier: "production_line_constraints",
          fields: [
            {
              name: "production_group_id",
            },
            {
              name: "capacity_lb",
            },
            {
              name: "capacity_ub",
            },
          ],
        },
        {
          name: "Product_Production_Constraints",
          fields: [
            {
              name: "sku",
            },
            {
              name: "yield_lb",
            },
            {
              name: "yield_ub",
            },
          ],
        },
        {
          name: "Site_Product_Production_Constraints",
          identifier: "site_product_production_constraints",
          fields: [
            {
              name: "site_id",
            },
            {
              name: "sku",
            },
            {
              name: "yield_lb",
            },
            {
              name: "yield_ub",
            },
          ],
        },
        {
          name: "Prohibited_Transportation_Routes",
          identifier: "prohibited_transportation_routes",
          fields: [
            {
              name: "origin_site_id",
            },
            {
              name: "destination_site_id",
            },
            {
              name: "sku",
            },
            {
              name: "active",
            },
          ],
        },
        {
          name: "Regional_Transportation_Constraints",
          fields: [
            {
              name: "region_id",
            },
            {
              name: "in_transportation_lb",
            },
            {
              name: "in_transportation_ub",
            },
            {
              name: "out_transportation_lb",
            },
            {
              name: "out_transportation_ub",
            },
          ],
        },
      ],
    },
  ],
  zh: [
    {
      apps: [
        "3858b807-4b6b-4e3c-8fde-fda737ef783f", //分销计划
      ],
      tables: [
        {
          name: "模型参数",
          fields: [
            {
              name: "参数名称",
            },
            {
              name: "参数值",
            },
          ],
        },
        {
          name: "产品",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "产品编码",
            },
          ],
        },
        {
          name: "站点",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "站点编码",
            },
          ],
        },
        {
          name: "需求计划",
          identifier: "demand_plan",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "站点名称",
            },
            {
              name: "需求量",
            },
          ],
        },
        {
          name: "生产计划",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "站点名称",
            },
            {
              name: "生产量",
            },
          ],
        },
        {
          name: "运输方式约束",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "运输方式",
            },
            {
              name: "允许",
            },
          ],
        },
        {
          name: "运输能力约束",
          fields: [
            {
              name: "起始站点/组名称",
            },
            {
              name: "目的站点/组名称",
            },
            {
              name: "运输方式/组名称",
            },
            {
              name: "限制类型",
            },
            {
              name: "限制值",
            },
          ],
        },
        {
          name: "流向约束",
          fields: [
            {
              name: "起始站点/组名称",
            },
            {
              name: "目的站点/组名称",
            },
            {
              name: "运输方式/组名称",
            },
            {
              name: "产品/组名称",
            },
            {
              name: "限制类型",
            },
            {
              name: "限制比例",
            },
          ],
        },
        {
          name: "站点集合",
          fields: [
            {
              name: "站点组名称",
            },
            {
              name: "站点名称",
            },
          ],
        },
        {
          name: "产品集合",
          fields: [
            {
              name: "产品组名称",
            },
            {
              name: "产品名称",
            },
          ],
        },
        {
          name: "运输方式集合",
          fields: [
            {
              name: "运输方式组名称",
            },
            {
              name: "运输方式",
            },
          ],
        },
        {
          name: "运输成本",
          fields: [
            {
              name: "起始站点名称",
            },
            {
              name: "目的站点名称",
            },
            {
              name: "产品/组名称",
            },
            {
              name: "运输方式",
            },
            {
              name: "成本",
            },
          ],
        },
        {
          name: "站点信息",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "纬度",
            },
            {
              name: "经度",
            },
          ],
        },
      ],
    },
    {
      apps: [
        "1ba183f2-6f42-4d97-9bb7-12d41a8f452a", //伊利液奶流量流向计划应用
      ],
      tables: [
        {
          name: "模型参数",
          fields: [
            {
              name: "参数名称",
            },
            {
              name: "参数值",
            },
          ],
        },
        {
          name: "需求计划",
          identifier: "demand_plan",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "站点名称",
            },
            {
              name: "需求量",
            },
          ],
        },
        {
          name: "生产计划",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "站点名称",
            },
            {
              name: "生产量",
            },
          ],
        },
        {
          name: "运输方式限制",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "运输方式",
            },
            {
              name: "允许",
            },
          ],
        },
        {
          name: "储运能力限制",
          fields: [
            {
              name: "始发地",
            },
            {
              name: "目的地",
            },
            {
              name: "运输方式",
            },
            {
              name: "限制类型",
            },
            {
              name: "限制值",
            },
          ],
        },
        {
          name: "流向限制",
          fields: [
            {
              name: "始发地",
            },
            {
              name: "目的地",
            },
            {
              name: "产品",
            },
            {
              name: "运输方式",
            },
            {
              name: "限制类型",
            },
            {
              name: "限制比例",
            },
          ],
        },
        {
          name: "地点集合",
          fields: [
            {
              name: "集合名称",
            },
            {
              name: "集合成员",
            },
          ],
        },
        {
          name: "产品集合",
          fields: [
            {
              name: "集合名称",
            },
            {
              name: "集合成员",
            },
          ],
        },
        {
          name: "运输方式集合",
          fields: [
            {
              name: "集合名称",
            },
            {
              name: "集合成员",
            },
          ],
        },
        {
          name: "运价",
          fields: [
            {
              name: "来源地",
            },
            {
              name: "目的地",
            },
            {
              name: "产品",
            },
            {
              name: "运输方式",
            },
            {
              name: "运输成本",
            },
          ],
        },
        {
          name: "经纬度",
          fields: [
            {
              name: "名称",
            },
            {
              name: "纬度",
            },
            {
              name: "经度",
            },
          ],
        },
      ],
    },
    {
      identifier: "master_production_planning",
      apps: [
        "f1a7b9b6-be8b-4d24-b2d6-4501912e8ce7", //产销协同与供应链主计划
        "d01290f4-5369-448c-b59e-1ae48f06e3b5", //伊利酸奶主生产计划应用
        "b389afa3-f3fe-46a1-87e2-32fd88ce64c4", //伊利冷饮主生产计划应用
      ],
      tables: [
        {
          name: "模型参数",
          fields: [
            {
              name: "参数名称",
            },
            {
              name: "参数值",
            },
          ],
        },
        {
          name: "产品",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "产品类型",
            },
            {
              name: "单位价格",
            },
            {
              name: "允许采购",
            },
          ],
        },
        {
          name: "站点",
          identifier: "site",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "站点类型",
            },
            {
              name: "存储成本",
            },
            {
              name: "区域",
            },
          ],
        },
        {
          name: "BOM",
          identifier: "bom",
          fields: [
            {
              name: "BOM编号",
            },
            {
              name: "产品名称",
            },
            {
              name: "物料数量",
            },
            {
              name: "输入/输出",
            },
          ],
        },
        {
          name: "需求计划",
          identifier: "demand_plan",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期",
            },
            {
              name: "需求量",
            },
            {
              name: "优先级",
            },
          ],
        },
        {
          name: "特殊需求",
          identifier: "special_requirements",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期",
            },
            {
              name: "需求量",
            },
            {
              name: "优先级",
            },
          ],
        },
        {
          name: "期初库存",
          identifier: "beginning_inventory",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "期初库存",
            },
          ],
        },
        {
          name: "库存目标",
          identifier: "inventory_target",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期",
            },
            {
              name: "期末库存目标",
            },
          ],
        },
        {
          name: "运输策略",
          identifier: "transportation_strategy",
          fields: [
            {
              name: "起始站点",
            },
            {
              name: "目的站点",
            },
            {
              name: "运输成本",
            },
            {
              name: "是否启用",
            },
            {
              name: "最小起运量",
            },
          ],
        },
        {
          name: "生产策略",
          identifier: "production_strategy",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "BOM编号",
            },
            {
              name: "生产成本",
            },
            {
              name: "生产线编号",
            },
            {
              name: "生产率",
            },
            {
              name: "经济批量",
            },
          ],
        },
        {
          name: "生产线约束",
          identifier: "production_line_constraints",
          fields: [
            {
              name: "生产线编号",
            },
            {
              name: "周期产能下限",
            },
            {
              name: "周期产能上限",
            },
          ],
        },
        {
          name: "产品生产约束",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "周期生产量下限",
            },
            {
              name: "周期生产量上限",
            },
          ],
        },
        {
          name: "站点产品生产约束",
          identifier: "site_product_production_constraints",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期生产量下限",
            },
            {
              name: "周期生产量上限",
            },
          ],
        },
        {
          name: "禁止运输线路",
          identifier: "prohibited_transportation_routes",
          fields: [
            {
              name: "起始站点",
            },
            {
              name: "目的站点",
            },
            {
              name: "产品名称",
            },
            {
              name: "是否生效",
            },
          ],
        },
        {
          name: "区域运输约束",
          fields: [
            {
              name: "区域",
            },
            {
              name: "进区域周期运输下限",
            },
            {
              name: "进区域周期运输上限",
            },
            {
              name: "出区域周期运输下限",
            },
            {
              name: "出区域周期运输上限",
            },
          ],
        },
      ],
    },
    {
      identifier: "master_production_planning",
      apps: [
        "175bb9b5-f5e5-400f-a7fd-1135908a0111", //伊利液奶主生产计划应用
      ],
      tables: [
        {
          name: "模型参数",
          fields: [
            {
              name: "参数名称",
            },
            {
              name: "参数值",
            },
          ],
        },
        {
          name: "产品",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "产品类型",
            },
            {
              name: "单位价格",
            },
            {
              name: "允许采购",
            },
          ],
        },
        {
          name: "站点",
          identifier: "site",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "站点类型",
            },
            {
              name: "存储成本",
            },
            {
              name: "区域",
            },
          ],
        },
        {
          name: "BOM",
          identifier: "bom",
          fields: [
            {
              name: "BOM编号",
            },
            {
              name: "产品名称",
            },
            {
              name: "物料数量",
            },
            {
              name: "输入/输出",
            },
          ],
        },
        {
          name: "需求计划",
          identifier: "demand_plan",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期",
            },
            {
              name: "需求量",
            },
            {
              name: "优先级",
            },
          ],
        },
        {
          name: "特殊需求",
          identifier: "special_requirements",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期",
            },
            {
              name: "需求量",
            },
            {
              name: "优先级",
            },
          ],
        },
        {
          name: "期初库存",
          identifier: "beginning_inventory",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "期初库存",
            },
          ],
        },
        {
          name: "库存目标",
          identifier: "inventory_target",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期",
            },
            {
              name: "期末库存目标",
            },
          ],
        },
        {
          name: "运输策略",
          identifier: "transportation_strategy",
          fields: [
            {
              name: "起始站点",
            },
            {
              name: "目的站点",
            },
            {
              name: "运输成本",
            },
            {
              name: "是否启用",
            },
            {
              name: "最小起运量",
            },
          ],
        },
        {
          name: "生产策略",
          identifier: "production_strategy",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "BOM编号",
            },
            {
              name: "生产成本",
            },
            {
              name: "生产线编号",
            },
            {
              name: "生产率",
            },
            {
              name: "经济批量",
            },
          ],
        },
        {
          name: "生产线约束",
          identifier: "production_line_constraints",
          fields: [
            {
              name: "生产线编号",
            },
            {
              name: "周期产能下限",
            },
            {
              name: "周期产能上限",
            },
          ],
        },
        {
          name: "产品生产约束",
          fields: [
            {
              name: "产品名称",
            },
            {
              name: "周期生产量下限",
            },
            {
              name: "周期生产量上限",
            },
          ],
        },
        {
          name: "站点产品生产约束",
          identifier: "site_product_production_constraints",
          fields: [
            {
              name: "站点名称",
            },
            {
              name: "产品名称",
            },
            {
              name: "周期生产量下限",
            },
            {
              name: "周期生产量上限",
            },
          ],
        },
      ],
    },
  ],
};

export const validateSchema = (
  currentDataset: Dataset,
  sheetTables: SheetTable[],
  t: TFunction,
  i18n: i18n
) => {
  const missingTables: {
    name: string;
  }[] = [];
  const invalidReferences: {
    referenceColumnName: string;
    referenceTable: SheetTable;
    row: number;
    value: string;
    definitionTable: SheetTable;
  }[] = [];
  const currentApp = currentDataset?.action_config.flow_app;
  const schemas = (Schema as any)[i18n.resolvedLanguage || "en"];
  const currentSchema = schemas.find((schema: any) => {
    return "apps" in schema && schema.apps.includes(currentApp);
  });

  if (!currentSchema) {
    return { status: true };
  }

  // check missing tables
  for (const table of currentSchema.tables) {
    const sheetTable = sheetTables.find((item) => {
      return item.name?.replace(`【${t("table.title")}】`, "") === table.name;
    });
    if (!sheetTable) {
      missingTables.push(table);
    }
  }

  // check invalid references
  let checkLists = [] as any[];
  if (
    [
      "f1a7b9b6-be8b-4d24-b2d6-4501912e8ce7", //产销协同与供应链主计划
      "d01290f4-5369-448c-b59e-1ae48f06e3b5", //伊利酸奶主生产计划应用
      // "b389afa3-f3fe-46a1-87e2-32fd88ce64c4", //伊利冷饮主生产计划应用
    ].includes(currentApp)
  ) {
    checkLists = [
      {
        referenceTableIdentifier: "demand_plan",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "special_requirements",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "beginning_inventory",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "inventory_target",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "transportation_strategy",
        referenceColumnIdentifier: "origin_site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "transportation_strategy",
        referenceColumnIdentifier: "destination_site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "production_strategy",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "prohibited_transportation_routes",
        referenceColumnIdentifier: "origin_site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "prohibited_transportation_routes",
        referenceColumnIdentifier: "destination_site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "production_strategy",
        referenceColumnIdentifier: "production_group_id",
        definitionTableIdentifier: "production_line_constraints",
        definitionColumnIdentifier: "production_group_id",
      },
      {
        referenceTableIdentifier: "production_strategy",
        referenceColumnIdentifier: "bom_header_id",
        definitionTableIdentifier: "bom",
        definitionColumnIdentifier: "bom_header_id",
      },
    ];
  }
  if (
    [
      "175bb9b5-f5e5-400f-a7fd-1135908a0111", //伊利液奶主生产计划应用
    ].includes(currentApp)
  ) {
    checkLists = [
      {
        referenceTableIdentifier: "demand_plan",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "special_requirements",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "beginning_inventory",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "inventory_target",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "transportation_strategy",
        referenceColumnIdentifier: "origin_site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "transportation_strategy",
        referenceColumnIdentifier: "destination_site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "production_strategy",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "site_product_production_constraints",
        referenceColumnIdentifier: "site_id",
        definitionTableIdentifier: "site",
        definitionColumnIdentifier: "name",
      },
      {
        referenceTableIdentifier: "production_strategy",
        referenceColumnIdentifier: "production_group_id",
        definitionTableIdentifier: "production_line_constraints",
        definitionColumnIdentifier: "production_group_id",
      },
      {
        referenceTableIdentifier: "production_strategy",
        referenceColumnIdentifier: "bom_header_id",
        definitionTableIdentifier: "bom",
        definitionColumnIdentifier: "bom_header_id",
      },
    ];
  }
  for (const checkList of checkLists) {
    const referenceTableName = currentSchema.tables.find((table: any) => {
      return table.identifier === checkList.referenceTableIdentifier;
    }).name;
    const referenceTable = sheetTables.find((sheetTable) => {
      return (
        sheetTable.name === referenceTableName ||
        sheetTable.origin_table_name === referenceTableName
      );
    });
    const definitionTableName = currentSchema.tables.find((table: any) => {
      return table.identifier === checkList.definitionTableIdentifier;
    }).name;
    const definitionTable = sheetTables.find((sheetTable) => {
      return (
        sheetTable.name === definitionTableName ||
        sheetTable.origin_table_name === definitionTableName
      );
    });

    if (
      !definitionTable ||
      !referenceTable ||
      !referenceTable.meta ||
      !referenceTable.meta.fields
    ) {
      return { status: false, missingTables, invalidReferences };
    }

    const referenceColumn = referenceTable.meta.fields.find((field) => {
      return field.identifier === checkList.referenceColumnIdentifier;
    });

    referenceTable?.data.forEach((referenceData: any, index: number) => {
      const value = referenceData[checkList.referenceColumnIdentifier];
      const matchedDefinitionColumn = definitionTable?.data.find(
        (definitionData: any) => {
          return value === definitionData[checkList.definitionColumnIdentifier];
        }
      );
      if (!matchedDefinitionColumn) {
        invalidReferences.push({
          referenceColumnName: referenceColumn?.name || "",
          referenceTable: referenceTable,
          row: index,
          value: value,
          definitionTable: definitionTable,
        });
      }
    });
  }

  if (missingTables.length === 0 && invalidReferences.length === 0) {
    return { status: true };
  } else {
    return { status: false, missingTables, invalidReferences };
  }
};
