import { API, responseData } from "./BaseApi";
import { Workspace } from "../types/Workspace";

export const getApiKeysApi = (workspace: Workspace) => {
  let queryParams = new URLSearchParams();
  if (workspace) queryParams.append("workspace_id", workspace.id.toString());
  return API.get<responseData, responseData>(
    `/workspace/${workspace.id}/api-keys/`
  );
};

export const createApiKeyApi = (
  data: { name: string },
  workspace: Workspace
) => {
  return API.post<responseData, responseData>(
    `/workspace/${workspace.id}/api-key/`,
    data
  );
};

export const deleteApiKeyApi = (data: { id: number }) => {
  return API.delete<responseData, responseData>(
    `/workspace/api-key/${data.id}/`
  );
};

export const updateApiKeyApi = (data: { id: number; name: string }) => {
  return API.put<responseData, responseData>(
    `/workspace/api-key/${data.id}/`,
    data
  );
};
