import React, { useEffect, useState } from "react";
import NavHyperLink from "../../components/NavHyperLink";
import {
  Button,
  Table,
  Modal,
  message,
  Form,
  Input,
  Checkbox,
  Select,
  Space,
  DatePicker,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  getAllWorkspacesApi,
  getSystemUserListApi,
  updateUserApi,
} from "../../api";
import viewUtils from "../../utils/viewUtils";
import { Membership, Workspace } from "../../types/Workspace";

const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const UserManagePage = () => {
  const [criteria, setCriteria] = useState({});

  const handleSearch = (data: any) => {
    setCriteria(data);
  };

  return (
    <>
      <NavHyperLink items={[{ title: "平台用户管理" }]} />
      <div style={{ height: "1.5em" }} />
      <Search onSubmit={handleSearch} />
      <UserTable criteria={criteria} />
    </>
  );
};

const Search = (props: { onSubmit: (data: any) => void }) => {
  const [form] = Form.useForm();
  const isSuperuserOption = [
    {
      value: null,
      label: "全部",
    },
    {
      value: true,
      label: "是",
    },
    {
      value: false,
      label: "否",
    },
  ];
  const [workspaceOptions, setWorkspaceOptions] = useState<any[]>();

  useEffect(() => {
    getAllWorkspacesApi().then((res) => {
      if (res.status) {
        setWorkspaceOptions([
          {
            value: null,
            label: "全部",
          },
          ...(res.data || []).map((item: Workspace) => {
            return { value: item.id, label: item.name };
          }),
        ]);
      }
    });
  }, []);

  const handleSubmit = () => {
    props.onSubmit(form.getFieldsValue());
  };

  const handleReset = () => {
    form.resetFields();
    props.onSubmit(form.getFieldsValue());
  };

  return (
    <Form
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gridAutoRows: "auto",
        gap: "1em",
      }}
      form={form}
      colon={false}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="inline"
      initialValues={{
        is_superuser: null,
        workspace_id: null,
      }}
    >
      <Form.Item name="username" label={"帐号"}>
        <Input />
      </Form.Item>
      <Form.Item name="is_superuser" label={"超级管理员"}>
        <Select options={isSuperuserOption} />
      </Form.Item>
      <Form.Item name="workspace_id" label={"所属空间"}>
        <Select options={workspaceOptions} />
      </Form.Item>
      <Form.Item name="date_joined" label={"注册时间"}>
        <RangePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            {"查询"}
          </Button>
          <Button htmlType="reset" onClick={handleReset}>
            {"重置"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const UserTable = (props: { criteria: any }) => {
  const [datum, setDatum] = useState([] as Membership[]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, page_size: 10 });

  useEffect(() => {
    getSystemUserList(pagination, props.criteria);
  }, [pagination]);

  useEffect(() => {
    setPagination({ page: 1, page_size: pagination.page_size });
  }, [props.criteria]);

  const handlePageChange = (page: number, page_size: number) => {
    setPagination((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const handleEdited = () => {
    getSystemUserList(pagination, props.criteria);
  };

  const getSystemUserList = (pagination: any, criteria: any) => {
    let fromDate = null;
    let toDate = null;
    if (criteria.date_joined && criteria.date_joined.length > 0) {
      fromDate = criteria.date_joined[0].format("YYYY-MM-DD");
    }
    if (criteria.date_joined && criteria.date_joined.length > 1) {
      toDate = criteria.date_joined[1].format("YYYY-MM-DD");
    }

    getSystemUserListApi({
      username: criteria.username,
      workspace_id: criteria.workspace_id,
      is_superuser: criteria.is_superuser,
      date_joined_from: fromDate,
      date_joined_to: toDate,
      ...pagination,
    }).then((res) => {
      if (res.status) {
        setDatum(res.data || []);
        setTotal(res.pagination?.count || 0);
      } else {
        setDatum([]);
        setTotal(0);
      }
    });
  };

  const columns: ColumnsType<Membership> = [
    {
      width: 100,
      title: "帐号",
      key: "username",
      render: (r: Membership) => r?.user.username,
    },
    {
      width: 100,
      title: "用户名",
      key: "name",
      render: (r: Membership) => r?.user.name || "-",
    },
    {
      width: 150,
      title: "超级管理员",
      key: "is_superuser",
      render: (r: Membership) => (r?.user.is_superuser ? "✅" : "❌"),
    },
    {
      width: 100,
      title: "所属空间",
      key: "workspace",
      render: (r: Membership) => (
        <Paragraph
          ellipsis={{ rows: 2, expandable: false, tooltip: r?.workspace?.name }}
        >
          {r?.workspace?.name || "-"}
        </Paragraph>
      ),
    },
    {
      width: 100,
      title: "邀请人",
      key: "operator",
      render: (r: Membership) =>
        r?.role === "OWNER" ? "-" : r?.operator?.name || "-",
    },
    {
      width: 200,
      title: "最后登录时间",
      key: "last_login",
      render: (r: Membership) => viewUtils.utcToLocal(r.user.last_login) || "-",
    },
    {
      width: 200,
      title: "注册时间",
      key: "date_joined",
      render: (r: Membership) => viewUtils.utcToLocal(r.user.date_joined),
    },
    {
      width: 100,
      title: "操作",
      key: "actions",
      fixed: "right",
      align: "center",
      render: (r: Membership) => (
        <div className="btn-group mr-2">
          <EditButton membership={r} onEdited={handleEdited} />
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive bg-white" style={{ marginTop: "1em" }}>
      <Table
        rowKey={(r) => r.id}
        columns={columns}
        dataSource={datum}
        scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
        pagination={{
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          current: pagination.page,
          pageSize: pagination.page_size,
          total: total,
          onChange: handlePageChange,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          style: { margin: "1em" },
        }}
      />
    </div>
  );
};

const EditButton = (props: {
  membership: Membership;
  onEdited: () => void;
}) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const handleClickEdit = () => {
    const user = props.membership.user;
    form.resetFields();
    form.setFieldsValue({
      username: user.username,
      is_superuser: user.is_superuser,
    });
    setOpen(true);
  };

  const handleOk = async () => {
    const userData = form.getFieldsValue(true);
    if (!!userData.username) {
      const response = await updateUserApi({
        username: userData.username,
        is_superuser: userData.is_superuser,
      });
      if (response.status) {
        message.success("更新用户成功");
        props.onEdited();
        setOpen(false);
      } else {
        message.error("更新用户失败");
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={handleClickEdit}>
        编辑
      </Button>
      <Modal
        title={"编辑用户"}
        okText={"保存"}
        cancelText={"取消"}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item label={"帐号"} name="username">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={"超级管理员"}
            name="is_superuser"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UserManagePage;
