import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { User } from "../../types/User";
import { Workspace } from "../../types/Workspace";
import { DatasetVersion, Task } from "../../types/Dataset";
import { createDatasetVersionTaskApi } from "../../api/DatasetApi";
import { getUserListApi } from "../../api";
import {
  Checkbox,
  Modal,
  Typography,
  message,
  Row,
  Col,
  Select,
  Tooltip,
} from "antd";
import viewUtils from "../../utils/viewUtils";
import { SheetTable } from "../../types/Table";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import GlobalLoader from "../../components/GlobalLoading";

type Props = {
  refInstance?: any;
  currentWorkspace?: Workspace;
  currentVersion?: DatasetVersion;
  versionTables?: SheetTable[];
  updateCallback?: (action: String, task: Task, table_id?: number) => void;
};

const DatasetBatchAddTaskModal = (props: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedOperators, setSelectedOperators] = useState<string[]>([]);
  const [selectedTables, setSelectedTables] = useState<number[]>([]);

  const tables = useMemo(
    () =>
      (props.versionTables || []).filter(
        (table: SheetTable) => table?.table_type === "COMMON" && !!table?.id
      ) || [],
    [props.versionTables]
  );
  const currentAvailableTables = useMemo(
    () =>
      tables
        ?.filter(
          (table: SheetTable) =>
            !table?.tasks?.find((task: Task) =>
              selectedOperators.includes(task?.assignee || "")
            )
        )
        .map((table: SheetTable) => Number(table.id)) || [],
    [tables, selectedOperators]
  );

  useEffect(() => {
    if (!!props.currentWorkspace?.id) {
      getUserListApi({ workspace: Number(props.currentWorkspace?.id) }).then(
        (res) => {
          if (res.success) {
            setUsers(res.data);
          }
        }
      );
    }
  }, [props.currentWorkspace]);

  useImperativeHandle(props.refInstance, () => ({
    show: () => setShow(true),
  }));

  const closeModal = () => setShow(false);

  useEffect(() => {
    setIndeterminate(false);
    setCheckAll(false);
    setSelectedOperators([]);
    setSelectedTables([]);
  }, [show]);

  useEffect(() => {
    const list = selectedTables.filter(
      (t) => !!currentAvailableTables.includes(t)
    );
    setIndeterminate(
      !!list.length && list.length < currentAvailableTables.length
    );
    setCheckAll(list.length >= currentAvailableTables.length);
    if (list.join("-") !== selectedTables.join("-")) {
      setSelectedTables(list);
      setIndeterminate(list.length < currentAvailableTables.length);
      setCheckAll(list.length >= currentAvailableTables.length);
    }
  }, [currentAvailableTables, selectedTables]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedTables(e.target.checked ? currentAvailableTables : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleOperatorChange = (value: string[]) => {
    setSelectedOperators(value);
  };

  const handleTablesChange = (list: any[]) => {
    setSelectedTables(list as number[]);
  };

  const handleSubmit = async () => {
    if (selectedTables.length < 1) {
      message.error(t("dataset.tips.task_assigne_table_required"));
      return;
    }
    if (selectedOperators.length < 1) {
      message.error(t("dataset.tips.task_assigne_operator_required"));
      return;
    }
    let error_count = 0;
    let success_count = 0;
    GlobalLoader.show();
    for (let tableIndex = 0; tableIndex < selectedTables.length; tableIndex++) {
      const table_id = selectedTables[tableIndex];
      const tableData = props.versionTables?.find(
        (table: SheetTable) => table?.id === table_id
      );
      if (!!tableData) {
        for (
          let userIndex = 0;
          userIndex < selectedOperators.length;
          userIndex++
        ) {
          const user = selectedOperators[userIndex];
          if (!tableData?.tasks.find((task: Task) => task.assignee === user)) {
            let res = await createDatasetVersionTaskApi({
              assignee: user,
              table_id: table_id,
              version_id: Number(props.currentVersion?.id),
            });
            if (res.success) {
              success_count++;
              props.updateCallback &&
                props.updateCallback("add", res.data, table_id);
            } else {
              error_count++;
            }
          }
        }
      }
    }
    if (error_count > 0) {
      message.error(
        t("dataset.tips.add_tasks_in_batches_fail", {
          success_count: success_count,
          fail_count: error_count,
        })
      );
    } else {
      message.success(t("dataset.tips.add_tasks_in_batches_success"));
    }
    // closeModal();
    GlobalLoader.hide();
  };

  return (
    <Modal
      centered
      width={700}
      destroyOnClose={true}
      title={t("dataset.add_tasks_in_batches")}
      open={!!show}
      onCancel={closeModal}
      onOk={handleSubmit}
      cancelText={t("common.cancel")}
      okText={t("common.ok")}
    >
      <Typography.Paragraph>
        {t("table.name")}
        <Checkbox
          style={{ marginLeft: "2em" }}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {t("common.check_all")}
        </Checkbox>
      </Typography.Paragraph>
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={handleTablesChange}
        className="compare-checkbox"
        value={selectedTables}
      >
        <Row gutter={[16, 10]} style={{ width: "100%" }}>
          {tables.map((table: SheetTable) => (
            <Col key={table.id} xs={12} sm={8} md={6}>
              <Tooltip
                placement="topLeft"
                title={
                  currentAvailableTables.includes(Number(table?.id))
                    ? ""
                    : t("dataset.tips.task_assigned")
                }
              >
                <Checkbox
                  value={table.id}
                  disabled={!currentAvailableTables.includes(Number(table?.id))}
                >
                  <Typography.Text ellipsis={{ tooltip: table.name }}>
                    {table.name}
                  </Typography.Text>
                </Checkbox>
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Typography.Paragraph style={{ marginTop: "2em", marginBottom: "0.5em" }}>
        {t("dataset.operator")}
      </Typography.Paragraph>
      <Select
        mode="multiple"
        showSearch={true}
        onChange={handleOperatorChange}
        style={{ width: "100%" }}
        options={users.map((item) => ({
          label: `${viewUtils.prettifyUsername(item.name)}(${
            item.username ?? ""
          })`,
          value: item.username,
        }))}
      />
    </Modal>
  );
};

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
});

let Component = connect(mapStateToProps, {})(DatasetBatchAddTaskModal);
export default forwardRef((props: Props, ref) => (
  <Component {...props} refInstance={ref} />
));
