import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Space,
  message,
  Button,
  Drawer,
  Dropdown,
  Modal,
  Empty,
  Card,
  Menu,
  Pagination,
} from "antd";
import { DatasetVersion } from "../../types/Dataset";
import { ChartView } from "../../types/ChartView";
import {
  getDatasetVersionChartViewsApi,
  deleteDatasetVersionChartViewApi,
} from "../../api/DatasetApi";
import IconChart from "../../common/images/icons/chart";
import DatasetChartFormModal from "./DatasetChartFormModal";
import { ExpandAltOutlined, EllipsisOutlined } from "@ant-design/icons";
import ChartViewComponent from "../../components/chart";

const DatasetChart = (props: { version?: DatasetVersion }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ show: false, page: 1, page_size: 10 });
  const [charts, setCharts] = useState<{ data: ChartView[]; total: number }>({
    data: [],
    total: 0,
  });
  const chartViewModalRef: any = useRef();

  useEffect(() => {
    if (props.version?.id && filter.show) {
      getDatasetVersionChartViewsApi({
        versionId: Number(props.version?.id),
        page: filter.page,
        page_size: filter.page_size,
      }).then((res) => {
        if (res.success) {
          setCharts({
            data: res.data,
            total: res.pagination?.count || 0,
          });
        }
      });
    }
  }, [props.version, filter]);

  const handlePageChange = (page: number) =>
    setFilter((data) => ({ ...data, page: page }));

  const handleAdd = () => {
    chartViewModalRef?.current?.show({});
  };
  const handleEdit = (chartView: ChartView) => {
    chartViewModalRef?.current?.show(chartView);
  };

  const handleCopy = (chartView: ChartView) => {
    chartViewModalRef?.current?.show({
      ...chartView,
      id: null,
      name: null,
    });
  };

  const handleDelete = (chartView: ChartView) => {
    Modal.confirm({
      title: t("chart_view.confirm.delete"),
      onOk() {
        deleteDatasetVersionChartViewApi({
          chartId: Number(chartView?.id),
        }).then((res) => {
          if (res.success) {
            message.success(
              t("chart_view.tip.delete", { status: t("common.success") })
            );
            setFilter((data) => ({ ...data }));
          } else {
            message.error(
              t("chart_view.tip.delete", { status: t("common.fail") })
            );
          }
        });
      },
    });
  };

  const chartViewModal = useMemo(
    () => (
      <DatasetChartFormModal
        version={props.version}
        ref={chartViewModalRef}
        callback={() => {
          setFilter((data) => ({ ...data }));
        }}
      />
    ),
    [props.version]
  );

  return (
    <>
      <Drawer
        title={t("chart_view.title")}
        extra={
          <Button type="primary" onClick={handleAdd}>
            {t("chart_view.action.add")}
          </Button>
        }
        placement="right"
        onClose={() => setFilter((data) => ({ ...data, show: false }))}
        open={!!filter.show}
        width={"max(50vw, 500px)"}
        getContainer={false}
        style={{ borderTop: "1px solid rgba(5, 5, 5, 0.06)" }}
      >
        <>
          <div className="chart-view-list">
            {(charts.data || []).map((chartView: ChartView) => (
              <div key={chartView.id} className="chart-view-item">
                <Card
                  size="small"
                  title={chartView.name}
                  headStyle={{ border: "none", padding: "8px 12px" }}
                  bodyStyle={{ width: "100%", height: "calc(100% - 48px)" }}
                  style={{ height: "100%" }}
                  extra={
                    <Space size={0}>
                      <Button
                        type="text"
                        icon={<ExpandAltOutlined />}
                        onClick={() => handleEdit(chartView)}
                      />
                      <Dropdown
                        dropdownRender={() => (
                          <Menu
                            className="chart-actions"
                            items={[
                              {
                                label: t("common.edit"),
                                key: "edit",
                                onClick: () => handleEdit(chartView),
                              },
                              {
                                label: t("common.copy"),
                                key: "copy",
                                onClick: () => handleCopy(chartView),
                              },
                              {
                                danger: true,
                                label: t("common.delete"),
                                key: "delete",
                                onClick: () => handleDelete(chartView),
                              },
                            ]}
                          />
                        )}
                        placement="bottomRight"
                      >
                        <Button type="text" icon={<EllipsisOutlined />} />
                      </Dropdown>
                    </Space>
                  }
                >
                  <ChartViewComponent
                    id={`chart-list-${chartView.id}`}
                    chart_view={chartView}
                  />
                </Card>
              </div>
            ))}
          </div>
          {!!charts.data && charts.data?.length < 1 && (
            <div style={{ padding: "15vh 0" }}>
              <Empty />
            </div>
          )}
          <div>
            <div className="table-responsive">
              <Pagination
                showQuickJumper
                hideOnSinglePage={!charts.data?.length}
                showTotal={(total) => t("common.total", { count: total })}
                current={filter.page}
                pageSize={filter.page_size}
                total={charts.total}
                onChange={handlePageChange}
                showSizeChanger={false}
                style={{
                  textAlign: "right",
                  justifyContent: "flex-end",
                  margin: "1em 0",
                }}
              />
            </div>
          </div>
        </>
      </Drawer>
      {chartViewModal}
      <div className="action-item">
        <Button
          icon={
            <IconChart style={{ fontSize: "1.4em", marginBottom: "0.1em" }} />
          }
          onClick={() =>
            setFilter((data) => ({ ...data, show: true, page: 1 }))
          }
        >
          {t("chart_view.title")}
        </Button>
      </div>
    </>
  );
};

export default DatasetChart;
