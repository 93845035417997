import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Typography } from "antd";

interface Route {
  path?: string;
  title: string;
}

interface Props {
  items: Route[];
  showBack?: boolean;
}

const NavHyperLink = (props: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    const preLink = props.items
      ?.reverse()
      ?.find((item) => !!item.path && item.path !== "#");
    if (!!preLink && !!preLink.path) {
      navigate(preLink.path, { replace: true });
    } else {
      navigate(-1);
    }
  };

  const LastName = (props.items || [])[(props.items?.length || 1) - 1];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {!!props.showBack && (
          <Button
            className="goback-btn common_pagetitle"
            icon={
              <ArrowLeftOutlined
                style={{ fontSize: "1.2em", color: "rgba(0,0,0,0.65)" }}
              />
            }
            style={{ marginRight: 10 }}
            shape="circle"
            onClick={goBack}
          />
        )}
        <Typography.Title
          level={4}
          className="common_pagetitle"
          style={{ maxWidth: "30vw", marginBottom: 0 }}
          ellipsis={{ rows: 1, tooltip: true }}
        >
          {LastName?.title || ""}
        </Typography.Title>
      </div>
      {props.items?.length > 1 && (
        <Breadcrumb
          className="common_pagetitle"
          items={props.items.map((item: Route, index: number) => ({
            title:
              index !== props.items?.length - 1 && !!item.path ? (
                <NavLink to={item.path}>
                  <Typography.Text
                    style={{ maxWidth: "15vw" }}
                    ellipsis={{ tooltip: true }}
                  >
                    {item.title}
                  </Typography.Text>
                </NavLink>
              ) : (
                <Typography.Text
                  style={{ maxWidth: "15vw" }}
                  ellipsis={{ tooltip: true }}
                >
                  {item.title}
                </Typography.Text>
              ),
          }))}
        />
      )}
    </div>
  );
};

export default NavHyperLink;
