import { responseData, API } from "./BaseApi";

export const getSystemTableListApi = (data: {
  page?: number;
  page_size?: number;
  sync_config?: string;
}) => {
  let queryParams = new URLSearchParams();
  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.sync_config) queryParams.append("sync_config", data.sync_config);

  return API.get<responseData, responseData>(
    `tables/system/?${queryParams.toString()}`
  );
};

export const getSystemTableApi = (data: { tableId: number }) => {
  return API.get<responseData, responseData>(`table/${data.tableId}/system`);
};

export const updateSystemTableApi = (data: {
  tableId: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `table/${data.tableId}/system`,
    data.data
  );
};

export const createTableApi = (data: object) => {
  return API.post<responseData, responseData>("table/", data);
};

export const getTableListApi = (data: {
  page?: number;
  page_size?: number;
  query?: string;
  ordering?: string;
  tableType?: string;
  sync_config?: string;
}) => {
  let queryParams = new URLSearchParams();
  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.query) queryParams.append("q", data.query);
  if (data.ordering) queryParams.append("ordering", data.ordering);
  if (data.tableType) queryParams.append("type", data.tableType);
  if (data.sync_config) queryParams.append("sync_config", data.sync_config);

  return API.get<responseData, responseData>(
    `tables/?${queryParams.toString()}`
  );
};

export const getTableApi = (data: { tableId: number }) => {
  return API.get<responseData, responseData>(`table/${data.tableId}/`);
};

export const updateTableApi = (data: { tableId: number; data: object }) => {
  return API.put<responseData, responseData>(
    `table/${data.tableId}/`,
    data.data
  );
};

export const deleteTableApi = (data: { tableId: number }) => {
  return API.delete<responseData, responseData>(`table/${data.tableId}/`);
};

export const getTableRowsApi = (data: { tableId: number }) => {
  return API.get<responseData, responseData>(`table/${data.tableId}/stats/`);
};

export const getTableDataApi = (
  data: { tableId: number },
  config: any = {},
  enableLimit: boolean = true
) => {
  const params = enableLimit ? { limit: config.limit || 10005 } : {};
  console.log("getTableDataApi", data, config, enableLimit, params);
  const finalConfig = {
    ...config,
    params: {
      ...config.params,
      ...params,
    },
  };

  return API.get<responseData, responseData>(
    `table/${data.tableId}/data/`,
    finalConfig
  );
};

export const exportTableDataApi = (data: { tableId: number }) => {
  return API.get<responseData, responseData>(`table/${data.tableId}/export/`, {
    responseType: "blob",
  });
};

export const updateTableDataApi = (data: { tableId: number; data: any }) => {
  return API.put<responseData, responseData>(
    `table/${data.tableId}/data/`,
    data.data
  );
};

export const checkTableWorkspacePermissionApi = (data: {
  datasetId: number;
}) => {
  return API.get<responseData, responseData>(
    `table/${data.datasetId}/check-workspace-permission/`
  );
};

export const getTableMembersApi = (data: { tableId: number }) => {
  return API.get<responseData, responseData>(`table/${data.tableId}/members/`);
};

export const updateTableMembersApi = (data: {
  tableId: number;
  data: object;
}) => {
  return API.post<responseData, responseData>(
    `table/${data.tableId}/members/`,
    data.data
  );
};

export const getTableCurrentSyncStatusApi = (data: { tableId: number }) => {
  return API.get<responseData, responseData>(
    `table/${data.tableId}/sync-status`
  );
};

export const syncTableApi = (data: { tableId: number }) => {
  return API.post<responseData, responseData>(`table/${data.tableId}/sync`);
};
