import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Form, Input, Button, message } from "antd";
import {
  createWorkspace,
  updateWorkspace,
  closeWorkspaceModal,
  setCurrentWorkspace,
} from "../store/account";
import { responseData } from "../api/BaseApi";
import type { Workspace } from "../types/Workspace";
import usePermission from "../utils/usePermission";

const WorkspaceFormModal = (props: {
  workspaces: Workspace[];
  showWorkspaceModal: Workspace | boolean | null;
  createWorkspace: (data: any) => void;
  updateWorkspace: (data: any) => void;
  closeWorkspaceModal: () => void;
  setCurrentWorkspace: (data: any) => void;
}) => {
  const [form] = Form.useForm();
  const permission = usePermission();

  useEffect(() => {
    form.resetFields();
    if (
      props.showWorkspaceModal &&
      props.showWorkspaceModal !== true &&
      props.showWorkspaceModal?.id
    ) {
      form.setFieldsValue({ name: props.showWorkspaceModal.name });
    }
  }, [props.showWorkspaceModal, form]);

  const ForceCreateWorkspace =
    props.workspaces?.length < 1 &&
    (!permission.enterpriseMode || !!permission.isSuperuser);

  const handleClose = () => {
    if (!ForceCreateWorkspace) {
      props.closeWorkspaceModal();
    }
  };

  const handleSubmitInstance = (data: { name: string }) => {
    if (
      props.showWorkspaceModal &&
      props.showWorkspaceModal !== true &&
      props.showWorkspaceModal?.id
    ) {
      props.updateWorkspace({
        workspace: {
          ...props.showWorkspaceModal,
          name: data.name,
        },
        success: (res: responseData) => {
          message.success("更新工作空间成功");
          if (props.showWorkspaceModal) {
            props.closeWorkspaceModal();
          }
        },
        fail: (res: responseData) => {
          if (JSON.stringify(res.message).includes("Cannot find")) {
            message.error("当前空间不存在，请刷新页面");
          } else {
            message.error("更新工作空间失败");
          }
        },
      });
    } else {
      props.createWorkspace({
        name: data.name,
        success: (res: responseData) => {
          message.success("创建工作空间成功");
          if (props.showWorkspaceModal) {
            props.closeWorkspaceModal();
          }
          props.setCurrentWorkspace({
            id: res.data.id,
            fail: () => {
              message.error("设置当前工作空间失败");
            },
          });
        },
        fail: (res: responseData) => {
          message.error("创建工作空间失败");
        },
      });
    }
  };

  return (
    <Modal
      centered
      forceRender
      maskClosable={!ForceCreateWorkspace}
      open={!!props.showWorkspaceModal || ForceCreateWorkspace}
      onCancel={handleClose}
      footer={null}
    >
      <Form
        form={form}
        name="workspace form"
        onFinish={handleSubmitInstance}
        className="text-center px-5 pt-5 pb-4"
        style={{
          textAlign: "center",
          padding: "2em 1em 1em 1em",
        }}
      >
        <div
          style={{ fontSize: "1.2em", fontWeight: "bold", marginBottom: "1em" }}
        >
          数据协同平台
        </div>
        <div style={{ marginBottom: "1em" }}>
          <span className="primary" style={{ lineHeight: "3em" }}>
            {`${
              props.showWorkspaceModal !== true &&
              (props.showWorkspaceModal || {})?.id
                ? "编辑"
                : "创建"
            }工作空间`}
          </span>
        </div>
        <Form.Item
          name="name"
          rules={[{ required: true, max: 50, message: "" }]}
        >
          <Input placeholder={"请输入工作空间名称"} showCount maxLength={50} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "60%", marginTop: "1em" }}
        >
          {props.showWorkspaceModal !== true &&
          (props.showWorkspaceModal || {})?.id
            ? "保存"
            : "创建"}
        </Button>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (store: any) => ({
  workspaces: store.account.workspaces,
  showWorkspaceModal: store.account.showWorkspaceModal,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      createWorkspace,
      updateWorkspace,
      closeWorkspaceModal,
      setCurrentWorkspace,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormModal);
