import {
  setCurrentWorkspaceApi,
  createWorkspaceApi,
  updateWorkspaceApi,
  deleteWorkspaceApi,
} from "../api";
import { User } from "../types/User";
import { Workspace } from "../types/Workspace";

const ZONE = "STORE/ACCOUNT/";

const INIT_ACCOUNT_INFO = ZONE + "INIT_ACCOUNT_INFO";
const SET_CURRENT_WORKSPACE = ZONE + "SET_CURRENT_WORKSPACE";
const CREATE_WORKSPACE = ZONE + "CREATE_WORKSPACE";
const UPDATE_WORKSPACE = ZONE + "UPDATE_WORKSPACE";
const DELETE_WORKSPACE = ZONE + "DELETE_WORKSPACE";
const OPEN_WORKSPACE_MODAL = ZONE + "OPEN_WORKSPACE_MODAL";
const CLOSE_WORKSPACE_MODAL = ZONE + "CLOSE_WORKSPACE_MODAL";
const RESET_WORKSPACE_LIST = ZONE + "RESET_WORKSPACE_LIST";
const REFRESH_UNREAD_NOTIFICATION = ZONE + "REFRESH_UNREAD_NOTIFICATION";

const defaultState = {
  user: null,
  token: null,
  workspaces: null,
  currentWorkspace: null,
  showWorkspaceModal: false,
  refreshNotification: null,
};

export const initAccountInfo = (data: {
  user: User;
  token: string;
  workspaces: Workspace[] | [];
  currentWorkspace: Workspace | null;
}) => {
  return { type: INIT_ACCOUNT_INFO, data: data };
};

export const refreshNotification = () => {
  return { type: REFRESH_UNREAD_NOTIFICATION, data: null };
};

export const resetWorkspaceList = (data: Workspace[]) => {
  return { type: RESET_WORKSPACE_LIST, data: data };
};

export const setCurrentWorkspace =
  (data: {
    id: number;
    success?: (res: any) => void;
    fail?: (res: any) => void;
  }) =>
  (dispatch: any) => {
    setCurrentWorkspaceApi({ workspace_id: data.id }).then((res) => {
      if (res.success) {
        dispatch({ type: SET_CURRENT_WORKSPACE, data: res.data });
        data.success && data.success(res);
      } else {
        data.fail && data.fail(res);
      }
    });
  };

export const deleteWorkspace =
  (data: {
    id: number;
    success?: (res: any) => void;
    fail?: (res: any) => void;
  }) =>
  (dispatch: any) => {
    deleteWorkspaceApi({ workspace_id: data.id }).then((res) => {
      if (res.success) {
        dispatch({ type: DELETE_WORKSPACE, data: data.id });
        data.success && data.success(res);
      } else {
        data.fail && data.fail(res);
      }
    });
  };

export const createWorkspace =
  (data: {
    name: string;
    success?: (res: any) => void;
    fail?: (res: any) => void;
  }) =>
  (dispatch: any) => {
    createWorkspaceApi({ name: data.name }).then((res) => {
      if (res.success) {
        dispatch({ type: CREATE_WORKSPACE, data: res.data });
        data.success && data.success(res);
      } else {
        data.fail && data.fail(res);
      }
    });
  };

export const updateWorkspace =
  (data: {
    workspace: Workspace;
    success?: (res: any) => void;
    fail?: (res: any) => void;
  }) =>
  (dispatch: any) => {
    updateWorkspaceApi(data.workspace).then((res) => {
      if (res.success) {
        dispatch({ type: UPDATE_WORKSPACE, data: data.workspace });
        data.success && data.success(data.workspace);
      } else {
        data.fail && data.fail(res);
      }
    });
  };

export const openWorkspaceModal = (data: Workspace | null) => {
  return { type: OPEN_WORKSPACE_MODAL, data: data || true };
};

export const closeWorkspaceModal = () => {
  return { type: CLOSE_WORKSPACE_MODAL };
};

export const reducer = (
  state: {
    showWorkspaceModal: boolean | Workspace;
    user: User | null;
    token: string | null;
    workspaces: Workspace[] | null;
    currentWorkspace: Workspace | null;
    refreshNotification: number | null;
  } = defaultState,
  action: {
    type: string;
    data: any;
  }
) => {
  switch (action.type) {
    case INIT_ACCOUNT_INFO:
      return {
        ...state,
        user: action.data.user,
        token: action.data.token,
        workspaces: action.data.workspaces,
        currentWorkspace: action.data.currentWorkspace,
      };
    case SET_CURRENT_WORKSPACE:
      return {
        ...state,
        currentWorkspace: action.data,
      };
    case RESET_WORKSPACE_LIST:
      return {
        ...state,
        currentWorkspace: action.data.find(
          (item: Workspace) => item.id === state.currentWorkspace?.id
        ),
        workspaces: action.data,
      };
    case DELETE_WORKSPACE:
      return {
        ...state,
        workspaces: (state.workspaces || []).filter(
          (workspace: Workspace) => workspace.id !== action.data
        ),
      };
    case CREATE_WORKSPACE:
      return {
        ...state,
        workspaces: [...(state.workspaces || []), action.data],
        currentWorkspace: state.currentWorkspace || action.data,
      };
    case UPDATE_WORKSPACE:
      return {
        ...state,
        workspaces: (state.workspaces || []).map((workspace: Workspace) => ({
          ...workspace,
          ...(workspace.id === action.data.id ? action.data : null),
        })),
        ...(action.data.id === state.currentWorkspace?.id
          ? {
              currentWorkspace: action.data,
            }
          : {}),
      };
    case OPEN_WORKSPACE_MODAL:
      return {
        ...state,
        showWorkspaceModal: action.data,
      };
    case CLOSE_WORKSPACE_MODAL:
      return {
        ...state,
        showWorkspaceModal: false,
      };
    case REFRESH_UNREAD_NOTIFICATION:
      return {
        ...state,
        refreshNotification: Math.random(),
      };
    default:
      return state;
  }
};
