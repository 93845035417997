import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, message, Modal, Image, Dropdown } from "antd";
import {
  DesktopOutlined,
  CheckOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import DatasetVersionIcon from "../../common/images/icons/dataset-version-icon.png";
import { DatasetVersion, Dataset } from "../../types/Dataset";
import DatasetVersionFormModal from "./DatasetVersionFormModal";
import { SheetTable } from "../../types/Table";
import { deleteDatasetVersionApi } from "../../api/DatasetApi";
import viewUtils from "../../utils/viewUtils";
import IconLock from "../../common/images/icons/lock";

const DatasetVersionAction = (props: {
  currentDataset?: Dataset;
  currentVersion?: DatasetVersion;
  versionList?: DatasetVersion[];

  updateVersion?: (version: DatasetVersion, resetTables?: SheetTable[]) => void;
  deleteVersion?: (version: DatasetVersion) => void;
  changeCurrentVersion?: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const datasetVersionFormModalRef: any = useRef();

  //init first version
  useEffect(() => {
    if (
      !!props.versionList &&
      props.versionList.length < 1 &&
      props.currentDataset?.is_admin
    ) {
      datasetVersionFormModalRef.current &&
        datasetVersionFormModalRef.current.show({
          dataset: props.currentDataset.id,
        });
    }
  }, [props.versionList, props.currentDataset]);

  const handleChangeVersion = (version: DatasetVersion) => {
    if (version.id !== props.currentVersion?.id) {
      props.changeCurrentVersion && props.changeCurrentVersion(version.id || 0);
    }
  };

  const handleEditVersion = (id?: number) => {
    if (!!id && datasetVersionFormModalRef.current) {
      datasetVersionFormModalRef.current.show(
        props.versionList?.find((v: DatasetVersion) => v.id === id)
      );
    }
  };

  const handleDeleteVersion = (version: DatasetVersion) => {
    if (!!version?.id) {
      Modal.confirm({
        title: t("dataset.tips.confirm_delete_version"),
        cancelText: t("common.cancel"),
        okText: t("common.delete"),
        centered: true,
        onOk: async () => {
          deleteDatasetVersionApi({
            versionId: Number(version?.id),
          }).then((res) => {
            if (res.success) {
              message.success(
                t("common.result_status", {
                  title: t("common.delete"),
                  status: t("common.success"),
                })
              );
              props.deleteVersion && props.deleteVersion(version);
            } else {
              message.error(
                viewUtils.prettifyErrorMessage(res.message) ||
                  t("common.result_status", {
                    title: t("common.delete"),
                    status: t("common.fail"),
                  })
              );
            }
          });
        },
      });
    }
  };

  const handleAddVersion = () => {
    datasetVersionFormModalRef.current &&
      datasetVersionFormModalRef.current.show({
        dataset: props.currentDataset?.id,
        show_copy: true,
      });
  };

  return (
    <>
      <DatasetVersionFormModal
        ref={datasetVersionFormModalRef}
        forceOpen={(props.versionList || []).length < 1}
        callback={props.updateVersion}
      />
      <div className="action-item" id="dataset-action-version-manage">
        <Button
          icon={
            <DesktopOutlined
              style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
            />
          }
        >
          <span>{t("dataset.version")}</span>
        </Button>
        <div className="version-manage-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.8em 0 0.6em 0",
              margin: "0 1.25em",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {t("dataset.versions")}
            </Typography.Title>
            <Button
              type="link"
              onClick={handleAddVersion}
              style={{ width: "auto", paddingLeft: 0, paddingRight: 0 }}
              disabled={
                !!(props.versionList || []).find((item) => !item.is_locked)
              }
            >
              + {t("dataset.actions.add_version")}
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              padding: "0.5em 0 1em 0",
            }}
          >
            {(props.versionList || []).map((item) => (
              <div key={item.id} className="version-item">
                <div
                  className="version-item-box"
                  style={{
                    alignItems: !!item.description ? "flex-start" : "center",
                  }}
                  onClick={() => handleChangeVersion(item)}
                >
                  <Image
                    src={DatasetVersionIcon}
                    preview={false}
                    style={{ width: 23, height: 23, marginRight: 10 }}
                  />
                  <div style={{ minWidth: 0, flex: 1 }}>
                    <Typography.Title
                      level={5}
                      ellipsis={{ rows: 1, tooltip: true }}
                      style={{ marginBottom: 0, lineHeight: 1 }}
                    >
                      {item.name}
                    </Typography.Title>
                    {!!item.description && (
                      <Typography.Paragraph
                        style={{
                          marginBottom: 0,
                          marginTop: "0.5em",
                          fontSize: "0.9em",
                        }}
                        ellipsis={{ rows: 1 }}
                      >
                        {item.description}
                      </Typography.Paragraph>
                    )}
                  </div>
                  <div
                    style={{
                      marginLeft: "1em",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="actions">
                      {props.currentVersion?.id === item.id && (
                        <Button
                          className="active"
                          type="link"
                          icon={<CheckOutlined style={{ color: "#52c41a" }} />}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      )}
                      {item.is_locked ? (
                        <Button
                          className={`${
                            props.currentVersion?.id === item.id
                              ? "actions-active"
                              : ""
                          }`}
                          type="link"
                          disabled
                          icon={<IconLock />}
                        />
                      ) : (
                        <Dropdown
                          placement="bottom"
                          overlayClassName={`minWidth0 dataset-version-item-manage-menu`}
                          getPopupContainer={(triggerNode: HTMLElement) =>
                            document.getElementById("floating-layer") ||
                            document.body
                          }
                          menu={{
                            items: [
                              {
                                key: "edit",
                                label: (
                                  <a
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleEditVersion(item.id);
                                    }}
                                  >
                                    {t("common.edit")}
                                  </a>
                                ),
                              },
                              {
                                key: "delete",
                                label: (
                                  <a
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleDeleteVersion(item);
                                    }}
                                  >
                                    {t("common.delete")}
                                  </a>
                                ),
                                danger: true,
                              },
                            ],
                          }}
                        >
                          <Button
                            className={`${
                              props.currentVersion?.id === item.id
                                ? "actions-active"
                                : ""
                            }`}
                            type="text"
                            icon={<EllipsisOutlined />}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          />
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default DatasetVersionAction;
