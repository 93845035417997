import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Button,
  Space,
  Spin,
  message,
  Form,
  Select,
  Input,
  InputNumber,
  Table,
} from "antd";
import type { DatasetVersion, Task } from "../../types/Dataset";
import type { SheetTable, TableField } from "../../types/Table";
import { calCompareTableDataStatistics } from "../../utils/SheetCompareUtils";
import {
  getSalesReportApi,
  getDatasetVersionDataApi,
  deleteDatasetTableApi,
  deleteDatasetVersionTaskApi,
  updateDatasetVersionTaskApi,
  updateDatasetVersionTaskDataApi,
} from "../../api/DatasetApi";
import { exportExcelByData } from "../../components/excelDownload";
import GlobalLoader from "../../components/GlobalLoading";
import usePermission from "../../utils/usePermission";
import { User } from "../../types/User";
import { connect } from "react-redux";
import viewUtils from "../../utils/viewUtils";
import TableFieldsManage from "./TableFieldsManage";
import FilterForm from "./FilterForm";
import ImportData from "./ImportData";

interface VersionData extends DatasetVersion {
  table?: SheetTable;
}

type CurrentTableData = {
  fields?: TableField[];
  data?: any;
  task?: any;
};

const SalesReportPage = (props: { currentUser?: User }) => {
  const [versionData, setVersionData] = useState<VersionData | undefined>();
  const [tableData, setTableData] = useState<CurrentTableData | undefined>();
  const [filter, setFilter] = useState<any>();
  const currentTask = !!filter?.assignee
    ? versionData?.table?.tasks?.find(
        (t: Task) => t.assignee === filter?.assignee
      )
    : null;
  const originTableData: any = useRef();
  const changedTableData: any = useRef({});
  const [form] = Form.useForm();

  const permission = usePermission();

  useEffect(() => {
    refreshAllData();
  }, []);

  useEffect(() => {
    if (!!originTableData.current && !!originTableData.current?.meta?.fields) {
      let data =
        (!!currentTask
          ? (originTableData.current?.tasks || {})[`${currentTask?.id}`]?.data
          : Object.values(originTableData.current?.tasks || {}).reduce(
              (ret: any, task: any) => {
                return ret.concat(task.data || []);
              },
              []
            )) || [];
      let filterFields =
        originTableData.current?.meta?.fields?.filter(
          (f: TableField) => !!f.is_filterable
        ) || [];
      let primaryFields =
        originTableData.current?.meta?.fields?.filter(
          (f: TableField) => !!f.is_pk
        ) || [];
      if (
        filterFields.length > 0 &&
        Object.values(filter?.fields || {}).filter(
          (item: any) => item !== undefined && item !== null && item !== ""
        ).length > 0
      ) {
        data = data.filter((row: any) => {
          for (let field in filter?.fields) {
            if (
              filter?.fields[field] !== undefined &&
              filter?.fields[field] !== null &&
              filter?.fields[field] !== "" &&
              row[field] != filter?.fields[field]
            ) {
              return false;
            }
          }
          return true;
        });
      }
      let dataRet = [];
      let formData: any = {};
      for (let i = 0, len = data.length; i < len; i++) {
        let row = data[i];
        let rowKey =
          primaryFields
            .map((field: TableField) => row[`${field.identifier}`])
            .join("\t") || "";
        if (!formData[`${rowKey}`]) {
          formData[`${rowKey}`] = {};
        }
        (
          originTableData.current?.meta?.fields?.filter(
            (f: TableField) => !f.is_pk
          ) || []
        ).forEach((f: TableField) => {
          formData[`${rowKey}`][`${f.identifier}`] = row[`${f.identifier}`];
        });
        dataRet.push({
          data: row,
          key: primaryFields
            .map((field: TableField) => row[`${field.identifier}`])
            .join("\t"),
        });
      }
      form.resetFields();
      form.setFieldsValue(formData);
      changedTableData.current = {};
      setTableData({
        fields: originTableData.current?.meta?.fields || [],
        data: dataRet,
        task: currentTask,
      });
    } else {
      setTableData(undefined);
    }
  }, [filter, setTableData, form]);

  const refreshAllData = () => {
    setVersionData(undefined);
    getSalesReportApi()
      .then((res) => {
        if (res.success) {
          return res.data;
        }
      })
      .then((version) => {
        if (!!version?.id) {
          getDatasetVersionDataApi({
            versionId: Number(version?.id),
          }).then((res) => {
            if (res.success) {
              let table = (res.data || [])[0];
              if (!!table) {
                table.meta.fields = viewUtils.sortTableFields(
                  table.meta?.fields || []
                );
              }
              setVersionData({
                ...version,
                table: table,
              });
              originTableData.current = !!table
                ? {
                    ...table,
                    tasks: (table?.tasks || []).reduce(
                      (ret: any, task: Task) => {
                        ret[`${task?.id}`] = task;
                        return ret;
                      },
                      {}
                    ),
                  }
                : null;
              setFilter((data: any) => {
                let current_assignee =
                  table?.tasks?.length === 1
                    ? table?.tasks[0]?.assignee
                    : data?.assignee;
                if (
                  !table?.tasks?.find(
                    (t: Task) => t.assignee === current_assignee
                  )
                ) {
                  current_assignee = null;
                }
                if (current_assignee !== data?.assignee) {
                  return {
                    assignee: current_assignee,
                    fields: {},
                  };
                } else {
                  return { ...data };
                }
              });
              GlobalLoader.hide();
            }
          });
        }
      });
  };

  const handleDeleteTable = () => {
    //delete existed table
    deleteDatasetTableApi({
      datasetId: Number(versionData?.dataset),
      tableId: Number(versionData?.table?.id),
    }).then((res) => {
      if (res.success) {
        message.success("清空数据重置字段成功");
        refreshAllData();
      } else {
        message.error(
          viewUtils.prettifyErrorMessage(res.message) || "清空数据重置字段失败"
        );
      }
    });
  };

  const handleDeleteTask = () => {
    deleteDatasetVersionTaskApi({
      taskId: Number(currentTask?.id),
    }).then((res) => {
      if (res.success) {
        message.success(`删除数据成功`);
        refreshAllData();
      } else {
        message.error(`删除数据失败`);
      }
    });
  };

  const handleUnLockCurrentTask = () => {
    if (!!currentTask?.id) {
      updateDatasetVersionTaskApi({
        taskId: Number(currentTask.id),
        data: {
          version_id: versionData?.id,
          assignee: currentTask?.assignee,
          table_id: versionData?.table?.id,
          is_locked: false,
        },
      }).then((res) => {
        if (res.success) {
          message.success("解锁成功");
          refreshAllData();
        } else {
          message.success("解锁失败");
        }
      });
    }
  };

  const handleLockCurrentTask = () => {
    if (!!currentTask?.id) {
      updateDatasetVersionTaskApi({
        taskId: Number(currentTask.id),
        data: {
          version_id: versionData?.id,
          assignee: currentTask?.assignee,
          table_id: versionData?.table?.id,
          is_locked: true,
        },
      }).then((res) => {
        if (res.success) {
          message.success("锁定成功");
          refreshAllData();
        } else {
          message.success("锁定失败");
        }
      });
    }
  };

  const handleChangeFilter = (data: any) => {
    setFilter((origin: any) => ({
      ...data,
      assignee:
        versionData?.table?.tasks?.length === 1
          ? origin.assignee
          : data.assignee,
    }));
  };

  const handleExport = () => {
    if (!!versionData?.table) {
      exportExcelByData(
        [
          {
            ...versionData.table,
            data: tableData?.data || [],
          },
        ],
        `销售提报数据`
      );
    }
  };

  const handleSave = () => {
    if (!!currentTask?.id && Object.keys(changedTableData.current).length > 0) {
      let data = [];
      let originData = originTableData.current.tasks[currentTask?.id]?.data;
      let primaryFields =
        originTableData.current?.meta?.fields?.filter(
          (f: TableField) => !!f.is_pk
        ) || [];
      for (let i = 0, len = originData.length; i < len; i++) {
        let row = { ...originData[i] };
        let rowKey =
          primaryFields
            .map((field: TableField) => row[`${field.identifier}`])
            .join("\t") || "";
        let changed = changedTableData.current[rowKey];
        if (!!changed) {
          Object.keys(changed).forEach((f: string) => {
            row[f] = changed[f];
          });
        }
        data.push(row);
      }
      const compareDataRet = calCompareTableDataStatistics(
        {
          id: originTableData.current?.id,
          name: originTableData.current?.name,
          meta: originTableData.current?.meta,
          data: originData,
        },
        { data: data }
      );
      updateDatasetVersionTaskDataApi({
        taskId: Number(currentTask?.id),
        data: {
          data: data,
          ...(compareDataRet.delete_count > 0 ||
          compareDataRet.add_count > 0 ||
          compareDataRet.change_data.length > 0
            ? {
                log: compareDataRet,
              }
            : {}),
        },
      }).then((res) => {
        if (!!res.success) {
          message.success(`保存成功`);
          refreshAllData();
        } else {
          message.error(`保存失败`);
        }
      });
    }
  };

  const renderTableData = useMemo(() => {
    const handleFieldsChange = (data: any) => {
      data.forEach((cell: any) => {
        if (!changedTableData.current[cell.name[0]]) {
          changedTableData.current[cell.name[0]] = {};
        }
        changedTableData.current[cell.name[0]][cell.name[1]] = cell.value;
      });
    };

    return (
      <Form form={form} onFieldsChange={handleFieldsChange}>
        <Table
          size="small"
          rowKey={(r, index) => index || ""}
          scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
          columns={(tableData?.fields || []).map((field: TableField) => ({
            key: field.identifier,
            title: field.name,
            render: (row: any, value: any, index: number) =>
              !!tableData?.task &&
              !tableData?.task?.is_locked &&
              !field.is_pk ? (
                <Form.Item name={[row.key, field.identifier]} noStyle>
                  {field.type === "NUMBER" ? (
                    <InputNumber
                      min={field.validation_rules?.minimum || 0}
                      max={field.validation_rules?.maximum || 10000000}
                      style={{ minWidth: "6em" }}
                    />
                  ) : field.type === "BOOLEAN" ? (
                    <Select
                      options={[
                        { label: "是", value: true },
                        { label: "否", value: false },
                      ]}
                      style={{ minWidth: "6em" }}
                    />
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              ) : (
                (row.data || {})[`${field.identifier}`]
              ),
          }))}
          dataSource={tableData?.data || []}
          pagination={{
            pageSize: 20,
          }}
        />
      </Form>
    );
  }, [tableData]);

  if (!versionData) {
    return (
      <div className="layout-content-box">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="layout-content-box">
        {!!versionData?.table?.id && (
          <FilterForm
            table={versionData?.table}
            filter={filter}
            submitCallback={handleChangeFilter}
          />
        )}
        <div className="page-header-actions">
          {!permission?.isAdmin && <div />}
          {!!versionData?.table ? (
            <Space size={10}>
              {versionData?.table &&
                ((!!currentTask && !currentTask.is_locked) ||
                  !permission?.isAdmin) && (
                  <Button type="primary" onClick={handleLockCurrentTask}>
                    锁定
                  </Button>
                )}
              {!!permission?.isAdmin &&
                !!currentTask &&
                !!currentTask.is_locked && (
                  <Button onClick={handleUnLockCurrentTask}>解锁</Button>
                )}
              {((!!currentTask && !currentTask.is_locked) ||
                !permission?.isAdmin) && (
                <>
                  <Button onClick={handleSave}>保存</Button>
                  <Button
                    onClick={() => setFilter((data: any) => ({ ...data }))}
                  >
                    重置数据
                  </Button>
                </>
              )}
              {!!permission?.isAdmin &&
                !!currentTask &&
                !currentTask.is_locked && (
                  <Button danger onClick={handleDeleteTask}>
                    删除数据
                  </Button>
                )}
              {!!permission?.isAdmin && (
                <ImportData
                  version={versionData}
                  table={versionData?.table}
                  refreshCallback={refreshAllData}
                />
              )}
              <Button onClick={handleExport}>导出</Button>
            </Space>
          ) : (
            <div />
          )}
          {!!permission?.isAdmin && (
            <Space size={10}>
              {!!versionData?.table?.id && (
                <Button type="primary" danger onClick={handleDeleteTable}>
                  清空重置字段
                </Button>
              )}
              <TableFieldsManage
                dataset={versionData?.dataset_data}
                table={versionData.table}
                refreshCallback={refreshAllData}
              />
            </Space>
          )}
        </div>
        {renderTableData}
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentUser: store.account.user,
});

export default connect(mapStateToProps, {})(SalesReportPage);
