import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import {
  ExpandAltOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Card,
  Space,
  Button,
  Dropdown,
  Menu,
  Empty,
  Pagination,
  message,
  Modal,
  Row,
  Col,
} from "antd";
import ChartViewComponent from "../../components/chart";
import { ChartView } from "../../types/ChartView";
import { getAllDatasetVersionChartViewsApi } from "../../api/DatasetApi";
import { connect } from "react-redux";
import {
  addDashboardItemApi,
  deleteDashboardItemApi,
  getDashboardItemsApi,
} from "../../api/DashboardApi";
import { DashboardItem, DashboardItemType } from "../../types/Dashboard";
import DatasetChartFormModal from "../Dataset/DatasetChartFormModal";
import { Workspace } from "../../types/Workspace";

const DashboardPage = (props: { currentWorkspace: Workspace }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ page: 1, page_size: 9 });
  const [dashboardItems, setDashboardItems] = useState<{
    data: DashboardItem[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const chartViewModalRef: any = useRef();
  const addChartViewModalRef: any = useRef();

  useEffect(() => {
    getDashboardItemsApi(filter).then((res) => {
      if (res.success) {
        setDashboardItems({
          data: res.data,
          total: res.pagination?.count || 0,
        });
      }
    });
  }, [props.currentWorkspace, filter]);

  const handlePageChange = (page: number) =>
    setFilter({ ...filter, page: page });

  const handleAdd = () => {
    addChartViewModalRef.current.show(true);
  };

  const handleEdit = (chartView: ChartView) => {
    chartViewModalRef?.current?.show(chartView);
  };

  const handleDelete = (dashboardItem: DashboardItem) => {
    Modal.confirm({
      title: t("dashboard.tips.remove"),
      onOk() {
        if (!dashboardItem?.id) {
          return;
        }
        deleteDashboardItemApi({
          id: dashboardItem?.id,
        }).then((res) => {
          if (res.success) {
            message.success(
              t("chart_view.tip.delete", { status: t("common.success") })
            );
            setFilter((data) => ({ ...data }));
          } else {
            message.error(
              t("chart_view.tip.delete", { status: t("common.fail") })
            );
          }
        });
      },
    });
  };

  const handleAddedChart = () => {
    setFilter({ ...filter });
  };

  const FullScreenButton = () => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <Modal
          open={open}
          className="full-screen-modal"
          onCancel={() => setOpen(false)}
          footer={null}
        >
          <Content
            is_full_screen={true}
            dashboardItems={dashboardItems}
            handleAdd={handleAdd}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Modal>
        <Button
          type="link"
          onClick={() => {
            setOpen(true);
          }}
        >
          {t("dashboard.full_screen")}
        </Button>
      </>
    );
  };

  return (
    <>
      <DatasetChartFormModal
        ref={chartViewModalRef}
        callback={() => {
          setFilter((data) => ({ ...data }));
        }}
      />
      <AddChartModal ref={addChartViewModalRef} onCancel={handleAddedChart} />
      <NavHyperLink items={[{ title: t("dashboard.title") }]} />
      <div className="layout-content-box" style={{ paddingTop: "1em" }}>
        {(dashboardItems.data || []).length > 0 && (
          <div style={{ textAlign: "end" }}>
            <Button type="link" onClick={handleAdd}>
              {t("dashboard.add_chart")}
            </Button>
            <FullScreenButton />
          </div>
        )}
        <Content
          is_full_screen={false}
          dashboardItems={dashboardItems}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage={!dashboardItems.data?.length}
          showTotal={(total) => t("common.total", { count: total })}
          current={filter.page}
          pageSize={filter.page_size}
          total={dashboardItems.total}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{
            textAlign: "right",
            justifyContent: "flex-end",
            margin: "1em 0",
          }}
        />
      </div>
    </>
  );
};

const Content = (props: {
  is_full_screen: boolean;
  dashboardItems: {
    data: DashboardItem[];
    total: number;
  };
  handleAdd: () => void;
  handleEdit: (chartView: ChartView) => void;
  handleDelete: (dashboardItem: DashboardItem) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        {props.dashboardItems.data.length === 0 ? (
          <div
            style={{
              width: "10em",
              height: "10em",
              background: "rgba(0, 0, 0, 0.02)",
              border: "1px dashed #d9d9d9",
              borderRadius: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={props.handleAdd}
          >
            <button style={{ border: 0, background: "none" }} type="button">
              <PlusOutlined style={{ fontSize: 16 }} />
              <div style={{ marginTop: 8 }}>{t("dashboard.add_chart")}</div>
            </button>
          </div>
        ) : (
          <Row gutter={16}>
            {props.dashboardItems.data.map((item) => {
              const chartView = item.dataset_version_chart_view;
              if (!chartView) {
                return <></>;
              }
              return (
                <Col key={item.id} span={8} style={{ marginBottom: 8 }}>
                  <Card
                    size="small"
                    title={chartView.name}
                    headStyle={{ border: "none", padding: "8px 12px" }}
                    extra={
                      <Space size={0}>
                        <Button
                          type="text"
                          icon={<ExpandAltOutlined />}
                          onClick={() => props.handleEdit(chartView)}
                        />
                        <Dropdown
                          dropdownRender={() => (
                            <Menu
                              className="chart-actions"
                              items={[
                                {
                                  danger: true,
                                  label: t("dashboard.remove"),
                                  key: "delete",
                                  onClick: () => props.handleDelete(item),
                                },
                                {
                                  label: t("common.edit"),
                                  key: "edit",
                                  onClick: () => props.handleEdit(chartView),
                                },
                              ]}
                            />
                          )}
                          placement="bottomRight"
                        >
                          <Button type="text" icon={<EllipsisOutlined />} />
                        </Dropdown>
                      </Space>
                    }
                  >
                    <ChartViewComponent
                      id={`${props.is_full_screen}-chart-list-${chartView.id}`}
                      chart_view={chartView}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
};

const AddChartModal = forwardRef((props: { onCancel: () => void }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({ page: 1, page_size: 9 });
  const [charts, setCharts] = useState<{ data: ChartView[]; total: number }>({
    data: [],
    total: 0,
  });

  useImperativeHandle(ref, () => ({
    show: () => {
      setFilter({ ...filter });
      setOpen(true);
    },
  }));

  useEffect(() => {
    getAllDatasetVersionChartViewsApi(filter).then((res) => {
      if (res.success) {
        setCharts({
          data: res.data,
          total: res.pagination?.count || 0,
        });
      }
    });
  }, [filter]);

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page: page });
  };

  const handleClickAdd = (chartView: ChartView) => {
    if (!chartView.id) {
      return;
    }
    addDashboardItemApi({
      dataset_version_chart_view_id: Number(chartView.id),
      type: DashboardItemType.CHART,
    }).then((res) => {
      if (res.success) {
        setFilter({ ...filter });
      }
    });
  };

  const handleCancel = () => {
    setOpen(false);
    props.onCancel();
  };

  return (
    <Modal
      title={t("dashboard.add_chart")}
      open={open}
      width={"80em"}
      onCancel={handleCancel}
      footer={null}
    >
      {charts.data.length === 0 ? (
        <Empty />
      ) : (
        <>
          <Row gutter={16}>
            {charts.data.map((item) => {
              return (
                <Col key={item.id} span={8}>
                  <div
                    style={{
                      height: "20em",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ height: "14em" }}>
                      <ChartViewComponent
                        id={`add-chart-list-${item.id}`}
                        chart_view={item}
                      />
                    </div>
                    <div
                      style={{
                        maxWidth: "100%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        maxWidth: "100%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {`${t("dashboard.origin", {
                        dataset_name: item.version_data?.dataset_data?.name,
                      })}`}
                    </div>
                    <Button
                      style={{ marginTop: 5 }}
                      disabled={item.in_dashboard}
                      type="primary"
                      onClick={() => handleClickAdd(item)}
                    >
                      {t("dashboard.add")}
                    </Button>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Pagination
            hideOnSinglePage={!charts.data?.length}
            current={filter.page}
            pageSize={filter.page_size}
            total={charts.total}
            onChange={handlePageChange}
            style={{
              textAlign: "center",
              justifyContent: "center",
              margin: "1em 0",
            }}
          />
        </>
      )}
    </Modal>
  );
});

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(DashboardPage);
