// @ts-nocheck
import React, { useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Select,
  Switch,
  InputNumber,
  Collapse,
  Slider,
  Button,
} from "antd";
import { ChartView } from "../../../types/ChartView";
import useWindowSize from "../../../utils/useWindowSize";
import * as d3 from "d3";
import ChinaGeoJson from "../../../common/data/ChinaGeoJson";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

type Props = {
  id: string;
  chart_view: any;
  table_data: any;
};

const D3LocationRelationship = (props: Props) => {
  const windowSize = useWindowSize({
    id: `d3-location-relationship-${props.id}`,
  });
  const TipTextSize = 12;
  const MapLineWidth = 1;
  const MapLableTextSize = 8;
  const tooltipRef = useRef();

  const drawChart = useCallback(() => {
    const svg = d3
      .select(`#d3-location-relationship-${props.id}`)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%");
    const zoom = d3
      .zoom()
      .scaleExtent([1, 8])
      .on("zoom", (e) => {
        const { transform } = e;
        g.attr("transform", transform);
        mapG.attr("stroke-width", MapLineWidth / transform.k);
        labelG.style("font-size", MapLableTextSize / transform.k);
        lineG.attr(
          "stroke-width",
          (props.chart_view?.chart_config?.line_width || 1) / transform.k
        );
        d3.selectAll(".sub-points circle").attr(
          "r",
          (d) => (props.chart_view?.chart_config?.sub_size || 2) / transform.k
        );
        d3.selectAll(".main-points circle").attr(
          "r",
          (d) => (props.chart_view?.chart_config?.main_size || 5) / transform.k
        );
      });
    const g = svg.append("g");
    let rootSize = {
      width: svg.node().getBoundingClientRect().width,
      height: svg.node().getBoundingClientRect().height,
    };
    const projection = d3.geoMercator().fitExtent(
      [
        [0, 0],
        [rootSize.width, rootSize.height],
      ],
      ChinaGeoJson
    );
    const pathGenerator = d3.geoPath().projection(projection);
    const mapG = g
      .append("g")
      .attr("stroke-width", MapLineWidth)
      .selectAll("path")
      .data(ChinaGeoJson.features)
      .join("path")
      .attr("d", pathGenerator)
      .attr("stroke", "#666666")
      .attr("fill", "#ffffff");

    const labelG = g
      .append("g")
      .style("font-size", MapLableTextSize)
      .selectAll("text")
      .data(ChinaGeoJson.features)
      .join("text")
      .style("fill", "rgba(0,0,0,0.65)")
      .style("text-anchor", "middle")
      .style("dominant-baseline", "middle")
      .attr("x", (d) => d3.geoPath().projection(projection).centroid(d)[0])
      .attr("y", (d) => d3.geoPath().projection(projection).centroid(d)[1])
      .text((d) => d.properties.name);

    let lineData = [];
    let mainScatterData = {};
    let subScatterData = {};
    for (
      let i = 0, len = props.table_data?.filtered_data?.length || 0;
      i < len;
      i++
    ) {
      let rowData = (props.table_data?.filtered_data || [])[i];
      const main_longitude =
        rowData[props.chart_view?.chart_config?.main_longitude];
      const main_latitude =
        rowData[props.chart_view?.chart_config?.main_latitude];
      const sub_longitude =
        rowData[props.chart_view?.chart_config?.sub_longitude];
      const sub_latitude =
        rowData[props.chart_view?.chart_config?.sub_latitude];
      if (
        !!main_longitude &&
        !!main_latitude &&
        !!sub_longitude &&
        !!sub_latitude
      ) {
        lineData.push({
          start: projection([sub_longitude, sub_latitude]),
          end: projection([main_longitude, main_latitude]),
        });
      }
      if (!!main_longitude && !!main_latitude) {
        const main_lnglat = `${main_longitude},${main_latitude}`;
        if (!mainScatterData[main_lnglat]) {
          mainScatterData[main_lnglat] = {
            point: projection([main_longitude, main_latitude]),
            name: rowData[props.chart_view?.chart_config?.main_name],
          };
        }
      }
      if (!!sub_longitude && !!sub_latitude) {
        const sub_lnglat = `${sub_longitude},${sub_latitude}`;
        if (!subScatterData[sub_lnglat]) {
          subScatterData[sub_lnglat] = {
            point: projection([sub_longitude, sub_latitude]),
            name: rowData[props.chart_view?.chart_config?.sub_name],
          };
        }
      }
    }

    const lineG = g
      .append("g")
      .attr("stroke-width", props.chart_view?.chart_config?.line_width || 1)
      .style("opacity", props.chart_view?.chart_config?.opacity || 0)
      .selectAll("line")
      .data(lineData)
      .join("line")
      .attr("x1", (d) => d.start[0])
      .attr("x2", (d) => d.end[0])
      .attr("y1", (d) => d.start[1])
      .attr("y2", (d) => d.end[1])
      .style("stroke", (d) => `rgba(100,100,100,0.7)`);
    g.append("g")
      .attr("class", "sub-points")
      .style("opacity", props.chart_view?.chart_config?.opacity || 0)
      .selectAll("circle")
      .data(Object.values(subScatterData))
      .join("circle")
      .attr("cx", (d) => d.point[0])
      .attr("cy", (d) => d.point[1])
      .attr("r", (d) => props.chart_view?.chart_config?.sub_size || 2)
      .attr("fill", (d) => `rgba(100,94,249,1)`)
      .on("mouseover", function (e, d) {
        if (
          !!tooltipRef.current &&
          props.chart_view?.chart_config?.show_tip &&
          props.chart_view?.chart_config?.sub_name
        ) {
          tooltipRef.current.style.top = `${e.pageY + 10}px`;
          tooltipRef.current.style.left = `${e.pageX + 10}px`;
          tooltipRef.current.innerHTML = `${d.name}`;
          tooltipRef.current.style.visibility = "visible";
          d3.select(e.toElement)
            .transition()
            .attr("opacity", 0.5)
            .duration(200);
        }
      })
      .on("mousemove", function (e, d) {
        if (
          !!tooltipRef.current &&
          props.chart_view?.chart_config?.show_tip &&
          props.chart_view?.chart_config?.sub_name
        ) {
          tooltipRef.current.style.top = `${e.pageY + 10}px`;
          tooltipRef.current.style.left = `${e.pageX + 10}px`;
        }
      })
      .on("mouseout", function (e, d) {
        if (
          !!tooltipRef.current &&
          props.chart_view?.chart_config?.show_tip &&
          props.chart_view?.chart_config?.sub_name
        ) {
          tooltipRef.current.style.visibility = "hidden";
          d3.select(e.fromElement)
            .transition()
            .attr("opacity", 1)
            .duration(200);
        }
      });
    g.append("g")
      .attr("class", "main-points")
      .style("opacity", props.chart_view?.chart_config?.opacity || 0)
      .selectAll("circle")
      .data(Object.values(mainScatterData))
      .join("circle")
      .attr("cx", (d) => d.point[0])
      .attr("cy", (d) => d.point[1])
      .attr("r", (d) => props.chart_view?.chart_config?.main_size || 5)
      .attr("fill", (d) => `rgba(255,0,0,1)`)
      .on("mouseover", function (e, d) {
        if (
          !!tooltipRef.current &&
          props.chart_view?.chart_config?.show_tip &&
          props.chart_view?.chart_config?.main_name
        ) {
          tooltipRef.current.style.top = `${e.pageY + 10}px`;
          tooltipRef.current.style.left = `${e.pageX + 10}px`;
          tooltipRef.current.innerHTML = `${d.name}`;
          tooltipRef.current.style.visibility = "visible";
          d3.select(e.toElement)
            .transition()
            .attr("opacity", 0.5)
            .duration(200);
        }
      })
      .on("mousemove", function (e, d) {
        if (
          !!tooltipRef.current &&
          props.chart_view?.chart_config?.show_tip &&
          props.chart_view?.chart_config?.main_name
        ) {
          tooltipRef.current.style.top = `${e.pageY + 10}px`;
          tooltipRef.current.style.left = `${e.pageX + 10}px`;
        }
      })
      .on("mouseout", function (e, d) {
        if (
          !!tooltipRef.current &&
          props.chart_view?.chart_config?.show_tip &&
          props.chart_view?.chart_config?.main_name
        ) {
          tooltipRef.current.style.visibility = "hidden";
          d3.select(e.fromElement)
            .transition()
            .attr("opacity", 1)
            .duration(200);
        }
      });

    d3.select(`#d3-location-relationship-${props.id}-zoomin`).on(
      "click",
      (e) => {
        zoom.scaleBy(svg, 1.6);
      }
    );
    d3.select(`#d3-location-relationship-${props.id}-zoomout`).on(
      "click",
      (e) => {
        zoom.scaleBy(svg, 0.625);
      }
    );
    svg.call(zoom);
  }, [props]);

  useEffect(() => {
    document.getElementById(`d3-location-relationship-${props.id}`).innerHTML =
      "";
    if (
      !props.chart_view?.chart_config?.main_latitude ||
      !props.chart_view?.chart_config?.main_longitude ||
      !props.chart_view?.chart_config?.sub_latitude ||
      !props.chart_view?.chart_config?.sub_longitude ||
      !props.table_data ||
      !props.table_data?.filtered_data
    ) {
      return;
    }
    (async () => {
      drawChart();
    })();
  }, [props, drawChart, windowSize]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        id={`d3-location-relationship-${props.id}`}
        style={{ width: "100%", height: "100%" }}
      ></div>
      <div
        ref={tooltipRef}
        style={{
          position: "fixed",
          background: "white",
          border: "1px solid #f0f0f0",
          boxShadow: "1px 1px 7px 1px rgba(0,0,0,0.1)",
          padding: `${TipTextSize / 2}px ${TipTextSize}px`,
          visibility: "hidden",
          zIndex: 99999,
          fontSize: TipTextSize,
        }}
      />
      <div
        style={{
          position: "absolute",
          background: "white",
          border: "1px solid #f0f0f0",
          boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.2)",
          bottom: 10,
          right: 10,
          display: "flex",
          flexDirection: "column",
          borderRadius: 4,
        }}
      >
        <Button
          id={`d3-location-relationship-${props.id}-zoomin`}
          type="text"
          style={{ borderRadius: "4px 4px 0 0" }}
          icon={<PlusOutlined />}
        />
        <div style={{ width: "100%", height: 1, background: "#f0f0f0" }} />
        <Button
          id={`d3-location-relationship-${props.id}-zoomout`}
          type="text"
          style={{ borderRadius: "0 0 4px 4px" }}
          icon={<MinusOutlined />}
        />
      </div>
    </div>
  );
};

export default D3LocationRelationship;

export const D3LocationRelationshipConfigForm = (props: {
  chart_view?: ChartView;
  fields: any;
  form: any;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("chart_view.config.location_relationship.main_longitude")}
        name={["chart_config", "main_longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.location_relationship.main_latitude")}
        name={["chart_config", "main_latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.location_relationship.sub_longitude")}
        name={["chart_config", "sub_longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.location_relationship.sub_latitude")}
        name={["chart_config", "sub_latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.show_tip !== curValues?.show_tip
        }
      >
        {() => {
          if (
            !props.form ||
            !props.form.getFieldValue("chart_config")?.show_tip
          ) {
            return <></>;
          }
          return (
            <>
              <Form.Item
                label={t("chart_view.config.location_relationship.main_name")}
                name={["chart_config", "main_name"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  options={props.fields
                    ?.filter((item) => item.type === "TEXT")
                    ?.map((item) => ({
                      label: item.name,
                      value: item.identifier,
                    }))}
                />
              </Form.Item>
              <Form.Item
                label={t("chart_view.config.location_relationship.sub_name")}
                name={["chart_config", "sub_name"]}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  options={props.fields
                    ?.filter((item) => item.type === "TEXT")
                    ?.map((item) => ({
                      label: item.name,
                      value: item.identifier,
                    }))}
                />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{ padding: 0, background: "white" }}
      >
        <Panel
          header={t("chart_view.more_settings")}
          key="more_settings"
          forceRender
        >
          <Form.Item
            label={t("chart_view.config.location_relationship.opacity")}
            name={["chart_config", "opacity"]}
          >
            <Slider min={0} max={1} step={0.1} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.show_tip")}
            name={["chart_config", "show_tip"]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.main_size")}
            name={["chart_config", "main_size"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.sub_size")}
            name={["chart_config", "sub_size"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.location_relationship.line_width")}
            name={["chart_config", "line_width"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Panel>
      </Collapse>
    </>
  );
};
