import React, { useState, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Space,
  Table,
  Input,
  message,
  Modal,
  Typography,
  Tooltip,
  Pagination,
  Image,
} from "antd";
import { Role, Table as TableType } from "../../types/Table";
import type { ColumnsType } from "antd/es/table";
import {
  deleteTableApi,
  updateTableApi,
  getTableListApi,
} from "../../api/TableApi";
import viewUtils from "../../utils/viewUtils";
import usePermission from "../../utils/usePermission";
import HeaderSearchIcon from "../../common/images/icons/header-search-icon.png";
import EditCell from "../../components/EditCell";
import { User } from "../../types/User";
import { connect } from "react-redux";

const TablePage = (props: { currentUser?: User }) => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const navigate = useNavigate();
  const [tables, setTables] = useState<{ data: TableType[]; total: number }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
  });
  const permission = usePermission();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFilter((data) => ({ ...data, search: e.target.value, page: 1 }));

  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  useEffect(() => {
    if (!!permission.username) {
      getTableListApi({
        tableType: "PRIMARY",
        query: filter.search,
        page: filter.page,
        page_size: filter.page_size,
        ordering: "-created_at",
      }).then((res) => {
        if (res.success) {
          setTables({
            data: res.data,
            total: res.pagination?.count || 0,
          });
        }
      });
    }
  }, [filter, permission]);

  const handleEditTable = (data: TableType) => {
    navigate(`${IsSimpleLayout ? "/simple" : ""}/table/info/${data.id}`);
  };

  const handleCloneTable = (data: TableType) => {
    Modal.confirm({
      title: t("table.tips.confirm_copy"),
      content: t("table.tips.confirm_copy_desc"),
      centered: true,
      async onOk() {
        navigate(`${IsSimpleLayout ? "/simple" : ""}/table/create/${data.id}`);
      },
    });
  };

  const handleDeleteTable = (data: TableType) => {
    Modal.confirm({
      title: t("table.tips.confirm_delete"),
      content: (
        <Trans
          i18nKey="table.tips.confirm_delete_desc"
          values={{
            table_name: data.name,
          }}
          components={{
            p: <p />,
          }}
        />
      ),
      centered: true,
      async onOk() {
        if (!data.id) {
          message.error(t("table.tips.delete_fail"));
          return;
        }
        deleteTableApi({ tableId: data.id! }).then((res) => {
          if (res.success) {
            message.success(t("table.tips.delete_success"));
            setFilter((prevState) => ({ ...prevState }));
          } else {
            let description = t("common.result_status", {
              title: t("common.delete"),
              status: t("common.fail"),
            });
            if (
              JSON.stringify(res.errors).includes(
                "Table is referenced by other tables"
              )
            ) {
              description = t("table.tips.is_referenced", {
                content: res.errors?.tables
                  ?.map((table: any) => `【${table.dataset}-${table.table}】`)
                  .join("、"),
              });
            } else if (JSON.stringify(res.errors).includes("Table has views")) {
              description = t("table.tips.has_views", {
                content: res.errors?.datasets
                  ?.map((name: string) => `【${name}】`)
                  .join("、"),
              });
            }
            message.error(description);
          }
        });
      },
    });
  };

  const handleCellSave = (table: TableType, value: string) => {
    updateTableApi({
      tableId: table.id!,
      data: { ...table, name: value },
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.update"),
            status: t("common.success"),
          })
        );
        setFilter((prevState) => ({ ...prevState }));
      } else {
        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const columns: ColumnsType<TableType> = [
    {
      key: "name",
      title: t("table.name"),
      width: 300,
      render: (r) => (
        <EditCell
          object={r}
          valueField={"name"}
          maxLength={50}
          isLink={true}
          disabled={r.owner !== props.currentUser?.username}
          handleCellSave={(value) => handleCellSave(r, value)}
        >
          <Typography.Paragraph
            className="table-link"
            ellipsis={{
              rows: 1,
              tooltip: {
                title: r.name,
                placement: "bottomLeft",
                overlayClassName: "description_tooltip",
              },
            }}
            style={{
              marginBottom: 0,
            }}
          >
            <NavLink
              to={`${IsSimpleLayout ? "/simple" : ""}/table/data/${r?.id}`}
            >
              {r.name}
            </NavLink>
          </Typography.Paragraph>
        </EditCell>
      ),
    },
    {
      key: "description",
      title: t("common.description"),
      width: 300,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: {
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          style={{ marginBottom: 0 }}
        >
          {r.description}
        </Typography.Paragraph>
      ),
    },
    {
      key: "created_at",
      title: t("common.created_at"),
      dataIndex: "created_at",
      width: 180,
      render: (date: string) => viewUtils.prettifyDatatime(date),
    },
    {
      key: "updated_at",
      title: t("common.updated_at"),
      width: 180,
      dataIndex: "updated_at",
      render: (date: string) => viewUtils.prettifyDatatime(date),
    },
    {
      key: "owner",
      title: t("common.owner"),
      width: 120,
      render: (r) => (
        <Tooltip title={r.owner_user?.username}>
          {viewUtils.prettifyUsername(r.owner_user?.name)}
        </Tooltip>
      ),
    },
    {
      key: "actions",
      title: t("common.actions"),
      fixed: "right",
      width: 230,
      render: (d: TableType) => (
        <>
          <NavLink
            to={`${IsSimpleLayout ? "/simple" : ""}/table/data/${d?.id}`}
          >
            <Button size="small" type="link">
              {t("common.info")}
            </Button>
          </NavLink>
          {d.role === Role.ADMIN && (
            <Button size="small" type="link" onClick={() => handleEditTable(d)}>
              {t("common.edit")}
            </Button>
          )}
          {d.role === Role.ADMIN && (
            <Button
              size="small"
              type="link"
              onClick={() => handleCloneTable(d)}
            >
              {t("common.copy")}
            </Button>
          )}
          {d.role === Role.ADMIN && (
            <Button
              size="small"
              type="link"
              onClick={() => handleDeleteTable(d)}
            >
              {t("common.delete")}
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <NavHyperLink items={[{ title: t("table.title") }]} />
      <div className="layout-content-box">
        <Space size={10} className="page-header-actions">
          <div>
            <Input
              value={filter?.search}
              className="list-search-input"
              placeholder={`${t("table.name")}、${t("common.owner")}`}
              onChange={handleSearch}
              suffix={
                <Image
                  src={HeaderSearchIcon}
                  preview={false}
                  style={{ width: 15, height: 15 }}
                />
              }
            />
          </div>
          <Space size={10}>
            {/*<Button disabled={true} onClick={handleImport}>
              导入表格
            </Button>*/}
            <NavLink to={`${IsSimpleLayout ? "/simple" : ""}/table/create`}>
              <Button type="primary">
                {t("common.add_with_info", { title: t("table.table") })}
              </Button>
            </NavLink>
          </Space>
        </Space>
        <div>
          <Table
            tableLayout="fixed"
            className="common-table"
            rowKey={(r) => r?.id || ""}
            scroll={{ x: 1310, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={tables.data}
            pagination={false}
          />
          <Pagination
            showQuickJumper
            hideOnSinglePage={!tables.data?.length}
            showTotal={(total) => t("common.total", { count: total })}
            current={filter.page}
            pageSize={filter.page_size}
            total={tables.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={[10, 20, 50]}
            style={{
              textAlign: "right",
              justifyContent: "flex-end",
              marginTop: "1em",
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentUser: store.account.user,
});

export default connect(mapStateToProps, {})(TablePage);
