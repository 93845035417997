import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
  Tree,
  message,
  Table,
  Space,
  Tag,
  Tooltip,
  Form,
  Select,
  Tabs,
  Radio,
} from "antd";
import type { TabsProps } from "antd";
import type { DirectoryTreeProps } from "antd/es/tree";
import { useNavigate } from "react-router-dom";
import viewUtils from "../../utils/viewUtils";
import {
  getCurrentWorkspaceDatasetTemplateListApi,
  createDatasetByTemplateApi,
} from "../../api/DatasetTemplateApi";
import { getTableListApi } from "../../api/TableApi";
import {
  DatasetTemplate,
  DatasetTemplateTable,
  DatasetTemplateTableField,
} from "../../types/DatasetTemplate";
import {
  TableFieldType,
  fieldTypeToTitle,
  TableFilterItem,
  Table as TableType,
} from "../../types/Table";
import usePermission from "../../utils/usePermission";
import { InfoCircleOutlined } from "@ant-design/icons";

const DatasetTemplateInfoModal = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const [showItem, setShowItem] = useState<DatasetTemplate | undefined>();
  const [selectedKey, setSelectedKey] = useState<[string, number]>([
    "tables",
    0,
  ]);

  useImperativeHandle(ref, () => ({
    show: (template: DatasetTemplate) => {
      setShowItem(template);
      setSelectedKey(["tables", 0]);
    },
  }));

  const closeModal = () => {
    setShowItem(undefined);
  };

  const onSelect: DirectoryTreeProps["onSelect"] = (keys, info) => {
    if (keys?.length > 0) {
      if (!["base_tables", "tables"].includes(`${keys[0]}`)) {
        const [type, index] = `${keys[0]}`.split("-");
        setSelectedKey([type, parseInt(index)]);
      }
    }
  };

  const TableFieldsList = () => {
    let currentTable: DatasetTemplateTable | undefined = ((selectedKey[0] ===
    "base_tables"
      ? showItem?.manifest?.base_tables
      : showItem?.manifest?.tables) || [])[selectedKey[1]];
    let filtersText = "";
    if (currentTable?.table_type === "TABLEVIEW") {
      let originBaseTable = showItem?.manifest?.base_tables?.find(
        (t: DatasetTemplateTable) => t.temp_id === currentTable?.parent_table
      );
      if (!!originBaseTable) {
        currentTable.name = originBaseTable.name;
        currentTable.description = originBaseTable.name;
        currentTable.fields =
          originBaseTable?.fields?.filter((f: DatasetTemplateTableField) =>
            currentTable?.columns?.includes(f.temp_id || "")
          ) || [];
      }
      if ((currentTable?.filters?.items || []).length > 0) {
        filtersText = `${t("dataset.version_info.count_filters", {
          count: currentTable?.filters?.items?.length,
        })}: `;
        filtersText += currentTable?.filters?.items
          ?.map((f: TableFilterItem) => {
            let OriginField = originBaseTable?.fields?.find(
              (field: DatasetTemplateTableField) => field.temp_id === f.field
            );
            let str = ["("];
            str.push(
              viewUtils.prettifyTextWithLocale(
                OriginField?.name,
                i18n.resolvedLanguage
              )
            );
            str.push(
              t(
                `table_filter.${
                  f.type === "="
                    ? "equal"
                    : f.type === "!="
                    ? "unequal"
                    : f.type === "INCLUDE"
                    ? "include"
                    : f.type === "EXCEPT"
                    ? "except"
                    : f.type === "NULL"
                    ? "null"
                    : f.type === "NOT NULL"
                    ? "not_null"
                    : f.type === "DUPLICATE"
                    ? "duplicate"
                    : f.type === ">"
                    ? OriginField?.type === "DATE"
                      ? "later_than"
                      : "greater_than"
                    : f.type === ">="
                    ? OriginField?.type === "DATE"
                      ? "later_than_or_equal_to"
                      : "greater_than_or_equal_to"
                    : f.type === "<"
                    ? OriginField?.type === "DATE"
                      ? "earlier_than"
                      : "less_than"
                    : f.type === "<="
                    ? OriginField?.type === "DATE"
                      ? "earlier_than_or_equal_to"
                      : "less_than_or_equal_to"
                    : "unknow"
                }`
              )
            );
            str.push(f.value);
            str.push(")");
            return str.join(" ");
          })
          .join(
            ` ${t(
              `table_filter.${
                currentTable?.filters?.type === "OR" ? "or" : "and"
              }`
            )} `
          );
      }
    }

    return (
      <>
        <Table
          size="small"
          rowKey={(r) => r?.temp_id || ""}
          scroll={{ x: 400, scrollToFirstRowOnChange: true }}
          pagination={false}
          columns={[
            {
              key: "name",
              title: t("table.field.name"),
              render: (d: DatasetTemplateTableField) => (
                <Space
                  size={[5, 0]}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {viewUtils.prettifyTextWithLocale(
                    d.name,
                    i18n.resolvedLanguage
                  )}
                  {!!d.readonly && (
                    <Tag color="red">{t("table.field.readonly")}</Tag>
                  )}
                </Space>
              ),
            },
            {
              key: "identifier",
              title: t("table.field.identifier"),
              dataIndex: "identifier",
            },
            {
              key: "type",
              title: t("table.field.type"),
              render: (d: DatasetTemplateTableField) => {
                if (d?.type === "REFERENCE") {
                  const fk_table = showItem?.manifest?.base_tables?.find(
                    (table: DatasetTemplateTable) => table.temp_id === d.fk_to
                  );
                  return (
                    <Space>
                      {i18n.resolvedLanguage === "zh"
                        ? fieldTypeToTitle[d?.type as TableFieldType]
                        : d?.type || ""}
                      <Tooltip
                        title={
                          <>
                            <p>
                              {t("table.field.reference_table")}：
                              {viewUtils.prettifyTextWithLocale(
                                fk_table?.name,
                                i18n.resolvedLanguage
                              )}
                            </p>
                            <p>
                              {t("table.field.reference_field")}：
                              {viewUtils.prettifyTextWithLocale(
                                (
                                  fk_table?.fields?.find(
                                    (field: DatasetTemplateTableField) =>
                                      field.temp_id === d?.fk_field
                                  ) || {}
                                ).name,
                                i18n.resolvedLanguage
                              ) || d?.fk_field}
                            </p>
                            <p>
                              {t("table.field.reference_field_display")}：
                              {viewUtils.prettifyTextWithLocale(
                                (
                                  fk_table?.fields?.find(
                                    (field: DatasetTemplateTableField) =>
                                      field.temp_id === d?.fk_literal
                                  ) || {}
                                ).name,
                                i18n.resolvedLanguage
                              ) || d?.fk_literal}
                            </p>
                          </>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  );
                } else {
                  return (
                    <Space>
                      {i18n.resolvedLanguage === "zh"
                        ? fieldTypeToTitle[d?.type as TableFieldType]
                        : d?.type || ""}
                    </Space>
                  );
                }
              },
            },
            {
              title: t("table.field.is_pk"),
              dataIndex: "is_pk",
              key: "is_pk",
              render: (record: any) =>
                record ? t("common.yes") : t("common.no"),
            },
            {
              title: t("table.field.is_unique"),
              dataIndex: "is_unique",
              key: "is_unique",
              render: (record: any) =>
                record ? t("common.yes") : t("common.no"),
            },
            {
              title: t("table.field.is_nullable"),
              dataIndex: "is_nullable",
              key: "is_nullable",
              render: (record: any) =>
                record ? t("common.yes") : t("common.no"),
            },
          ]}
          dataSource={currentTable?.fields || []}
        />
        {!!filtersText && <div style={{ marginTop: "1em" }}>{filtersText}</div>}
      </>
    );
  };

  return (
    <Modal
      centered
      width={1000}
      title={
        !!showItem
          ? viewUtils.prettifyTextWithLocale(
              showItem?.manifest?.title,
              i18n.resolvedLanguage
            )
          : ""
      }
      open={!!showItem}
      onCancel={closeModal}
      footer={null}
    >
      <div style={{ display: "flex", margin: "2em 0", minHeight: "50vh" }}>
        <div style={{ minWidth: "15em", maxWidth: "50%" }}>
          <Tree
            className="template-view-tree"
            showLine={true}
            defaultExpandAll
            onSelect={onSelect}
            selectedKeys={[selectedKey.join("-")]}
            treeData={[
              ...((showItem?.manifest?.base_tables || []).length > 0
                ? [
                    {
                      title: t("dataset.template_import.base_tables"),
                      key: "base_tables",
                      children: (showItem?.manifest?.base_tables || []).map(
                        (table: DatasetTemplateTable, index: number) => ({
                          title: viewUtils.prettifyTextWithLocale(
                            table?.name,
                            i18n.resolvedLanguage
                          ),
                          key: `base_tables-${index}`,
                        })
                      ),
                    },
                  ]
                : []),
              {
                title: t("dataset.template_import.all_tables"),
                key: "tables",
                children: (showItem?.manifest?.tables || []).map(
                  (table: DatasetTemplateTable, index: number) => ({
                    title: `${
                      table.table_type === "TABLEVIEW"
                        ? `(${t("dataset.template_import.base_table")})`
                        : ""
                    }${viewUtils.prettifyTextWithLocale(
                      table.table_type === "TABLEVIEW"
                        ? showItem?.manifest?.base_tables?.find(
                            (t: DatasetTemplateTable) =>
                              t.temp_id === table.parent_table
                          )?.name
                        : table?.name,
                      i18n.resolvedLanguage
                    )}`,
                    key: `tables-${index}`,
                  })
                ),
              },
            ]}
          />
        </div>
        <div style={{ flex: 1, position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <TableFieldsList />
          </div>
        </div>
      </div>
    </Modal>
  );
});

const CreateDatasetModal = forwardRef(
  (
    props: {
      callback: () => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
    const [showItem, setShowItem] = useState<DatasetTemplate | undefined>();
    const [baseTables, setBaseTables] = useState<TableType[]>([]);
    const [form] = Form.useForm();
    const [currentTab, setCurrentTab] = useState("");

    useImperativeHandle(ref, () => ({
      show: (template: DatasetTemplate) => {
        if ((template.manifest?.base_tables || []).length > 0) {
          setShowItem(template);
          setCurrentTab(template.manifest?.base_tables![0]?.temp_id!);
          form.resetFields();
          form.setFieldsValue(
            template.manifest?.base_tables?.reduce(
              (ret: any, table: DatasetTemplateTable) => {
                ret[table.temp_id!] = { create: false };
                return ret;
              },
              {}
            )
          );
        } else {
          handleCreateDataset(template, {});
        }
      },
    }));

    useEffect(() => {
      getTableListApi({
        tableType: "PRIMARY",
        page_size: 1000,
      }).then((res) => {
        if (res.success) {
          setBaseTables(res.data);
        }
      });
    }, [showItem]);

    const closeModal = () => {
      setShowItem(undefined);
    };

    const handleCreateDataset = (template: DatasetTemplate, tables: object) => {
      createDatasetByTemplateApi({
        id: Number(template.id),
        data: {
          locale: i18n.resolvedLanguage,
          tables: tables,
        },
      }).then((res) => {
        if (res.success) {
          navigate(
            `${IsSimpleLayout ? "/simple" : ""}/dataset/data/${res.data.id}`
          );
          closeModal();
          props.callback && props.callback();
        } else {
          let messageStr = t("common.result_status", {
            title: t("common.create"),
            status: t("common.fail"),
          });
          if (
            JSON.stringify(res.message).includes("table name must be unique")
          ) {
            messageStr = t("dataset.template_import.table_name_unique_fail");
          }
          message.error(messageStr);
        }
      });
    };

    const handleSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e: any) {
        setCurrentTab(((e?.errorFields || [])[0]?.name || [])[0]);
        return;
      }
      let res = form.getFieldsValue();
      handleCreateDataset(showItem!, res);
    };

    const handleTableChange = (table: string, key: number) => {
      const linkedTable = baseTables.find((t: TableType) => t.id === key);
      const originTable = showItem?.manifest?.base_tables?.find(
        (t) => t.temp_id === table
      );
      let originFormTableData = form.getFieldValue(table) || {};
      let newData: any = {};
      newData[table] = {
        ...originFormTableData,
        fields: (originTable?.fields || []).reduce(
          (ret: any, f: DatasetTemplateTableField) => {
            ret[f.temp_id!] = linkedTable?.meta?.fields?.find(
              (lf) => lf.identifier === f.identifier
            )?.identifier;
            return ret;
          },
          {}
        ),
      };
      form.setFieldsValue(newData);
    };

    const items: TabsProps["items"] =
      showItem?.manifest?.base_tables?.map((table: DatasetTemplateTable) => ({
        key: table.temp_id!,
        label: viewUtils.prettifyTextWithLocale(
          table?.name,
          i18n.resolvedLanguage
        ),
        forceRender: true,
        children: (
          <>
            <Form.Item
              name={[table.temp_id!, "create"]}
              rules={[{ required: true, message: "" }]}
            >
              <Radio.Group disabled={!table.creatable}>
                <Radio value={true}>
                  {t("dataset.template_import.new_base_table")}
                </Radio>
                <Radio value={false}>
                  {t("dataset.template_import.link_base_table")}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues[table.temp_id!]?.create !==
                  curValues[table.temp_id!]?.create ||
                prevValues[table.temp_id!]?.table !==
                  curValues[table.temp_id!]?.table
              }
            >
              {() => {
                const fields = table.fields || [];
                const create = !!form.getFieldValue(table.temp_id!)?.create;
                if (!!create) {
                  return null;
                }
                const linkedTable = baseTables.find(
                  (t: TableType) =>
                    t.id === form.getFieldValue(table.temp_id!)?.table
                );
                return (
                  <>
                    <Form.Item
                      name={[table.temp_id!, "table"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <Select
                        showSearch
                        placeholder={t("dataset.table_views.select_table")}
                        style={{ width: "100%" }}
                        options={baseTables.map((t: TableType) => ({
                          label: t.name,
                          value: t.id,
                        }))}
                        onChange={(key: number) =>
                          handleTableChange(table.temp_id!, key)
                        }
                      />
                    </Form.Item>
                    <Table
                      size="small"
                      rowClassName={"field-list-dragula-table-row"}
                      rowKey={(r) => r?.temp_id || ""}
                      className={"field-list-dragula-table"}
                      scroll={{ x: 400, scrollToFirstRowOnChange: true }}
                      pagination={false}
                      columns={[
                        {
                          key: "name",
                          title: t("table.field.name"),
                          render: (d: DatasetTemplateTableField) => (
                            <Space
                              size={[5, 0]}
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {viewUtils.prettifyTextWithLocale(
                                d.name,
                                i18n.resolvedLanguage
                              )}
                              {!!d.readonly && (
                                <Tag color="red">
                                  {t("table.field.readonly")}
                                </Tag>
                              )}
                            </Space>
                          ),
                        },
                        {
                          key: "identifier",
                          title: t("table.field.identifier"),
                          dataIndex: "identifier",
                        },
                        {
                          key: "type",
                          title: t("table.field.type"),
                          render: (d: DatasetTemplateTableField) => (
                            <Space>
                              {i18n.resolvedLanguage === "zh"
                                ? fieldTypeToTitle[d?.type as TableFieldType]
                                : d?.type || ""}
                            </Space>
                          ),
                        },
                        {
                          title: t("table.field.is_pk"),
                          dataIndex: "is_pk",
                          key: "is_pk",
                          render: (record: any) =>
                            record ? t("common.yes") : t("common.no"),
                        },
                        {
                          title: t("table.field.is_unique"),
                          dataIndex: "is_unique",
                          key: "is_unique",
                          render: (record: any) =>
                            record ? t("common.yes") : t("common.no"),
                        },
                        {
                          title: t("table.field.is_nullable"),
                          dataIndex: "is_nullable",
                          key: "is_nullable",
                          render: (record: any) =>
                            record ? t("common.yes") : t("common.no"),
                        },
                        {
                          title: t("dataset.template_import.link_field"),
                          key: "link_field",
                          render: (record: any) => (
                            <Form.Item
                              name={[table.temp_id!, "fields", record.temp_id!]}
                              rules={[{ required: true, message: "" }]}
                              style={{ marginBottom: 0 }}
                            >
                              <Select
                                options={
                                  linkedTable?.meta?.fields
                                    ?.filter((f) => f.type === record.type)
                                    ?.map((f) => ({
                                      label: f.name,
                                      value: f.identifier,
                                    })) || []
                                }
                              />
                            </Form.Item>
                          ),
                        },
                      ]}
                      dataSource={fields || []}
                    />
                  </>
                );
              }}
            </Form.Item>
          </>
        ),
      })) || [];

    return (
      <Modal
        centered
        width={1000}
        title={
          !!showItem
            ? `${t(
                "dataset.template_import.title"
              )}（${viewUtils.prettifyTextWithLocale(
                showItem?.manifest?.title,
                i18n.resolvedLanguage
              )}）`
            : ""
        }
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
        open={!!showItem}
        onCancel={closeModal}
        onOk={handleSubmit}
      >
        <Form form={form} name="create dataset by template form">
          <Tabs
            items={items}
            activeKey={currentTab}
            onChange={(activeKey: string) => setCurrentTab(activeKey)}
          />
        </Form>
      </Modal>
    );
  }
);

const DatasetTemplateAction = () => {
  const { t, i18n } = useTranslation();
  const permission = usePermission();
  const [show, setShow] = useState(false);
  const [templates, setTemplates] = useState<DatasetTemplate[]>([]);
  const datasetTemplateInfoModalRef: any = useRef();
  const createDatasetModalRef: any = useRef();

  useEffect(() => {
    if (!!permission?.username) {
      getCurrentWorkspaceDatasetTemplateListApi({
        page: 1,
        page_size: 100,
      }).then((res) => {
        if (res.success) {
          setTemplates(res.data);
        }
      });
    }
  }, [permission]);

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        centered
        width={700}
        title={t("dataset.template_import.title")}
        open={!!show}
        onCancel={closeModal}
        footer={null}
      >
        <Row gutter={[10, 10]} style={{ margin: "8px 0" }}>
          {templates.map((template: DatasetTemplate) => (
            <Col key={template.id} xs={24} sm={12} md={8}>
              <div className="dataset-template-card">
                <div>
                  <Typography.Title
                    style={{
                      fontSize: "1.1em",
                      fontWeight: "bold",
                      marginBottom: "0.8em",
                    }}
                  >
                    {viewUtils.prettifyTextWithLocale(
                      template?.manifest?.title,
                      i18n.resolvedLanguage
                    )}
                  </Typography.Title>
                  <Typography.Paragraph
                    type="secondary"
                    style={{ marginBottom: 0, minHeight: "6em" }}
                  >
                    {viewUtils.prettifyTextWithLocale(
                      template?.manifest?.desc,
                      i18n.resolvedLanguage
                    )}
                  </Typography.Paragraph>
                </div>
                <div className="actions">
                  <Button
                    onClick={() => {
                      datasetTemplateInfoModalRef.current &&
                        datasetTemplateInfoModalRef.current.show(template);
                    }}
                    style={{ color: "#1677ff", borderColor: "#1677ff" }}
                  >
                    {t("dataset.template_import.view")}
                  </Button>
                  <div style={{ width: "8px" }} />
                  <Button
                    type="primary"
                    onClick={() => {
                      createDatasetModalRef.current &&
                        createDatasetModalRef.current.show(template);
                    }}
                  >
                    {t("dataset.template_import.use")}
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
      <DatasetTemplateInfoModal ref={datasetTemplateInfoModalRef} />
      <CreateDatasetModal ref={createDatasetModalRef} callback={closeModal} />
      {templates.length > 0 && (
        <Button onClick={() => setShow(true)}>
          {t("dataset.template_import.create")}
        </Button>
      )}
    </>
  );
};

export default DatasetTemplateAction;
