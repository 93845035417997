import { API, responseData } from "./BaseApi";
// import type { Table, TableView } from "../types/Table";

export const getAllDatasetTemplateListApi = (data: {
  page?: number;
  page_size?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  return API.get<responseData, responseData>(
    `dataset-templates/?${queryParams.toString()}`
  );
};

export const getCurrentWorkspaceDatasetTemplateListApi = (data: {
  page?: number;
  page_size?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  return API.get<responseData, responseData>(
    `dataset-templates/current/?${queryParams.toString()}`
  );
};

export const getDatasetTemplateApi = (data: { id: number }) => {
  return API.get<responseData, responseData>(`dataset-templates/${data.id}/`);
};

export const createDatasetTemplateApi = (data: object) => {
  return API.post<responseData, responseData>(`dataset-templates/`, data);
};

export const deleteDatasetTemplateApi = (data: { id: number }) => {
  return API.delete<responseData, responseData>(
    `dataset-templates/${data.id}/`
  );
};

export const updateDatasetTemplateApi = (data: {
  id: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `dataset-templates/${data.id}/`,
    data.data
  );
};

export const createDatasetByTemplateApi = (data: {
  id: number;
  data: object;
}) => {
  return API.post<responseData, responseData>(
    `dataset-templates/${data.id}/create-dataset/`,
    data.data
  );
};
