import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getHealthApi } from "../api";
import { Image, Tooltip } from "antd";
import { WifiOutlined } from "@ant-design/icons";
import Offline from "../common/images/no-wifi.png";

const Network = () => {
  const { t } = useTranslation();

  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    let intervalId: any = null;
    intervalId = setInterval(() => {
      getHealthApi().then((res: any) => {
        setOnline(res.success);
      });
    }, 6 * 10000);
    return () => {
      !!intervalId && clearInterval(intervalId);
    };
  }, []);

  return online ? (
    <Tooltip placement="top" title={t("nav.tip.network_available")}>
      <WifiOutlined style={{ fontSize: 20, color: "green" }} />
    </Tooltip>
  ) : (
    <Tooltip placement="top" title={t("nav.tip.network_unavailable")}>
      <Image src={Offline} preview={false} height={"16px"} />
    </Tooltip>
  );
};

export default Network;
