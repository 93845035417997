import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom/client";
import { Spin, Empty, Dropdown, Button } from "antd";
import DefaultSheetOption from "../config/DefaultSheetOption";
import {
  SheetTable,
  TableField,
  DataRowValidation,
  TableFieldType,
} from "../types/Table";
import { toBoolean, toDate, toNumber } from "../utils/TypeUtils";
import { exportExcel } from "../components/excelDownload";
import TableImportModal from "../components/TableImportModal";
import GlobalLoader from "./GlobalLoading";
import { translateDataValidation } from "../utils/SheetValidationUtils";
import LockIcon from "../common/images/icons/lock.png";
import viewUtils from "../utils/viewUtils";

const LockIconImg = new Image();
LockIconImg.src = LockIcon;

const Window: any = window;

type CellData = {
  r: number;
  c: number;
  v: any;
};

type DataVerification = {
  type:
    | "dropdown"
    | "checkbox"
    | "number"
    | "number_integer"
    | "number_decimal"
    | "text_content"
    | "text_length"
    | "date"
    | "validity";
  type2: string | null | undefined;
  value1: string | number | RegExp | null | undefined;
  value2: string | number | null | undefined;
  remote: boolean;
  prohibitInput: boolean;
  hintShow: boolean;
  hintText: string;
  checked: boolean;
  searched?: number;
};

// type for luckysheet cell type
type CT = {
  fa: string;
  t: string;
};

const getLuckySheetDataType: (field: TableField) => CT = function (
  field: TableField
): CT {
  const fieldType = field.type?.toUpperCase();

  switch (fieldType) {
    case "NUMBER":
      return { fa: "General", t: "n" };
    case "DATE":
      return { fa: "@", t: "s" };
    case "BOOLEAN":
      return { fa: "General", t: "b" };
    case "TEXT":
      return { fa: "@", t: "s" };
    case "REFERENCE":
      return { fa: "General", t: "g" };
    default:
      return { fa: "General", t: "g" };
  }
};

const ROW_LIMITS = 10000;
const EDITABLE_MIN_ROW = 49;
const SHEET_LOADING_TAG = "⌛️";

const TablesSheet = forwardRef(
  (
    props: {
      id: string;
      minHeight?: string;
      tables?: SheetTable[];
      changeTableCallback?: (table: SheetTable, needInit?: boolean) => void;
      updateCallback?: () => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const sheetInit: any = useRef(false);
    const tableImportModalRef: any = useRef();
    const currentTableIndex: any = useRef();
    const currentErrorTip: any = useRef();

    useEffect(() => {
      let resizeObserver = new ResizeObserver((entries) => {
        try {
          Window.luckysheet.resize();
        } catch (e) {}
      });
      if (document.getElementById(props?.id)) {
        resizeObserver.observe(
          document.getElementById(props?.id) || document.body
        );
      }
      return () => {
        resizeObserver.disconnect();
      };
    }, [props.id]);

    useImperativeHandle(ref, () => ({
      getCurrentTableData: getData,
      resetCurrentTable: (
        data: any,
        editable?: boolean,
        skipLoading?: boolean,
        skipRowLimit?: boolean
      ) => {
        showEditBtn(!!editable);
        let table = Window.luckysheet.getSheet()?.originTableData;
        table.editable = !!editable;
        handleResetResult(
          data || [],
          skipRowLimit,
          !!editable && (data || []).length === 0
            ? EDITABLE_MIN_ROW
            : Math.max((data || []).length, 1),
          skipLoading
        );
      },
      importCurrentTable: (table: SheetTable) => {
        tableImportModalRef.current && tableImportModalRef.current.show(table);
      },
      exportCurrentTable: (name: string) => {
        try {
          Window.luckysheet.saveEditingCell();
        } catch (err) {}
        const sheet = Window.luckysheet.getSheet();
        if (!!sheet) {
          exportExcel([sheet], name);
        }
      },
      prepareValidation: prepareValidation,
      setSheetActive: (index?: string) => {
        setSheetActive(index);
      },
      addTable: (table: SheetTable, afterIndex: string) => {
        let order = null;
        if (afterIndex) {
          const sheet = Window.luckysheet.getSheet({ index: afterIndex });
          if (!!sheet) {
            order = sheet.order;
          }
        }
        Window.luckysheet.setSheetAdd({
          sheetObject: formatSheet(table),
          ...(order !== null ? { order: order + 1 } : {}),
        });
      },
      sheetDataLoaded: (
        table: SheetTable,
        skipLoading?: boolean,
        skipRowLimit?: boolean
      ) => {
        if (currentTableIndex.current === table.identifier) {
          showEditBtn(!!table.editable);
        }
        let currentSheet = Window.luckysheet.getSheet({
          index: table.identifier,
        });
        if (!!currentSheet) {
          if (!!table?.meta?.fields) {
            try {
              currentSheet.originTableData.meta.fields =
                currentSheet?.originTableData?.meta?.fields?.map(
                  (field: TableField) => ({
                    ...field,
                    ...table?.meta?.fields?.find(
                      (f: TableField) => f.id === field.id
                    ),
                  })
                );
            } catch (e) {}
          }
          currentSheet.originTableData.editable = !!table.editable;
          const tableData = table.data || [];
          handleResetResult(
            tableData,
            skipRowLimit,
            !!table?.editable && tableData.length === 0
              ? EDITABLE_MIN_ROW
              : Math.max(tableData.length, 1),
            skipLoading,
            table.identifier
          );
          if (!!table.name) {
            Window.luckysheet.setSheetName(table.name, {
              order: currentSheet.order,
            });
          }
        }
      },
      resetInitStatus: () => {
        sheetInit.current = false;
      },
      changeSheetEditStatus: (editable: boolean, index?: string) => {
        let currentSheet = Window.luckysheet.getSheet({
          index: index || currentTableIndex.current,
        });
        if (!!currentSheet) {
          currentSheet.originTableData.editable = !!editable;
        }
        if (!index || currentTableIndex.current === index) {
          showEditBtn(!!editable);
        }
      },
    }));

    const setSheetActive = (index?: string) => {
      if (!index) {
        return false;
      }
      const sheet = Window.luckysheet.getSheet({ index: index });
      if (!sheet) {
        return false;
      }
      Window.luckysheet.setSheetActive(sheet.order);
      return sheet;
    };

    const calColumnlen = (str: string) => {
      return (str.match(/[\u4E00-\u9FA5]/g) || []).length * 8 + str.length * 8;
    };

    const formatSheetData = (table: SheetTable) => {
      let tableData = table.data || [];
      const tableFields = viewUtils
        .sortTableFields(table.meta?.fields || [])
        .map((field: TableField) => ({
          ...field,
          fk_enums_mapping:
            field.type === "REFERENCE"
              ? field.fk_enums?.reduce((ret: any, value: any) => {
                  ret[value.field] = value.literal;
                  return ret;
                }, {})
              : {},
        }));
      let cellData: CellData[] = [];
      for (let i = 0, len = tableFields.length; i < len; i++) {
        cellData.push({
          r: 0,
          c: i,
          v: {
            v: tableFields[i]?.name,
            bl: 1,
            notEditable: true,
          },
        });
      }
      for (let r = (tableData || []).length; r > 0; r--) {
        for (let c = 0, clen = tableFields.length; c < clen; c++) {
          let row = (tableData || [])[r - 1] || {};
          let field = tableFields[c];
          if (
            (row[field.identifier!] !== null &&
              row[field.identifier!] !== undefined) ||
            !!field.readonly
          ) {
            cellData.push({
              r: r,
              c: c,
              v: {
                ct: getLuckySheetDataType(field),
                v:
                  field.type === "REFERENCE"
                    ? (field?.fk_enums_mapping || {})[row[field.identifier!]]
                    : row[field.identifier!],
                m:
                  field.type === "REFERENCE"
                    ? (field?.fk_enums_mapping || {})[row[field.identifier!]]
                    : row[field.identifier!],
              },
            });
          }
        }
      }
      return cellData;
    };

    const formatSheet = (table: SheetTable, index?: number) => {
      let isRowLimitExceeded = false;
      let tableData = table.data || [];
      if (table?.table_type === "PRIMARY" && tableData.length > ROW_LIMITS) {
        isRowLimitExceeded = true;
        tableData = tableData?.slice(0, ROW_LIMITS);
      }

      const columnLength = table.meta?.fields?.length;
      const rowLength =
        (!!table.data && !!table.editable && tableData?.length === 0
          ? EDITABLE_MIN_ROW
          : tableData?.length) + 1;
      const tableFields = (table.meta?.fields || []).map(
        (field: TableField) => ({
          ...field,
          fk_enums_mapping:
            field.type === "REFERENCE"
              ? field.fk_enums?.reduce((ret: any, value: any) => {
                  ret[value.field] = value.literal;
                  return ret;
                }, {})
              : {},
        })
      );

      let config: any = {};
      config.columnlen = tableFields.reduce(
        (ret: any, field: TableField, index: number) => {
          ret[`${index}`] = Math.max(
            field.type === "REFERENCE" ? 250 : 100,
            calColumnlen(field?.name || "")
          );
          return ret;
        },
        {}
      );

      config.colExpand = tableFields.reduce(
        (ret: any, field: TableField, index: number) => {
          if (!!field.readonly) {
            ret[index] = (index: number, text: string, canvasOptions: any) => {
              canvasOptions.canvas.drawImage(
                LockIconImg,
                canvasOptions.x0,
                (canvasOptions.y1 - 16) / 2.0 - 1,
                16,
                16
              );
            };
          }
          return ret;
        },
        {}
      );

      return {
        order: index,
        index: table.identifier,
        name: `${!!table.data ? "" : SHEET_LOADING_TAG}${table.name}`,
        originTableData: table,
        column: columnLength,
        row: rowLength,
        celldata: formatSheetData(
          isRowLimitExceeded ? { ...table, data: tableData } : table
        ),
        config: config,
        isRowLimitExceeded: isRowLimitExceeded,
      };
    };

    const showEditBtn = (show: boolean) => {
      const display = show ? "block" : "none";
      document
        .getElementById("luckysheet-bottom-controll-row")
        ?.style?.setProperty("display", display);
      if (show) {
        document
          .getElementById("luckysheet-rightclick-menu")
          ?.classList?.remove("disabled");
      } else {
        document
          .getElementById("luckysheet-rightclick-menu")
          ?.classList?.add("disabled");
      }
      Window.luckysheet.exitEditMode();
    };

    const showRowLimitTip = (show?: boolean) => {
      if (!!show) {
        document
          .getElementById("exceed_max_row_tip")
          ?.style?.setProperty("display", "block");
      } else {
        document
          .getElementById("exceed_max_row_tip")
          ?.style?.setProperty("display", "none");
      }
    };

    const isCurrentRowLimit = () => {
      const currentTable = Window.luckysheet.getSheet();
      return (
        currentTable?.originTableData?.table_type === "PRIMARY" &&
        currentTable?.data?.length > ROW_LIMITS
      );
    };

    useEffect(() => {
      if (!!props.tables && props.tables.length > 0 && !!props.tables[0]?.id) {
        const options = {
          ...DefaultSheetOption,
          lang: i18n.resolvedLanguage === "zh" ? "zh" : "en",
          allowUpdate: false,
          allowEdit: true,
          enableAddRow: true,
          container: props.id,
          gridKey: props.id,
          data: !!sheetInit.current
            ? sheetInit.current
            : props.tables.map(formatSheet),
          hook: {
            cellEditBefore: (range: any) => {
              try {
                const fieldIndex = range[0].column[0];
                const currentTable =
                  Window.luckysheet.getSheet()?.originTableData;
                if (!currentTable?.editable) {
                  return false;
                }
                if (isCurrentRowLimit()) {
                  return false;
                }
                if (!!currentTable?.is_admin) {
                  return true;
                }
                return !(currentTable?.meta?.fields || [])[fieldIndex]
                  ?.readonly;
              } catch (e) {
                return false;
              }
            },
            cellUpdateBefore: (r: any, c: any, value: any, isRefresh: any) => {
              try {
                const currentTable =
                  Window.luckysheet.getSheet()?.originTableData;
                if (!currentTable?.editable) {
                  return false;
                }
                if (isCurrentRowLimit()) {
                  return false;
                }
                if (!!currentTable?.is_admin) {
                  return true;
                }
                return !(currentTable?.meta?.fields || [])[c]?.readonly;
              } catch (e) {
                return false;
              }
            },
            tableAddBefore: (
              type: string,
              index: any,
              value: any,
              direction: any,
              sheetIndex: any
            ) => {
              const currentTable = Window.luckysheet.getSheet({
                index: sheetIndex,
              })?.originTableData;
              if (type === "row") {
                if (!currentTable?.editable) {
                  return false;
                }
                if (isCurrentRowLimit()) {
                  return false;
                }
                if (
                  currentTable?.meta?.fields?.find(
                    (item: TableField) => !!item.readonly
                  ) &&
                  !currentTable?.is_admin
                ) {
                  return false;
                }
                if (index === 0 && direction === "lefttop") {
                  return false;
                } else {
                  return true;
                }
              }
              return false;
            },
            tableDeleteBefore: (
              type: string,
              st: any,
              ed: any,
              sheetIndex: any
            ) => {
              const currentTable = Window.luckysheet.getSheet({
                index: sheetIndex,
              });
              if (!!currentTable?.originTableData?.editable && type === "row") {
                if (isCurrentRowLimit()) {
                  return false;
                }
                if (
                  currentTable?.originTableData?.meta?.fields?.find(
                    (item: TableField) => !!item.readonly
                  )
                ) {
                  return false;
                }
                if (st === 0) {
                  return false;
                } else {
                  return true;
                }
              }
              return false;
            },
            updated: (operate: any) => {
              if (
                [
                  "updateDataVerificationOfCheckbox",
                  "datachange",
                  "pasteCut",
                ].includes(operate.type)
              ) {
                props.updateCallback && props.updateCallback();
              }
              if (operate.type === "addRC") {
                resetTableDataVerification();
              }
            },
            workbookCreateAfter: async () => {
              sheetInit.current = true;
              const addRowCtl = document.getElementById(
                "luckysheet-bottom-add-row"
              );
              if (addRowCtl) {
                addRowCtl.innerHTML =
                  "<div style='float: left; font-size: 1.5em;line-height: 1;margin-top:-0.1em;margin-right:0.3em;'>+</div> " +
                  t("sheet.add_row");
              }
              const toolHeader = document.getElementById(
                "luckysheet-wa-editor"
              );
              if (toolHeader) {
                const btn = document.createElement("div");
                btn.id = "luckysheet-change-row-height";
                btn.className =
                  "luckysheet-toolbar-menu-button luckysheet-inline-block";
                btn.dataset.tips = t("sheet.set_row_height");
                toolHeader.append(btn);
                const root = ReactDOM.createRoot(
                  document.getElementById(
                    "luckysheet-change-row-height"
                  ) as HTMLElement
                );
                root.render(
                  <Dropdown
                    overlayClassName="luckysheet-cols-menu-style"
                    overlayStyle={{
                      width: 150,
                      maxHeight: "auto !important",
                    }}
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: 19,
                          label: t("sheet.default_height"),
                        },
                        {
                          key: 25,
                          label: t("sheet.middle_height"),
                        },
                        {
                          key: 35,
                          label: t("sheet.high_height"),
                        },
                        {
                          key: 50,
                          label: t("sheet.extremely_high_height"),
                        },
                      ],
                      style: {
                        boxShadow: "none",
                      },
                      onClick: ({ item, key, keyPath, domEvent }) => {
                        let data: any = {};
                        for (
                          let i = 0,
                            len = (Window.luckysheet.getSheetData() || [])
                              .length;
                          i < len;
                          i++
                        ) {
                          data[i] = key;
                        }
                        Window.luckysheet.setRowHeight(data);
                        Window.luckysheet.setRangeShow("A1", { show: false });
                      },
                    }}
                  >
                    <Button
                      size="small"
                      type="text"
                      style={{ fontSize: "1em" }}
                    >
                      {t("sheet.row_height")}
                    </Button>
                  </Dropdown>
                );
              }
              Window.luckysheet.setRangeShow("A1", { show: false });
              (Window.luckysheet.getluckysheetfile() || []).forEach(
                (sheet: any) => {
                  resetTableDataVerification(sheet.originTableData);
                }
              );
              const sheet = setSheetActive(currentTableIndex.current);
              if (sheet) {
                showEditBtn(!!sheet.originTableData?.editable);
                showRowLimitTip(sheet?.isRowLimitExceeded);
                return;
              }
              const currentTable = (props.tables || [])[0];
              currentTableIndex.current = currentTable.identifier;
              showEditBtn(!!currentTable?.editable);
              showRowLimitTip(
                currentTable.table_type === "PRIMARY" &&
                  (currentTable.data || []).length > ROW_LIMITS
              );
              props.tables &&
                props.changeTableCallback &&
                props.changeTableCallback(currentTable);
            },
            sheetActivate: async (
              index: string,
              isPivotInitial: boolean,
              isNewSheet: boolean
            ) => {
              const currentTable = Window.luckysheet.getSheet({
                index: index,
              });
              showRowLimitTip(currentTable?.isRowLimitExceeded);
              currentTableIndex.current =
                currentTable.originTableData.identifier;
              currentTable &&
                props.changeTableCallback &&
                props.changeTableCallback(
                  currentTable.originTableData,
                  currentTable?.name?.includes(SHEET_LOADING_TAG)
                );
              showEditBtn(!!currentTable.originTableData.editable);
              Window.luckysheet.setRangeShow("A1", { show: false });
            },
          },
        };
        try {
          Window.luckysheet.create(options);
        } catch {}
      } else {
        Window.luckysheet.destroy();
      }
      return () => {
        if (!!sheetInit.current) {
          sheetInit.current = Window.luckysheet.getluckysheetfile();
        }
        Window.luckysheet.destroy();
      };
      // eslint-disable-next-line
    }, [props.tables, props.id, i18n, t]);

    const setDataVerification = (
      field: TableField,
      setting: {
        range: any;
        order: number;
      }
    ) => {
      try {
        let dataVerification: DataVerification | undefined = undefined;
        // get current row number
        if (field.type === TableFieldType.REFERENCE) {
          dataVerification = {
            type: "dropdown",
            type2: null,
            value1:
              field.fk_enums?.map((item) => item.literal).join(",") || " ",
            value2: null,
            remote: false,
            prohibitInput: false,
            hintShow: false,
            hintText: "",
            checked: false,
            searched: 15,
          };
        } else if (field.type === TableFieldType.BOOLEAN) {
          dataVerification = {
            type: "validity",
            type2: "regular",
            value1: /^(true|yes|是|真|1|false|no|否|假|0)$/i,
            value2: t("sheet.boolean_error"),
            remote: false,
            prohibitInput: false,
            hintShow: false,
            hintText: "",
            checked: false,
          };
        } else if (field.type === TableFieldType.DATE) {
          dataVerification = {
            type: "validity",
            type2: "regular",
            value1: /^\d{4}[-/]\d{2}[-/]\d{2}( \d{2}:\d{2}:\d{2})?$/,
            value2: t("sheet.date_error"),
            remote: false,
            prohibitInput: false,
            hintShow: false,
            hintText: "",
            checked: false,
          };
        }
        if (!dataVerification) {
          return;
        }
        Window.luckysheet.setDataVerification(dataVerification, setting);
      } catch (e) {}
    };

    const resetTableDataVerification = (table?: SheetTable) => {
      const sheet = Window.luckysheet.getSheet(
        !!table ? { index: table?.identifier } : {}
      );
      if (
        !sheet ||
        !sheet?.originTableData?.editable ||
        sheet.data.length < 2
      ) {
        return;
      }
      (sheet?.originTableData?.meta?.fields || [])
        .filter((field: TableField) => !!field)
        .forEach((field: TableField, index: number) => {
          setDataVerification(field, {
            range: { row: [1, sheet.data.length - 1], column: [index, index] },
            order: sheet.order,
          });
        });
    };

    const handleResetResult = (
      data: any[],
      skipRowLimit?: boolean,
      rowLength?: number,
      skipLoading?: boolean,
      index?: any
    ) => {
      const sheet = Window.luckysheet.getSheet(!!index ? { index: index } : {});
      if (sheet) {
        if (!skipLoading) {
          GlobalLoader.show();
        }
        setTimeout(() => {
          if (
            !skipRowLimit &&
            sheet.originTableData?.table_type === "PRIMARY" &&
            (data || []).length > ROW_LIMITS
          ) {
            sheet.isRowLimitExceeded = true;
          } else {
            sheet.isRowLimitExceeded = false;
          }
          if (!!index && index === currentTableIndex.current) {
            showRowLimitTip(sheet.isRowLimitExceeded);
          }
          const cellData = formatSheetData({
            ...sheet?.originTableData,
            data: sheet.isRowLimitExceeded
              ? (data || []).slice(0, ROW_LIMITS)
              : data || [],
          });
          Window.luckysheet.updateSheetCelldata(
            cellData,
            sheet.index,
            null,
            sheet.isRowLimitExceeded ? ROW_LIMITS + 1 : rowLength
          );
          props.updateCallback && props.updateCallback();
          resetTableDataVerification(sheet.originTableData);
          Window.luckysheet.setRangeShow("A1", { show: false });
          if (!skipLoading) {
            GlobalLoader.hide();
          }
        }, 1);
      }
    };

    const getData = () => {
      Window.luckysheet.saveEditingCell();
      const sheet = Window.luckysheet.getSheet();
      if (!sheet) {
        return [];
      }
      const columns = sheet?.originTableData?.meta?.fields;
      if (!columns || columns.length < 1) {
        return [];
      }

      let sheetData = sheet.data;
      let data = [];
      for (let i = 1; i < sheetData.length; i++) {
        let rowData: any = {};
        for (let j = 0; j < columns.length; j++) {
          // convert to actual type
          const fk_enums_mapping =
            columns[j].type === "REFERENCE"
              ? columns[j].fk_enums?.reduce((ret: any, value: any) => {
                  ret[value.literal] = value.field;
                  return ret;
                }, {})
              : {};
          if (sheetData[i][j]?.v !== undefined && sheetData[i][j]?.v !== null) {
            switch (columns[j].type?.toUpperCase()) {
              case "REFERENCE":
                rowData[columns[j].identifier!] =
                  fk_enums_mapping[sheetData[i][j]?.v];
                break;
              case "TEXT":
                rowData[columns[j].identifier!] = `${
                  sheetData[i][j]?.ct?.t === "d"
                    ? sheetData[i][j]?.m
                    : sheetData[i][j]?.v
                }`;
                break;
              case "NUMBER":
                rowData[columns[j].identifier!] = toNumber(sheetData[i][j]?.v);
                break;
              case "BOOLEAN":
                rowData[columns[j].identifier!] = toBoolean(sheetData[i][j]?.v);
                break;
              case "DATE":
                rowData[columns[j].identifier!] = toDate(
                  sheetData[i][j]?.ct?.t === "d"
                    ? sheetData[i][j]?.m
                    : sheetData[i][j]?.v
                );
                break;
              default:
                rowData[columns[j].identifier!] =
                  sheetData[i][j]?.ct?.t === "d"
                    ? sheetData[i][j]?.m
                    : sheetData[i][j]?.v;
                break;
            }
          } else {
            rowData[columns[j].identifier!] = null;
          }
        }
        if (Object.values(rowData).filter((item) => item !== null).length > 0) {
          data.push(rowData);
        }
      }
      return data;
    };

    const prepareValidation = (
      validation?: DataRowValidation[],
      tableIdentifier?: string
    ) => {
      const sheet = Window.luckysheet.getSheet({
        index: tableIdentifier || currentTableIndex.current,
      });
      if (!sheet?.originTableData?.meta?.fields) {
        return;
      }
      if (!currentErrorTip.current) {
        currentErrorTip.current = {};
      }
      let columnIndexMap = new Map();
      viewUtils
        .sortTableFields(sheet?.originTableData?.meta?.fields || [])
        .forEach((col: TableField, index: number) => {
          columnIndexMap.set(col.identifier!, index);
        }
      );
      if (
        (currentErrorTip?.current || {})[sheet?.originTableData?.identifier]
          ?.length > 0
      ) {
        currentErrorTip.current[sheet?.originTableData?.identifier].forEach(
          (item: any, index: number) => {
            const currentCell = sheet?.data[item[0]][item[1]];
            Window.luckysheet.setCellValue(
              item[0],
              item[1],
              {
                ...currentCell,
                ps: null,
              },
              {
                order: sheet?.order,
                isRefresh:
                  index ===
                    currentErrorTip.current[sheet?.originTableData?.identifier]
                      ?.length -
                      1 && !validation,
              }
            );
          }
        );
        currentErrorTip.current[sheet?.originTableData?.identifier] = [];
      }
      if (validation) {
        currentErrorTip.current[sheet?.originTableData?.identifier] = [];
        let validationErrors: any = [];
        if (validation.length > 10000) {
          validationErrors = validation?.slice(0, 10000);
        } else {
          validationErrors = validation;
        }
        validationErrors?.forEach((item: DataRowValidation, index: number) => {
          const currentCell =
            sheet?.data[item.index + 1][columnIndexMap.get(item.field)];
          const validationItem =
            i18n.resolvedLanguage === "zh"
              ? translateDataValidation(
                  item,
                  currentCell?.m || currentCell?.v?.v || currentCell?.v || ""
                )
              : item;
          currentErrorTip.current[sheet?.originTableData?.identifier].push([
            validationItem.index + 1,
            columnIndexMap.get(validationItem.field),
          ]);
          Window.luckysheet.setCellValue(
            validationItem.index + 1,
            columnIndexMap.get(validationItem.field),
            {
              ...currentCell,
              ps: { value: validationItem.message },
            },
            { isRefresh: index === validationErrors.length - 1 }
          );
        });
      }
    };

    return (
      <>
        <TableImportModal
          ref={tableImportModalRef}
          submitCallback={(data: any) => handleResetResult(data, true)}
        />
        <div
          id={props.id}
          style={{
            margin: "0px",
            padding: "0px",
            position: "relative",
            width: "100%",
            minHeight: props.minHeight || `calc(100vh - 234px)`,
            height: "400px",
            left: "0px",
            top: "0px",
          }}
        ></div>
        <div
          style={{
            background: "white",
            opacity: 0.6,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display:
              !!props.tables && props.tables.length > 0 ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          {!!props.tables && props.tables.length === 0 ? (
            <Empty />
          ) : (
            <Spin size="large" />
          )}
        </div>
        <div
          id="exceed_max_row_tip"
          style={{
            color: "red",
            position: "absolute",
            right: 18,
            bottom: 72,
            zIndex: 999,
            display: "none",
          }}
        >
          {t("sheet.exceed_max_row")}
        </div>
      </>
    );
  }
);

export default TablesSheet;
