import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Collapse, Radio, InputNumber } from "antd";
import { ChartView } from "../../types/ChartView";
import { TableField, SheetTable } from "../../types/Table";
import { colorTheme } from "./ColorTheme";
import * as d3 from "d3";
import useWindowSize from "../../utils/useWindowSize";

const { Panel } = Collapse;
const { Option } = Select;

type Props = {
  id: string;
  chart_view?: ChartView;
  table?: SheetTable;
};

const PieChart = (props: Props) => {
  const windowSize = useWindowSize({ id: `pie-chart-${props.id}` });
  const textSize = props.chart_view?.chart_config?.text_size || 12;
  const tooltipRef = useRef();

  const drawChart = useCallback(() => {
    const countBy = props.chart_view?.chart_config?.count_by;
    const operation = props.chart_view?.chart_config?.operation;
    const color = props.chart_view?.chart_config?.color;

    const dimensionKey = props.chart_view?.chart_config?.dimension as any;
    const valueKey = props.chart_view?.chart_config?.value as any;

    const rawData = props.table?.filtered_data || [];
    const dimensionValues: string[] = Array.from(
      new Set(rawData.map((item: any) => item[dimensionKey]))
    );

    const data: any[] = [];
    for (const dimensionValue of dimensionValues) {
      const record: any = { _count: 0 };
      record[dimensionKey] = dimensionValue;
      record[valueKey] = 0;
      data.push(record);
    }

    for (const item of rawData) {
      const record = data.find((e) => {
        return e[dimensionKey] === item[dimensionKey];
      });
      if (countBy === "count_records") {
        record[valueKey] += 1;
      } else {
        if (operation === "sum") {
          record[valueKey] += item[valueKey];
        } else if (operation === "min") {
          if (record.firstCompared) {
            record[valueKey] = Math.min(record[valueKey], item[valueKey]);
          } else {
            record[valueKey] = item[valueKey];
            record.firstCompared = true;
          }
        } else if (operation === "max") {
          if (record.firstCompared) {
            record[valueKey] = Math.max(record[valueKey], item[valueKey]);
          } else {
            record[valueKey] = item[valueKey];
            record.firstCompared = true;
          }
        } else if (operation === "average") {
          record[valueKey] += item[valueKey];
          record._count += 1;
        }
      }
    }
    if (operation === "average") {
      for (const item of data) {
        item[valueKey] /= item._count;
      }
    }
    const sum = d3.sum(data, (d: any) => d[valueKey]);
    for (const item of data) {
      item[valueKey] = (item[valueKey] / sum) * 100;
    }

    const formatData = (data: any) => {
      if (!data) {
        return "";
      }
      return `${data[dimensionKey]}:${data[valueKey].toFixed(1)}%`;
    };

    const svg = d3
      .select(`#pie-chart-${props.id}`)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%");

    let rootSize = {
      width: svg.node()?.getBoundingClientRect().width || 0,
      height: svg.node()?.getBoundingClientRect().height || 0,
      showLegend: false,
      showXAxis: false,
      showYAxis: false,
    };
    let legendSize = {
      width: Math.min(
        textSize * 11.5,
        d3.max(dimensionValues, (d) => (d.length + 1.5) * textSize) || 0
      ),
      x: 0,
      y: textSize,
    };
    legendSize.x = rootSize.width - legendSize.width;
    let chartSize = {
      width: Math.min(
        rootSize.width - 3 * legendSize.width - textSize,
        rootSize.height - (textSize * 1.5 * data.length) / 4
      ),
      x: 0,
      y: rootSize.height * 0.5,
      radius: 0,
      showLabel: false,
    };
    chartSize.showLabel = chartSize.width > legendSize.width;
    chartSize.radius = chartSize.showLabel
      ? chartSize.width * 0.5
      : Math.min(
          rootSize.width * 0.5 - 1.5 - textSize,
          rootSize.height * 0.5 - 1.5 - textSize
        );
    chartSize.x = chartSize.showLabel
      ? (legendSize.x - textSize) * 0.5
      : rootSize.width * 0.5;

    const colors = d3.scaleOrdinal().range(
      colorTheme.find((item) => {
        return item.value === color;
      })?.colors || []
    );

    // chart
    const chartSection = svg
      .append("g")
      .attr("transform", `translate(${chartSize.x},${chartSize.y})`);

    const pie = d3.pie().value((d: any) => d[valueKey]);
    const data_ready = pie(data);

    const arc = d3.arc().innerRadius(0).outerRadius(chartSize.radius) as any;
    const arcOver = d3
      .arc()
      .innerRadius(0)
      .outerRadius(chartSize.radius + textSize) as any;

    chartSection
      .selectAll("allSlices")
      .data(data_ready)
      .join("path")
      .attr("d", arc)
      .attr("fill", (d: any, i: any) => colors(d.index as any) as string)
      .attr("stroke", "white")
      .style("stroke-width", "1.5px")
      .on("mouseover", function (e: any, d: any) {
        if (!!tooltipRef.current) {
          (tooltipRef.current as any).style.top = `${e.pageY + 10}px`;
          (tooltipRef.current as any).style.left = `${e.pageX + 10}px`;
          (tooltipRef.current as any).innerHTML = formatData(d.data);
          (tooltipRef.current as any).style.visibility = "visible";
          d3.select(e.toElement)
            .transition()
            .attr("d", arcOver)
            .attr("opacity", 0.5)
            .duration(200);
        }
      })
      .on("mousemove", function (e: any, d: any) {
        if (!!tooltipRef.current) {
          (tooltipRef.current as any).style.top = `${e.pageY + 10}px`;
          (tooltipRef.current as any).style.left = `${e.pageX + 10}px`;
        }
      })
      .on("mouseout", function (e: any, d: any) {
        if (!!tooltipRef.current) {
          (tooltipRef.current as any).style.visibility = "hidden";
          d3.select(e.fromElement)
            .transition()
            .attr("d", arc)
            .attr("opacity", 1)
            .duration(200);
        }
      });

    if (chartSize.showLabel) {
      // legend
      svg
        .selectAll("legend")
        .data(dimensionValues)
        .enter()
        .append("circle")
        .attr("cx", legendSize.x + textSize * 0.5)
        .attr("cy", (d: any, i: any) => legendSize.y + i * textSize * 1.5)
        .attr("r", textSize * 0.5)
        .style(
          "fill",
          (d: any, i: any) => colors(data_ready[i].index as any) as string
        );

      svg
        .selectAll("legend")
        .data(dimensionValues)
        .enter()
        .append("text")
        .style("font-size", textSize)
        .style("dominant-baseline", "middle")
        .attr("x", legendSize.x + textSize * 1.5)
        .attr(
          "y",
          (d: any, i: any) => legendSize.y + i * textSize * 1.5 + textSize * 0.1
        )
        .text((d: any, i: any) => d);

      const labelPositions = data_ready
        .sort((a, b) => a.index - b.index)
        .reduce((ret: any, d: any, index) => {
          let posB = d3
            .arc()
            .innerRadius(chartSize.radius - 1.5)
            .outerRadius(chartSize.radius - 1.5 + textSize)
            .centroid(d);
          let point = [posB[0], posB[1], posB[1]];
          if (
            index > 0 &&
            Math.abs(posB[1] - ret[index - 1][2]) < textSize * 1.2
          ) {
            point[1] =
              ret[index - 1][1] +
              (posB[1] - ret[index - 1][2] > 0 ? 1 : -1) * textSize * 1.2;
          }
          ret.push(point);
          return ret;
        }, [])
        .map((item: any) => [item[0], item[1]]);

      chartSection
        .selectAll("allPolylines")
        .data(data_ready)
        .join("polyline")
        .attr("stroke", (d: any, i: any) => colors(d.index as any) as string)
        .style("fill", "none")
        .attr("stroke-width", 1)
        // @ts-ignore
        .attr("points", (d: any, i: number) => {
          const midangle = (d.startAngle + d.endAngle) / 2;
          let posA = d3
            .arc()
            .innerRadius(chartSize.radius - 1.5)
            .outerRadius(chartSize.radius - 1.5)
            .centroid(d);
          let posB = labelPositions[i],
            posC = [...labelPositions[i]];
          posC[0] = chartSize.radius * (midangle < Math.PI ? 1 : -1);
          return [posA, posB, posC];
        });

      chartSection
        .selectAll("allLabels")
        .data(data_ready)
        .join("text")
        .text((d: any) => formatData(d.data))
        .attr("transform", (d: any, i: number) => {
          const pos = [...labelPositions[i]];
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] =
            (chartSize.radius + textSize * 0.5) * (midangle < Math.PI ? 1 : -1);
          return `translate(${pos})`;
        })
        .style("font-size", textSize)
        .style("dominant-baseline", "middle")
        .style("text-anchor", (d: any) => {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midangle < Math.PI ? "start" : "end";
        });
    }
  }, [props, textSize]);

  useEffect(() => {
    const chartElem = document.getElementById(`pie-chart-${props.id}`);
    if (!!chartElem) {
      chartElem.innerHTML = "";
    } else {
      return;
    }
    if (
      (props.chart_view?.chart_config?.count_by === "count_a_field" &&
        !props.chart_view?.chart_config?.value) ||
      !props.chart_view?.chart_config?.count_by ||
      !props.chart_view?.chart_config?.dimension ||
      !props.table ||
      !props.table?.filtered_data
    ) {
      return;
    }
    drawChart();
  }, [props, drawChart, windowSize]);

  return (
    <>
      <div
        id={`pie-chart-${props.id}`}
        style={{ width: "100%", height: "100%", maxHeight: "" }}
      ></div>
      <div
        ref={tooltipRef as any}
        style={{
          position: "fixed",
          background: "white",
          border: "1px solid #f0f0f0",
          boxShadow: "1px 1px 7px 1px rgba(0,0,0,0.1)",
          padding: `${textSize / 2}px ${textSize}px`,
          visibility: "hidden",
          zIndex: 99999,
          fontSize: textSize,
        }}
      />
    </>
  );
};

export default PieChart;

export const PieChartConfigForm = (props: {
  chart_view?: ChartView;
  fields: TableField[];
  form: any;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("chart_view.config.pie_chart.dimension")}
        name={["chart_config", "dimension"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields?.map((item: any) => ({
            label: item.name,
            value: item.identifier,
          }))}
        />
      </Form.Item>
      <Form.Item
        style={{
          marginBottom:
            !props.form ||
            props.form.getFieldValue("chart_config")?.count_by ===
              "count_records"
              ? 24
              : 0,
        }}
        label={t("chart_view.config.pie_chart.value")}
        name={["chart_config", "count_by"]}
      >
        <Radio.Group>
          <Radio value={"count_records"}>{t("chart_view.count_records")}</Radio>
          <Radio value={"count_a_field"}>{t("chart_view.count_a_field")}</Radio>
        </Radio.Group>
      </Form.Item>
      <div
        style={{
          display:
            !props.form ||
            props.form.getFieldValue("chart_config")?.count_by ===
              "count_records"
              ? "none"
              : "flex",
        }}
      >
        <Form.Item
          style={{ flex: 1 }}
          name={["chart_config", "value"]}
          rules={[
            {
              required:
                props.form.getFieldValue("chart_config")?.count_by !==
                "count_records",
              message: t("chart_view.select_filed"),
            },
          ]}
        >
          <Select
            options={props.fields
              ?.filter((item: any) => item.type === "NUMBER")
              ?.map((item: any) => ({
                label: item.name,
                value: item.identifier,
              }))}
          />
        </Form.Item>
        <div style={{ width: 10 }}></div>
        <Form.Item
          style={{ flex: 1 }}
          initialValue="sum"
          name={["chart_config", "operation"]}
        >
          <Select
            options={[
              {
                label: t("chart_view.sum"),
                value: "sum",
              },
              {
                label: t("chart_view.min"),
                value: "min",
              },
              {
                label: t("chart_view.max"),
                value: "max",
              },
              {
                label: t("chart_view.average"),
                value: "average",
              },
            ]}
          />
        </Form.Item>
      </div>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{ padding: 0, background: "white" }}
      >
        <Panel
          header={t("chart_view.more_settings")}
          key="more_settings"
          forceRender
        >
          <Form.Item
            label={t("chart_view.config.pie_chart.text_size")}
            name={["chart_config", "text_size"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.color_picker")}
            name={["chart_config", "color"]}
          >
            <Select
              dropdownAlign={{
                points: ["cl", "cr"],
              }}
              optionLabelProp="label"
            >
              <div style={{ pointerEvents: "none" }}>
                {t("chart_view.multi_color_theme")}
              </div>
              {colorTheme
                .filter((item) => item.value.startsWith("theme"))
                .map((item) => {
                  return (
                    <Option
                      value={`${item.value}`}
                      label={t(`chart_view.${item.value}`)}
                    >
                      {item.colors.map((color) => {
                        return (
                          <div
                            style={{
                              display: "inline-block",
                              width: "10%",
                              height: "30px",
                              background: color,
                            }}
                          />
                        );
                      })}
                    </Option>
                  );
                })}
              <div style={{ pointerEvents: "none" }}>
                {t("chart_view.monochrome_gradient_theme")}
              </div>
              {colorTheme
                .filter((item) => !item.value.startsWith("theme"))
                .map((item) => {
                  return (
                    <Option
                      value={`${item.value}`}
                      label={t(`common.${item.value}`)}
                    >
                      {item.colors.map((color) => {
                        return (
                          <div
                            style={{
                              display: "inline-block",
                              width: "10%",
                              height: "30px",
                              background: color,
                            }}
                          />
                        );
                      })}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Panel>
      </Collapse>
    </>
  );
};
