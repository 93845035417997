import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import {
  DatasetTemplateTable,
  DatasetTemplateTableField,
} from "../../../types/DatasetTemplate";
import {
  fieldTypeToTitle,
  TableFieldType,
  TableFilters,
} from "../../../types/Table";
import {
  Modal,
  Select,
  Table,
  Space,
  Tag,
  Tooltip,
  Button,
  message,
} from "antd";
import { InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DatasetTemplateTableFilterModal from "./DatasetTemplateTableFilterModal";

const DatasetTemplateTableViewModal = forwardRef(
  (
    props: {
      submitCallback?: (data: DatasetTemplateTable) => void;
    },
    ref
  ) => {
    const [showItem, setShowItem] = useState<
      DatasetTemplateTable | undefined
    >();
    const [referenceTables, setReferencedTables] = useState<
      DatasetTemplateTable[]
    >([]);

    const tableFilterModalRef: any = useRef();

    useImperativeHandle(ref, () => ({
      show: (
        table: DatasetTemplateTable,
        referenceTables: DatasetTemplateTable[]
      ) => {
        setShowItem(table);
        setReferencedTables(referenceTables);
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleTableChange = (key: string) => {
      setShowItem((table) => ({
        temp_id: table?.temp_id,
        parent_table: key,
        columns: [],
        filters: {},
      }));
    };

    const handleAddFilters = () => {
      tableFilterModalRef.current &&
        tableFilterModalRef.current.show(
          showItem?.filters || {},
          referenceTables.find(
            (t: DatasetTemplateTable) => t.temp_id === showItem?.parent_table
          )?.fields || []
        );
    };

    const handleFiltersInfoSubmit = (filters: TableFilters) => {
      setShowItem((data) => ({
        ...data,
        filters: filters,
      }));
      tableFilterModalRef.current && tableFilterModalRef.current.close();
    };

    const handleSubmit = () => {
      if (
        !!showItem?.parent_table &&
        !!showItem?.columns &&
        showItem?.columns?.length > 0
      ) {
        props.submitCallback &&
          props.submitCallback({
            ...showItem,
            filters: showItem?.filters || {},
          });
      } else {
        message.error("请选择表和字段");
      }
    };

    return (
      <>
        <DatasetTemplateTableFilterModal
          ref={tableFilterModalRef}
          submitCallback={handleFiltersInfoSubmit}
        />
        <Modal
          centered
          width={1000}
          title={"基础数据"}
          open={!!showItem}
          onCancel={handleCancel}
          onOk={handleSubmit}
        >
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "1.5em" }}
            placeholder={"请选择基础数据表"}
            options={(referenceTables || []).map((table) => ({
              label: `${table.name?.zh}(${table.name?.en})`,
              value: table.temp_id,
            }))}
            value={showItem?.parent_table}
            onChange={handleTableChange}
          />
          <Table
            size="small"
            rowKey={(r) => r?.temp_id || ""}
            scroll={{ x: 400, scrollToFirstRowOnChange: true }}
            pagination={false}
            columns={[
              {
                key: "name",
                title: "字段名称",
                render: (d: DatasetTemplateTableField) => (
                  <Space
                    size={[5, 0]}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {d.name?.zh}
                    {!!d.readonly && <Tag color="red">只读</Tag>}
                  </Space>
                ),
              },
              {
                key: "identifier",
                title: "字段标识",
                dataIndex: "identifier",
              },
              {
                key: "type",
                title: "字段类型",
                render: (d: DatasetTemplateTableField) => {
                  if (d?.type === "REFERENCE") {
                    const fk_table = referenceTables?.find(
                      (table: DatasetTemplateTable) => table.temp_id === d.fk_to
                    );
                    return (
                      <Space>
                        {fieldTypeToTitle[d?.type as TableFieldType]}
                        <Tooltip
                          title={
                            <>
                              <p>
                                引用表：
                                {fk_table?.name?.zh || fk_table?.name?.en}
                              </p>
                              <p>
                                引用字段：
                                {(
                                  fk_table?.fields?.find(
                                    (field: DatasetTemplateTableField) =>
                                      field.temp_id === d?.fk_field
                                  ) || {}
                                ).name?.zh || d?.fk_field}
                              </p>
                              <p>
                                引用字段显示值：
                                {(
                                  fk_table?.fields?.find(
                                    (field: DatasetTemplateTableField) =>
                                      field.temp_id === d?.fk_literal
                                  ) || {}
                                ).name?.zh || d?.fk_literal}
                              </p>
                            </>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Space>
                    );
                  } else {
                    return fieldTypeToTitle[d?.type as TableFieldType];
                  }
                },
              },
              {
                title: "是否主键",
                dataIndex: "is_pk",
                key: "is_pk",
                render: (record: any) => (record ? "是" : "否"),
              },
              {
                title: "是否唯一",
                dataIndex: "is_unique",
                key: "is_unique",
                render: (record: any) => (record ? "是" : "否"),
              },
              {
                title: "是否允许空值",
                dataIndex: "is_nullable",
                key: "is_nullable",
                render: (record: any) => (record ? "是" : "否"),
              },
            ]}
            dataSource={
              referenceTables.find(
                (t: DatasetTemplateTable) =>
                  t.temp_id === showItem?.parent_table
              )?.fields || []
            }
            rowSelection={{
              selectedRowKeys: showItem?.columns || [],
              onChange: (selectedRowKeys: React.Key[]) => {
                setShowItem((data) => ({
                  ...data,
                  columns: selectedRowKeys as string[],
                }));
              },
              columnWidth: 50,
              fixed: true,
            }}
          />
          {!!showItem?.parent_table && (
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={handleAddFilters}
              style={{ paddingLeft: 17, marginTop: "1.5em" }}
            >
              {showItem.filters &&
              showItem.filters.items &&
              showItem.filters.items.length > 0
                ? `${showItem.filters.items.length} 个筛选条件`
                : "添加筛选条件"}
            </Button>
          )}
        </Modal>
      </>
    );
  }
);

export default DatasetTemplateTableViewModal;
