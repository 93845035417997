import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Table as TableType,
  TableView,
  TableType as TableTypeEnum,
  TableFilters,
} from "../types/Table";
import { Modal, Select, message, Button } from "antd";
import TableDataFilterSheet from "./TableDataFilterSheet";
import TableFieldsList from "../components/TableFieldsList";
import { getTableListApi } from "../api/TableApi";

type TableViewFormData = {
  selectedTable?: TableType;
  selectedFields?: string[];
  filters?: TableFilters;
};

const TableViewFormModal = forwardRef(
  (
    props: {
      submitCallback?: (data: TableView) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [basetables, setBasetables] = useState<TableType[]>([]);
    const [showItem, setShowItem] = useState<TableView | undefined>();
    const [viewData, setViewData] = useState<TableViewFormData>({});
    const tableDataFilterSheetRef: any = useRef();

    useEffect(() => {
      getTableListApi({
        tableType: TableTypeEnum.PRIMARY,
        page_size: 1000,
      }).then((res) => {
        if (res.success) {
          setBasetables(res.data);
        }
      });
    }, []);

    useEffect(() => {
      if (!!showItem?.parent_table) {
        tableDataFilterSheetRef.current &&
          tableDataFilterSheetRef.current?.resetTable(showItem);
        setViewData({
          selectedTable: basetables.find(
            (table) => table.id === showItem.parent_table
          ),
          selectedFields: showItem?.columns || [],
          filters: showItem?.filters,
        });
      } else {
        setViewData({});
      }
      // eslint-disable-next-line
    }, [showItem]);

    useImperativeHandle(ref, () => ({
      show: (item: TableView) => {
        setShowItem(item);
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleTableChange = (key: number) => {
      setViewData({
        selectedTable: basetables.find((table) => table.id === key),
      });
    };

    const handlePre = () => {
      setViewData((data) => ({
        ...data,
        filters: undefined,
      }));
    };

    const handleNext = () => {
      if (
        !viewData.selectedTable?.id ||
        !viewData.selectedFields ||
        viewData.selectedFields.length < 1
      ) {
        message.error(t("dataset.table_views.select_table_field"));
      } else {
        setViewData((data) => ({
          ...data,
          filters: {} as TableFilters,
        }));
        tableDataFilterSheetRef.current &&
          tableDataFilterSheetRef.current?.resetTable({
            parent_table: viewData.selectedTable?.id,
            columns: viewData.selectedFields,
            parent_table_data: viewData.selectedTable,
          } as TableView);
      }
    };

    const handleSubmit = () => {
      if (
        !viewData.selectedTable?.id ||
        !viewData.selectedFields ||
        viewData.selectedFields.length < 1
      ) {
        message.error(t("dataset.table_views.select_table_field"));
      } else {
        props.submitCallback &&
          props.submitCallback({
            ...showItem,
            parent_table: viewData.selectedTable?.id,
            parent_table_data: viewData.selectedTable,
            columns: viewData.selectedFields,
            filters: viewData.filters,
          });
      }
    };

    const tableDataFilterSheet = useMemo(
      () => (
        <TableDataFilterSheet
          ref={tableDataFilterSheetRef}
          id="table-view-filter-form"
          submitCallback={(filters: TableFilters) => {
            setViewData((data) => ({
              ...data,
              filters: filters,
            }));
            tableDataFilterSheetRef.current &&
              tableDataFilterSheetRef.current?.filter(filters);
          }}
        />
      ),
      [setViewData]
    );

    return (
      <Modal
        centered
        width={1000}
        title={t("table.title")}
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t("common.cancel")}
          </Button>,
          ...(!!viewData?.filters
            ? [
                <Button key="pre" onClick={handlePre}>
                  {t("common.step.previous")}
                </Button>,
              ]
            : []),
          <Button
            key="submit"
            type="primary"
            onClick={!viewData?.filters ? handleNext : handleSubmit}
          >
            {!!viewData?.filters
              ? !!showItem?.parent_table
                ? t("common.save")
                : t("common.add")
              : t("common.step.next")}
          </Button>,
        ]}
      >
        <div style={{ display: !!viewData?.filters ? "block" : "none" }}>
          {tableDataFilterSheet}
        </div>
        {!viewData?.filters && (
          <>
            <Select
              showSearch
              style={{ width: "100%", marginBottom: "1em" }}
              placeholder={t("dataset.table_views.select_table")}
              options={(basetables || []).map((table) => ({
                label: `${table.name}`,
                value: table.id,
              }))}
              value={viewData.selectedTable?.id}
              onChange={handleTableChange}
            />
            <TableFieldsList
              fields={viewData.selectedTable?.meta?.fields}
              rowSelection={{
                selectedRowKeys: viewData?.selectedFields || [],
                onChange: (selectedRowKeys: string[]) => {
                  setViewData((data) => ({
                    ...data,
                    selectedFields: selectedRowKeys,
                  }));
                },
                columnWidth: 50,
                fixed: true,
              }}
            />
          </>
        )}
      </Modal>
    );
  }
);

export default TableViewFormModal;
