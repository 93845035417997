// @ts-nocheck
import React, { useEffect, useCallback } from "react";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import ChinaGeoJson from "../../../common/data/ChinaGeoJson";
import { Form, Select, InputNumber, Collapse, Button } from "antd";
import { ChartView } from "../../../types/ChartView";
import useWindowSize from "../../../utils/useWindowSize";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

type Props = {
  id: string;
  chart_view: any;
  table_data: any;
};

const D3Heatmap = (props: Props) => {
  const windowSize = useWindowSize({ id: `d3-heatmap-${props.id}` });
  const MapLineWidth = 1;
  const MapLableTextSize = 8;

  const drawChart = useCallback(() => {
    const svg = d3
      .select(`#d3-heatmap-${props.id}`)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%");
    const zoom = d3
      .zoom()
      .scaleExtent([1, 8])
      .on("zoom", (e) => {
        const { transform } = e;
        g.attr("transform", transform);
        mapG.attr("stroke-width", MapLineWidth / transform.k);
        labelG.style("font-size", MapLableTextSize / transform.k);
      });
    const g = svg.append("g");
    let rootSize = {
      width: svg.node().getBoundingClientRect().width,
      height: svg.node().getBoundingClientRect().height,
    };
    const projection = d3.geoMercator().fitExtent(
      [
        [0, 0],
        [rootSize.width, rootSize.height],
      ],
      ChinaGeoJson
    );
    const pathGenerator = d3.geoPath().projection(projection);
    const mapG = g
      .append("g")
      .attr("stroke-width", MapLineWidth)
      .selectAll("path")
      .data(ChinaGeoJson.features)
      .join("path")
      .attr("d", pathGenerator)
      .attr("stroke-width", 0.5)
      .attr("stroke", "#666666")
      .attr("fill", "#ffffff");

    const labelG = g
      .append("g")
      .style("font-size", MapLableTextSize)
      .selectAll("text")
      .data(ChinaGeoJson.features)
      .join("text")
      .style("fill", "rgba(0,0,0,0.65)")
      .style("text-anchor", "middle")
      .style("dominant-baseline", "middle")
      .attr("x", (d) => d3.geoPath().projection(projection).centroid(d)[0])
      .attr("y", (d) => d3.geoPath().projection(projection).centroid(d)[1])
      .text((d) => d.properties.name);

    let geoData = [];
    for (
      let i = 0, len = props.table_data?.filtered_data?.length || 0;
      i < len;
      i++
    ) {
      let rowData = (props.table_data?.filtered_data || [])[i];
      const location = [
        rowData[props.chart_view?.chart_config?.longitude],
        rowData[props.chart_view?.chart_config?.latitude],
      ];
      const point = projection(location);
      geoData.push({
        x: point[0],
        y: point[1],
        location: location,
        value: rowData[props.chart_view?.chart_config?.value],
      });
    }
    let configValueRange = [
      Math.max(props.chart_view?.chart_config?.min || 0),
      props.chart_view?.chart_config?.max,
    ];
    if (
      !!configValueRange[0] &&
      !!configValueRange[1] &&
      configValueRange[0] > configValueRange[1]
    ) {
      configValueRange = [configValueRange[1], configValueRange[0]];
    }
    const valueRange = [
      configValueRange[0],
      !!configValueRange[1]
        ? Math.min(
            configValueRange[1],
            d3.max(geoData, (d) => d.value)
          )
        : d3.max(geoData, (d) => d.value),
    ];
    const colorScale = d3.scaleLinear(valueRange, [0.1, 0.8]);
    const radiusScale = d3.scaleLinear(valueRange, [0, rootSize.width * 0.03]);
    g.selectAll("circle")
      .data(geoData)
      .join("circle")
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .attr("r", (d) => radiusScale(d.value))
      .attr("fill", (d) => `rgba(255,0,0,${colorScale(d.value)})`);

    d3.select(`#d3-heatmap-${props.id}-zoomin`).on("click", (e) => {
      zoom.scaleBy(svg, 1.6);
    });
    d3.select(`#d3-heatmap-${props.id}-zoomout`).on("click", (e) => {
      zoom.scaleBy(svg, 0.625);
    });

    svg.call(zoom);
  }, [props]);

  useEffect(() => {
    document.getElementById(`d3-heatmap-${props.id}`).innerHTML = "";
    if (
      !props.chart_view?.chart_config?.latitude ||
      !props.chart_view?.chart_config?.longitude ||
      !props.chart_view?.chart_config?.value ||
      !props.table_data ||
      !props.table_data?.filtered_data
    ) {
      return;
    }
    drawChart();
  }, [props, drawChart, windowSize]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        id={`d3-heatmap-${props.id}`}
        style={{ width: "100%", height: "100%" }}
      ></div>
      <div
        style={{
          position: "absolute",
          background: "white",
          border: "1px solid #f0f0f0",
          boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.2)",
          bottom: 10,
          right: 10,
          display: "flex",
          flexDirection: "column",
          borderRadius: 4,
        }}
      >
        <Button
          id={`d3-heatmap-${props.id}-zoomin`}
          type="text"
          style={{ borderRadius: "4px 4px 0 0" }}
          icon={<PlusOutlined />}
        />
        <div style={{ width: "100%", height: 1, background: "#f0f0f0" }} />
        <Button
          id={`d3-heatmap-${props.id}-zoomout`}
          type="text"
          style={{ borderRadius: "0 0 4px 4px" }}
          icon={<MinusOutlined />}
        />
      </div>
    </div>
  );
};

export default D3Heatmap;

export const D3HeatmapConfigForm = (props: {
  chart_view?: ChartView;
  fields: any;
  form: any;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.longitude")}
        name={["chart_config", "longitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.latitude")}
        name={["chart_config", "latitude"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Form.Item
        label={t("chart_view.config.gaode_heatmap.value")}
        name={["chart_config", "value"]}
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={props.fields
            ?.filter((item: any) => item.type === "NUMBER")
            ?.map((item) => ({
              label: item.name,
              value: item.identifier,
            }))}
        />
      </Form.Item>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        style={{ padding: 0, background: "white" }}
      >
        <Panel
          header={t("chart_view.more_settings")}
          key="more_settings"
          forceRender
        >
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.min")}
            name={["chart_config", "min"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("chart_view.config.gaode_heatmap.max")}
            name={["chart_config", "max"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Panel>
      </Collapse>
    </>
  );
};
