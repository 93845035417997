import React from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { LoadingView } from "./components/GlobalLoading";
import CommonErrorView from "./components/CommonErrorView";

import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import "moment/locale/zh-cn";
import "dayjs/locale/zh-cn";

import useInitApp from "./utils/useInitApp";

import ManageLayout from "./components/layouts/ManageLayout";

import DatasetPage from "./pages/Dataset";
import DatasetInfoPage from "./pages/Dataset/DatasetInfoPage";
import DatasetDataPage from "./pages/Dataset/DatasetDataPage";
import DatasetVersionComparePage from "./pages/Dataset/DatasetVersionComparePage";
import TablePage from "./pages/Table";
import TableInfoPage from "./pages/Table/TableInfoPage";
import TableDataPage from "./pages/Table/TableDataPage";
import WorkspaceManagePage from "./pages/Settings/WorkspaceManagePage";
import NotificationPage from "./pages/NotificationPage";
import SalesReportPage from "./pages/SalesReport";
import usePermission from "./utils/usePermission";

import SystemManage from "./pages/SystemManage/index";
import DashboardPage from "./pages/Dashboard/DashboardPage";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale("zh-cn");

const ThemeConfig = {
  token: {
    colorPrimary: "#2A61FF",
    colorLink: "#2A61FF",
    colorError: "#FF4D4F",
    colorSuccess: "#52C41A",
    colorTextBase: "rgba(0,0,0,0.85)",
    colorWarning: "#FAAD14",
    borderRadius: 4,
    colorPrimaryHover: "#638bff",
    colorLinkHover: "#638BFF",
  },
};

const AdminRoute = (props: any) => {
  const permission = usePermission();
  let navigate = useNavigate();

  React.useEffect(() => {
    if (permission.isAdmin === false) {
      navigate("/");
    }
  }, [permission]);

  return permission.isAdmin ? props.children : null;
};

const PageRoutes = () => {
  return (
    <ManageLayout>
      <Routes>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="/" element={<DatasetPage />} />
        <Route path="dataset" element={<DatasetPage />} />
        <Route path="dataset/create" element={<DatasetInfoPage />} />
        <Route
          path="dataset/create/:dataset_id"
          element={<DatasetInfoPage />}
        />
        <Route path="dataset/info/:dataset_id" element={<DatasetInfoPage />} />
        <Route path="dataset/data/:dataset_id" element={<DatasetDataPage />} />
        <Route
          path="dataset/:dataset_id/compare/:versions/:tables"
          element={<DatasetVersionComparePage />}
        />

        {/*simple page*/}
        <Route path="simple/dataset" element={<DatasetPage />} />
        <Route path="simple/dataset/create" element={<DatasetInfoPage />} />
        <Route
          path="simple/dataset/create/:dataset_id"
          element={<DatasetInfoPage />}
        />
        <Route
          path="simple/dataset/info/:dataset_id"
          element={<DatasetInfoPage />}
        />
        <Route
          path="simple/dataset/data/:dataset_id"
          element={<DatasetDataPage />}
        />
        <Route
          path="simple/dataset/:dataset_id/compare/:versions/:tables"
          element={<DatasetVersionComparePage />}
        />

        <Route path="table" element={<TablePage />} />
        <Route path="table/create" element={<TableInfoPage />} />
        <Route path="table/info/:table_id" element={<TableInfoPage />} />
        <Route path="table/create/:table_id" element={<TableInfoPage />} />
        <Route path="table/data/:table_id" element={<TableDataPage />} />

        {/*simple table page*/}
        <Route path="simple/table" element={<TablePage />} />
        <Route path="simple/table/create" element={<TableInfoPage />} />
        <Route
          path="simple/table/create/:table_id"
          element={<TableInfoPage />}
        />
        <Route path="simple/table/info/:table_id" element={<TableInfoPage />} />
        <Route path="simple/table/data/:table_id" element={<TableDataPage />} />
        <Route
          path="settings/workspace"
          element={
            <AdminRoute>
              <WorkspaceManagePage />
            </AdminRoute>
          }
        />
        <Route path="notification" element={<NotificationPage />} />
        <Route path="sales_report" element={<SalesReportPage />} />
      </Routes>
    </ManageLayout>
  );
};

function App() {
  const { i18n } = useTranslation();
  const initAppStatus = useInitApp();

  if (initAppStatus.status === "error") {
    return <CommonErrorView />;
  }

  if (initAppStatus.status === "loading") {
    return <LoadingView />;
  }

  return (
    <ConfigProvider
      theme={ThemeConfig}
      locale={i18n.resolvedLanguage === "zh" ? zhCN : enUS}
    >
      <BrowserRouter>
        <Routes>
          <Route path="system_manage/*" element={<SystemManage />} />
          <Route path="*" element={<PageRoutes />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
