import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, ConfigProvider } from "antd";
import usePermission from "../../../utils/usePermission";
import IconMenuTable from "../../../common/images/icons/menu-table";
import IconMenuDataset from "../../../common/images/icons/menu-dataset";
import IconMenuSetting from "../../../common/images/icons/menu-setting";
import IconNotification from "../../../common/images/icons/notification";
import { DashboardOutlined, TableOutlined } from "@ant-design/icons";

const NavMenu = (props: { clickCallback?: () => void }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const permission = usePermission();

  type MenuItem = {
    label: React.ReactNode;
    key: string;
    selected?: boolean;
    icon?: React.ReactNode;
    children?: MenuItem[];
  };

  const MenuList: MenuItem[] = [
    {
      label: <NavLink to={`/dashboard`}>{t("nav.dashboard")}</NavLink>,
      key: `dashboard`,
      selected: location.pathname === "/dashboard",
      icon: <DashboardOutlined />,
    },
    {
      label: <NavLink to={`/dataset`}>{t("nav.dataset")}</NavLink>,
      key: `dataset`,
      selected:
        location.pathname.startsWith("/dataset") || location.pathname === "/",
      icon: <IconMenuDataset />,
    },
    {
      label: <NavLink to={`/table`}>{t("nav.table")}</NavLink>,
      key: `table`,
      selected: location.pathname.startsWith("/table"),
      icon: <IconMenuTable />,
    },
    ...(location.pathname.startsWith("/sales_report")
      ? [
          {
            label: <NavLink to={`/sales_report`}>销售提报</NavLink>,
            key: `sales_report`,
            selected: true,
            icon: <TableOutlined />,
          },
        ]
      : []),
    {
      label: <NavLink to={`/notification`}>{t("nav.notification")}</NavLink>,
      key: `notification`,
      selected: location.pathname.startsWith("/notification"),
      icon: <IconNotification />,
    },
  ];

  if (permission.isAdmin) {
    MenuList.splice(3, 0, {
      label: <div>{t("nav.settings")}</div>,
      key: `settings`,
      icon: <IconMenuSetting />,
      children: [
        {
          label: (
            <NavLink to={`/settings/workspace`}>
              {t("nav.workspace_manage")}
            </NavLink>
          ),
          key: "settings.workspace",
          selected: location.pathname.startsWith("/settings/workspace"),
        },
      ],
    });
  }

  const defaultActiveKey = MenuList.reduce((ret, item) => {
    let active: any = item;
    if (!!item.children) {
      active = item.children.find(
        (subitem) => !!subitem.selected && subitem.label
      );
    }
    if (!!active && !!active.selected) {
      ret = active.key;
    }
    return ret;
  }, "dataset");

  const [openKeys, setOpenKeys] = useState<string[]>(
    MenuList.filter(
      (item) => !!item?.children?.find((subItem) => !!subItem.selected)
    ).map((item) => String(item.key))
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemBg: "transparent",
            darkItemSelectedBg: "#537BE9",
            darkItemHoverBg: "#537BE9",
            darkSubMenuItemBg: "rgba(0,0,0,0.8)",
            itemMarginInline: 10,
            itemHeight: 46,
            darkItemColor: "#FFFFFF",
            iconSize: 20,
            collapsedIconSize: 18,
            iconMarginInlineEnd: 20,
          },
        },
      }}
    >
      <Menu
        theme="dark"
        mode="inline"
        className="sidebar-menu"
        items={MenuList}
        openKeys={openKeys}
        onOpenChange={(openKeys: string[]) => setOpenKeys(openKeys)}
        onClick={(e) => {
          if (e.key?.split(".")?.length < 2) {
            setOpenKeys([]);
          }
          props.clickCallback && props.clickCallback();
        }}
        selectedKeys={[defaultActiveKey]}
      ></Menu>
    </ConfigProvider>
  );
};

export default NavMenu;
