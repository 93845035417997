import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "antd";
import { Dataset } from "../types/Dataset";
import { useTranslation } from "react-i18next";
import { getFlowInstancesApi } from "../api/IntegrationsApis";

const SelectInstancesModal = ({
  dataset,
  tables,
  handleResult,
}: {
  dataset?: Dataset;
  tables?: any[];
  handleResult: (data: any[]) => void;
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [instances, setInstances] = useState<any[]>([]);
  const [selectedInstances, setSelectedInstances] = useState<any[]>([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (dataset && (dataset.action_config || dataset.push_config)) {
      let config = dataset.action_config || dataset.push_config;
      const flowApiToken = config.flow_api_token;
      const flowWorkspaceId = config.flow_workspace_id;
      const flowAppId = config.flow_app_id;
      getFlowInstancesApi({
        flowApiToken: flowApiToken,
        flowAppId: flowAppId,
        flowWorkspaceId: flowWorkspaceId,
        page: pagination.page,
        pageSize: pagination.pageSize,
      }).then((res) => {
        setInstances(res.data);
        setTotal(res.pagination?.count ?? 0);
      });
    }
  }, [dataset, pagination.page, pagination.pageSize]);

  const handleOk = () => {
    let instancesData = [];
    let tableIdentifier;
    let validTables = [];

    if (tables) {
      for (let table of tables) {
        const identifiers = ["id", "name"];
        const hasIdentifiers = identifiers.every((identifier) =>
          table.meta.fields.some(
            (field: { identifier: string }) => field.identifier === identifier
          )
        );
        if (hasIdentifiers) {
          validTables.push(table);
        }
      }

      if (validTables.length === 1) {
        tableIdentifier = validTables[0].identifier;
      } else {
        const flowInstanceTable = validTables.find(
          (table) => table.meta.name === "流量流向实例"
        );
        if (flowInstanceTable) {
          tableIdentifier = flowInstanceTable.identifier;
        } else {
          tableIdentifier = validTables[0].identifier;
        }
      }
    }

    for (let instance of selectedInstances) {
      instancesData.push({
        id: instance.id,
        name: instance.name,
      });
    }

    let data = [];
    data.push({
      table_identifier: tableIdentifier,
      data: instancesData,
    });
    handleResult(data);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: t("flow_instance.instance_id"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("flow_instance.instance_name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("flow_instance.instance_description"),
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        {t("flow_instance.get_instances_title")}
      </Button>
      <Modal
        title={t("flow_instance.select_instances_title")}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        width={1000}
      >
        <Table
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedInstances.map((instance) => instance.id),
            onChange: (selectedRowKeys, selectedRows) => {
              const newSelectedInstances = [
                ...selectedInstances.filter(
                  (instance) => !selectedRowKeys.includes(instance.id)
                ),
                ...selectedRows,
              ];
              setSelectedInstances(newSelectedInstances);
            },
          }}
          columns={columns}
          rowKey="id"
          dataSource={instances}
          pagination={{
            total: total,
            current: pagination.page,
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              setPagination({ page, pageSize });
            },
          }}
          size="small"
        />
      </Modal>
    </>
  );
};

export default SelectInstancesModal;
