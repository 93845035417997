import { API, responseData } from "./BaseApi";

export const getDashboardItemsApi = (data: {
  page?: number;
  page_size?: number;
}) => {
  const queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());

  return API.get<responseData, responseData>(
    `dashboard-items/?${queryParams.toString()}`
  );
};

export const addDashboardItemApi = (data: {
  dataset_version_chart_view_id: number;
  type: string;
}) => {
  return API.post<responseData, responseData>(`dashboard-items/`, data);
};

export const deleteDashboardItemApi = (data: { id: number }) => {
  return API.delete<responseData, responseData>(`dashboard-items/${data.id}/`);
};
