import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { reducer as accountReducer } from "./account";

const reducer = combineReducers({
  account: accountReducer,
});

const store = createStore(reducer, applyMiddleware(...[thunk]));

export default store;
