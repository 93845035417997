import { SheetTable, TableField } from "../types/Table";

export const validateEmptyFields = (
  table: SheetTable | undefined,
  data: any
) => {
  const missingFields: {
    row: number;
    field: TableField;
  }[] = [];
  for (let i = 0; i < data.length; i++) {
    const record = data[i];
    for (const key in record) {
      const field =
        table?.meta?.fields?.find((item) => {
          return item.identifier === key;
        }) || {};
      if (field.is_nullable) {
        continue;
      }
      const value = record[key];
      if (value === null || value === undefined || value === "") {
        missingFields.push({
          row: i,
          field: field,
        });
      }
    }
  }
  if (missingFields.length === 0) {
    return { status: true };
  } else {
    return { status: false, missingFields };
  }
};
