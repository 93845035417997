import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import { useNavigate } from "react-router-dom";
import TableForm from "../../components/TableForm";
import { useParams, useLocation } from "react-router-dom";
import {
  Table as TableType,
  TableField,
  TableType as TableTypeEnum,
} from "../../types/Table";
import { Spin, Empty, message } from "antd";
import {
  checkTableWorkspacePermissionApi,
  createTableApi,
  getTableApi,
  updateTableApi,
} from "../../api/TableApi";
import viewUtils from "../../utils/viewUtils";
import usePermission from "../../utils/usePermission";
import { showWorkspacePermissionModal } from "../../components/WorkspacePermissionModal";

const TableInfoPage = () => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const permission = usePermission();
  const navigate = useNavigate();
  const { table_id } = useParams();
  const location = useLocation();
  const [tabledata, setTabledata] = useState<TableType | undefined | null>();
  const IsCreate = location.pathname.includes("create");

  useEffect(() => {
    if (!!permission.username) {
      if (table_id) {
        getTableApi({ tableId: Number(table_id) }).then((res) => {
          if (res.success) {
            setTabledata({
              ...res.data,
              name: IsCreate ? undefined : res.data.name,
              id: IsCreate ? undefined : res.data.id,
              identifier: IsCreate ? undefined : res.data.identifier,
              meta: {
                ...res.data?.meta,
                fields: IsCreate
                  ? res.data?.meta?.fields?.map((field: TableField) => ({
                      ...field,
                      id: undefined,
                    }))
                  : res.data?.meta?.fields,
              },
            });
          } else {
            if (res.code === 404) {
              checkTableWorkspacePermissionApi({
                datasetId: Number(table_id),
              }).then((res) => {
                if (res.success) {
                  showWorkspacePermissionModal({
                    hasPermission: res.data.has_permission,
                    targetWorkspaceId: res.data.workspace_id,
                  });
                }
              });
            }
            setTabledata(null);
          }
        });
      } else {
        setTabledata({
          table_type: TableTypeEnum.PRIMARY,
        });
      }
    }
  }, [permission, table_id, IsCreate]);

  const handleUpdate = async (data: TableType) => {
    updateTableApi({ tableId: Number(table_id), data: data }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.update"),
            status: t("common.success"),
          })
        );
        navigate(`${IsSimpleLayout ? "/simple" : ""}/table`);
      } else {
        if (
          JSON.stringify(res.message).includes(
            "table is not empty, cannot change table meta"
          )
        ) {
          message.error(t("table.tips.change_field_fail"));
          return;
        }

        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const handleCreate = async (data: TableType) => {
    createTableApi(data).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.create"),
            status: t("common.success"),
          })
        );
        navigate(`${IsSimpleLayout ? "/simple" : ""}/table`);
      } else {
        let description = t("common.result_status", {
          title: t("common.create"),
          status: t("common.fail"),
        });
        if (
          JSON.stringify(res.message).includes("name must make a unique set")
        ) {
          description = t("table.tips.name_existed");
        } else if (
          JSON.stringify(res.message).includes("table_meta_id is not provided")
        ) {
          description = t("table.tips.fields_empty");
        }
        message.error(description);
      }
    });
  };

  return (
    <>
      <NavHyperLink
        showBack={true}
        items={[
          {
            title: t("table.title"),
            path: `${IsSimpleLayout ? "/simple" : ""}/table`,
          },
          {
            title: `${
              IsCreate
                ? table_id
                  ? t("common.copy")
                  : t("common.create")
                : t("common.edit")
            }`,
          },
        ]}
      />
      <div className="layout-content-box" style={{ marginBottom: "80px" }}>
        {!!tabledata ? (
          <TableForm
            {...(table_id ? { initData: tabledata } : null)}
            submitCallback={table_id && !IsCreate ? handleUpdate : handleCreate}
            cancelCallback={() =>
              navigate(`${IsSimpleLayout ? "/simple" : ""}/table`)
            }
            needPrimaryKey={true}
            checkName={{ tableType: "PRIMARY" }}
            disabled={!!tabledata?.id && tabledata.role !== "ADMIN"}
            action_class="page-form-actions"
          />
        ) : (
          <div style={{ textAlign: "center", padding: "10vh 0" }}>
            {tabledata === null ? (
              <Empty description={t("common.no_permission")} />
            ) : (
              <Spin />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TableInfoPage;
