import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Descriptions, Empty, List, message, Modal, Spin } from "antd";
import { LogViewer, LogViewerSearch } from "@patternfly/react-log-viewer";
import viewUtils from "../utils/viewUtils";
import { useLocation } from "react-router-dom";
import { ProcessNode, StatusEnum } from "../types/Integrations";
import { getRunLogsApi } from "../api/IntegrationsApis";

const ExecutionLogs = (props: { run_id: number; onClose: () => void }) => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [processMainLogs, setProcessMainLogs] = useState<ProcessNode[]>();

  useEffect(() => {
    getRunLogsApi({
      run_id: props.run_id,
    }).then((res: any) => {
      if (res.status) {
        setProcessMainLogs(res.data);
      } else {
        message.error(t("dataset.action_record.logs.get_info_fail"));
      }
      setLoading(false); // Stop loading once data is fetched
    });
  }, [props, t]);

  const LogTable = () => {
    const nodeLogs = processMainLogs || [];
    const [search, setSearch] = useState("");
    const handleSearchChange = (key: string) => {
      setSearch(key);
    };
    const formatMainLog = (log?: string) => {
      const formatLog = (log || "").replace(
        /([^|\n].*ERROR.*(\n|$))/g,
        `\x1b[1;4;31m$1\x1b[0m`
      );
      const ret = !!search
        ? formatLog.replace(
            new RegExp(`(${search})`, "gim"),
            `\x1b[43m$1\x1b[47m`
          )
        : formatLog;
      return (ret || "") + new Array(3).fill("").join("\n");
    };
    return (
      <div>
        <List
          itemLayout={"horizontal"}
          dataSource={nodeLogs}
          renderItem={(item) => (
            <div>
              <div className="bg-white">
                <Descriptions bordered={true} column={1}>
                  <Descriptions.Item
                    label={t("dataset.action_record.logs.name")}
                  >
                    {item.displayName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("dataset.action_record.logs.id")}>
                    {item.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t("dataset.action_record.logs.status")}
                  >
                    {t(`${StatusEnum.get(item?.phase)}`)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t("dataset.action_record.logs.started_at")}
                  >
                    {viewUtils.utcToLocal(item?.startedAt)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t("dataset.action_record.logs.finished_at")}
                  >
                    {item?.finishedAt
                      ? viewUtils.utcToLocal(item.finishedAt)
                      : "-"}
                  </Descriptions.Item>
                </Descriptions>
              </div>
              <div className="mt-3 bg-white p-4">
                {!!item.main_log ? (
                  <LogViewer
                    data={formatMainLog(item.main_log)}
                    hasLineNumbers={true}
                    theme={"dark"}
                    isTextWrapped={false}
                    toolbar={
                      <LogViewerSearch
                        placeholder={t("common.keyword_search")}
                        minSearchChars={1}
                        className="log-search-form mb-3"
                        onChange={(_event, value) => handleSearchChange(value)}
                      />
                    }
                  />
                ) : (
                  <Empty description={t("common.empty")} className="my-5" />
                )}
              </div>
            </div>
          )}
        />
      </div>
    );
  };
  return (
    <Modal
      title={t("dataset.action_record.logs.title")}
      open={true}
      onCancel={props.onClose}
      footer={null}
      width={1000} // Adjust the width as needed
      centered // Ensure the modal is centered on the screen
    >
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px 0" }}>
          <Spin size="large" />
        </div>
      ) : (
        <LogTable />
      )}
    </Modal>
  );
};

export default ExecutionLogs;
