import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  SheetTable,
  Table as TableType,
  TableField,
  TableType as TableTypeEnum,
} from "../../types/Table";
import { Dataset } from "../../types/Dataset";
import {
  Button,
  Form,
  Input,
  Space,
  Modal,
  Radio,
  Select,
  InputNumber,
  message,
  Drawer,
} from "antd";
import TableFieldsList from "../../components/TableFieldsList";
import {
  updateDatasetTableApi,
  createDatasetTableApi,
} from "../../api/DatasetApi";
import viewUtils from "../../utils/viewUtils";

const TableFieldInfoModal = forwardRef(
  (
    props: {
      table_type: string;
      submitCallback: (data: TableField, closeModal: () => void) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<TableField | undefined>();
    const [tableFields, setTableFields] = useState<TableField[]>([]);
    const [form] = Form.useForm();
    const autoFillIdentifier = useRef(true);
    const isPk = Form.useWatch("is_pk", form);

    useEffect(() => {
      form.resetFields();
      form.setFieldsValue({
        is_pk: false,
        is_unique: false,
        is_nullable: true,
        is_filterable: false,
        readonly: false,
        ...showItem,
      });
      if (!!showItem?.identifier) {
        autoFillIdentifier.current = false;
      } else {
        autoFillIdentifier.current = true;
      }
    }, [showItem, form]);

    useEffect(() => {
      form.setFieldValue("is_nullable", !isPk);
    }, [isPk, form]);

    useImperativeHandle(ref, () => ({
      show: (item: TableField, fields: TableField[]) => {
        setTableFields(fields);
        setShowItem(item);
      },
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      let field: TableField = form.getFieldsValue();
      field = {
        ...field,
        name: field.name?.trim(),
      };
      props.submitCallback && props.submitCallback(field, handleCancel);
    };

    const checkValueSame = (key: string, value: string) => {
      return !tableFields.find(
        (item: TableField) =>
          item[key === "identifier" ? "identifier" : "name"] === value
      );
    };

    const handleValuesChange = (changedValues: any, allValues: any) => {
      try {
        if (Object.keys(changedValues).includes("name")) {
          if (!!autoFillIdentifier.current) {
            form.setFieldsValue({
              identifier: viewUtils.autoFormatIdentifier(
                changedValues?.name || ""
              ),
            });
          }
        }
        if (Object.keys(changedValues).includes("identifier")) {
          autoFillIdentifier.current = !changedValues?.identifier;
        }
      } catch (e) {}
    };

    return (
      <Modal
        centered
        title={
          showItem?.name
            ? t("table.field.edit_field")
            : t("table.field.add_field")
        }
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
      >
        <Form
          labelCol={{ span: 6 }}
          form={form}
          style={{ margin: "2em 0" }}
          onValuesChange={handleValuesChange}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="index_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="index" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={t("table.field.name")}
            rules={[
              {
                required: true,
                message: t("common.required", { title: t("table.field.name") }),
              },
              {
                max: 50,
                message: t("common.char_len_limit", {
                  title: t("table.field.name"),
                  count: 50,
                }),
              },
              {
                pattern:
                  /^[\u4e00-\u9fa5a-zA-Z0-9\s.,;!?'"\\/:[\]{}()-_=+*&^%$#@<>~|\uFF08-\uFF09]*$/,
                message: t("table.field.name_format"),
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || checkValueSame("name", value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("table.field.name_existed"))
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="identifier"
            label={t("table.field.identifier")}
            tooltip={t("table.field.identifier_info")}
            rules={[
              {
                required: true,
                message: t("table.field.identifier_format"),
              },
              {
                max: 64,
                message: t("common.char_len_limit", {
                  title: t("table.field.name"),
                  count: 64,
                }),
              },
              {
                pattern: /^[a-z][a-z_$0-9]{0,63}$/,
                message: t("table.field.identifier_format"),
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || checkValueSame("identifier", value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("table.field.identifier_existed"))
                  );
                },
              }),
            ]}
          >
            <Input disabled={!!showItem?.id} />
          </Form.Item>
          <Form.Item name="is_pk" label={t("table.field.is_pk")}>
            <Radio.Group disabled={!!showItem?.id}>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="is_unique" label={t("table.field.is_unique")}>
            <Radio.Group disabled={!!showItem?.id}>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="is_nullable" label={t("table.field.is_nullable")}>
            <Radio.Group disabled={isPk}>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="is_filterable" label={"是否筛选项"}>
            <Radio.Group>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="type"
            label={t("table.field.type")}
            rules={[
              {
                required: true,
                message: t("common.required", { title: t("table.field.type") }),
              },
            ]}
          >
            <Select disabled={!!showItem?.id}>
              <Select.Option value="TEXT">
                {t("table.field.text")}
              </Select.Option>
              <Select.Option value="NUMBER">
                {t("table.field.number")}
              </Select.Option>
              <Select.Option value="DATE">
                {t("table.field.date")}
              </Select.Option>
              <Select.Option value="BOOLEAN">
                {t("table.field.boolean")}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.type !== curValues?.type
            }
          >
            {() => {
              if (form.getFieldValue("type") !== "NUMBER") {
                return null;
              }
              return (
                <>
                  <Form.Item
                    name="validation_rules"
                    noStyle
                    rules={[
                      {
                        required: false,
                        message: t("table.field.rule"),
                      },
                    ]}
                  >
                    <Form.Item
                      name={["validation_rules", "minimum"]}
                      label={t("table.field.min")}
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: t("common.required", {
                            title: t("table.field.min"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber placeholder={t("table.field.min")} />
                    </Form.Item>
                    <Form.Item
                      name={["validation_rules", "maximum"]}
                      label={t("table.field.max")}
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: t("common.required", {
                            title: t("table.field.max"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber placeholder={t("table.field.max")} />
                    </Form.Item>
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

const TableForm = (props: {
  initData?: TableType;
  submitCallback?: (data: TableType) => void;
  cancelCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const tableFieldModalRef: any = useRef();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...props.initData,
      name: props.initData?.name || "销售提报",
      table_type: props.initData?.table_type || TableTypeEnum.COMMON,
      fields: props.initData?.meta?.fields || props.initData?.fields,
    });
  }, [props.initData, form]);

  const handleAddFiled = () => {
    tableFieldModalRef?.current &&
      tableFieldModalRef?.current.show({}, form.getFieldValue("fields") || []);
  };

  const handleDeleteField = (filed: TableField, index: number) => {
    const OriginFileds = form.getFieldValue("fields") || [];
    form.setFieldsValue({
      fields: OriginFileds.filter(
        (item: TableField, tindex: number) => tindex !== index
      ),
    });
  };

  const handleEditField = (filed: TableField, index: number) => {
    tableFieldModalRef?.current &&
      tableFieldModalRef?.current.show(
        {
          ...filed,
          index_id: index,
        },
        (form.getFieldValue("fields") || []).filter(
          (item: TableField, findex: number) => findex !== index
        )
      );
  };

  const handleCloneField = (filed: TableField) => {
    tableFieldModalRef?.current &&
      tableFieldModalRef?.current.show(
        {
          ...filed,
          id: undefined,
          name: undefined,
          identifier: undefined,
          index: undefined,
        },
        form.getFieldValue("fields") || []
      );
  };

  const handleTableFieldInfoSubmit = (
    data: TableField,
    closeModal: () => void
  ) => {
    const OriginFileds = form.getFieldValue("fields") || [];
    if (data?.index === undefined || data?.index === null) {
      data.index =
        Math.max(
          ...[
            -1,
            ...OriginFileds.filter(
              (f: TableField) => !!f.index || f.index === 0
            ).map((f: TableField) => f.index),
          ]
        ) + 1;
    }
    if (data?.index_id !== undefined && data?.index_id !== null) {
      form.setFieldsValue({
        fields: OriginFileds.map((item: TableField, tindex: number) =>
          data.index_id === tindex ? { ...data, index_id: undefined } : item
        ),
      });
    } else {
      form.setFieldsValue({
        fields: [...OriginFileds, { ...data, index_id: undefined }],
      });
    }
    closeModal();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const data = form.getFieldsValue();

    props.submitCallback && props.submitCallback(data as TableType);
  };

  const handleCancel = () => {
    props.cancelCallback && props.cancelCallback();
  };

  const checkPrimaryKey = async (fields: TableField[]) => {
    if (!fields.find((item: TableField) => !!item.is_pk)) {
      return false;
    }
    return true;
  };

  return (
    <>
      <TableFieldInfoModal
        ref={tableFieldModalRef}
        submitCallback={handleTableFieldInfoSubmit}
        table_type={props.initData?.table_type || TableTypeEnum.COMMON}
      />
      <Form
        form={form}
        labelAlign="right"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button type="primary" onClick={handleAddFiled}>
            新增字段
          </Button>
        </div>
        <Form.Item name="name" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="identifier" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="table_type" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="fields"
          rules={[
            { required: true, message: t("common.required", { title: "" }) },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (!value || (await checkPrimaryKey(value))) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("table.tips.pk_must")));
              },
            }),
          ]}
          className="hidden-item-with-rules"
        >
          <Input />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues?.fields !== curValues?.fields
          }
        >
          {() => {
            const fields = (form.getFieldValue("fields") || []) as TableField[];
            const handleFieldsChange = (fields: TableField[]) => {
              form.setFieldValue("fields", fields);
            };
            return (
              <TableFieldsList
                fields={fields}
                onFieldsChange={handleFieldsChange}
                showFilterField={true}
                actions={(d: TableType, index: number) => (
                  <>
                    <Button
                      size="small"
                      type="link"
                      onClick={() => handleEditField(d, index)}
                    >
                      {t("common.edit")}
                    </Button>
                    <Button
                      size="small"
                      type="link"
                      onClick={() => handleCloneField(d)}
                    >
                      {t("common.copy")}
                    </Button>
                    {!props.initData?.id && (
                      <Button
                        size="small"
                        type="link"
                        onClick={() => handleDeleteField(d, index)}
                      >
                        {t("common.delete")}
                      </Button>
                    )}
                  </>
                )}
              />
            );
          }}
        </Form.Item>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleCancel}>{t("common.back")}</Button>
          <Button type="primary" htmlType="submit">
            {t("common.save")}
          </Button>
        </Space>
      </Form>
    </>
  );
};

const TableFieldsManage = (props: {
  dataset?: Dataset | null;
  table?: SheetTable;
  refreshCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleCancel = () => setOpen(false);

  const handleSubmit = (table: TableType) => {
    if (!!table.id) {
      //update existed dataset table
      updateDatasetTableApi({
        datasetId: Number(props.dataset?.id),
        tableId: Number(table?.id),
        data: table,
      }).then((res) => {
        if (res.success) {
          message.success(
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.success"),
            })
          );
          handleCancel();
          !!props.refreshCallback && props.refreshCallback();
        } else {
          let description = t("common.result_status", {
            title: t("common.update"),
            status: t("common.fail"),
          });
          message.error(description);
        }
      });
    } else {
      //create existed dataset table
      createDatasetTableApi({
        datasetId: Number(props.dataset?.id),
        data: table,
      }).then((res) => {
        if (res.success) {
          message.success(
            t("common.result_status", {
              title: t("common.create"),
              status: t("common.success"),
            })
          );
          handleCancel();
          !!props.refreshCallback && props.refreshCallback();
        } else {
          let description = t("common.result_status", {
            title: t("common.create"),
            status: t("common.fail"),
          });
          message.error(description);
        }
      });
    }
  };

  return (
    <>
      <Drawer
        title={"字段设置"}
        placement="right"
        onClose={handleCancel}
        open={!!open}
        width={"max(50vw, 500px)"}
        destroyOnClose={true}
      >
        <TableForm
          initData={props.table}
          submitCallback={handleSubmit}
          cancelCallback={handleCancel}
        />
      </Drawer>
      <Button type="primary" onClick={() => setOpen(true)}>
        字段设置
      </Button>
    </>
  );
};

export default TableFieldsManage;
